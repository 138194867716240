// MANAGED BY App.js
import { useState, useEffect } from 'react'

import * as Routes from '../../routes'
import { useFetchDealership } from '../dataHooks'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useParams, Link } from 'react-router-dom'
import { CopyableText } from '../shared/CopyableText'
import ContactForm from './ContactForm'
import { formatPhone } from '../entries/utils'
import { DateRangePickerComponent } from '../leads/DateRangePicker'
import { truncateString } from '../entries/utils'

const Header = ({ dealershipId }) => {
  let [formVisible, setFormVisible] = useState(false)

  return (
    <div className="clearfix mb-2">
      <ContactForm visible={formVisible} setVisible={setFormVisible} />
      <h3>
        Listing contacts
        <div className="float-right">
          <a
            href={`/dealerships/${dealershipId}/contacts.csv`}
            className="btn btn-outline-primary mr-2"
          >
            <i className="fa fa-download" /> Export to CSV
          </a>
          <a
            href={Routes.upload_dealership_contacts_path(dealershipId)}
            className="btn btn-outline-secondary mr-2"
          >
            <i className="fa fa-upload" /> Upload CSV
          </a>
          <a
            onClick={(e) => {
              e.preventDefault()
              setFormVisible(true)
            }}
            className="btn btn-outline-success"
          >
            <i className="fa fa-plus mr-1" />
            Contact
          </a>
        </div>
      </h3>
    </div>
  )
}

const Name = (rowData) => {
  let { dealershipSlug } = useParams()
  return (
    <Link to={Routes.dealership_contact_path(dealershipSlug, rowData.id)}>
      {truncateString(rowData.full_name, 50)}
    </Link>
  )
}

const Email = (rowData) => {
  if (!rowData.email) {
    return <span className="text-secondary">No email</span>
  }

  return <CopyableText text={rowData.email} />
}

const PhoneNumber = (rowData) => {
  return <CopyableText text={formatPhone(rowData.phone_number)} />
}

const Actions = (rowData) => {
  let { dealershipSlug } = useParams()
  return (
    <>
      <Link
        to={Routes.dealership_contact_path(dealershipSlug, rowData.id)}
        className="btn btn-sm btn-outline-secondary mr-2"
      >
        <i className="fa fa-eye" />
      </Link>
      <a
        href={`/dealerships/${dealershipSlug}/contacts/${rowData.id}/edit`}
        className="btn btn-sm btn-outline-secondary mr-2"
      >
        <i className="fa fa-edit" />
      </a>
      <a
        href={`/dealerships/${dealershipSlug}/contacts/${rowData.id}`}
        className="btn btn-sm btn-outline-secondary"
        data-method="delete"
      >
        <i className="fa fa-trash" />
      </a>
    </>
  )
}

const PortalAccess = ({ signed_in_to_portal }) => {
  if (signed_in_to_portal) {
    return (
      <span className="text-success">
        <i className="fa fa-check" />
      </span>
    )
  } else {
    return (
      <span className="text-danger">
        <i className="fa fa-times" />
      </span>
    )
  }
}

const App = () => {
  const { dealership } = useFetchDealership()
  let { dealershipSlug } = useParams()
  let [contacts, setContacts] = useState([])
  let [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  let [query, setQuery] = useState('')
  let [totalCount, setTotalCount] = useState(0)
  let [minDate, setMinDate] = useState(null)
  let [maxDate, setMaxDate] = useState(null)
  const rows = 25

  useEffect(() => {
    setLoading(true)

    const params = {
      page,
      per_page: rows,
      query,
      min_date: minDate,
      max_date: maxDate,
    }

    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(([_, value]) => value != null)
    )

    const queryString = new URLSearchParams(filteredParams).toString()
    const url = `/dealerships/${dealershipSlug}/contacts/index_react.json?${queryString}`

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setContacts(data.contacts)
        setTotalCount(data.total_count)
        setLoading(false)
      })
  }, [page, query, minDate, maxDate])

  const onPageChange = (event) => {
    setPage(event.page + 1) // PrimeReact pages are 0-based; Kaminari pages are 1-based
  }

  return (
    <div className="p-3 w-100">
      <Header dealershipId={dealership?.id || 0} />

      <div className="box p-3 mb-3">
        <div className="row">
          <div className="col-md-4">
            <input
              className="form-control"
              type="text"
              placeholder="Search Contacts"
              onChange={(e) => {
                setQuery(e.target.value)
                setPage(1)
              }}
            />
          </div>
          <div className="col-md-4">
            <DateRangePickerComponent
              refine={(min, max) => {
                setMinDate(min)
                setMaxDate(max)
                setPage(1)
              }}
            />
          </div>
        </div>
      </div>

      <div className="box">
        <DataTable
          value={contacts}
          loading={loading}
          lazy
          paginator
          rows={rows}
          first={(page - 1) * rows}
          totalRecords={totalCount}
          onPage={onPageChange}
        >
          <Column field="full_name" header="Name" body={Name} />
          <Column field="email" header="Email" body={Email} />
          <Column field="phone_number" header="Phone" body={PhoneNumber} />
          <Column field="vehicle.make" header="Make" />
          <Column field="vehicle.model" header="Model" />
          <Column field="signed_in_to_portal" header="Portal Access" body={PortalAccess} />
          <Column header="Actions" body={Actions} />
        </DataTable>
      </div>
    </div>
  )
}

export default App
