import React from 'react'
import ModulePicker from '../features/EditMode/components/ModulePicker'
import Settings from '../features/DashboardSettings/components/Settings'
import SelectLocations from '../features/DashboardSettings/components/SelectLocations'
import TimeRangePicker from '../../entries/TimeRange'
import { useAnalyticsDashboard, useConfig, useItems, useTimeRangeLocation } from '../contexts/hooks'
import EditMode from '../features/EditMode/components/EditMode'
import LayoutSizeComponent from '../features/DashboardSettings/components/LayoutSizeComponent'
import { useNameBasedOnLevel } from '../hooks'
import HeaderButtons from '../features/EditMode/components/HeaderButtons'
import { ModuleItem, TimeRange } from '../types'

// Manufacturer-specific header component
const ManufacturerHeader: React.FC<{
  editModeEnabled: boolean
  items: ModuleItem[]
  handleUpdateTimeRange: (newTimeRange: TimeRange) => void
}> = ({ editModeEnabled, items, handleUpdateTimeRange }) => (
  <div className="row">
    <h3 className="col-6">Analytics Dashboard</h3>
    <div className="col-6 d-flex justify-content-end">
      <TimeRangePicker handleUpdateTimeRange={handleUpdateTimeRange} />
    </div>
    {editModeEnabled && <div className="ml-auto"></div>}
    <div className="col-12 d-flex justify-content-end align-items-center py-2">
      {editModeEnabled ? (
        <div className="d-flex">
          <LayoutSizeComponent />
          <ModulePicker items={items} />
        </div>
      ) : null}
      <HeaderButtons />
      <EditMode />
    </div>
  </div>
)

const Header: React.FC = () => {
  const { editModeEnabled, currentUser, dashboardLevel } = useAnalyticsDashboard()
  const { items } = useItems()
  const { config } = useConfig()
  const { handleUpdateTimeRange, handleUpdateSelectedLocation } = useTimeRangeLocation()

  const name = useNameBasedOnLevel()

  // Conditional rendering variables
  const showSettings: boolean =
    (currentUser?.admin || !config?.fixedMode) &&
    (dashboardLevel === 'Dealership' || dashboardLevel === 'Website')

  if (dashboardLevel === 'Manufacturer') {
    return (
      <ManufacturerHeader
        editModeEnabled={editModeEnabled}
        items={items}
        handleUpdateTimeRange={handleUpdateTimeRange}
      />
    )
  }

  return (
    <>
      <div className="row">
        {/* Show the dashboard name */}
        {name !== undefined ? <h4 className="col-6 pl-3">{name}</h4> : null}

        <div className="col-6 d-flex pl-0">
          <div className="ml-auto d-flex">
            {/* Settings button */}
            {showSettings ? <Settings /> : null}

            {/* Location picker */}
            {currentUser ? (
              <SelectLocations
                handleUpdateSelectedLocation={handleUpdateSelectedLocation}
                currentUser={currentUser}
              />
            ) : null}

            {/* Time range picker */}
            <TimeRangePicker handleUpdateTimeRange={handleUpdateTimeRange} />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center py-2">
        {/* Dashboard header for non-manufacturers */}
        <h3 className="col-6 p-0">Analytics Dashboard</h3>

        <div className={'col-6 p-0 d-flex justify-content-end'}>
          {/* Layout size component */}
          {editModeEnabled ? <LayoutSizeComponent /> : null}

          {/* Header buttons */}
          <HeaderButtons />

          {/* Edit mode */}
          <EditMode />

          {/* Module picker for non-manufacturers*/}
          {editModeEnabled ? <ModulePicker items={items} /> : null}
        </div>
      </div>
    </>
  )
}

export default Header
