import { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import * as Routes from '../../routes'

const Anchor = ({ to, children, ...props }) => (
  <a href={to} {...props}>
    {children}
  </a>
)

const SettingsMenu = ({ children }) => {
  let [links, setLinks] = useState([])
  let { dealershipSlug } = useParams()

  useEffect(() => {
    fetch(Routes.settings_sidemenu_links_dealership_path(dealershipSlug))
      .then((res) => res.json())
      .then((res) => {
        setLinks(res.links)
      })
  }, [dealershipSlug])

  return (
    <div className="pr-3">
      <div className="row">
        <div className="col-md-2">
          <ul className="list-group settings-menu">
            {links.map((link, index) => {
              let LinkComponent = link.reactRouter ? Link : Anchor
              return (
                <LinkComponent
                  to={link.path}
                  className="list-group-item list-group-item-action"
                  key={index}
                >
                  <i className={`fa ${link.iconClass} mr-1`}></i>
                  {link.text}
                  {link.badge && <span className="badge badge-danger ml-2">{link.badge}</span>}
                </LinkComponent>
              )
            })}
          </ul>
        </div>
        <div className="col-md-10 py-3">{children}</div>
      </div>
    </div>
  )
}

export default SettingsMenu
