import { useContext } from 'react'
import { Panel } from 'primereact/panel'
import moment from 'moment'
import CarsAlsoViewed from '../leads/CarsAlsoViewed'
import { LeadClusterContext } from '../contexts'
import Emails from './Emails'

const LeadContainer = ({ lead, position }) => {
  let { leadCluster } = useContext(LeadClusterContext)

  const Header = () => (
    <div>
      Lead {position + 1}: {lead.category}
      <small className="text-secondary">
        <span> - {moment(lead.created_at).fromNow()}</span>
        {lead.source && <span> from {lead.source}</span>}
      </small>
    </div>
  )

  const Footer = () => (
    <div className="d-flex">
      <div>
        <a
          href={`/dealerships/${leadCluster.dealership_id}/leads/${lead.id}/edit`}
          className="btn btn-outline-secondary btn-sm"
        >
          <i className="fa fa-edit mr-2"></i>
          Edit
        </a>
        <Emails lead={lead} />
      </div>
      <div className="ml-auto text-secondary">{moment(lead.created_at).format('h:mm a')}</div>
    </div>
  )

  return (
    <Panel header={Header} footer={Footer} toggleable collapsed={position > 2} className="mb-3">
      {lead.car && <p>Lead submitted on a {lead.car.name}</p>}
      <div style={{ overflowX: 'scroll' }} dangerouslySetInnerHTML={{ __html: lead.leadDetails }} />
      {lead.carsAlsoViewed?.length > 0 && <CarsAlsoViewed lead={lead} />}
    </Panel>
  )
}

export default LeadContainer
