import { generateRandomId } from '../../common/commonUtils'
import { getErrorMessage } from '../templateUtils'

import { type WebsiteDetailsProps } from '../../types'

export function termsAndConditionsTemplate(websiteDetails: WebsiteDetailsProps) {
  if (websiteDetails === undefined) {
    return getErrorMessage()
  }

  return [
    {
      id: generateRandomId(10),
      type: 'header',
      data: { text: 'Terms and Conditions', level: 1 },
      tunes: {
        alignment: { alignment: 'center' },
        container: { contain: false },
        backgroundColor: { backgroundColor: 'light', customBackgroundColor: '#000000' },
        textColor: { textColor: 'none', customtextColor: '#000000' },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
          mobile: { active: false, spacingTop: 0, spacingBottom: 0 },
          tablet: { active: false, spacingTop: 0, spacingBottom: 0 },
        },
        padding: {
          paddingTop: 7,
          paddingBottom: 6,
          mobile: { active: false, paddingTop: 0, paddingBottom: 0 },
          tablet: { active: false, paddingTop: 0, paddingBottom: 0 },
        },
        classname: { className: '' },
      },
    },
    {
      id: generateRandomId(10),
      type: 'paragraph',
      data: {
        text: `This site is provided as an introduction to ${websiteDetails.name} only, based on general information including that provided by third parties. Information given may change and some time may pass before this website can be updated in respect of all information affected. That being so ${websiteDetails.name} and Total Dealer do not guarantee or warrant that information on this website is accurate or complete and makes no representations or warranties of any kind, express or implied, as to the operation of the site or the information, content or details disclosed on this site.`,
      },
      tunes: {
        alignment: { alignment: 'left' },
        container: { contain: false },
        backgroundColor: { backgroundColor: 'none', customBackgroundColor: '#000000' },
        textColor: { textColor: 'secondary', customTextColor: '#000000' },
        margin: {
          spacingTop: 5,
          spacingBottom: 0,
          mobile: { active: false, spacingTop: 0, spacingBottom: 0 },
          tablet: { active: false, spacingTop: 0, spacingBottom: 0 },
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
          mobile: { active: false, paddingTop: 0, paddingBottom: 0 },
          tablet: { active: false, paddingTop: 0, paddingBottom: 0 },
        },
        classname: { className: '' },
      },
    },
    {
      id: generateRandomId(10),
      type: 'paragraph',
      data: {
        text: `Except as expressly provided for in writing or as regarded by law, the liability of ${websiteDetails.name} and Total Dealer arising from the use of this site or the goods and services purchased using this site is specifically excluded and ${websiteDetails.name} and Total Dealer disclaimers all warranties and any liability for damages of any kind and any liability whether in contract, tort under statute or otherwise for any injury, damage or loss whatsoever. No reliance should be placed on information contained or is to be implied or inferred from this website without checking the details with an authorised officer of ${websiteDetails.name}. Specifications and descriptions are provided by manufacturers.`,
      },
      tunes: {
        alignment: { alignment: 'left' },
        container: { contain: false },
        backgroundColor: { backgroundColor: 'none', customBackgroundColor: '#000000' },
        textColor: { textColor: 'secondary', customTextColor: '#000000' },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
          mobile: { active: false, spacingTop: 0, spacingBottom: 0 },
          tablet: { active: false, spacingTop: 0, spacingBottom: 0 },
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
          mobile: { active: false, paddingTop: 0, paddingBottom: 0 },
          tablet: { active: false, paddingTop: 0, paddingBottom: 0 },
        },
        classname: { className: '' },
      },
    },
    {
      id: generateRandomId(10),
      type: 'header',
      data: { text: 'Vehicle Information', level: 5 },
      tunes: {
        alignment: { alignment: 'left' },
        container: { contain: false },
        backgroundColor: { backgroundColor: 'none', customBackgroundColor: '#000000' },
        textColor: { textColor: 'secondary', customTextColor: '#000000' },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
          mobile: { active: false, spacingTop: 0, spacingBottom: 0 },
          tablet: { active: false, spacingTop: 0, spacingBottom: 0 },
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
          mobile: { active: false, paddingTop: 0, paddingBottom: 0 },
          tablet: { active: false, paddingTop: 0, paddingBottom: 0 },
        },
        classname: { className: '' },
      },
    },
    {
      id: generateRandomId(10),
      type: 'paragraph',
      data: {
        text: `The information, pictures, colours, and specifications contained within the ${websiteDetails.name} website are presented as a general guide only. Although every effort has been made to ensure that such information is correct and up to date, no warrant is provided that all such information is reliable, complete, accurate or without error. In some cases pictures of overseas models may be shown as a guide. Therefore, ${websiteDetails.name} and Total Dealer do not accept liability for damages of any kind resulting from the access or use of this site and its contents.`,
      },
      tunes: {
        alignment: { alignment: 'left' },
        container: { contain: false },
        backgroundColor: { backgroundColor: 'none', customBackgroundColor: '#000000' },
        textColor: { textColor: 'secondary', customTextColor: '#000000' },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
          mobile: { active: false, spacingTop: 0, spacingBottom: 0 },
          tablet: { active: false, spacingTop: 0, spacingBottom: 0 },
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
          mobile: { active: false, paddingTop: 0, paddingBottom: 0 },
          tablet: { active: false, paddingTop: 0, paddingBottom: 0 },
        },
        classname: { className: '' },
      },
    },
    {
      id: generateRandomId(10),
      type: 'header',
      data: { text: 'Copyright and Intellectual Property', level: 5 },
      tunes: {
        alignment: { alignment: 'left' },
        container: { contain: false },
        backgroundColor: { backgroundColor: 'none', customBackgroundColor: '#000000' },
        textColor: { textColor: 'secondary', customTextColor: '#000000' },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
          mobile: { active: false, spacingTop: 0, spacingBottom: 0 },
          tablet: { active: false, spacingTop: 0, spacingBottom: 0 },
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
          mobile: { active: false, paddingTop: 0, paddingBottom: 0 },
          tablet: { active: false, paddingTop: 0, paddingBottom: 0 },
        },
        classname: { className: '' },
      },
    },
    {
      id: generateRandomId(10),
      type: 'paragraph',
      data: {
        text: `All text, images and sounds supplied by the manufacturer, third parties or ${websiteDetails.name} on this site are subject to Copyright and other intellectual property rights of the manufacturer, suppliers and/or ${websiteDetails.name}. All production, designs and design related works and software, including but not limited to website source code, on this site are subject to Copyright and intellectual property rights of Total Dealer and are licensed for use by ${websiteDetails.name}. These materials may not be copied for commercial use or distribution or otherwise modified or reposted to other sites.`,
      },
      tunes: {
        alignment: { alignment: 'left' },
        container: { contain: false },
        backgroundColor: { backgroundColor: 'none', customBackgroundColor: '#000000' },
        textColor: { textColor: 'secondary', customTextColor: '#000000' },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
          mobile: { active: false, spacingTop: 0, spacingBottom: 0 },
          tablet: { active: false, spacingTop: 0, spacingBottom: 0 },
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
          mobile: { active: false, paddingTop: 0, paddingBottom: 0 },
          tablet: { active: false, paddingTop: 0, paddingBottom: 0 },
        },
        classname: { className: '' },
      },
    },
    {
      id: generateRandomId(10),
      type: 'header',
      data: { text: 'Personal &amp; Non Commercial Use Only', level: 5 },
      tunes: {
        alignment: { alignment: 'left' },
        container: { contain: false },
        backgroundColor: { backgroundColor: 'none', customBackgroundColor: '#000000' },
        textColor: { textColor: 'secondary', customTextColor: '#000000' },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
          mobile: { active: false, spacingTop: 0, spacingBottom: 0 },
          tablet: { active: false, spacingTop: 0, spacingBottom: 0 },
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
          mobile: { active: false, paddingTop: 0, paddingBottom: 0 },
          tablet: { active: false, paddingTop: 0, paddingBottom: 0 },
        },
        classname: { className: '' },
      },
    },
    {
      id: generateRandomId(10),
      type: 'paragraph',
      data: {
        text: 'Data on this website are provided for your personal and non-commercial use only. You must not, without the written approval of Total Dealer:',
      },
      tunes: {
        alignment: { alignment: 'left' },
        container: { contain: false },
        backgroundColor: { backgroundColor: 'none', customBackgroundColor: '#000000' },
        textColor: { textColor: 'secondary', customTextColor: '#000000' },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
          mobile: { active: false, spacingTop: 0, spacingBottom: 0 },
          tablet: { active: false, spacingTop: 0, spacingBottom: 0 },
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
          mobile: { active: false, paddingTop: 0, paddingBottom: 0 },
          tablet: { active: false, paddingTop: 0, paddingBottom: 0 },
        },
        classname: { className: '' },
      },
    },
    {
      id: generateRandomId(10),
      type: 'list',
      data: {
        style: 'unordered',
        items: [
          'Modify, copy, distribute, transmit, display, perform, reproduce, publish or license any data from this website;',
          'Use or attempt to use any data published on this website to create any web site or publication;',
          'Mirror or frame any data published within this website;',
          'Use any automated process of any sort to query, access or copy any data on this website or generate or compile any document or database based on the data published on this website;',
          'Transfer or sell any data offered on this website.',
        ],
      },
      tunes: {
        container: { contain: false },
        backgroundColor: { backgroundColor: 'none', customBackgroundColor: '#000000' },
        textColor: { textColor: 'secondary', customTextColor: '#000000' },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
          mobile: { active: false, spacingTop: 0, spacingBottom: 0 },
          tablet: { active: false, spacingTop: 0, spacingBottom: 0 },
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
          mobile: { active: false, paddingTop: 0, paddingBottom: 0 },
          tablet: { active: false, paddingTop: 0, paddingBottom: 0 },
        },
        classname: { className: '' },
      },
    },
    {
      id: generateRandomId(10),
      type: 'header',
      data: { text: 'Trademark', level: 5 },
      tunes: {
        alignment: { alignment: 'left' },
        container: { contain: false },
        backgroundColor: { backgroundColor: 'none', customBackgroundColor: '#000000' },
        textColor: { textColor: 'secondary', customTextColor: '#000000' },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
          mobile: { active: false, spacingTop: 0, spacingBottom: 0 },
          tablet: { active: false, spacingTop: 0, spacingBottom: 0 },
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
          mobile: { active: false, paddingTop: 0, paddingBottom: 0 },
          tablet: { active: false, paddingTop: 0, paddingBottom: 0 },
        },
        classname: { className: '' },
      },
    },
    {
      id: generateRandomId(10),
      type: 'paragraph',
      data: {
        text: `All Trademarks displayed on this site are subject to the legal rights of ${websiteDetails.name} or the other Trademark owners and the unauthorised use of any Trademark displayed on this site is strictly prohibited.`,
      },
      tunes: {
        alignment: { alignment: 'left' },
        container: { contain: false },
        backgroundColor: { backgroundColor: 'none', customBackgroundColor: '#000000' },
        textColor: { textColor: 'secondary', customTextColor: '#000000' },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
          mobile: { active: false, spacingTop: 0, spacingBottom: 0 },
          tablet: { active: false, spacingTop: 0, spacingBottom: 0 },
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
          mobile: { active: false, paddingTop: 0, paddingBottom: 0 },
          tablet: { active: false, paddingTop: 0, paddingBottom: 0 },
        },
        classname: { className: '' },
      },
    },
    {
      id: generateRandomId(10),
      type: 'header',
      data: { text: 'Contracts', level: 5 },
      tunes: {
        alignment: { alignment: 'left' },
        container: { contain: false },
        backgroundColor: { backgroundColor: 'none', customBackgroundColor: '#000000' },
        textColor: { textColor: 'secondary', customTextColor: '#000000' },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
          mobile: { active: false, spacingTop: 0, spacingBottom: 0 },
          tablet: { active: false, spacingTop: 0, spacingBottom: 0 },
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
          mobile: { active: false, paddingTop: 0, paddingBottom: 0 },
          tablet: { active: false, paddingTop: 0, paddingBottom: 0 },
        },
        classname: { className: '' },
      },
    },
    {
      id: generateRandomId(10),
      type: 'paragraph',
      data: {
        text: `This site may only be used for lawful purposes and ${websiteDetails.name} reserves the right to deal or refuse to deal with any user. No contract will be made or deemed to be entered into unless confirmed in writing by ${websiteDetails.name}.`,
      },
      tunes: {
        alignment: { alignment: 'left' },
        container: { contain: false },
        backgroundColor: { backgroundColor: 'none', customBackgroundColor: '#000000' },
        textColor: { textColor: 'secondary', customTextColor: '#000000' },
        margin: {
          spacingTop: 0,
          spacingBottom: 5,
          mobile: { active: false, spacingTop: 0, spacingBottom: 0 },
          tablet: { active: false, spacingTop: 0, spacingBottom: 0 },
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
          mobile: { active: false, paddingTop: 0, paddingBottom: 0 },
          tablet: { active: false, paddingTop: 0, paddingBottom: 0 },
        },
        classname: { className: '' },
      },
    },
  ]
}
