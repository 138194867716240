import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as Routes from '../../../routes'
import ChartSection from '../components/ChartSection'
import { fetchResultData } from '../../AnalyticsDashboard/utils'
import { showLoading } from '../../entries/utils'
import { useTimeRangeLocation } from '../../AnalyticsDashboard/contexts/hooks'
import TimeRangePicker from '../../entries/TimeRange'
import ContextWrapper from '../../AnalyticsDashboard/contexts/ContextWrapper'
import LoadingWrapper from '../../AnalyticsDashboard/components/LoadingWrapper'
import CarStatsContent from '../../AnalyticsDashboard/features/AnalyticsModules/components/CarStatsContent'
import { CurrentUserContext } from '../../contexts'
import { User } from '../../AnalyticsDashboard/types'

const CarAnalytics: React.FC = () => {
  const { handleUpdateTimeRange, timeRange } = useTimeRangeLocation()
  const { dealershipSlug } = useParams()
  const [loading, setLoading] = useState<boolean>(true)
  const [analyticsData, setAnalyticsData] = useState<any>()
  const currentUser = useContext(CurrentUserContext) as User

  useEffect(() => {
    if (!dealershipSlug) return

    const fetchData = async () => {
      setLoading(true)
      try {
        const queryString = new URLSearchParams(timeRange as any).toString()
        const URL = `${Routes.analytics_dealership_cars_path(dealershipSlug)}.json?${queryString}`
        const data = await fetchResultData(URL)
        setAnalyticsData(data)
      } catch (err) {
        setLoading(false)
        throw err
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [dealershipSlug, timeRange]) // Dependency on both dealershipSlug and timeRange

  const dealership = currentUser
    ? Object.values(currentUser?.dealerships).find(
        (dealership) => dealership.slug === dealershipSlug
      )
    : null

  return (
    <div className="p-4 w-100">
      <div className="row">
        <h4 className="col-6 p-0 pl-3">Car Analytics</h4>
        <div className="float-right col-6 d-flex justify-content-end">
          <div className="d-flex flex-column align-items-end">
            <TimeRangePicker handleUpdateTimeRange={handleUpdateTimeRange} />
          </div>
        </div>
      </div>

      {analyticsData && !loading ? (
        <div>
          <div className="row">
            <div className="col-md-6">
              <ChartSection
                title="Distribution of cars by price"
                subtitle={timeRange?.timeframe || 'Last 30 days'}
                chartData={analyticsData?.cars_by_price}
                chartType="Bar"
              />
              <ChartSection
                title="Cars online"
                subtitle={timeRange?.timeframe || 'Last 30 days'}
                chartData={analyticsData?.cars_online}
                chartType="Bar"
              />
              {analyticsData?.cars_grades?.length > 0 && (
                <ChartSection
                  title="Car Grade"
                  subtitle={timeRange?.timeframe || 'Last 30 days'}
                  chartData={analyticsData?.cars_grades}
                  chartType="Pie"
                />
              )}
            </div>
            <div className="col-md-6">
              <div className="box position-relative mb-3 p-3 mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <h4>Car Stats</h4>
                  <div className="float-right">
                    <a
                      href={Routes.dealership_cars_path(dealershipSlug)}
                      className="btn btn-secondary btn-sm"
                    >
                      View Cars
                    </a>
                  </div>
                </div>
                <CarStatsContent
                  data={analyticsData?.car_stats}
                  dealership={dealership}
                  timeframe={timeRange?.timeframe || 'last 30 days'}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        showLoading()
      )}
    </div>
  )
}

const CarAnalyticsApp = () => (
  <ContextWrapper>
    <LoadingWrapper>
      <CarAnalytics />
    </LoadingWrapper>
  </ContextWrapper>
)

export default CarAnalyticsApp
