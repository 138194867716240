import { Dealership, ModuleComponents } from './types'

import { Trophy, Facebook, Google } from './components/Icons'
import {
  CarStats,
  LeadAnalytics,
  LeaderBoard,
  LeadsByMake,
  TopPageViews,
  GenericModuleComponent,
  EngagementByCampaign,
  PerformanceByCampaign,
  TopPerformingKeywords,
  LeadClusterCountByAge,
  CarsStatsByDealership,
} from './features/AnalyticsModules/components/index'

// Module Components
export const moduleComponents: ModuleComponents = {
  daily_leads_count: {
    visibleLevels: ['Dealership', 'Manufacturer', 'Website', 'Dealership Group'],
    component: GenericModuleComponent,
    title: 'Total Leads',
    infoDescription: 'Total leads received.',
    xLabel: 'Day/Month',
    yLabel: 'Total Leads',
    showSpamLeadsDisclaimer: true,
    group: 'Leads',
  },
  lead_analytics: {
    visibleLevels: ['Dealership', 'Website', 'Dealership Group'],
    component: LeadAnalytics,
    title: 'Lead Analytics',
    infoDescription:
      'An overview of the leads as a whole, including enquiry type, source, total leads etc. as well as change from previous time range',
    showTimeFrame: false,
    button: {
      text: 'View Leads',
      link: (dealership: Dealership) => `/dealerships/${dealership?.slug}/lead_clusters`,
    },
    isCustomChart: true,
    group: 'Leads',
  },
  daily_phone_calls_count: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    title: 'Daily Phone Calls',
    infoDescription: 'Total phone calls received.',
    xLabel: 'Day/Month',
    yLabel: 'Total Phone Calls',
    group: 'General',
  },
  leads_per_website: {
    visibleLevels: ['Dealership', 'Manufacturer', 'Dealership Group'],
    component: GenericModuleComponent,
    title: 'Leads Per Website',
    infoDescription: 'Total leads received. Sorted by website',
    defaultChartType: 'Pie',
    excludedChartTypes: ['Line'],
    xLabel: 'Website',
    yLabel: 'Leads',
    showSpamLeadsDisclaimer: true,
    group: 'Leads',
  },
  car_stats: {
    visibleLevels: ['Dealership'],
    component: CarStats,
    title: 'Car Stats',
    infoDescription:
      'An overview of the cars as a whole, including cars in stock, recently added/sold cars, average age of stock as well as which cars don’t have photos or dealer comments (CTA’s). Shows a list of the most popular cars by number of views',
    showTimeFrame: false,
    button: {
      text: 'View Cars',
      link: (dealership: Dealership) => `/dealerships/${dealership?.slug}/cars`,
    },
    isCustomChart: true,
    group: 'Cars',
  },
  leads_per_category: {
    visibleLevels: ['Dealership', 'Manufacturer', 'Website', 'Dealership Group'],
    component: GenericModuleComponent,
    title: 'Leads Per Category',
    infoDescription: 'Total leads received. Sorted by category/enquiry type',
    defaultChartType: 'Pie',
    excludedChartTypes: ['Line'],
    xLabel: 'Category',
    yLabel: 'Leads',
    showSpamLeadsDisclaimer: true,
    group: 'Leads',
  },
  delivery_leaderboard: {
    visibleLevels: ['Dealership'],
    component: LeaderBoard,
    title: 'Delivery Leaderboard',
    infoDescription: 'Delivery Leaderboard',
    showTimeFrame: false,
    Icon: Trophy,
    isCustomChart: true,
    group: 'General',
  },
  daily_payments_count: {
    visibleLevels: ['Dealership'],
    component: GenericModuleComponent,
    infoDescription:
      'Shows the total payments received as well as total payment count and total dollar amount.',
    title: 'Payments',
    xLabel: 'Day/Month',
    yLabel: 'Total Payments',
    group: 'General',
  },
  daily_test_drives_count: {
    visibleLevels: ['Dealership'],
    component: GenericModuleComponent,
    infoDescription: 'Shows the number of test drives.',
    title: 'Test Drives',
    xLabel: 'Day/Month',
    yLabel: 'Total Test Drives',
    group: 'General',
  },
  cars_sold_count: {
    visibleLevels: ['Dealership'],
    component: GenericModuleComponent,
    infoDescription: 'Shows the total number of cars sold.',
    title: 'Cars Sold',
    xLabel: 'Day/Month',
    yLabel: 'Cars Sold',
    group: 'Cars',
  },
  google_reviews_count: {
    visibleLevels: ['Dealership'],
    component: GenericModuleComponent,
    title: 'Reviews',
    infoDescription: 'Total Google reviews received.',
    xLabel: 'Day/Month',
    yLabel: 'Total Reviews',
    group: 'General',
    Icon: Google,
  },
  leads_by_hour: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    title: 'Leads By Hour',
    infoDescription: 'Shows what time (hour) leads were created.',
    xLabel: 'Hour',
    yLabel: 'Leads',
    showSpamLeadsDisclaimer: true,
    group: 'Leads',
  },
  leads_by_make: {
    visibleLevels: ['Dealership', 'Manufacturer', 'Website', 'Dealership Group'],
    component: LeadsByMake,
    title: 'Leads By Make',
    infoDescription: 'Shows what car make leads were created for.',
    isCustomChart: true,
    group: 'Leads',
  },
  lead_cluster_conversion_rate_by_source: {
    visibleLevels: ['Dealership'],
    excludedChartTypes: ['Line'],
    component: GenericModuleComponent,
    title: 'Lead Conversion Rate By Source',
    infoDescription: 'Shows the conversion rate (as a percentage) of leads by source.',
    xLabel: 'Source',
    yLabel: 'Percentage',
    barChartOptionsConfig: {
      scales: {
        y: {
          title: {
            text: 'Percentage',
          },
          min: 0,
          max: 100,
          ticks: {
            stepSize: 10,
          },
        },
      },
    },
    showSpamLeadsDisclaimer: true,
    showInsufficientDataSourcesDisclaimer: true,
    isConversionRateModule: true,
    showExcludedCategoriesDisclaimer: true,
    group: 'Leads',
  },
  google_analytics: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    title: 'Active Users',
    infoDescription: 'Number of unique users who interacted with your site in real time.',
    xLabel: 'Day/Month',
    yLabel: 'Active Users',
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    Icon: Google,
    group: 'Google Analytics',
  },
  sessions: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    title: 'Sessions',
    infoDescription: 'Periods of active user engagement with your site',
    xLabel: 'Day/Month',
    yLabel: 'Total Sessions',
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    Icon: Google,
    group: 'Google Analytics',
  },
  sessions_by_medium: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    title: 'Sessions By Medium',
    infoDescription: 'Shows the sessions breakdown by medium/source.',
    excludedChartTypes: ['Line', 'Pie'],
    xLabel: 'Medium/Source',
    yLabel: 'Sessions',
    defaultChartType: 'Bar',
    Icon: Google,
    group: 'Google Analytics',
  },
  total_users: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    title: 'Total Users',
    infoDescription: 'Cumulative unique users who have engaged with your site.',
    xLabel: 'Day/Month',
    yLabel: 'Total Users',
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    Icon: Google,
    group: 'Google Analytics',
  },
  traffic_by_device: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    title: 'Traffic By Device',
    infoDescription: 'Shows the Traffic breakdown by Device based on total sessions.',
    defaultChartType: 'Pie',
    excludedChartTypes: ['Line'],
    xLabel: 'Device',
    yLabel: 'Sessions',
    Icon: Google,
    group: 'Google Analytics',
  },
  new_users: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    title: 'New Users',
    infoDescription: 'First-time visitors to your site.',
    xLabel: 'Day/Month',
    yLabel: 'New Users',
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    Icon: Google,
    group: 'Google Analytics',
  },
  views: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    title: 'Views',
    infoDescription: 'Total number of pages viewed on your site or app, including repeated views.',
    xLabel: 'Day/Month',
    yLabel: 'Total Views',
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    Icon: Google,
    group: 'Google Analytics',
  },
  top_page_views: {
    visibleLevels: ['Dealership', 'Website'],
    component: TopPageViews,
    title: 'Top Page Views',
    infoDescription:
      'An overview of the top pages viewed on websites, including page path, total count etc. as well as change from previous time range',
    showTimeFrame: false,
    isCustomChart: true,
    Icon: Google,
    group: 'Google Analytics',
  },
  // Maybe not necessary
  // total_vehicle_leads: {
  //   visibleLevels: ['Dealership', 'Website'],
  //   component: GenericModuleComponent,
  //   title: 'Total Vehicle Leads',
  //   infoDescription: 'Total vehicle related leads received.',
  //   xLabel: 'Day/Month',
  //   yLabel: 'Total Vehicle Leads',
  //   showSpamLeadsDisclaimer: true,
  // },
  total_general_enquiry_leads: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    title: 'Total General Enquiries',
    infoDescription: 'Total general enquiry leads received.',
    xLabel: 'Day/Month',
    yLabel: 'Total General Enquiries',
    showSpamLeadsDisclaimer: true,
    group: 'Leads',
  },
  total_sales_leads: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    title: 'Total Sales Leads',
    infoDescription: 'Total sales related leads received.',
    xLabel: 'Day/Month',
    yLabel: 'Total Sales Leads',
    showSpamLeadsDisclaimer: true,
    group: 'Leads',
  },
  total_service_leads: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    title: 'Total Service Leads',
    infoDescription: 'Total service related leads received.',
    xLabel: 'Day/Month',
    yLabel: 'Total Service Leads',
    showSpamLeadsDisclaimer: true,
    group: 'Leads',
  },
  total_trade_leads: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    title: 'Total Trade Leads',
    infoDescription: 'Total trade related leads received.',
    xLabel: 'Day/Month',
    yLabel: 'Total Trade Leads',
    showSpamLeadsDisclaimer: true,
    group: 'Leads',
  },
  lead_cluster_conversion_rate_by_location: {
    visibleLevels: ['Dealership'],
    excludedChartTypes: ['Line'],
    component: GenericModuleComponent,
    title: 'Lead Conversion Rate By Location',
    infoDescription: 'Shows the conversion rate (as a percentage) of leads by location.',
    xLabel: 'Location',
    yLabel: 'Percentage',
    barChartOptionsConfig: {
      scales: {
        y: {
          title: {
            text: 'Percentage',
          },
          min: 0,
          max: 100,
          ticks: {
            stepSize: 10,
          },
        },
      },
    },
    showSpamLeadsDisclaimer: true,
    showInsufficientDataSourcesDisclaimer: true,
    isConversionRateModule: true,
    showExcludedCategoriesDisclaimer: true,
    group: 'Leads',
  },
  lead_cluster_conversion_rate_by_user: {
    visibleLevels: ['Dealership'],
    excludedChartTypes: ['Line'],
    component: GenericModuleComponent,
    title: 'Lead Conversion Rate By Team Member',
    infoDescription: 'Shows the conversion rate (as a percentage) of leads by user.',
    xLabel: 'Team Member',
    yLabel: 'Percentage',
    barChartOptionsConfig: {
      scales: {
        y: {
          title: {
            text: 'Percentage',
          },
          min: 0,
          max: 100,
          ticks: {
            stepSize: 10,
          },
        },
      },
    },
    showSpamLeadsDisclaimer: true,
    showInsufficientDataSourcesDisclaimer: true,
    isConversionRateModule: true,
    showExcludedCategoriesDisclaimer: true,
    group: 'Leads',
  },
  lead_cluster_conversion_rate_by_source_and_body: {
    visibleLevels: ['Dealership'],
    excludedChartTypes: ['Line', 'Pie'],
    component: GenericModuleComponent,
    title: 'Lead Conversion Rate By Source & Body',
    infoDescription:
      'Shows the conversion rate (as a percentage) of leads by source, with a breakdown per body.',
    xLabel: 'Source',
    yLabel: 'Percentage',
    barChartOptionsConfig: {
      scales: {
        y: {
          min: 0,
          max: 100,
          ticks: {
            stepSize: 10,
          },
        },
      },
    },
    showSpamLeadsDisclaimer: true,
    showInsufficientDataSourcesDisclaimer: true,
    isConversionRateModule: true,
    showExcludedCategoriesDisclaimer: true,
    group: 'Leads',
  },
  lead_cluster_conversion_by_source: {
    visibleLevels: ['Dealership'],
    excludedChartTypes: ['Line', 'Pie'],
    component: GenericModuleComponent,
    title: 'Lead Conversions By Source',
    infoDescription: 'Shows the total converted leads by source.',

    xLabel: 'Source',
    yLabel: 'Total Leads',
    showSpamLeadsDisclaimer: true,
    showExcludedCategoriesDisclaimer: true,
    isConversionModule: true,
    group: 'Leads',
  },
  lead_cluster_conversion_by_marketing_source: {
    visibleLevels: ['Dealership'],
    excludedChartTypes: ['Line', 'Pie'],
    component: GenericModuleComponent,
    title: 'Lead Conversions By Marketing Source',
    infoDescription: 'Shows the total converted leads by marketing source.',
    xLabel: 'Marketing Source',
    yLabel: 'Total Leads',
    showSpamLeadsDisclaimer: true,
    showExcludedCategoriesDisclaimer: true,
    isConversionModule: true,
    group: 'Leads',
  },
  autobot_leads: {
    visibleLevels: ['Website'],
    component: LeadAnalytics,
    title: 'Autobot Leads',
    infoDescription:
      'An overview of the leads from the Chatbot source. Gives more details including enquiry type, source, total leads etc., as well as changes from previous time range',
    showTimeFrame: false,
    button: {
      text: 'View Leads',
      link: (dealership: Dealership) => `/dealerships/${dealership?.slug}/lead_clusters`,
    },
    isCustomChart: true,
    group: 'Leads',
  },
  facebook_ads_engagement_by_campaign: {
    visibleLevels: ['Dealership', 'Website'],
    component: EngagementByCampaign,
    title: 'Engagement By Campaign',
    infoDescription:
      'An comparison between active campaigns, showing metrics such as impressions, reach, spend & clicks.',
    showTimeFrame: false,
    isCustomChart: true,
    Icon: Facebook,
    group: 'Facebook Ads',
  },
  facebook_ads_inline_link_clicks: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Clicks',
    infoDescription: 'Total clicks on your Facebook Ads',
    xLabel: 'Day/Month',
    yLabel: 'Total Clicks',
    Icon: Facebook,
    group: 'Facebook Ads',
  },
  facebook_ads_impressions: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Impressions',
    infoDescription: 'Total impressions on your Facebook Ads',
    xLabel: 'Day/Month',
    yLabel: 'Total Impressions',
    Icon: Facebook,
    group: 'Facebook Ads',
  },
  facebook_ads_reach: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Reach',
    infoDescription: 'Total reach on your Facebook Ads',
    xLabel: 'Day/Month',
    yLabel: 'Total Reach',
    Icon: Facebook,
    group: 'Facebook Ads',
  },
  facebook_ads_cpc: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Cost Per Click',
    infoDescription: 'Cost Per Click on your Facebook Ads',
    xLabel: 'Day/Month',
    yLabel: 'Cost Per Click',
    isCurrency: true,
    Icon: Facebook,
    group: 'Facebook Ads',
  },
  facebook_ads_leads: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Leads',
    infoDescription: 'Total Leads on your Facebook Ads',
    xLabel: 'Day/Month',
    yLabel: 'Total Leads',
    Icon: Facebook,
    group: 'Facebook Ads',
  },
  facebook_ads_spend: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Spend',
    infoDescription: 'Total spend on your Facebook Ads',
    xLabel: 'Day/Month',
    yLabel: 'Total Spend',
    isCurrency: true,
    Icon: Facebook,
    group: 'Facebook Ads',
  },
  facebook_ads_ctr: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Click Through Rate',
    infoDescription: 'Click Through Rate (Clicks per Impressions * 100) on your Facebook Ads',
    xLabel: 'Day/Month',
    yLabel: 'Click Through Rate',
    isPercentage: true,
    Icon: Facebook,
    group: 'Facebook Ads',
  },
  facebook_ads_cost_per_lead: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Cost Per Lead',
    infoDescription: 'Cost Per Lead on your Facebook Ads',
    xLabel: 'Day/Month',
    yLabel: 'Cost Per Lead',
    isCurrency: true,
    Icon: Facebook,
    group: 'Facebook Ads',
  },
  google_ad_leads: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Leads',
    infoDescription: 'Leads from your Google Ads',
    xLabel: 'Day/Month',
    yLabel: 'Leads',
    Icon: Google,
    group: 'Google Ads',
  },
  google_ad_cost_per_lead: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Cost Per Lead',
    infoDescription: 'Cost per Lead from Google Ads',
    xLabel: 'Day/Month',
    yLabel: 'Cost',
    Icon: Google,
    group: 'Google Ads',
  },
  google_ad_cpc: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Cost Per Click',
    infoDescription: 'Cost Per Click(CPC) from your Google Ads',
    xLabel: 'Day/Month',
    yLabel: 'Cost Per Click (CPC)',
    Icon: Google,
    group: 'Google Ads',
  },
  google_ad_website_phone_calls: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Website Phone Calls',
    infoDescription: 'Website Phone Calls from your Google Ads',
    xLabel: 'Day/Month',
    yLabel: 'Total Website Phone Calls',
    Icon: Google,
    group: 'Google Ads',
  },
  google_ad_phone_calls: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Phone Calls',
    infoDescription: 'Phone Calls from your Google Ads',
    xLabel: 'Day/Month',
    yLabel: 'Total Phone Calls',
    Icon: Google,
    group: 'Google Ads',
  },
  google_ad_vehicle_leads: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Vehicle Leads',
    infoDescription: 'Vehicle Leads from your Google Ads',
    xLabel: 'Day/Month',
    yLabel: 'Total Vehicle Leads',
    Icon: Google,
    group: 'Google Ads',
  },
  google_ad_ctr: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Click-Through Rate',
    infoDescription: 'Click-Through Rate(CTR) from your Google Ads',
    xLabel: 'Day/Month',
    yLabel: 'Clicks Through-Rate(CTR)',
    Icon: Google,
    group: 'Google Ads',
  },
  google_ad_clicks: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Clicks',
    infoDescription: 'Total clicks from your Google Ads',
    xLabel: 'Day/Month',
    yLabel: 'Total Clicks',
    Icon: Google,
    group: 'Google Ads',
  },
  google_ad_performance_by_campaign: {
    visibleLevels: ['Dealership', 'Website'],
    component: PerformanceByCampaign,
    title: 'Performance By Campaign',
    infoDescription:
      'A comparison between active campaigns, showing metrics such as impressions, reach, spend & clicks from Google Ads',
    showTimeFrame: false,
    isCustomChart: true,
    Icon: Google,
    group: 'Google Ads',
  },
  /**
   * Commented out as these are not being used currently
   * It can be used in the future as API calls is ready for these data
  google_ad_impressions: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Impressions',
    infoDescription: 'Total Impressions from your Google Ads',
    xLabel: 'Day/Month',
    yLabel: 'Total Impressions',
    Icon: Google,
    group: 'Google Ads',
  },
  google_ad_vehicle_phone_calls: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Vehicle Phone Calls',
    infoDescription: 'Total Vehicle Phone Calls from your Google Ads',
    xLabel: 'Day/Month',
    yLabel: 'Total Vehicle Phone Calls',
    Icon: Google,
    group: 'Google Ads',
  },
  */
  google_ads_leads: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Leads',
    infoDescription: 'Leads from your Google Adwords Ads',
    xLabel: 'Day/Month',
    yLabel: 'Leads',
    Icon: Google,
    group: 'Google Adwords Ads',
  },
  google_ads_cost_per_lead: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Cost Per Lead',
    infoDescription: 'Cost per Lead from Google Adwords Ads',
    xLabel: 'Day/Month',
    yLabel: 'Cost',
    Icon: Google,
    group: 'Google Adwords Ads',
  },
  google_ads_cpc: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Cost Per Click',
    infoDescription: 'Cost Per Click(CPC) from your Google Adwords Ads',
    xLabel: 'Day/Month',
    yLabel: 'Cost Per Click (CPC)',
    Icon: Google,
    group: 'Google Adwords Ads',
  },
  google_ads_website_phone_calls: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Website Phone Calls',
    infoDescription: 'Total Website Phone Calls from your Google Adwords Ads',
    xLabel: 'Day/Month',
    yLabel: 'Total Website Phone Calls',
    Icon: Google,
    group: 'Google Adwords Ads',
  },
  google_ads_phone_calls: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Phone Calls From Ads',
    infoDescription: 'Total Phone Calls from your Google Adwords Ads',
    xLabel: 'Day/Month',
    yLabel: 'Total Phone Calls',
    Icon: Google,
    group: 'Google Adwords Ads',
  },
  google_ads_vehicle_leads: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Vehicle Leads',
    infoDescription: 'Total Vehicle Leads from your Google Adwords Ads',
    xLabel: 'Day/Month',
    yLabel: 'Total Vehicle Leads',
    Icon: Google,
    group: 'Google Adwords Ads',
  },
  google_ads_performance_by_campaign: {
    visibleLevels: ['Dealership', 'Website'],
    component: PerformanceByCampaign,
    title: 'Performance By Campaign',
    infoDescription:
      'A comparison between active campaigns, showing metrics such as impressions, reach, spend & clicks from Google Ads',
    showTimeFrame: false,
    isCustomChart: true,
    Icon: Google,
    group: 'Google Adwords Ads',
  },
  google_ads_ctr: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Clicks Through Rate',
    infoDescription: 'Clicks Through-Rate(CTR) from your Google Adwords Ads',
    xLabel: 'Day/Month',
    yLabel: 'Clicks Through-Rate(CTR)',
    Icon: Google,
    group: 'Google Adwords Ads',
  },
  google_ads_impressions: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Impressions',
    infoDescription: 'Total Impressions from your Google Adwords Ads',
    xLabel: 'Day/Month',
    yLabel: 'Total Impressions',
    Icon: Google,
    group: 'Google Adwords Ads',
  },
  google_ads_clicks: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Clicks',
    infoDescription: 'Total clicks from your Google Adwords Ads',
    xLabel: 'Day/Month',
    yLabel: 'Total Clicks',
    Icon: Google,
    group: 'Google Adwords Ads',
  },
  google_ads_top_performing_keywords: {
    visibleLevels: ['Dealership', 'Website'],
    component: TopPerformingKeywords,
    title: 'Top Performing Keywords',
    infoDescription:
      'A comparison between Top performing Search Keywords, showing metrics such as impressions, reach, spend & clicks from Google Adwords Ads',
    showTimeFrame: false,
    isCustomChart: true,
    Icon: Google,
    group: 'Google Adwords Ads',
  },
  google_ads_vehicle_phone_calls: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Vehicle Phone Calls',
    infoDescription: 'Total Vehicle Phone Calls from your Google Adwords Ads',
    xLabel: 'Day/Month',
    yLabel: 'Total Vehicle Phone Calls',
    Icon: Google,
    group: 'Google Adwords Ads',
  },
  google_display_ads_leads: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Leads',
    infoDescription: 'Leads from your Google Display Ads',
    xLabel: 'Day/Month',
    yLabel: 'Leads',
    Icon: Google,
    group: 'Google Display Ads',
  },
  google_display_ads_cost_per_lead: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Cost Per Lead',
    infoDescription: 'Cost per Lead from Google Display Ads',
    xLabel: 'Day/Month',
    yLabel: 'Cost',
    Icon: Google,
    group: 'Google Display Ads',
  },
  google_display_ads_cpc: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Cost Per Click',
    infoDescription: 'Cost Per Click from your Google Display Ads',
    xLabel: 'Day/Month',
    yLabel: 'Cost Per Click',
    Icon: Google,
    group: 'Google Display Ads',
  },
  google_display_ads_website_phone_calls: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Website Phone Calls',
    infoDescription: 'Total Website Phone Calls from your Google Display Ads',
    xLabel: 'Day/Month',
    yLabel: 'Total Website Phone Calls',
    Icon: Google,
    group: 'Google Display Ads',
  },
  google_display_ads_clicks: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Clicks',
    infoDescription: 'Clicks from your Google Display Ads',
    xLabel: 'Day/Month',
    yLabel: 'Total Clicks',
    Icon: Google,
    group: 'Google Display Ads',
  },
  google_display_ads_ctr: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Click-Through Rate',
    infoDescription: 'Click-Through Rate from your Google Display Ads',
    xLabel: 'Day/Month',
    yLabel: 'Click Through Rate',
    Icon: Google,
    group: 'Google Display Ads',
  },
  google_display_ads_performance_by_campaign: {
    visibleLevels: ['Dealership', 'Website'],
    component: PerformanceByCampaign,
    title: 'Performance By Campaign',
    infoDescription:
      'A comparison between active campaigns, showing metrics such as impressions, reach, spend & clicks from Google Display Ads',
    showTimeFrame: false,
    isCustomChart: true,
    Icon: Google,
    group: 'Google Display Ads',
  },
  /** 
   * Commented out as these are not being used currently
   * It can be used in the future as API calls is ready for these data
  google_display_ads_impressions: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Impressions',
    infoDescription: 'Impressions from your Google Display Ads',
    xLabel: 'Day/Month',
    yLabel: 'Total Impressions',
    Icon: Google,
    group: 'Google Display Ads',
  },
  google_display_ads_vehicle_phone_calls: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Vehicle Phone Calls',
    infoDescription: 'Total Vehicle Phone Calls from your Google Display Ads',
    xLabel: 'Day/Month',
    yLabel: 'Total Vehicle Phone Calls',
    Icon: Google,
    group: 'Google Display Ads',
  },
  google_display_ads_vehicle_leads: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Vehicle Leads ',
    infoDescription: 'Total Vehicle Leads from your Google Display Ads',
    xLabel: 'Day/Month',
    yLabel: 'Total Vehicle Leads',
    Icon: Google,
    group: 'Google Display Ads',
  },
  */
  google_vla_ads_leads: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Leads',
    infoDescription: 'Total Leads from your Google VLA Ads',
    xLabel: 'Day/Month',
    yLabel: 'Total Leads',
    Icon: Google,
    group: 'Google VLA Ads',
  },
  google_vla_ads_cost_per_lead: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Cost Per Lead',
    infoDescription: 'Cost per Lead from Google VLA Ads',
    xLabel: 'Day/Month',
    yLabel: 'Cost',
    Icon: Google,
    group: 'Google VLA Ads',
  },
  google_vla_ads_cpc: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Cost Per Click ',
    infoDescription: 'Total Cost Per Click from your Google VLA Ads',
    xLabel: 'Day/Month',
    yLabel: 'Total Cost Per Click',
    Icon: Google,
    group: 'Google VLA Ads',
  },
  google_vla_ads_clicks: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Clicks',
    infoDescription: 'Total Clicks from your Google VLA Ads',
    xLabel: 'Day/Month',
    yLabel: 'Total Clicks',
    Icon: Google,
    group: 'Google VLA Ads',
  },
  google_vla_ads_impressions: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Impressions',
    infoDescription: 'Total Impressions from your Google VLA Ads',
    xLabel: 'Day/Month',
    yLabel: 'Total Impressions',
    Icon: Google,
    group: 'Google VLA Ads',
  },
  google_vla_ads_ctr: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Click Through Rate',
    infoDescription: 'Total Click Through Rate from your Google VLA Ads',
    xLabel: 'Day/Month',
    yLabel: 'Total Click Through Rate',
    Icon: Google,
    group: 'Google VLA Ads',
  },
  google_vla_ads_performance_by_campaign: {
    visibleLevels: ['Dealership', 'Website'],
    component: PerformanceByCampaign,
    title: 'Performance By Campaign',
    infoDescription:
      'An comparison between VLA campaigns, showing metrics such as impressions, reach, spend & clicks from Google Ads',
    showTimeFrame: false,
    isCustomChart: true,
    Icon: Google,
    group: 'Google VLA Ads',
  },
  /** 
   * Commented out as these are not being used currently
   * It can be used in the future as API calls is ready for these data
  google_vla_ads_vehicle_leads: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Vehicle Leads',
    infoDescription: 'Total Vehicle Leads from your Google VLA Ads',
    xLabel: 'Day/Month',
    yLabel: 'Total Vehicle Leads',
    Icon: Google,
    group: 'Google VLA Ads',
  },
  google_vla_ads_vehicle_phone_calls: {
    visibleLevels: ['Dealership', 'Website'],
    component: GenericModuleComponent,
    excludedChartTypes: ['Pie'],
    defaultChartType: 'Line',
    title: 'Vehicle Phone Calls',
    infoDescription: 'Total Vehicle Phone Calls from your Google VLA Ads',
    xLabel: 'Day/Month',
    yLabel: 'Total Vehicle Phone Calls',
    Icon: Google,
    group: 'Google VLA Ads',
  },
  */
  car_lead_cluster_matrix: {
    visibleLevels: ['Dealership'],
    component: LeadClusterCountByAge,
    title: 'Lead Cluster Count By Age',
    infoDescription: 'Cars grouped by age and count of lead clusters',
    xLabel: 'Day/Month',
    group: 'Leads',
    isCustomChart: true,
  },
  car_stats_by_dealership: {
    visibleLevels: ['Dealership Group'],
    component: CarsStatsByDealership,
    title: 'Car Stats by Dealership',
    infoDescription: 'Cars stock and leads, broken down by Dealership',
    group: 'Cars',
    isCustomChart: true,
  },
}
