import { default as React, useCallback, useState } from 'react'
import { createRoot } from 'react-dom/client'
import {
  ItemOption,
  LabeledCheckbox,
  LabeledInput,
  LabeledNumberInput,
  LabeledSelect,
  LabeledTextarea,
  useActions,
  EmptyPlaceHolder,
  LabeledFileInput,
  ToolHeader,
} from '../../common'
import {
  generateRandomId,
  renderEditSettingsButton,
  renderHiddenModalButton,
} from '../../common/commonUtils'
import Dialog from '../../common/Dialog'

import { capitalise, unique } from '../../../entries/utils'

import { TabsPreview } from './ModelFeaturesPreview'

const starters = {
  empty: {
    type: 'tabs',
    componentHeight: 500,
    modelFeatures: [],
    overlay: true,
  },
  tabs: {
    type: 'tabs',
    componentHeight: 500,
    overlay: true,
    modelFeatures: [
      {
        id: generateRandomId(10),
        heading: 'Heading1',
        subheading: 'Subheading1',
        tab: 'Tab1',
        description: 'Description1',
        image: {
          url: 'https://res.cloudinary.com/total-dealer/image/upload/v1681367513/production/z5odmu0za8wtq9woamhqe25pwv5g',
        },
      },
      {
        id: generateRandomId(10),
        heading: 'Heading2',
        subheading: 'Subheading2',
        tab: 'Tab2',
        description: 'Description2',
        image: {
          url: 'https://res.cloudinary.com/total-dealer/image/upload/v1681367513/production/z5odmu0za8wtq9woamhqe25pwv5g',
        },
      },
      {
        id: generateRandomId(10),
        heading: 'Heading3',
        subheading: 'Subheading3',
        tab: 'Tab3',
        description: 'Description3',
        image: {
          url: 'https://res.cloudinary.com/total-dealer/image/upload/v1681367513/production/z5odmu0za8wtq9woamhqe25pwv5g',
        },
      },
    ],
  },
}

const initialState = (data) => {
  return {
    starter: 'empty',
    activated: !!data.activated || data.modelFeatures.length > 0,
    modelFeatures: data.modelFeatures || [],
    type: data.type,
    componentHeight: data.componentHeight,
    overlay: data.overlay,
  }
}

const defaultItem = () => {
  return {
    id: unique(),
    heading: 'Heading',
    subheading: 'Subheading',
    tab: 'Tab',
    description: 'Description',
  }
}

export const FeatureEditor = ({ index, item, updateItem, deleteItem, moveUp, imageEndpoint }) => {
  const [edit, setEdit] = useState(true)
  return (
    <ItemOption
      edit={edit}
      index={index}
      item={item}
      title="Feature"
      hideBtnText
      setEdit={setEdit}
      moveUp={moveUp}
      deleteItem={deleteItem}
      hidePreview
    >
      {/* Form field Inputs */}
      <div className="row">
        <div className="col-12 mt-2">
          <LabeledInput
            item={item}
            itemName="heading"
            label="Feature Heading"
            updateItem={updateItem}
          />
        </div>
        <div className="col-12 mt-2">
          <LabeledInput
            item={item}
            itemName="subheading"
            label="Feature Subheading"
            updateItem={updateItem}
          />
        </div>
        <div className="col-12 mt-2">
          <LabeledInput
            item={item}
            itemName="tab"
            label="Feature Tab Text"
            updateItem={updateItem}
          />
        </div>
        <div className="col-12 mt-2">
          <LabeledTextarea
            item={item}
            itemName="description"
            label="Feature Description"
            updateItem={updateItem}
          />
        </div>
        <div className="col-12 mt-2">
          <LabeledFileInput
            item={item}
            itemName="image"
            file={item.image}
            updateItem={updateItem}
            imageEndpoint={imageEndpoint}
          />
        </div>
      </div>
    </ItemOption>
  )
}

const RenderedModelFeaturesTool = ({ data, toolInfo, onDataChange, imageEndpoint, uniqueId }) => {
  const [state, setState] = useState(initialState(data))
  const [items, setItems] = useState(data.modelFeatures || [])
  const [starter, setStarter] = useState('empty')

  const { type, componentHeight, overlay, activated } = state

  // Open the modal by default if the tool is not activated yet
  const [show, setShow] = useState(activated ? false : true)

  /**
   * Update the items that are added/removed
   */
  const syncedItemsUpdate = useCallback(
    (updatedItems) => {
      setItems([...updatedItems])
      onDataChange({
        modelFeatures: [...updatedItems],
      })
    },
    [onDataChange]
  )

  /**
   * Update the common fields such as section heading or subheading
   */
  const syncedStateUpdate = useCallback(
    (item) => {
      setState({
        ...item,
        starter: state.starter,
      })
      onDataChange(item)
    },
    [state.starter, onDataChange]
  )

  const actions = useActions({
    items,
    defaultItem,
    syncedUpdate: syncedItemsUpdate,
  })

  const addStarterContent = () => {
    const initialBlocks = starters[starter]
    onDataChange({ ...initialBlocks, activated: true })
    setItems(initialBlocks.modelFeatures)
    syncedStateUpdate({ ...initialBlocks, activated: true })
  }

  return (
    <>
      {activated ? (
        type === 'tabs' ? (
          <TabsPreview componentHeight={componentHeight} overlay={overlay} items={items} />
        ) : (
          <>{/* Will add the others once Chery is done */}</>
        )
      ) : (
        <span className="text-danger">Please select a starter...</span>
      )}

      <Dialog title="Model Features" show={show} closeClickHandler={() => setShow(false)}>
        <ToolHeader {...toolInfo} addItem={actions.addItem} />
        <div className="pt-3 border-top">
          <div className="row">
            {activated ? (
              <>
                <div className="col-12 col-md-6">
                  <LabeledSelect
                    controlled={false}
                    item={state}
                    itemName="type"
                    label="Model Features Variant"
                    updateItem={syncedStateUpdate}
                    options={['tabs']} // Add more after getting Chery working
                  />
                </div>
                <div className="col-12 col-md-6">
                  <LabeledNumberInput
                    controlled={false}
                    item={state}
                    itemName="componentHeight"
                    label="Component Height (px)"
                    placeholder="500"
                    updateItem={syncedStateUpdate}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <div className="pl-4">
                    <LabeledCheckbox
                      controlled={false}
                      item={state}
                      itemName="overlay"
                      updateItem={syncedStateUpdate}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="row">
                    {items.length > 0 ? (
                      items.map((item, id) => (
                        <div
                          className={'col-lg-6 ' + (id !== items.length - 1 ? 'mb-3' : '')}
                          key={item.id}
                        >
                          <FeatureEditor
                            index={id}
                            item={item}
                            imageEndpoint={imageEndpoint}
                            {...actions}
                          />
                        </div>
                      ))
                    ) : (
                      <EmptyPlaceHolder itemName="card" />
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div className="col-md-8 col-lg-6 col-xl-4">
                <div className="form-group">
                  <label htmlFor="starter" className="form-label">
                    Select a starter option
                  </label>
                  <select
                    id={'starter'}
                    className="form-control"
                    value={starter}
                    onChange={(e) => setStarter(e.target.value)}
                  >
                    {Object.keys(starters).map((option) => (
                      <option key={option} value={option}>
                        {capitalise(option)}
                      </option>
                    ))}
                  </select>
                </div>
                <button className="btn btn-primary" onClick={addStarterContent}>
                  Get Started
                </button>
              </div>
            )}
          </div>
        </div>
      </Dialog>
      {/* Hidden button that handles opening the settings modal */}
      {renderHiddenModalButton(uniqueId, setShow)}
    </>
  )
}

class ModelFeaturesTool {
  constructor({ data, api, config, block }) {
    this.api = api
    this.config = config
    this.blockAPI = block
    this.uniqueId = unique()

    const defaultData = {
      activated: false,
      modelFeatures: [],
      type: 'tabs',
      componentHeight: 500,
      overlay: true,
    }

    this.data = Object.keys(data).length ? data : defaultData

    this.nodes = {
      holder: null,
    }

    this.toolInfo = {
      heading: undefined,
      helpText: `Display information about the features of a Model with images and text.`,
      itemName: 'Model Feature',
      hideToggle: true,
    }
  }

  static get toolbox() {
    return {
      title: 'Model Features',
      icon: `<svg stroke="currentColor" fill="none" stroke-width="1" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.8 19.817l-2.172 1.138a.392 .392 0 0 1 -.568 -.41l.415 -2.411l-1.757 -1.707a.389 .389 0 0 1 .217 -.665l2.428 -.352l1.086 -2.193a.392 .392 0 0 1 .702 0l1.086 2.193l2.428 .352a.39 .39 0 0 1 .217 .665l-1.757 1.707l.414 2.41a.39 .39 0 0 1 -.567 .411l-2.172 -1.138z"></path><path d="M6.2 19.817l-2.172 1.138a.392 .392 0 0 1 -.568 -.41l.415 -2.411l-1.757 -1.707a.389 .389 0 0 1 .217 -.665l2.428 -.352l1.086 -2.193a.392 .392 0 0 1 .702 0l1.086 2.193l2.428 .352a.39 .39 0 0 1 .217 .665l-1.757 1.707l.414 2.41a.39 .39 0 0 1 -.567 .411l-2.172 -1.138z"></path>
        <path d="M12 9.817l-2.172 1.138a.392 .392 0 0 1 -.568 -.41l.415 -2.411l-1.757 -1.707a.389 .389 0 0 1 .217 -.665l2.428 -.352l1.086 -2.193a.392 .392 0 0 1 .702 0l1.086 2.193l2.428 .352a.39 .39 0 0 1 .217 .665l-1.757 1.707l.414 2.41a.39 .39 0 0 1 -.567 .411l-2.172 -1.138z"></path>
      </svg>`,
    }
  }

  render() {
    const rootNode = document.createElement('div')
    this.nodes.holder = rootNode

    const onDataChange = (newData) => {
      this.data = {
        ...this.data,
        ...newData,
      }
      this.config.save()
      // Force editor onChange event
      this.blockAPI.dispatchChange()
    }

    const root = createRoot(rootNode)
    root.render(
      <RenderedModelFeaturesTool
        onDataChange={onDataChange}
        data={this.data}
        toolInfo={this.toolInfo}
        imageEndpoint={this.config.imageUrl}
        uniqueId={this.uniqueId}
      />
    )

    return this.nodes.holder
  }

  /** Create the settings panel for the block */
  renderSettings() {
    const wrapper = document.createElement('div')

    // Add edit button
    const editButton = renderEditSettingsButton(this.uniqueId)

    wrapper.appendChild(editButton)

    return wrapper
  }

  async save() {
    return this.data
  }
}

export default ModelFeaturesTool
