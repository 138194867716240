import { useState, useContext, useEffect } from 'react'
import showToast from '../shared/ShowToast'
import { SearchContext } from '../contexts'
import { Dialog } from 'primereact/dialog'
import Select from 'react-select'
import { useParams } from 'react-router-dom'
import { standardHeaders } from '../entries/utils'
import { useHits } from 'react-instantsearch'
import { Checkbox } from 'primereact/checkbox'

export const SelectedHitsWrapper = ({ children }) => {
  const [selectedHits, setSelectedHits] = useState([])

  // Add in UI notifcation to let users know the job is queued
  function handleSelectHit(carId) {
    setSelectedHits((prevSelectedHits) => {
      if (prevSelectedHits.includes(carId)) {
        return prevSelectedHits.filter((id) => id !== carId)
      } else {
        return [...prevSelectedHits, carId]
      }
    })
  }

  return (
    <SearchContext.Provider value={{ handleSelectHit, selectedHits, setSelectedHits }}>
      {children}
    </SearchContext.Provider>
  )
}

let selectStyle = {
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
}

export const SelectAllHits = () => {
  let [checked, setChecked] = useState(false)
  let { setSelectedHits } = useContext(SearchContext)
  let { items } = useHits()

  const handleCheckboxChange = (e) => {
    setChecked(e.target.checked)
    if (e.target.checked) {
      setSelectedHits(items.map((hit) => hit.id))
    } else {
      setSelectedHits([])
    }
  }

  return (
    <div className="mt-2">
      <Checkbox
        inputId="select-all-hits"
        value="Select All"
        onChange={handleCheckboxChange}
        checked={checked}
      />
      <label htmlFor="select-all-hits" className="ml-2 mb-0">
        Select All ({items.length})
      </label>
    </div>
  )
}

const TagMultiple = ({ selectedHits, setRecentlyUpdatedCars }) => {
  let [visible, setVisible] = useState(false)
  let [availableTags, setAvailableTags] = useState(false)
  let [tag, setTag] = useState(null)
  let [loading, setLoading] = useState(false)
  let { dealershipSlug } = useParams()
  let [loadingSubmit, setLoadingSubmit] = useState(false)

  useEffect(() => {
    if (visible) {
      setLoading(true)
      // Fetch the available car tags
      fetch(`/api/v1/dealerships/${dealershipSlug}/tags.json`)
        .then((res) => res.json())
        .then((res) => {
          setAvailableTags(res.tags)
          setLoading(false)
        })
    }
  }, [visible])

  const Submit = () => {
    setLoadingSubmit(true)

    fetch(`/dealerships/${dealershipSlug}/cars/update_multiple`, {
      method: 'PUT',
      headers: standardHeaders,
      body: JSON.stringify({
        car_ids: selectedHits,
        tag: tag,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        const carMap = new Map(res.cars.map((c) => [c.id, c.tag]))

        setRecentlyUpdatedCars((prevCars) =>
          prevCars.map((car) => (carMap.has(car.id) ? { ...car, tag: carMap.get(car.id) } : car))
        )
        setLoadingSubmit(false)
        setVisible(false)
      })
  }

  return (
    <>
      <Dialog
        header="Tag Multiple"
        visible={visible}
        style={{ width: '50vw' }}
        onHide={() => setVisible(false)}
        dismissableMask
      >
        <p className="font-weight-bold">Tagging: {selectedHits.length} cars</p>
        {loading ? (
          'loading...'
        ) : (
          <>
            <Select
              options={
                availableTags &&
                availableTags.map((o) => {
                  return { value: o, label: o ? o : 'No Tag' }
                })
              }
              onChange={(e) => setTag(e.value)}
              isClearable={true}
              placeholder="Tag..."
              styles={selectStyle}
              menuPortalTarget={document.body}
            />
            <button
              onClick={() => Submit()}
              className={`btn btn-outline-success btn-block mt-2 ${loadingSubmit ? 'disabled' : ''}`}
              disabled={loadingSubmit}
            >
              {loadingSubmit ? (
                <>
                  <i className="fa fa-circle-notch fa-spin mr-2" />
                  Loading...
                </>
              ) : (
                'Update'
              )}
            </button>
          </>
        )}
      </Dialog>
      <button className="dropdown-item" onClick={() => setVisible(true)}>
        Tag Multiple
      </button>
    </>
  )
}

export const MultiSelectDropdown = ({ notification, setRecentlyUpdatedCars }) => {
  let { selectedHits, handleSelectHit } = useContext(SearchContext)

  function printSelectedHits(selectedHits) {
    if (selectedHits.length === 0) {
      showToast(
        notification,
        'warning',
        'No documents have been selected',
        'Please select documents to export.'
      )
      return
    }
    fetch('/cars/export_pdfs', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ car_ids: selectedHits }),
    })
      .then((response) => response.json())
      .then((data) => {
        showToast(
          notification,
          'success',
          'Documents are being exported',
          'You will receive an email when they are ready.'
        )
      })
      .catch((error) => {
        console.error(error)
        showToast(notification, 'error', 'Error exporting documents', 'Please contact support.')
      })
  }

  return (
    <div className="dropdown ml-2">
      <button
        className={`btn btn-info dropdown-toggle ${selectedHits.length === 0 ? 'disabled' : ''}`}
        disabled={selectedHits.length === 0}
        data-toggle="dropdown"
      >
        {selectedHits.length} Cars
      </button>
      <div className="dropdown-menu dropdown-menu-right">
        <button className="dropdown-item" onClick={() => printSelectedHits(selectedHits)}>
          Print Selected Hits
        </button>
        <TagMultiple selectedHits={selectedHits} setRecentlyUpdatedCars={setRecentlyUpdatedCars} />
      </div>
    </div>
  )
}
