import React from 'react'

import * as Routes from '../../routes'
import { Dealership, Email } from './types'
import moment from 'moment'
import { humanize } from '../entries/utils'

interface RecentEmailsSectionProps {
  dealership: Dealership
  emails: Email[]
}

const RecentEmailsSection: React.FC<RecentEmailsSectionProps> = ({ dealership, emails }) => (
  <div className="box mb-3">
    <div className="p-3">
      <h4>
        <i className="fa fa-envelope mr-1" /> Recent Emails
      </h4>
    </div>
    <table className="table">
      <thead>
        <tr>
          <th>Subject</th>
          <th>Status</th>
          <th>Sent At</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {emails.map((email) => (
          <tr key={email.id}>
            <td>
              <i className="fa fa-envelope" /> {email.subject || 'No subject'}
            </td>
            <td
              className={
                ['Opened', 'open', 'click'].includes(email.status)
                  ? 'text-success'
                  : 'text-secondary'
              }
            >
              {humanize(email.status)}
            </td>
            <td>{moment(email.sent_at).fromNow()}</td>
            <td>
              <a
                className="btn btn-outline-primary"
                href={Routes.dealership_email_path(dealership, email)}
              >
                View
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)

export default RecentEmailsSection
