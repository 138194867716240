import { Dispatch, SetStateAction, useState } from 'react'
import { parameterize } from '../../../common/commonUtils'

interface RadioCardProps {
  title: string
  description: string
  value: string
  imageSrc?: string
  isChecked: boolean
  setSelected: Dispatch<SetStateAction<string>>
}

const RadioCard: React.FC<RadioCardProps> = ({
  title,
  description,
  value,
  imageSrc,
  isChecked,
  setSelected,
}) => {
  const id = `${parameterize(title || '')}-${Math.floor(Math.random() * 1000)}`

  return (
    <div className="col-12 col-md-4 mb-3 pl-0 pr-4">
      <label
        htmlFor={id}
        className="mb-0 d-flex flex-column justify-content-between rounded shadow overflow-hidden"
        id={'Card' + id}
        style={{
          cursor: 'pointer',
          color: isChecked ? 'white' : 'black',
          backgroundColor: isChecked ? 'var(--primary)' : 'white',
        }}
        onClick={() => setSelected(value)}
      >
        {imageSrc && (
          <div style={{ height: 150 }}>
            <img
              src={imageSrc}
              alt={title}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              draggable={false}
            />
          </div>
        )}
        <div className="p-4">
          <h4 className="font-weight-bold mb-1">{title}</h4>
          <p className="mb-0">{description}</p>
        </div>
      </label>
    </div>
  )
}

interface CardOption {
  title: string
  description: string
  value: string
  image: { url: string }
}

const RadioCardInput = ({
  item,
}: {
  item: { label: string; required: boolean; cardOptions: CardOption[]; defaultSelected: string }
}) => {
  const [selected, setSelected] = useState(item.defaultSelected)

  return (
    <div className="form-group mb-0">
      <label className="form-label">
        {item.label}
        {item.required && <span className="text-danger">*</span>}
      </label>
      <div className="d-flex flex-wrap">
        {item?.cardOptions?.map((option, index) => {
          // If the option is missing all the optional fields OR a value, don't render it
          if (
            (!option?.image?.url &&
              (!option?.title || option.title === '') &&
              (!option.description || option.description === '')) ||
            !option.value ||
            option.value === ''
          )
            return null

          return (
            <RadioCard
              key={`${option.title}-${index}`}
              title={option.title}
              description={option.description}
              value={option.value}
              imageSrc={option?.image?.url}
              isChecked={selected === option.value}
              setSelected={setSelected}
            />
          )
        })}
      </div>
    </div>
  )
}

export default RadioCardInput
