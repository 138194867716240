import { useConfig, useItems } from '../../../contexts/hooks'
import { ModuleItem } from '../../../types'
import { isEmptyObject } from '../../../utils'

export const useValidExpandPositions = (itemIndex: number) => {
  const { config } = useConfig()
  const { itemsLength } = useItems()
  const validSmallLayoutExpandPositions =
    config?.layoutSize === 'small' &&
    ((itemsLength === 1 && itemIndex === 0) ||
      (itemsLength === 5 && itemIndex === 4) ||
      (itemsLength === 9 && itemIndex === 8) ||
      (itemsLength === 13 && itemIndex === 12))

  const validMediumLayoutExpandPositions =
    config?.layoutSize === 'medium' &&
    ((itemsLength === 1 && itemIndex === 0) ||
      (itemsLength === 4 && itemIndex === 3) ||
      (itemsLength === 7 && itemIndex === 6) ||
      (itemsLength === 10 && itemIndex === 9) ||
      (itemsLength === 13 && itemIndex === 12))

  const validLargeLayoutExpandPositions =
    config?.layoutSize === 'large' &&
    ((itemsLength === 1 && itemIndex === 0) ||
      (itemsLength === 3 && itemIndex === 2) ||
      (itemsLength === 5 && itemIndex === 4) ||
      (itemsLength === 7 && itemIndex === 6) ||
      (itemsLength === 9 && itemIndex === 8) ||
      (itemsLength === 11 && itemIndex === 10) ||
      (itemsLength === 13 && itemIndex === 12))

  return {
    validSmallLayoutExpandPositions,
    validMediumLayoutExpandPositions,
    validLargeLayoutExpandPositions,
  }
}

export const useModuleData = (
  data: any,
  item: ModuleItem,
  editingPreviewData: { key: ModuleItem[] } | null,
  editModeEnabled: boolean
) => {
  // Show preview data first, if it exists and isn't empty. Then check is data exists and isn't empty. If they are empty set to []
  const notEmptyPreviewData = isEmptyObject(editingPreviewData?.[item?.module])
    ? []
    : editingPreviewData?.[item?.module]

  const notEmptyData = isEmptyObject(data?.[item?.module]) ? [] : data?.[item?.module]

  const dataToShow =
    editModeEnabled && editingPreviewData?.[item?.module] ? notEmptyPreviewData : notEmptyData

  return dataToShow
}
