import { RefinementList } from 'react-instantsearch'
import {
  FacetDropdown,
  type DropdownButtonTextOptions,
} from '../../facetDropdown/components/FacetDropdown'

const RefinementListSelect = ({
  attribute,
  buttonText,
  ...props
}: {
  attribute: string
  buttonText: string | ((options: DropdownButtonTextOptions) => string)
} & typeof RefinementList) => (
  <FacetDropdown buttonText={buttonText}>
    <RefinementList attribute={attribute} {...props} />
  </FacetDropdown>
)

export default RefinementListSelect
