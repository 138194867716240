import { useState, useEffect, useRef } from 'react'
import { Link, useParams } from 'react-router-dom'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Toast } from 'primereact/toast'

import showToast from './../shared/ShowToast'

const DealershipEmailAddressesIndex = () => {
  const notification = useRef(null)
  let { dealershipSlug } = useParams()
  let [dealershipEmailAddresses, setDealershipEmailAddresses] = useState([])

  useEffect(() => {
    fetchDealershipEmailAddresses(dealershipSlug)
  }, [])

  const ActionButtons = (dealershipEmailAddress) => {
    return (
      <>
        <Link
          to={Routes.edit_dealership_dealership_email_address_path(
            dealershipSlug,
            dealershipEmailAddress.id
          )}
          className={'btn btn-outline-primary btn-sm mr-3'}
        >
          <i className="fa fa-edit"></i>
        </Link>
        <a
          className={'btn btn-outline-primary btn-sm'}
          onClick={() => {
            destroyDealershipEmailAddress(dealershipEmailAddress)
          }}
        >
          <i className="fa fa-trash"></i>
        </a>
      </>
    )
  }

  const destroyDealershipEmailAddress = async (dealershipEmailAddress) => {
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute('content')
    const userConfirmation = window.confirm('Are you sure?')

    if (!userConfirmation) {
      return
    }

    try {
      await fetch(
        `${Routes.dealership_dealership_email_address_path(
          dealershipSlug,
          dealershipEmailAddress.id
        )}.json`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrf,
          },
          credentials: 'same-origin',
        }
      )
      await fetchDealershipEmailAddresses(dealershipSlug)
      showToast(notification, 'success', 'Delete successful', '')
    } catch (err) {
      console.error('Error deleting dealership email address:', err)
      showToast(notification, 'error', 'Error deleting dealership email address.', '')
    }
  }

  const fetchDealershipEmailAddresses = async (dealershipSlug) => {
    try {
      const URL = Routes.dealership_dealership_email_addresses_path(dealershipSlug)
      const response = await fetch(URL, {
        headers: {
          Accept: 'application/json',
        },
      })
      const data = await response.json()
      setDealershipEmailAddresses(data)
    } catch (error) {
      console.error('Error fetching dealership email addresses:', error)
      showToast(notification, 'error', 'Error fetching dealership email addresses.', '')
    }
  }

  return (
    <>
      <Toast ref={notification} />
      <div className="box">
        <div className="p-3">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <h5 className="m-0">Dealership Email Addresses</h5>
            <Link
              to={Routes.new_dealership_dealership_email_address_path(dealershipSlug)}
              className={'btn btn-outline-primary btn-sm'}
            >
              <i className="fa fa-plus" /> New
            </Link>
          </div>
          <DataTable value={dealershipEmailAddresses}>
            <Column field="email_address" header="Email" />
            <Column field="service" header="Service" />
            <Column field="dkim_verified" header="DKIM Verified" />
            <Column field="spf_verified" header="SPF Verified" />
            <Column field="verified" header="Verified" />
            <Column body={ActionButtons} header=""></Column>
          </DataTable>
        </div>
      </div>
    </>
  )
}

export default DealershipEmailAddressesIndex
