import { default as React, useState, useCallback } from 'react'
import { createRoot } from 'react-dom/client'
import {
  ToolHeader,
  ItemOption,
  EmptyPlaceHolder,
  LabeledInput,
  LabeledColorInput,
  LabeledSelect,
} from '../common'
import { renderEditSettingsButton, renderHiddenModalButton } from '../common/commonUtils'
import { useActions } from '../common/ActionButtons'
import { bootstrapColours } from '../common/constants'
import Dialog from '../common/Dialog'

import { unique } from '../../entries/utils'

const defaultItem = () => {
  return {
    id: unique(),
    text: 'Tag',
    backgroundColor: 'primary',
    customBackgroundColor: '#000000',
    textColor: 'light',
    customTextColor: '#FFFFFF',
  }
}

const TagPreview = ({ item }) => {
  return (
    <div
      className="d-inline-block px-2 mx-1 rounded py-1"
      style={{
        backgroundColor:
          item.backgroundColor === 'custom'
            ? item.customBackgroundColor
            : `var(--${item.backgroundColor})`,
        color: item.textColor === 'custom' ? item.customTextColor : `var(--${item.textColor})`,
      }}
    >
      {item.text}
    </div>
  )
}

export const TagEditor = ({ index, item, updateItem, deleteItem, moveUp }) => {
  const [edit, setEdit] = useState(true)

  const editable = updateItem && edit

  const colorOptions = Array.from(bootstrapColours)
  colorOptions.push('custom')

  return (
    <ItemOption
      edit={edit}
      index={index}
      item={item}
      title="Tag"
      setEdit={setEdit}
      moveUp={moveUp}
      deleteItem={deleteItem}
    >
      {editable ? (
        // Form field Inputs
        <div className="row">
          <div className="col-12 col-md-6">
            <LabeledInput item={item} itemName="text" label="Tag Text" updateItem={updateItem} />
          </div>
          <div className="col-12 col-md-6">
            <LabeledSelect
              item={item}
              itemName="backgroundColor"
              label="Background Colour"
              updateItem={updateItem}
              options={colorOptions}
            />
          </div>
          {item.backgroundColor === 'custom' && (
            <div className="col-12 col-md-6">
              <LabeledColorInput
                item={item}
                itemName="customBackgroundColor"
                label="Custom Background Colour"
                updateItem={updateItem}
                options={colorOptions}
                color={item.customBackgroundColor}
              />
            </div>
          )}
          <div className="col-12 col-md-6">
            <LabeledSelect
              item={item}
              itemName="textColor"
              updateItem={updateItem}
              options={colorOptions}
            />
          </div>
          {item.textColor === 'custom' && (
            <div className="col-12 col-md-6">
              <LabeledColorInput
                item={item}
                itemName="customTextColor"
                label="Custom Text Colour"
                updateItem={updateItem}
                options={colorOptions}
                color={item.customTextColor}
              />
            </div>
          )}
        </div>
      ) : (
        <TagPreview item={item} />
      )}
    </ItemOption>
  )
}

const RenderedTagsComponent = ({ onDataChange, data, toolInfo, uniqueId }) => {
  const [show, setShow] = useState(false)
  const [items, setItems] = useState(data.tags || [])

  const syncedUpdate = useCallback(
    (updatedItems) => {
      setItems([...updatedItems])
      onDataChange({
        tags: [...updatedItems],
      })
    },
    [onDataChange]
  )

  const actions = useActions({
    items,
    defaultItem,
    syncedUpdate,
  })

  return (
    <>
      {items?.map((item) => (
        <TagPreview key={item.id} item={item} />
      ))}
      <Dialog title="Tags" show={show} closeClickHandler={() => setShow(false)}>
        <ToolHeader {...toolInfo} addItem={actions.addItem} />
        {items.length > 0 ? (
          items.map((item, id) => <TagEditor key={item.id} index={id} item={item} {...actions} />)
        ) : (
          <EmptyPlaceHolder itemName="tag" />
        )}
      </Dialog>
      {/* Hidden button that handles opening the settings modal */}
      {renderHiddenModalButton(uniqueId, setShow)}
    </>
  )
}

class TagsTool {
  constructor({ data, config, api, block }) {
    this.blockAPI = block
    this.api = api
    this.config = config
    this.uniqueId = unique()

    let defaultValue = {
      tags: [defaultItem()], // Contain a tag by default
    }

    this.data = Object.keys(data).length > 0 ? data : defaultValue

    this.nodes = {
      holder: null,
    }

    this.toolInfo = {
      heading: undefined,
      helpText:
        'Displays tags. *Note - Primary defaults to blue in our dashboard, but on your website it will be based on the primary colour of your theme.',
      itemName: 'Tag',
      hideToggle: true,
    }
  }

  static get toolbox() {
    return {
      title: 'Tags',
      icon: `
        <svg stroke="currentColor" fill="none" stroke-width="0" viewBox="0 0 15 15" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M2 5H13C13.5523 5 14 5.44772 14 6V9C14 9.55228 13.5523 10 13 10H2C1.44772 10 1 9.55228 1 9V6C1 5.44772 1.44772 5 2 5ZM0 6C0 4.89543 0.895431 4 2 4H13C14.1046 4 15 4.89543 15 6V9C15 10.1046 14.1046 11 13 11H2C0.89543 11 0 10.1046 0 9V6ZM4.5 6.75C4.08579 6.75 3.75 7.08579 3.75 7.5C3.75 7.91421 4.08579 8.25 4.5 8.25C4.91421 8.25 5.25 7.91421 5.25 7.5C5.25 7.08579 4.91421 6.75 4.5 6.75ZM6.75 7.5C6.75 7.08579 7.08579 6.75 7.5 6.75C7.91421 6.75 8.25 7.08579 8.25 7.5C8.25 7.91421 7.91421 8.25 7.5 8.25C7.08579 8.25 6.75 7.91421 6.75 7.5ZM10.5 6.75C10.0858 6.75 9.75 7.08579 9.75 7.5C9.75 7.91421 10.0858 8.25 10.5 8.25C10.9142 8.25 11.25 7.91421 11.25 7.5C11.25 7.08579 10.9142 6.75 10.5 6.75Z" fill="currentColor"></path>
        </svg>
      `,
    }
  }

  render() {
    const rootNode = document.createElement('div')
    this.nodes.holder = rootNode

    const onDataChange = (newData) => {
      this.data = {
        ...this.data,
        ...newData,
      }
      this.config.save()
      // Force editor onChange event
      this.blockAPI.dispatchChange()
    }

    const root = createRoot(rootNode)
    root.render(
      <RenderedTagsComponent
        onDataChange={onDataChange}
        data={this.data}
        toolInfo={this.toolInfo}
        uniqueId={this.uniqueId}
      />
    )

    return this.nodes.holder
  }

  /** Create the settings panel for the block */
  renderSettings() {
    const wrapper = document.createElement('div')

    // Add edit button
    const editButton = renderEditSettingsButton(this.uniqueId)

    wrapper.appendChild(editButton)

    return wrapper
  }

  async save() {
    return this.data
  }
}

export default TagsTool
