import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { standardHeaders } from '../entries/utils'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import Loading from '../Loading'

const Url = (props) => {
  let url = props.url
  return <div className="small">{url}</div>
}

const Actions = (props) => {
  let { dealershipSlug } = useParams()

  return (
    <div className="d-flex">
      <a
        href={`/dealerships/${dealershipSlug}/qr_codes/${props.id}`}
        className="btn btn-outline-primary btn-sm mr-1"
      >
        <i className="fa fa-eye"></i>
      </a>
      <a
        href={`/dealerships/${dealershipSlug}/qr_codes/${props.id}/edit`}
        className="btn btn-outline-primary btn-sm mr-1"
      >
        <i className="fa fa-pencil"></i>
      </a>
      <a
        href={`/dealerships/${dealershipSlug}/qr_codes/${props.id}`}
        data-method="delete"
        className="btn btn-outline-danger btn-sm"
      >
        <i className="fa fa-trash"></i>
      </a>
    </div>
  )
}

const QrCodes = () => {
  let [loading, setLoading] = useState(true)
  let [qrCodes, setQrCodes] = useState([])
  let [totalCount, setTotalCount] = useState()
  const [page, setPage] = useState(1)
  const rows = 25

  let { dealershipSlug } = useParams()

  useEffect(() => {
    fetch(`/dealerships/${dealershipSlug}/qr_codes?page=${page}&per_page=${rows}`, {
      headers: standardHeaders,
    })
      .then((res) => res.json())
      .then((data) => {
        setQrCodes(data.qr_codes)
        setTotalCount(data.total_count)
        setLoading(false)
      })
  }, [page])

  if (loading) {
    return (
      <div className="p-3">
        <Loading />
      </div>
    )
  }

  const onPageChange = (event) => {
    setPage(event.page + 1) // PrimeReact pages are 0-based; Kaminari pages are 1-based
  }

  return (
    <div className="p-3">
      <div className="d-flex mb-2">
        <h4>QR Codes</h4>
        <div className="ml-auto">
          <a
            href={`/dealerships/${dealershipSlug}/qr_codes/new`}
            className="btn btn-outline-primary"
          >
            <i className="fa fa-plus mr-1"></i>
            QR Code
          </a>
        </div>
      </div>
      <div className="box">
        <DataTable
          value={qrCodes}
          paginator
          rows={rows}
          first={(page - 1) * rows}
          totalRecords={totalCount}
          onPage={onPageChange}
          lazy
        >
          <Column field="label" header="Label"></Column>
          <Column field="url" header="URL" body={Url}></Column>
          <Column field="views" header="Views"></Column>
          <Column field="actions" header="Actions" body={Actions}></Column>
        </DataTable>
      </div>
    </div>
  )
}

export default QrCodes
