import EditorJS from '@editorjs/editorjs'
import { default as React, useState, useCallback, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import EditorJsRenderer from '../../common/EditorJsRenderer/EditorJsRenderer'
import { ToolHeader } from '../../common'
import Dialog from '../../common/Dialog'
import Tooltip from '../../common/Tooltip'
import {
  renderHiddenModalButton,
  renderTabs,
  generateRandomId,
  handleEmbeddedEditorActions,
  renderEditSettingsButton,
  removeDuplicateLocations,
  capitalize,
} from '../../common/commonUtils'
import { initialiseStyling } from '../../common/commonUtils'
import { unique } from '../../../entries/utils'
import { Add } from '../../common/Icons'

import { MAX_COLS, MIN_COLS, defaultBlocks, refreshSteps, handleDeleteCol } from './utils'
import { AddColTooltip, DeleteColTooltip } from './ColumnsTooltips'
import ColumnsBlock from '../../common/EditorJsRenderer/blocks/ColumnsBlock'

const ColumnEditor = ({
  stepIndex,
  isActive,
  isModalOpen,
  numCols,
  activeIndexState,
  setActiveIndexState,
  state,
  onDataChange,
  getUpdatedData,
  syncedStateUpdate,
  setSteps,
  config,
}) => {
  // ! Embedded edtior components can't have any internal state management
  const editorJsID = generateRandomId(5)
  const colState = getUpdatedData()?.cols[stepIndex]

  // Div that holds the embedded editor instance
  const ContentEditorHolder = () => (
    <div
      className="w-100 rounded border position-relative modal-embedded-editor-js-instance"
      id={editorJsID}
    ></div>
  )

  const contentEditorjsInstance = new EditorJS({
    defaultBlock: 'paragraph',
    placeholder: 'Add your content...',
    holder: editorJsID,
    tools: config.tools,
    data: { blocks: colState?.blocks ?? [] },
    minHeight: 50,
    onReady: () => {
      if (colState?.blocks) {
        colState?.blocks?.map((block) => {
          const div = document.querySelector(`[data-id='${block.id}']`)
          // Add custom event listeners to the embedded editor blocks
          handleEmbeddedEditorActions(div, contentEditorjsInstance)
          // Initialise styling for blocks
          initialiseStyling(div, block.tunes)
        })
      }
    },
    onChange: async () => {
      // ! Custom tunes work correctly
      // ! in-built tunes (.e.g alignement) are not working
      const saveData = await contentEditorjsInstance.save()
      const tempBlocks = []

      // Loop through columns and ONLY update the current active column
      for (let index = 0; index < numCols; index++) {
        const startingColState = getUpdatedData()?.cols[index]
          ? getUpdatedData()?.cols[index]
          : { blocks: defaultBlocks }

        tempBlocks.push({
          ...startingColState,
          blocks: index === stepIndex ? saveData.blocks : startingColState?.blocks,
        })
      }

      // Save the changes
      onDataChange({
        cols: tempBlocks,
      })

      // Add event listeners to the new blocks
      if (saveData?.blocks) {
        saveData?.blocks?.map((block) => {
          const div = document.querySelector(`[data-id='${block.id}']`)
          // Add custom event listeners to the embedded editor blocks
          handleEmbeddedEditorActions(div, contentEditorjsInstance)
        })
      }
    },
  })

  // Save state updates when changing away from this step
  useEffect(() => {
    if (
      (!isActive && activeIndexState.previousActiveIndex === stepIndex) ||
      (!isModalOpen && activeIndexState.activeIndex === stepIndex)
    ) {
      const updatedData = getUpdatedData()
      syncedStateUpdate({ ...updatedData })
    }
  }, [isActive, isModalOpen])

  return (
    <div className={isActive ? 'd-block' : 'd-none'}>
      <div className="row">
        <div className="col-12 z-2 mb-2" style={{ zIndex: 2 }}>
          <ContentEditorHolder />
        </div>
        <div className="col-12 d-flex justify-content-end">
          {/* Delete Column Button */}
          <Tooltip
            tooltipTrigger={
              <button
                className="btn btn-danger mb-3"
                onClick={() =>
                  handleDeleteCol(
                    stepIndex,
                    getUpdatedData,
                    syncedStateUpdate,
                    setActiveIndexState,
                    state,
                    setSteps,
                    ColumnEditor
                  )
                }
                disabled={numCols === MIN_COLS}
              >
                Delete Column
              </button>
            }
            tooltipContent={DeleteColTooltip.content}
            width={200}
          />
        </div>
      </div>
    </div>
  )
}

const RenderedColumnsComponent = ({
  onDataChange,
  getUpdatedData,
  data,
  config,
  toolInfo,
  locations,
  uniqueId,
}) => {
  const [state, setState] = useState(data) // Tool state
  const [show, setShow] = useState(false) // Modal state
  const [steps, setSteps] = useState([])

  // Track form state (active index, previous active index)
  const [activeIndexState, setActiveIndexState] = useState({
    activeIndex: 0,
    previousActiveIndex: undefined,
  })
  const locationOptions = []
  locations.map((location) =>
    locationOptions.push({
      label: `${location.name} - ${capitalize(location.location_type ?? 'sales')}`,
      value: location.id,
    })
  )

  // Initialise steps data
  useEffect(() => {
    refreshSteps(data, getUpdatedData, setSteps, ColumnEditor)
  }, [])

  // Keep data and state synced
  const syncedStateUpdate = useCallback(
    (item) => {
      setState({ ...item })
      onDataChange({
        ...item,
      })
    },
    [onDataChange]
  )

  return (
    <>
      {/* Render Preview */}
      <ColumnsBlock block={state} locationOptions={locationOptions} />
      {/* Edit Settings Modal */}
      <Dialog title="Columns" show={show} closeClickHandler={() => setShow(false)}>
        <ToolHeader {...toolInfo} hideToggle />
        <div className="pt-3 border-top">
          {renderTabs(
            steps,
            activeIndexState,
            setActiveIndexState,
            undefined,
            <Tooltip
              tooltipTrigger={
                <button
                  className="btn btn-small btn-success d-flex align-items-center ml-2"
                  onClick={() => {
                    const newCol = { blocks: defaultBlocks }
                    const updatedCols = [...getUpdatedData().cols, newCol]

                    syncedStateUpdate({
                      ...getUpdatedData(),
                      cols: updatedCols,
                      colLayout: updatedCols.length,
                    })
                    setActiveIndexState({
                      activeIndex: updatedCols.length - 1,
                      previousActiveIndex: activeIndexState.activeIndex,
                    })
                    refreshSteps(data, getUpdatedData, setSteps, ColumnEditor)
                  }}
                  disabled={state.cols.length === MAX_COLS}
                >
                  <Add size={25} />
                </button>
              }
              tooltipContent={AddColTooltip.content}
              width={200}
            />
          )}
          <div className="row pt-3">
            <div className="col-12">
              <div className="form-group mb-0">
                {/* Render Steps */}
                {steps?.map((step, index) => {
                  const StepComponent = step.component

                  return (
                    <StepComponent
                      key={step.name + index}
                      stepIndex={index}
                      isActive={activeIndexState.activeIndex === index}
                      isModalOpen={show}
                      numCols={steps?.length}
                      activeIndexState={activeIndexState}
                      setActiveIndexState={setActiveIndexState}
                      state={state}
                      onDataChange={onDataChange}
                      getUpdatedData={getUpdatedData}
                      syncedStateUpdate={syncedStateUpdate}
                      setSteps={setSteps}
                      config={config}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      {/* Hidden button that handles opening the settings modal */}
      {renderHiddenModalButton(uniqueId, setShow)}
    </>
  )
}

class ColumnsTool {
  constructor({ data, config, block }) {
    this.config = config
    this.blockAPI = block
    this.uniqueId = unique()
    this.locations = removeDuplicateLocations(this.config.locations)

    const defaultData = {
      cols: [{ blocks: defaultBlocks }, { blocks: defaultBlocks }], // 2 columns by default
      reverse: false,
      colLayout: 2,
    }

    this.data = Object.keys(data).length ? data : defaultData
    // This updates previous column data with a colLayout value
    if (Object.keys(data).length) {
      this.data.colLayout =
        this.data.colLayout === this.data.cols.length ? this.data.colLayout : this.data.cols.length
    }

    this.CSS = {
      wrapper: 'walkthrough-timeline',
    }

    this.toolInfo = {
      heading: undefined,
      helpText: 'Create a row of responsive columns that look great on any device.',
      itemName: 'Column',
    }

    this.nodes = {
      holder: null,
    }
  }

  static get toolbox() {
    return {
      title: 'Columns',
      icon: '<svg stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.75 2h5.5c.966 0 1.75.784 1.75 1.75v16.5A1.75 1.75 0 0 1 9.25 22h-5.5A1.75 1.75 0 0 1 2 20.25V3.75C2 2.784 2.784 2 3.75 2Zm11 0h5.5c.966 0 1.75.784 1.75 1.75v16.5A1.75 1.75 0 0 1 20.25 22h-5.5A1.75 1.75 0 0 1 13 20.25V3.75c0-.966.784-1.75 1.75-1.75ZM3.5 3.75v16.5c0 .138.112.25.25.25h5.5a.25.25 0 0 0 .25-.25V3.75a.25.25 0 0 0-.25-.25h-5.5a.25.25 0 0 0-.25.25Zm11 0v16.5c0 .138.112.25.25.25h5.5a.25.25 0 0 0 .25-.25V3.75a.25.25 0 0 0-.25-.25h-5.5a.25.25 0 0 0-.25.25Z"></path></svg>',
    }
  }

  onDataChange(newData) {
    this.data = {
      ...this.data,
      ...newData,
    }
    this.config.save()
    // Force editor onChange event
    this.blockAPI.dispatchChange()
  }

  renderSettings() {
    const wrapper = document.createElement('div')

    // Add edit button
    const editButton = renderEditSettingsButton(this.uniqueId)

    wrapper.appendChild(editButton)

    return wrapper
  }

  render() {
    const rootNode = document.createElement('div')
    rootNode.setAttribute('class', this.CSS.wrapper)
    this.nodes.holder = rootNode

    const onDataChange = (newData) => {
      this.data = {
        ...this.data,
        ...newData,
      }

      this.config.save()
    }

    const getUpdatedData = () => {
      return this.data
    }

    const root = createRoot(rootNode)
    root.render(
      <RenderedColumnsComponent
        onDataChange={onDataChange}
        getUpdatedData={getUpdatedData}
        data={this.data}
        config={this.config}
        toolInfo={this.toolInfo}
        locations={this.locations}
        uniqueId={this.uniqueId}
      />
    )

    return this.nodes.holder
  }

  save() {
    return this.data
  }
}

export default ColumnsTool
