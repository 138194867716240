import { useState } from 'react'
import { Dialog } from 'primereact/dialog'
import CreatableSelect from 'react-select/creatable'
import { standardHeaders } from '../entries/utils'

const Tags = ({ leadCluster, setLeadCluster, renderButton }) => {
  let [visible, setVisible] = useState(false)
  let [tags, setTags] = useState(leadCluster?.tags || [])
  let [loading, setLoading] = useState(false)

  const onSave = () => {
    setLoading(true)
    fetch(`/dealerships/${leadCluster.dealership_id}/lead_clusters/${leadCluster.id}`, {
      method: 'PATCH',
      headers: standardHeaders,
      body: JSON.stringify({ lead_cluster: { tags } }),
    })
      .then((res) => res.json())
      .then((res) => {
        setLeadCluster(res)
        setLoading(false)
        setVisible(false)
      })
  }

  return (
    <>
      <Dialog
        header="Tags"
        visible={visible}
        style={{ width: '50vw', minHeight: '300px' }}
        onHide={() => setVisible(false)}
        dismissableMask
      >
        <div className="mb-2">Add Tags</div>
        <CreatableSelect
          isClearable
          isMulti
          onChange={(tags) => setTags(tags.map((t) => t.value))}
          value={tags.map((t) => ({ value: t, label: t }))}
        />
        <div
          className={'btn btn-outline-primary mt-3 btn-block' + (loading ? ' disabled' : '')}
          onClick={onSave}
        >
          {loading ? 'Saving...' : 'Save'}
        </div>
      </Dialog>
      {renderButton(setVisible)}
    </>
  )
}

export default Tags
