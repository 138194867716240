import { useEffect, useState } from 'react'
import { time_ago } from '../entries/utils'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { FilterMatchMode } from 'primereact/api'
import DataTableSearch from '../entries/DataTableSearch'
import { Sidebar } from 'primereact/sidebar'
import { Deployments } from '../websites/WebsiteDeployments'
import { csrfToken } from '../entries/utils'
import { Message } from 'primereact/message'

const WebsiteName = (website) => {
  let [visible, setVisible] = useState(false)

  let statusClass

  switch (website.last_build_status) {
    case 'READY':
      statusClass = 'success'
      break
    case 'BUILDING':
      statusClass = 'warning'
      break
    default:
      statusClass = 'danger'
  }

  return (
    <>
      <div>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault()
            setVisible(true)
          }}
        >
          {website.website_name}
        </a>
      </div>
      {website.last_build_status && (
        <div className={`badge badge-${statusClass}`}>Last Build: {website.last_build_status}</div>
      )}
      <Sidebar
        header={`${website.website_name}`}
        visible={visible}
        style={{ width: '60vw' }}
        position="right"
        onHide={() => setVisible(false)}
      >
        <Deployments websiteSlug={website.slug} />
      </Sidebar>
    </>
  )
}

const App = ({}) => {
  let [data, setData] = useState([])
  let [loading, setLoading] = useState(false)

  const fetchData = () => {
    setLoading(true)
    fetch(`/admin/website_builds.json`)
      .then((response) => response.json())
      .then((data) => {
        setData(data)
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  })

  const Action = (website) => {
    return <RebuildLink website={website} />
  }

  const LastBuiltIn = (website) => {
    if (website.last_built_at == 'N/A') {
      return (
        <div>
          <div>{website.last_built_at}</div>
        </div>
      )
    } else {
      return (
        <div>
          <div>{website.last_built_at}</div>
          <div className="small text-secondary">{time_ago(website.last_built_at)}</div>
        </div>
      )
    }
  }

  function RebuildLink({ website }) {
    let [loading, setLoading] = useState(false)

    const handleClick = (event) => {
      setLoading(true)
      event.preventDefault()

      fetch(website.rebuild_url, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': csrfToken,
        },
      }).then(() => {
        fetchData()
      })
    }

    return (
      <a href="#" onClick={handleClick} className={`btn btn-sm btn-outline-secondary`}>
        {loading ? (
          <i className="fa fa-spinner fa-spin" />
        ) : (
          <i className={`fa fa-upload ${!website.website_ready_for_rebuild ? 'disabled' : ''}`} />
        )}
      </a>
    )
  }
  const header = DataTableSearch({ filters: filters, setFilters: setFilters })
  return (
    <div className="p-3 container">
      <div className="mb-3">
        <Message
          severity="info"
          text="Everyday at 3am we rebuild the latest 10 websites (if last vercel build was greater than 1 month ago)"
        />
      </div>
      {loading && <div className="text-center">Loading...</div>}
      {data.website_builds && (
        <div className="box mb-3">
          <div className="p-3">
            <h4>Website Builds</h4>
          </div>
          <DataTable
            value={data.website_builds}
            globalFilterFields={['website_name']}
            className="p-datatable-striped"
            paginator
            rows={25}
            header={header}
            filters={filters}
            filterDisplay="row"
          >
            <Column sortable field="website_name" header="Website" body={WebsiteName} />
            <Column sortable field="last_built_at" body={LastBuiltIn} header="Last Build at" />
            <Column body={Action} header="Rebuild"></Column>
          </DataTable>
        </div>
      )}
    </div>
  )
}

export default App
