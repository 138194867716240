import { CSSProperties, useEffect, useState } from 'react'
import { BarChart, LineChart, PieChart } from './Charts'
import { BarChartOptionsConfig, LineChartOptionsConfig, PieChartOptionsConfig } from '../types'
import { defaultResponsiveLayoutSizeStyle, getModuleComponentFromName } from '../../../utils'
import {
  generateChartDataConfig,
  generateChartOptionsConfig,
  generateMultiChartDataConfig,
  hasMultipleDataSets,
} from '../utils'
import {
  useAnalyticsDashboard,
  useConfig,
  useItems,
  useTabs,
  useTimeRangeLocation,
} from '../../../contexts/hooks'

type ChartComponentProps = {
  chartData: any // TODO: Define chartData type
  isOverlay: boolean
  module: string
}

const ChartComponent: React.FC<ChartComponentProps> = ({ chartData, isOverlay, module }) => {
  const [timeRangeType, setTimeRangeType] = useState<string>('')
  const { chartTypes } = useItems()
  const { selectedTab } = useTabs()

  const { config } = useConfig()
  const { timeRange } = useTimeRangeLocation()
  const { editModeEnabled } = useAnalyticsDashboard()

  // Check if a chart type exists for the current item
  const chartTypeExists = chartTypes[selectedTab] ? module in chartTypes[selectedTab] : false

  // Get the default chart type for the current item
  const moduleComponent = getModuleComponentFromName(module)
  const defaultChartType = moduleComponent?.defaultChartType || 'Bar'

  // Set the chart type to the default if it doesn't exist
  const chartType = chartTypeExists ? chartTypes[selectedTab][module] : defaultChartType

  const chartDataConfig = hasMultipleDataSets(chartData)
    ? generateMultiChartDataConfig({ chartData, chartType })
    : generateChartDataConfig({ chartData, chartType })

  const chartOptionsConfig = generateChartOptionsConfig({
    chartType,
    moduleComponent,
    chartData,
    chartDataConfig,
    isOverlay,
    timeRangeType,
  })

  const responsiveLayoutSizeStyles: CSSProperties =
    config?.layoutSize === 'small'
      ? { height: editModeEnabled ? '100%' : '11vw' }
      : config?.layoutSize === 'medium'
        ? { height: editModeEnabled ? '100%' : '15vw' }
        : {
            height: editModeEnabled ? '100%' : defaultResponsiveLayoutSizeStyle.height,
          }

  useEffect(() => {
    setTimeRangeType(timeRange?.type)
  }, [timeRange])

  return (
    <>
      <div className="h-100" style={{ minHeight: 'inherit' }}>
        {chartType === 'Bar' ? (
          <BarChart
            chartDataConfig={chartDataConfig}
            chartOptionsConfig={chartOptionsConfig as BarChartOptionsConfig}
            responsiveLayoutSizeStyles={responsiveLayoutSizeStyles}
          />
        ) : chartType === 'Line' ? (
          <LineChart
            chartDataConfig={chartDataConfig}
            chartOptionsConfig={chartOptionsConfig as LineChartOptionsConfig}
            responsiveLayoutSizeStyles={responsiveLayoutSizeStyles}
          />
        ) : chartType === 'Pie' ? (
          <PieChart
            chartDataConfig={chartDataConfig}
            chartOptionsConfig={chartOptionsConfig as PieChartOptionsConfig}
            responsiveLayoutSizeStyles={responsiveLayoutSizeStyles}
          />
        ) : null}
      </div>
    </>
  )
}

export default ChartComponent
