import { useEffect, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import LoadingBoxes from '../../../../entries/LoadingBoxes'
import type { ModuleProps, TimeRange } from '../../../types'

import { fetchModuleData } from '../../../api'
import { useViewportData } from '../../../hooks'
import { Dialog } from 'primereact/dialog'
import { useAnalyticsDashboard } from '../../../contexts/hooks'

type LeadAnalyticsData = {
  today_count: number
  raw_statistics: string
  count_by_category: CountByCategory[]
  count_by_source: CountBySource[]
  count_by_dealerships: CountByDealership[]
}

type CountByCategory = {
  category: string
  current: number
  raw: string
}

type CountBySource = {
  source: string
  current: number
  raw: string
}

type CountByDealership = {
  slug: string
  name: string
  raw: string
}

type Subcategories = {
  leads_by_subcategory: [string, number][] | null
  leads_by_lead_state: [string, number][] | null
}

const CategoryCount: React.FC<{
  categoryCount: CountByCategory
  timeRange: TimeRange
}> = ({ categoryCount, timeRange }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [subcategories, setSubcategories] = useState<Subcategories>(null)
  const { leads_by_subcategory: leadsBySubcategory, leads_by_lead_state: leadsByLeadState } =
    subcategories || {}
  const { dashboardLevel, dashboardLevelLoaded, dealership, dealershipGroup, website } =
    useAnalyticsDashboard()
  useEffect(() => {
    if (visible && dashboardLevelLoaded) {
      fetchModuleData({
        module: 'leads_by_subcategory',
        category: categoryCount.category,
        timeRange: timeRange,
        dashboardLevel,
        dashboardLevelLoaded,
        dealership,
        dealershipGroup,
        website,
      }).then((data) => {
        setSubcategories(data)
      })
    }
  }, [visible, dealership, website])

  return (
    <div className="row" key={`category_count_${categoryCount.category}_${categoryCount.current}`}>
      <div className="col-6 py-1" onClick={() => setVisible(true)} style={{ cursor: 'pointer' }}>
        {categoryCount.category}
      </div>
      <div className="col-6 py-1">
        {categoryCount.current} &nbsp;
        <span
          dangerouslySetInnerHTML={{
            __html: categoryCount.raw,
          }}
        />
      </div>
      <Dialog
        header={categoryCount.category}
        visible={visible}
        style={{ width: '50vw' }}
        onHide={() => setVisible(false)}
        contentClassName="m-0 p-0"
        dismissableMask
      >
        <DataTable value={leadsBySubcategory}>
          <Column field="subcategory" header="Subcategory" style={{ width: '50%' }} />
          <Column field="count" header="Leads" style={{ width: '50%' }} />
        </DataTable>
        <div className="p-3">
          <h6>Leads by Leads State</h6>
        </div>
        <DataTable value={leadsByLeadState}>
          <Column field="lead_state" header="State" style={{ width: '50%' }} />
          <Column field="count" header="Leads" style={{ width: '50%' }} />
        </DataTable>
      </Dialog>
    </div>
  )
}

const LeadAnalytics: React.FC<ModuleProps> = ({ timeRange, item, selectedLocation }) => {
  const { data, loading, viewportRef } = useViewportData(item?.module, timeRange, selectedLocation)
  const { dashboardLevel } = useAnalyticsDashboard()

  const {
    today_count: todayCount,
    raw_statistics: rawStatistics,
    count_by_category: countByCategory,
    count_by_source: countBySource,
    count_by_dealerships: countByDealership,
  }: LeadAnalyticsData = data || {}

  return (
    <div ref={viewportRef}>
      {!loading && data ? (
        <div>
          <ul>
            <li>{todayCount || 0} Leads today</li>
            {rawStatistics && (
              <li>
                <div
                  dangerouslySetInnerHTML={{
                    __html: rawStatistics,
                  }}
                />
              </li>
            )}
          </ul>
          <div>
            <div className="row font-weight-bold ">
              <div
                style={{
                  backgroundColor: '#dee2e6',
                  height: '1px',
                  width: '97%',
                  margin: '0 auto',
                }}
              />
              <div className="col-6 py-2">Category</div>
              <div className="col-6 py-2">
                Leads &nbsp;
                <span className="small text-secondary">
                  ({timeRange?.timeframe || 'Last 30 Days'})
                </span>
              </div>
              <div
                style={{
                  backgroundColor: '#dee2e6',
                  height: '2px',
                  width: '97%',
                  margin: '0 auto',
                }}
              />
            </div>
            <div className="mt-2">
              {countByCategory?.map((categoryCount, index) => (
                <CategoryCount
                  key={`category_${index}`}
                  categoryCount={categoryCount}
                  timeRange={timeRange}
                />
              ))}
            </div>
          </div>
          {item?.module !== 'autobot_leads' ? (
            <>
              <div className="row font-weight-bold mt-2">
                <div
                  style={{
                    backgroundColor: '#dee2e6',
                    height: '1px',
                    width: '97%',
                    margin: '0 auto',
                  }}
                />
                <div className="col-6 py-2">Source</div>
                <div className="col-6 py-2">
                  Leads &nbsp;
                  <span className="small text-secondary">
                    ({timeRange?.timeframe || 'Last 30 Days'})
                  </span>
                </div>
                <div
                  style={{
                    backgroundColor: '#dee2e6',
                    height: '2px',
                    width: '97%',
                    margin: '0 auto',
                  }}
                />
              </div>
              <div className="mt-2">
                {countBySource?.map((sourceCount, index) => (
                  <div
                    className="row"
                    key={`source_count_${sourceCount.source}_${sourceCount.current}`}
                  >
                    <div className="col-6 py-1"> {sourceCount.source}</div>
                    <div className="col-6 py-1">
                      {sourceCount.current} &nbsp;
                      <span
                        dangerouslySetInnerHTML={{
                          __html: sourceCount.raw,
                        }}
                      />
                    </div>
                    {index < countBySource.length - 1 && (
                      <div
                        style={{
                          backgroundColor: '#dee2e6',
                          height: '1px',
                          width: '97%',
                          margin: '0 auto',
                        }}
                      />
                    )}
                  </div>
                ))}
              </div>
            </>
          ) : null}
          {dashboardLevel === 'Website' && (
            <div className="row font-weight-bold mt-2">
              <div
                style={{
                  backgroundColor: '#dee2e6',
                  height: '1px',
                  width: '97%',
                  margin: '0 auto',
                }}
              />
              <div className="col-6 py-2">Dealership</div>
              <div className="col-6 py-2">
                Leads &nbsp;
                <span className="small text-secondary">
                  ({timeRange?.timeframe || 'Last 30 Days'})
                </span>
              </div>
              <div
                style={{
                  backgroundColor: '#dee2e6',
                  height: '2px',
                  width: '97%',
                  margin: '0 auto',
                }}
              />
            </div>
          )}
          <div className="mt-2">
            {dashboardLevel == 'Website' &&
              countByDealership?.map((dealershipCount, index) => (
                <div className="row" key={`source_count_${dealershipCount.name}`}>
                  <div className="col-6 py-1">
                    <a href={`/dealerships/${dealershipCount.slug}`}>{dealershipCount.name}</a>
                  </div>
                  <div className="col-6 py-1">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: dealershipCount.raw,
                      }}
                    />
                  </div>
                  {index < countByDealership.length - 1 && (
                    <div
                      style={{
                        backgroundColor: '#dee2e6',
                        height: '1px',
                        width: '97%',
                        margin: '0 auto',
                      }}
                    />
                  )}
                </div>
              ))}
          </div>
        </div>
      ) : (
        <LoadingBoxes />
      )}
      <small className="text-secondary">*Excludes leads marked as "spam"</small>
    </div>
  )
}

export default LeadAnalytics
