import { generateRandomId } from '../../common/commonUtils'

// Constants
export const MAX_COLS = 3
export const MIN_COLS = 2

// Default block values
export const defaultBlocks = [
  {
    id: generateRandomId(10),
    type: 'header',
    data: {
      text: 'Heading...',
      level: 2,
    },
    tunes: {
      alignment: {
        alignment: 'left',
      },
      classname: {
        className: '',
      },
    },
  },
  {
    id: generateRandomId(10),
    type: 'paragraph',
    data: {
      text: 'Text block...',
    },
    tunes: {
      alignment: {
        alignment: 'left',
      },
      classname: {
        className: '',
      },
    },
  },
]

/**
 * Updates the steps of the editor to account for the number of active rows.
 * @param {*} data The current data object.
 * @param {*} getUpdatedData Function to get the updated data object.
 * @param {*} setSteps Function to set the steps of the editor.
 * @param {*} ColumnEditor The editor component for the columns.
 */
export function refreshSteps(data, getUpdatedData, setSteps, ColumnEditor) {
  const tempSteps = []
  const colNumber = data !== undefined ? getUpdatedData().colLayout : 2

  // Add each columns as a step
  for (let index = 0; index < colNumber; index++) {
    tempSteps.push({ name: `Column ${index + 1}`, component: ColumnEditor })
  }

  setSteps(tempSteps)
}

/**
 * Handles the deletion of a column from the tool.
 * @param {*} index The index of the column to delete.
 * @param {*} getUpdatedData Function to get the updated data object.
 * @param {*} syncedStateUpdate Function to update the state of the editor.
 * @param {*} setActiveIndexState Function to set the active index of the editor.
 * @param {*} data The current data object.
 * @param {*} setSteps Function to set the steps of the editor.
 * @param {*} ColumnEditor The editor component for the columns.
 */
export function handleDeleteCol(
  index,
  getUpdatedData,
  syncedStateUpdate,
  setActiveIndexState,
  data,
  setSteps,
  ColumnEditor
) {
  // Confirm delete action
  if (
    window.confirm(
      'Are you sure you want to delete this column? This action will delete all data within this column.'
    )
  ) {
    const updatedCols = []
    getUpdatedData().cols.map((col, colIndex) => {
      if (colIndex !== index) {
        updatedCols.push(col)
      }
    })

    syncedStateUpdate({
      ...getUpdatedData(),
      cols: updatedCols,
      colLayout: updatedCols.length,
    })
    setActiveIndexState({
      activeIndex: index === 0 ? 0 : index - 1,
      previousActiveIndex: undefined,
    })

    refreshSteps(data, getUpdatedData, setSteps, ColumnEditor)
  }
}
