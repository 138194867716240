import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import * as Routes from '../../../routes'
import { Link } from 'react-router-dom'

import { Title, Contact, Status, Location, LastEditBy, CreatedAt } from '../Search'
import { useFetchDealership } from '../../dataHooks'

const editButton = (testDrive) => {
  return (
    <a
      className="btn btn-primary"
      href={Routes.edit_dealership_test_drive_path(testDrive.dealership_id, testDrive.id)}
    >
      <i className="fa fa-edit mr-1"></i>
      Edit
    </a>
  )
}

const TestDrivesBox = ({ testDrives, ownerType }) => {
  let { dealership } = useFetchDealership()
  const firstColumnComponent = ownerType === 'contact' ? Title : Contact
  const firstColumnHeader = ownerType === 'contact' ? 'Title' : 'Contact'
  const firstColumnField = ownerType === 'contact_name' ? 'title' : 'contact_name'

  // TODO: Implement a "Add Car Loan" button that prefills the form with car or contact info, depending on the ownerType
  return (
    <div className="box mb-3">
      <div className="p-3">
        <div className="d-flex">
          <h4 className="mb-1">
            <i className="fa fa-steering-wheel mr-1"></i>Test Drives
          </h4>
          <div className="ml-auto">
            <Link
              to={`/dealerships/${dealership?.slug}/test_drives/new`}
              className="btn btn-outline-primary btn-sm"
            >
              <i className="fa fa-plus mr-1"></i>
              Add Test Drive
            </Link>
          </div>
        </div>
      </div>
      <DataTable value={testDrives} tableStyle={{ minWidth: '50rem' }} paginator rows={10}>
        <Column
          sortable
          body={firstColumnComponent}
          field={firstColumnField}
          header={firstColumnHeader}
        ></Column>
        <Column sortable field="status" header="Status" body={Status} />
        <Column
          sortable
          body={Location}
          field="location"
          header="Location"
          showFilterMenu={false}
          filterMenuStyle={{ width: '14rem' }}
        />
        <Column
          sortable
          body={CreatedAt}
          field="created_at"
          header="Creation Date"
          dataType="date"
        />
        <Column
          sortable
          body={LastEditBy}
          field="last_edit_by"
          header="Last Edited By"
          dataType="date"
        ></Column>
        <Column body={(testDrive) => editButton(testDrive)}></Column>
      </DataTable>
    </div>
  )
}

export default TestDrivesBox
