import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useFetchDealership } from '../dataHooks'
import * as Routes from '../../routes'

const Breadcrumb = () => {
  let { websiteSlug } = useParams()

  let { dealership } = useFetchDealership()

  if (!dealership || !websiteSlug) {
    return null
  }

  let website = dealership.websites?.find((website) => website.slug === websiteSlug)

  if (!website) {
    return null
  }

  return (
    <div className="d-flex align-items-center" style={{ background: '#e9ecef' }}>
      <nav className="w-100">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item active d-flex align-items-center">
            Dealership:
            <Link to={Routes.dealership_path(dealership.slug)} className="ml-1">
              {dealership.name}
            </Link>
          </li>
          <li className="breadcrumb-item active d-flex align-items-center">
            <button
              className="btn btn-outline-secondary btn-sm dropdown-toggle"
              type="button"
              data-toggle="dropdown"
            >
              {website.name}
            </button>
            <div className="dropdown-menu">
              {dealership.websites.map((site) => {
                return (
                  <a
                    key={site.id}
                    href={Routes.dealership_website_path(dealership.slug, site.slug)}
                    className="dropdown-item"
                  >
                    {site.name}
                  </a>
                )
              })}
            </div>
          </li>
        </ol>
      </nav>
      <div className="ml-auto pr-2 align-items-center d-md-flex d-none">
        <div className="dropdown">
          <button
            className="btn btn-outline-primary dropdown-toggle btn-sm"
            type="button"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            Action
          </button>
          <ul className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
            <li>
              <a className="dropdown-item" href={website.url} target="_blank">
                <i className="fa fa-link mr-2" />
                Open Website
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Breadcrumb
