import { generateRandomId } from '../../common/commonUtils'
import {
  getPrimaryLocation,
  getLocationType,
  getMapMarginValue,
  getErrorMessage,
} from '../templateUtils'
import { handleManufacturerSection } from './partsUtils'

import { type WebsiteDetailsProps } from '../../types'
import { getFormTemplate } from '../../tools/FormBuilderTool/FormBuilderTemplates'

export function partsTemplate(websiteDetails: WebsiteDetailsProps, manufacturer?: string) {
  // Error Handling
  if (websiteDetails === undefined) {
    return getErrorMessage()
  }

  // Filter locations & addresses
  const primaryLocation = getPrimaryLocation(websiteDetails)

  return [
    {
      id: generateRandomId(10),
      type: 'imageBanner',
      data: {
        image: {
          type: 'default',
          existingCategory: 'default',
          existingFilters: {},
          image: {
            url: 'https://res.cloudinary.com/total-dealer/image/upload/v1644660263/td_next/service_ok7nd6',
          },
        },
        layout: {
          type: 'default',
          imageLayout: 'cover',
          customAspectRatio: {
            height: 1,
            width: 4,
          },
          customHeight: {
            mobileHeight: 250,
            desktopHeight: 450,
          },
        },
        overlay: {
          active: 'false',
          color: '#000000',
          opacity: 50,
        },
        content: {
          active: 'false',
          blocks: [
            {
              id: generateRandomId(10),
              type: 'header',
              data: {
                text: 'Image Banner',
                level: 2,
              },
              tunes: {
                alignment: {
                  alignment: 'center',
                },
                container: {
                  contain: true,
                },
                textColor: {
                  textColor: 'light',
                  customTextColor: '#000000',
                },
                margin: {
                  spacingTop: 5,
                  spacingBottom: 5,
                },
                padding: {
                  paddingTop: 0,
                  paddingBottom: 0,
                },
                classname: {
                  className: '',
                },
              },
            },
          ],
        },
      },
      tunes: {
        margin: {
          spacingTop: 0,
          spacingBottom: 5,
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'header',
      data: {
        text: 'Genuine Parts &amp; Accessories',
        level: 1,
      },
      tunes: {
        alignment: {
          alignment: 'center',
        },
        container: {
          contain: false,
        },
        backgroundColor: {
          backgroundColor: 'none',
          customBackgroundColor: '#000000',
        },
        textColor: {
          textColor: 'none',
          customTextColor: '#000000',
        },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'header',
      data: {
        text: `${primaryLocation?.address?.city} ${primaryLocation?.address?.state}`,
        level: 2,
      },
      tunes: {
        alignment: {
          alignment: 'center',
        },
        container: {
          contain: false,
        },
        backgroundColor: {
          backgroundColor: 'none',
          customBackgroundColor: '#000000',
        },
        textColor: {
          textColor: 'secondary',
          customTextColor: '#000000',
        },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'horizontalRule',
      data: {
        usePageTitle: true,
        height: 1,
        borderRadius: 5,
        opacity: 1,
        color: { colorType: 'primary', customColor: '000000' },
      },
      tunes: {
        container: { contain: false },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
          mobile: { active: false, spacingTop: 0, spacingBottom: 0 },
          tablet: { active: false, spacingTop: 0, spacingBottom: 0 },
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
          mobile: { active: false, paddingTop: 0, paddingBottom: 0 },
          tablet: { active: false, paddingTop: 0, paddingBottom: 0 },
        },
        classname: { className: '' },
      },
    },
    {
      id: generateRandomId(10),
      type: 'paragraph',
      data: {
        text: `At ${websiteDetails.name} we are your one-stop shop for high-quality and OEM spare car parts, tools, and car accessories. Our friendly staff look forward to keeping your car running in excellent condition. Please call us directly or simply order parts online by filling in the form and we'll contact you as soon as possible.`,
      },
      tunes: {
        alignment: {
          alignment: 'center',
        },
        container: {
          contain: true,
        },
        backgroundColor: {
          backgroundColor: 'none',
          customBackgroundColor: '#000000',
        },
        textColor: {
          textColor: 'none',
          customTextColor: '#000000',
        },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
        },
        classname: {
          className: '',
        },
      },
    },
    ...handleManufacturerSection(manufacturer),
    {
      id: generateRandomId(10),
      type: 'formBuilder',
      data: getFormTemplate('parts'),
      tunes: {
        backgroundColor: {
          backgroundColor: 'light',
          customBackgroundColor: '#000000',
        },
        margin: {
          spacingTop: 3,
          spacingBottom: 0,
        },
        padding: {
          paddingTop: 5,
          paddingBottom: 5,
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'locations',
      data: {
        filter: getLocationType(['parts', 'service', 'primary'], websiteDetails.locations),
        manufacturer: false,
        subtype: false,
        filteredLocations: [],
        primaryLocationId: primaryLocation?.id,
      },
      tunes: {
        margin: {
          spacingTop: getMapMarginValue(['parts', 'service', 'primary'], websiteDetails.locations),
          spacingBottom: getMapMarginValue(
            ['parts', 'service', 'primary'],
            websiteDetails.locations
          ),
        },
        classname: {
          className: '',
        },
      },
    },
  ]
}
