import { useState } from 'react'
import { cleanHtml } from '../../common/EditorJsRenderer/editorJsUtils'
import { renderTemplateRefreshOptions } from '../templateUtils'
import {
  getAlternatingColumn,
  getCard,
  getFullWidthSection,
  handleBanner,
  handleDisclaimer,
  handleFeatures,
} from './modelUtils'
import type { BlockProps, EditorProps, ModelProps, RefreshOptionProps } from '../../types'

/**
 * Gets the feature data for a specific feature ID from a model.
 * @param model - The model data
 * @param featureId - The feature ID
 * @returns An object containing the image URL, title, and description of the feature
 */
function getModelFeatureData(model: ModelProps, featureId: number) {
  const modelFeatures = model.features || []
  const feature = modelFeatures.find((f) => f.id === featureId)
  return { imageUrl: feature?.image?.url, title: feature?.title, description: feature?.description }
}

/**
 * Determines whether a feature update is required based on the existing feature data and the updated model data.
 * @param model - The model data
 * @param featureId - The feature ID
 * @param existingImageUrl - The existing image URL
 * @param existingTitle - The existing title
 * @param existingDescription - The existing description
 * @returns A boolean indicating whether a feature update is required
 */
function isFeatureUpdateRequired(
  model: ModelProps,
  featureId: number,
  existingImageUrl: string,
  existingTitle: string,
  existingDescription: string
) {
  let updatedRequired = false
  const updatedFeatureData = getModelFeatureData(model, featureId)

  if (updatedFeatureData.imageUrl !== existingImageUrl) {
    updatedRequired = true
  } else if (
    (!existingTitle && updatedFeatureData.title) ||
    cleanHtml(updatedFeatureData.title) !== cleanHtml(existingTitle)
  ) {
    updatedRequired = true
  } else if (
    (!existingDescription && updatedFeatureData.description) ||
    cleanHtml(updatedFeatureData.description) !== cleanHtml(existingDescription)
  ) {
    updatedRequired = true
  }

  return updatedRequired
}

/**
 * Handles adding a refresh option for the banner block.
 * @param blocks - The current blocks in the editor
 * @param editor - The editor instance
 * @param model - The updated model data
 * @param refreshOptions - The array of refresh options
 */
function handleBannerRefresh(
  blocks: BlockProps[],
  editor: EditorProps,
  model: ModelProps,
  refreshOptions: RefreshOptionProps[]
) {
  const bannerBlock = blocks.find((block: BlockProps) => block?.data?.isTemplateBanner)
  const newBannerBlock = handleBanner(model)[0]

  // If bannerBlock is found, check if the banner type, image or video has changed
  if (bannerBlock) {
    if (newBannerBlock.type !== bannerBlock.type) {
      refreshOptions.push({
        actionText: 'Convert Banner',
        note: 'The banner block type has changed. Would you like to update it?',
        action: () => {
          const index = editor.blocks.getBlockIndex(bannerBlock.id)
          editor.blocks.delete(index)
          editor.blocks.insert(
            newBannerBlock.type,
            newBannerBlock.data,
            newBannerBlock.tunes,
            index
          )
        },
      })
    } else if (
      bannerBlock.type === 'imageBanner' &&
      newBannerBlock.type === 'imageBanner' &&
      // @ts-ignore
      newBannerBlock.data.image.url !== bannerBlock.data.image.url
    ) {
      refreshOptions.push({
        actionText: 'Update Banner Image',
        note: 'The banner image has changed. Would you like to update it?',
        action: () => editor.blocks.update(bannerBlock.id, newBannerBlock.data),
      })
    } else if (
      bannerBlock.type === 'video' &&
      newBannerBlock.type === 'video' &&
      // @ts-ignore
      newBannerBlock.data.url !== bannerBlock.data.url
    ) {
      refreshOptions.push({
        actionText: 'Update Banner Video',
        note: 'The banner video has changed. Would you like to update it?',
        action: () => editor.blocks.update(bannerBlock.id, newBannerBlock.data),
      })
    }
  } else {
    // If bannerBlock is not found, offer to add a new banner block
    refreshOptions.push({
      actionText: 'Add Banner',
      note: 'There is no template banner on this page. Would you like to add one?',
      action: () =>
        editor.blocks.insert(newBannerBlock.type, newBannerBlock.data, newBannerBlock.tunes, 0),
    })
  }
}

function handleAlternatingColumnsFeaturesRefresh(
  newFeaturesData: BlockProps[],
  blocks: BlockProps[],
  editor: EditorProps,
  model: ModelProps,
  refreshOptions: RefreshOptionProps[]
) {
  // Get existing and new alternating columns blocks
  const alternatingColumnsBlocks = blocks?.filter(
    (block: BlockProps) => block?.type === 'alternatingColumns' && block?.data?.isTemplateFeatures
  )
  const newAlternatingColumnsBlocks = newFeaturesData?.filter(
    (block: BlockProps) => block?.type === 'alternatingColumns' && block?.data?.isTemplateFeatures
  )

  // Check if there are any existing alternating columns blocks
  if (alternatingColumnsBlocks.length > 0) {
    // Map over existing features and check if any need to be updated
    alternatingColumnsBlocks?.map((alternatingColumnsBlock: BlockProps, index: number) => {
      // Store updated features in an array for later use
      const updatedFeatures = []
      alternatingColumnsBlock?.data?.columns?.map((existingFeature: any) => {
        // Only check for updates if the feature has an ID
        if (existingFeature?.imgCol?.blocks[0]?.data?.featureId) {
          // Check if an update is required for the feature
          const existingFeatureId = existingFeature?.imgCol?.blocks[0]?.data?.featureId
          const existingImageUrl = existingFeature?.imgCol?.blocks[0]?.data?.file?.url
          const existingTitle = existingFeature?.textCol?.blocks[0]?.data?.text
          const existingDescription = existingFeature?.textCol?.blocks[1]?.data?.text
          const updatedRequired = isFeatureUpdateRequired(
            model,
            existingFeatureId,
            existingImageUrl,
            existingTitle,
            existingDescription
          )
          // Store features that have been updated
          if (updatedRequired) {
            const updatedFeature = getAlternatingColumn(
              model?.features?.find((f: any) => f.id === existingFeatureId)
            )
            if (updatedFeature) {
              updatedFeatures.push({
                title: existingTitle,
                featureId: existingFeatureId,
                data: updatedFeature,
              })
            }
          }
        }
      })
      // Also need to check if there are any new features that can be added
      newAlternatingColumnsBlocks[index]?.data?.columns?.map((newFeature: any) => {
        const newFeatureId = newFeature?.imgCol?.blocks[0]?.data?.featureId
        const existingFeature = alternatingColumnsBlock?.data?.columns?.find(
          (f: any) => f?.imgCol?.blocks[0]?.data?.featureId === newFeatureId
        )
        // If the feature is not already present, add it
        if (!existingFeature) {
          const newFeatureData = getModelFeatureData(model, newFeatureId)
          const newFeature = getAlternatingColumn(
            model?.features?.find((f: any) => f.id === newFeatureId)
          )
          if (newFeature) {
            updatedFeatures.push({
              title: `${newFeatureData.title} - New`,
              featureId: newFeatureId,
              data: newFeature,
            })
          }
        }
      })

      // If there are no updated features don't add a refresh option
      if (updatedFeatures.length < 1) return

      // Otherwide add refresh option
      refreshOptions.push({
        actionText: 'Update Alternating Columns Features',
        note: `Some Alternating Columns features have been updated. Please select the ones you would like to update.`,
        options: updatedFeatures,
        action: (selectedOptions: { featureId: number; data: any }[]) => {
          const newColumns = []
          // Map over existing columns and update if required
          alternatingColumnsBlock.data.columns.map((feature: any) => {
            const existingFeatureId = feature?.imgCol?.blocks[0]?.data?.featureId
            const updatedData = selectedOptions.find(
              (option) => option.featureId === existingFeatureId
            )
            if (updatedData) {
              newColumns.push(updatedData.data)
            } else {
              newColumns.push(feature)
            }
          })

          // Map over new columns and add if not already present
          selectedOptions.map((option) => {
            const existingFeature = alternatingColumnsBlock.data.columns.find(
              (f: any) => f?.imgCol?.blocks[0]?.data?.featureId === option.featureId
            )
            if (!existingFeature) {
              newColumns.push(option.data)
            }
          })

          // Construct new data object
          const newData = {
            ...alternatingColumnsBlock.data,
            columns: newColumns,
          }

          // Update Block
          editor.blocks.update(alternatingColumnsBlock.id, newData)
        },
      })
    })
  }

  // If there are more new alternating columns blocks than existing ones
  if (alternatingColumnsBlocks.length !== newAlternatingColumnsBlocks.length) {
    // Offer to add the missing features blocks
    refreshOptions.push({
      actionText: 'Add Alternating Columns Features',
      note: 'There are some alternating columns features missing from this page. Would you like to add them?',
      action: () =>
        // Add to the end of the page
        newAlternatingColumnsBlocks.map((newAlternatingColumnsBlock: BlockProps, index: number) => {
          // Skip if the block already exists
          if (index < alternatingColumnsBlocks.length) return
          editor.blocks.insert(
            'alternatingColumns',
            newAlternatingColumnsBlock?.data,
            newAlternatingColumnsBlock?.tunes,
            blocks.length + index
          )
        }),
    })
  }
}

function handleFullWidthFeaturesRefresh(
  newFeaturesData: BlockProps[],
  blocks: BlockProps[],
  editor: EditorProps,
  model: ModelProps,
  refreshOptions: RefreshOptionProps[]
) {
  // Get existing and new cards blocks
  const fullWidthImageBlocks = blocks?.filter(
    (block: BlockProps) => block?.type === 'imageBanner' && block?.data?.featureId
  )
  const newFullWidthImageBlocks = newFeaturesData?.filter(
    (block: BlockProps) => block?.type === 'imageBanner' && block?.data?.featureId
  )
  const existingFeatureIds = fullWidthImageBlocks.map((block: BlockProps) => block?.data?.featureId)

  // Check existing full width features for updates
  fullWidthImageBlocks?.map((fullWidthImageBlock: BlockProps) => {
    const blockIndex = editor.blocks.getBlockIndex(fullWidthImageBlock.id)
    const existingFeatureId = fullWidthImageBlock?.data?.featureId
    const existingImageUrl = fullWidthImageBlock?.data?.image?.image?.url

    // Check for heading & paragraph blocks (next 2 blocks)
    let headingBlock: BlockProps, paragraphBlock: BlockProps
    for (let i = 1; i < 3; i++) {
      const tempBlock = blocks[blockIndex + i]
      if (tempBlock?.type === 'header') {
        headingBlock = tempBlock
      } else if (tempBlock?.type === 'paragraph') {
        paragraphBlock = tempBlock
      }
    }

    const updatedRequired = isFeatureUpdateRequired(
      model,
      existingFeatureId,
      existingImageUrl,
      headingBlock ? headingBlock.data.text : '',
      paragraphBlock ? paragraphBlock.data.text : ''
    )

    if (updatedRequired) {
      const newFullWidthFeatureData = getModelFeatureData(model, existingFeatureId)
      const updatedFeature = getFullWidthSection(
        model,
        model?.features?.find((f: any) => f.id === existingFeatureId)
      )
      if (updatedFeature) {
        // Otherwide add refresh option
        refreshOptions.push({
          actionText: 'Update Full Width Feature',
          note: `The ${newFullWidthFeatureData.title} Full Width feature has been updated. Would you like to update it?`,
          action: () => {
            let index = blockIndex // Track where to add/update

            // Separate new feature blocks
            const newImageBlock = updatedFeature.find(
              (updatedBlock) => updatedBlock.type === 'imageBanner'
            )
            const newHeadingBlock = updatedFeature.find(
              (updatedBlock) => updatedBlock.type === 'header'
            )
            const newParagraphBlock = updatedFeature.find(
              (updatedBlock) => updatedBlock.type === 'paragraph'
            )

            // If the image block is updated, update the image block
            if (newImageBlock) {
              editor.blocks.update(fullWidthImageBlock.id, newImageBlock.data)
              index = index + 1
            } else {
              // If the image was removed, delete the image block
              editor.blocks.delete(blockIndex)
            }

            // If there is a heading block, update it
            if (headingBlock && newHeadingBlock) {
              editor.blocks.update(headingBlock.id, newHeadingBlock.data)
              index = index + 1
            } else if (!headingBlock && newHeadingBlock) {
              // If there is no heading block, insert it
              editor.blocks.insert(
                newHeadingBlock.type,
                newHeadingBlock.data,
                newHeadingBlock.tunes,
                index
              )
              index = index + 1
            } else if (headingBlock && !newHeadingBlock) {
              // If the heading text was removed, delete it
              editor.blocks.delete(index)
            }

            // If there is a paragraph block, update it
            if (paragraphBlock && newParagraphBlock) {
              editor.blocks.update(paragraphBlock.id, newParagraphBlock.data)
            } else if (!paragraphBlock && newParagraphBlock) {
              // If there is no paragraph block, insert it
              editor.blocks.insert(
                newParagraphBlock.type,
                newParagraphBlock.data,
                newParagraphBlock.tunes,
                index
              )
            } else if (paragraphBlock && !newParagraphBlock) {
              // If the paragraph text was removed, delete it
              editor.blocks.delete(index)
            }
          },
        })
      }
    }
  })

  // If there are more new full width blocks than existing ones
  if (fullWidthImageBlocks.length !== newFullWidthImageBlocks.length) {
    // Offer to add the missing features blocks
    refreshOptions.push({
      actionText: 'Add Full Width Features',
      note: 'There are some Full Width features missing from this page. Would you like to add them?',
      action: () =>
        // Add to the end of the page
        newFullWidthImageBlocks.map((newFullWidthBlock: BlockProps, index: number) => {
          // Skip if the feature already exists
          if (existingFeatureIds.includes(newFullWidthBlock.data.featureId)) return

          const updatedFeature = getFullWidthSection(
            model,
            model?.features?.find((f: any) => f.id === newFullWidthBlock.data.featureId)
          )
          if (updatedFeature) {
            let featureIndex = blocks.length + 1 + index // Track where to add/update

            // Separate new feature blocks
            const newImageBlock = updatedFeature.find(
              (updatedBlock) => updatedBlock.type === 'imageBanner'
            )
            const newHeadingBlock = updatedFeature.find(
              (updatedBlock) => updatedBlock.type === 'header'
            )
            const newParagraphBlock = updatedFeature.find(
              (updatedBlock) => updatedBlock.type === 'paragraph'
            )

            // If the image block is exists, insert the image block
            if (newImageBlock) {
              editor.blocks.insert(
                newImageBlock.type,
                newImageBlock.data,
                newImageBlock.tunes,
                featureIndex
              )
              featureIndex = featureIndex + 1
            }

            // If there is a heading block, insert it
            if (newHeadingBlock) {
              // If there is no heading block, insert it
              editor.blocks.insert(
                newHeadingBlock.type,
                newHeadingBlock.data,
                newHeadingBlock.tunes,
                featureIndex
              )
              featureIndex = featureIndex + 1
            }

            // If there is a paragraph block, insert it
            if (newParagraphBlock) {
              // If there is no paragraph block, insert it
              editor.blocks.insert(
                newParagraphBlock.type,
                newParagraphBlock.data,
                newParagraphBlock.tunes,
                featureIndex
              )
            }
          }
        }),
    })
  }
}

function handleCardsFeaturesRefresh(
  newFeaturesData: BlockProps[],
  blocks: BlockProps[],
  editor: EditorProps,
  model: ModelProps,
  refreshOptions: RefreshOptionProps[]
) {
  // Get existing and new cards blocks
  const cardsBlocks = blocks?.filter(
    (block: BlockProps) => block?.type === 'cardsTool' && block?.data?.isTemplateFeatures
  )
  const newCardsBlocks = newFeaturesData?.filter(
    (block: BlockProps) => block?.type === 'cardsTool' && block?.data?.isTemplateFeatures
  )
  const existingCardsBlockTypes = cardsBlocks.map((block: BlockProps) => block?.data?.cardCols)

  // Check if there are any existing cards blocks
  if (cardsBlocks.length > 0) {
    // Map over existing features and check if any need to be updated
    cardsBlocks?.map((cardsBlock: BlockProps) => {
      // Store updated features in an array for later use
      const updatedFeatures = []
      cardsBlock?.data?.cards?.map((existingFeature: any) => {
        // Only check for updates if the feature has an ID
        if (existingFeature?.featureId) {
          // Check if an update is required for the feature
          const existingFeatureId = existingFeature?.featureId
          const existingImageUrl = existingFeature?.image?.url
          const existingTitle = existingFeature?.heading
          const existingDescription = existingFeature?.description
          const updatedRequired = isFeatureUpdateRequired(
            model,
            existingFeatureId,
            existingImageUrl,
            existingTitle,
            existingDescription
          )
          // Store features that have been updated
          if (updatedRequired) {
            const updatedFeature = getCard(
              model?.features?.find((f: any) => f.id === existingFeatureId)
            )
            if (updatedFeature) {
              updatedFeatures.push({
                title: existingTitle,
                featureId: existingFeatureId,
                data: updatedFeature,
              })
            }
          }
        }
      })
      // Also need to check if there are any new features that can be added
      const newCardsBlock = newCardsBlocks.find(
        (block) => block.data.cardCols === cardsBlock.data.cardCols
      )

      newCardsBlock?.data?.cards?.map((newFeature: any) => {
        const newFeatureId = newFeature?.featureId
        const existingFeature = cardsBlock?.data?.cards?.find(
          (f: any) => f?.featureId === newFeatureId
        )
        // If the feature is not already present, add it
        if (!existingFeature) {
          const newFeatureData = getModelFeatureData(model, newFeatureId)
          const newFeature = getCard(model?.features?.find((f: any) => f.id === newFeatureId))
          if (newFeature) {
            updatedFeatures.push({
              title: `${newFeatureData.title} - New`,
              featureId: newFeatureId,
              data: newFeature,
            })
          }
        }
      })

      // If there are no updated features don't add a refresh option
      if (updatedFeatures.length < 1) return

      // Otherwide add refresh option
      refreshOptions.push({
        actionText: 'Update Cards Features',
        note: `Some Cards features have been updated. Please select the ones you would like to update.`,
        options: updatedFeatures,
        action: (selectedOptions: { featureId: number; data: any }[]) => {
          const newCards = []
          // Map over existing cards and update if required
          cardsBlock.data.cards.map((feature: any) => {
            const existingFeatureId = feature?.featureId
            const updatedData = selectedOptions.find(
              (option) => option.featureId === existingFeatureId
            )
            if (updatedData) {
              newCards.push(updatedData.data)
            } else {
              newCards.push(feature)
            }
          })

          // Map over new cards and add if not already present
          selectedOptions.map((option) => {
            const existingFeature = cardsBlock.data.cards.find(
              (f: any) => f?.featureId === option.featureId
            )
            if (!existingFeature) {
              newCards.push(option.data)
            }
          })

          // Construct new data object
          const newData = {
            ...cardsBlock.data,
            cards: newCards,
          }

          // Update Block
          editor.blocks.update(cardsBlock.id, newData)
        },
      })
    })
  }

  // If there are more new cards blocks than existing ones
  if (cardsBlocks.length !== newCardsBlocks.length) {
    // Offer to add the missing features blocks
    refreshOptions.push({
      actionText: 'Add Cards Features',
      note: 'There are some cards features missing from this page. Would you like to add them?',
      action: () =>
        // Add to the end of the page
        newCardsBlocks.map((newCardsBlock: BlockProps, index: number) => {
          // Skip if the block already exists
          if (existingCardsBlockTypes.includes(newCardsBlock.data.cardCols)) return
          editor.blocks.insert(
            'cardsTool',
            newCardsBlock?.data,
            newCardsBlock?.tunes,
            blocks.length + index
          )
        }),
    })
  }
}

/**
 * Handles adding a refresh option for the disclaimer block.
 * @param blocks - The current blocks in the editor
 * @param editor - The editor instance
 * @param model - The updated model data
 * @param refreshOptions - The array of refresh options
 */
function handleDisclaimerRefresh(
  blocks: BlockProps[],
  editor: EditorProps,
  model: ModelProps,
  refreshOptions: RefreshOptionProps[]
) {
  const disclaimerBlock = blocks.find((block: BlockProps) =>
    block?.tunes?.classname?.className?.includes('disclaimer')
  )
  const newDisclaimerBlock = handleDisclaimer(model.disclaimer)

  // If Disclaimer block is found, check if the type or content has changed
  if (disclaimerBlock) {
    if (newDisclaimerBlock.type !== disclaimerBlock.type) {
      refreshOptions.push({
        actionText: 'Convert Disclaimer',
        note: 'The disclaimer block type has changed. Would you like to update it?',
        action: () => {
          const index = editor.blocks.getBlockIndex(disclaimerBlock.id)
          editor.blocks.delete(index)
          editor.blocks.insert(
            newDisclaimerBlock.type,
            newDisclaimerBlock.data,
            newDisclaimerBlock.tunes,
            index
          )
        },
      })
    } else if (
      disclaimerBlock.type === 'list' &&
      newDisclaimerBlock.type === 'list' &&
      JSON.stringify(newDisclaimerBlock.data.items) !== JSON.stringify(disclaimerBlock.data.items)
    ) {
      refreshOptions.push({
        actionText: 'Update Disclaimer',
        note: 'The disclaimer content has changed. Would you like to update it?',
        action: () => editor.blocks.update(disclaimerBlock.id, newDisclaimerBlock.data),
      })
    } else if (
      disclaimerBlock.type === 'paragraph' &&
      newDisclaimerBlock.type === 'paragraph' &&
      JSON.stringify(newDisclaimerBlock.data.text) !== JSON.stringify(disclaimerBlock.data.text)
    ) {
      refreshOptions.push({
        actionText: 'Update Disclaimer',
        note: 'The disclaimer content has changed. Would you like to update it?',
        action: () => editor.blocks.update(disclaimerBlock.id, newDisclaimerBlock.data),
      })
    }
  }
}

/**
 * Renders a refresh option for the template refresh modal.
 * @param index - The index of the option
 * @param option - The refresh option data
 * @param save - The save function
 * @returns A refresh option component
 */
const RenderRefreshOption = ({
  index,
  option,
  save,
}: {
  index: number
  option: RefreshOptionProps
  save: () => void
}) => {
  const [isRefreshed, setIsRefreshed] = useState(false)
  const [selected, setSelected] = useState([]) // Store selected options for multi-feature components

  // Remove the refresh option after it has been actioned
  return isRefreshed ? (
    <div className="col-12 mb-3">
      <p className="mb-o">{index + 1}. Refresh option completed successfully.</p>
    </div>
  ) : (
    <div className="col-12 mb-3">
      <p className="mb-1">
        {index + 1}. {option?.note}
      </p>
      {option?.options?.length > 0 && (
        <div className="row">
          {option?.options?.map((option, index) => (
            <div key={`option-${index}`} className="col-12 col-md-4">
              <div className="form-check">
                <input
                  id={option.featureId}
                  className="form-check-input"
                  type="checkbox"
                  value={option.featureId}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelected([...selected, option.featureId])
                    } else {
                      setSelected(selected.filter((id) => id !== option.featureId))
                    }
                  }}
                />
                <label className="form-check-label" htmlFor={option.featureId}>
                  {option.title}
                </label>
              </div>
            </div>
          ))}
        </div>
      )}
      <button
        className="btn btn-sm btn-primary mt-2"
        disabled={option?.options?.length > 0 && selected.length < 1}
        onClick={() => {
          if (option?.options?.length > 0) {
            const selectedOptions = option?.options?.filter((option) =>
              selected.includes(option.featureId)
            )
            option?.action(selectedOptions)
          } else {
            option?.action()
          }
          save()
          setIsRefreshed(true)
        }}
      >
        {option?.actionText}
      </button>
    </div>
  )
}

/**
 * Handles the template refresh options based on the updated model data.
 * @param model - The updated model data
 * @param editor - The editor instance
 */
async function handleTemplateRefresh(model: ModelProps, editor: EditorProps) {
  // Make sure editor is ready
  await editor.isReady

  const blocks = editor.configuration.data.blocks
  const refreshOptions = []

  // Banner Refresh
  handleBannerRefresh(blocks, editor, model, refreshOptions)

  // Features Refresh
  const newFeaturesData = handleFeatures(model, model.features)
  handleAlternatingColumnsFeaturesRefresh(newFeaturesData, blocks, editor, model, refreshOptions)
  handleFullWidthFeaturesRefresh(newFeaturesData, blocks, editor, model, refreshOptions)
  handleCardsFeaturesRefresh(newFeaturesData, blocks, editor, model, refreshOptions)

  // Disclaimer Refresh
  handleDisclaimerRefresh(blocks, editor, model, refreshOptions)

  // If there are no refresh options, render nothing in the modal
  if (refreshOptions.length === 0) return

  // Add options to Modal
  renderTemplateRefreshOptions(
    <div className="refresh-options-row row">
      {refreshOptions.map((option: RefreshOptionProps, index: number) => (
        <RenderRefreshOption
          key={`refresh-option-${index}`}
          index={index}
          option={option}
          save={editor.save}
        />
      ))}
    </div>
  )
}

export { handleTemplateRefresh }
