import { FormGroup } from './FormGroup'
import InputLabel from './InputLabel'
import type { DefaultInputProps } from '../types'

export const LabeledNumberInput = ({
  controlled = true,
  item,
  itemName,
  updateItem,
  label,
  placeholder,
  min,
  max,
  step = 1,
  disabled,
  inputClassName,
  customOnChange,
  tooltip,
}: DefaultInputProps & { placeholder?: string; min?: number; max?: number; step?: number }) => {
  return (
    <FormGroup>
      <InputLabel label={label} itemName={itemName} tooltip={tooltip} />
      <input
        id={itemName}
        placeholder={placeholder || `Enter ${itemName}...`}
        className={`form-control ${inputClassName}`}
        type="number"
        value={controlled ? `${item[itemName] || ''}` : undefined}
        defaultValue={!controlled ? `${item[itemName] || ''}` : undefined}
        onChange={(e) =>
          customOnChange
            ? customOnChange(e)
            : updateItem({ ...item, [itemName]: parseInt(e.target.value) })
        }
        min={min}
        max={max}
        step={step}
        disabled={disabled}
      />
    </FormGroup>
  )
}
