import React from 'react'

import { Contact } from './types'
import moment from 'moment'
import EditableItem from './EditableItem'
import { Toggle } from '../settings/leadSettings'
import { useParams } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'

interface ContactDetailsProps {
  contact: Contact
  setContact: (contact: Contact) => void
}

const EditableToggle = ({ contact, label, attributeName, hint = null }) => {
  let { dealershipSlug } = useParams()

  return (
    <div className="col-md-6 mb-2">
      <div
        style={{
          fontSize: '12px',
          textTransform: 'uppercase',
          fontWeight: 'bold',
          marginBottom: '0.25rem',
        }}
      >
        <span>{label}</span>
        {hint && (
          <>
            <span className="ml-1" data-tip id={`${attributeName}-tooltip`}>
              <i className="fa fa-question-circle" />
            </span>
            <Tooltip
              anchorSelect={`#${attributeName}-tooltip`}
              place="bottom"
              style={{ zIndex: 10000 }}
            >
              {hint}
            </Tooltip>
          </>
        )}
      </div>
      <Toggle
        attributeName={attributeName}
        rowData={contact}
        objectType="contact"
        url={`/dealerships/${dealershipSlug}/contacts/${contact.id}`}
      />
    </div>
  )
}

const ContactDetails: React.FC<ContactDetailsProps> = ({ contact, setContact }) => {
  return (
    <div className="box">
      <div className="p-3">
        <div className="row">
          <div className="col-md-6">
            <EditableItem
              field="first_name"
              label="First Name"
              value={contact.first_name}
              placeholder="First Name"
              setContact={setContact}
              contact={contact}
            />
          </div>
          <div className="col-md-6">
            <EditableItem
              field="last_name"
              label="Last Name"
              value={contact.last_name}
              placeholder="Last Name"
              setContact={setContact}
              contact={contact}
            />
          </div>
        </div>
        <EditableItem
          field="email"
          label="Email"
          value={contact.email}
          placeholder="Email"
          setContact={setContact}
          contact={contact}
        />
        <EditableItem
          field="phone_number"
          label="Phone Number"
          value={contact.phone_number}
          placeholder="Phone Number"
          setContact={setContact}
          contact={contact}
        />
        <EditableItem
          field="business_name"
          label="Business Name"
          value={contact.business_name}
          placeholder="Business Name"
          setContact={setContact}
          contact={contact}
        />
      </div>
      <hr className="m-0" />
      <div className="p-3">
        <div className="row">
          <EditableToggle
            contact={contact}
            label="Opt in to marketing (consent required)"
            attributeName="opt_in_to_marketing"
          />
          <EditableToggle
            contact={contact}
            label="Blocked"
            attributeName="blocked"
            hint="Blocking a contact prevents them from submitting leads"
          />
        </div>
      </div>
      <hr className="m-0" />
      <div className="p-3">
        <p>
          <strong>Address:</strong> {contact.full_address}
        </p>
        <p>
          <strong>Created at:</strong> {moment(contact.created_at).format('DD/MM/YYYY')} (
          {moment(contact.created_at).fromNow()})
        </p>
      </div>
    </div>
  )
}

export default ContactDetails
