import { useState, useContext } from 'react'
import { VehicleBuilder } from '../entries/FormElements'
import { updateLeadAPI } from '../leads/utils'
import { LeadClusterContext } from '../contexts'

const colourOptions = [
  { label: 'Beige', value: 'beige' },
  { label: 'Blue', value: 'blue' },
  { label: 'Black', value: 'black' },
  { label: 'Bronze', value: 'bronze' },
  { label: 'Brown', value: 'brown' },
  { label: 'Burgundy', value: 'burgundy' },
  { label: 'Grey', value: 'grey' },
  { label: 'Green', value: 'green' },
  { label: 'Gold', value: 'gold' },
  { label: 'Maroon', value: 'maroon' },
  { label: 'Magenta', value: 'magenta' },
  { label: 'Orange', value: 'orange' },
  { label: 'Pink', value: 'pink' },
  { label: 'Purple', value: 'purple' },
  { label: 'Red', value: 'red' },
  { label: 'Silver', value: 'silver' },
  { label: 'White', value: 'white' },
  { label: 'Yellow', value: 'yellow' },
]

const AddCarForm = ({ setVisible }) => {
  const { leadCluster, setLeadCluster } = useContext(LeadClusterContext) || {}
  let [manufacturer, setManufacturer] = useState()
  let [family, setFamily] = useState()
  let [year, setYear] = useState()
  let [badge, setBadge] = useState()
  let [loading, setLoading] = useState(false)
  let [colour, setColour] = useState()

  const addCar = () => {
    let params = {
      lead_cluster: {
        car_lead_clusters_attributes: [
          {
            manufacturer_id: manufacturer,
            family_id: family,
            year: year,
            badge: badge,
            colour: colour,
          },
        ],
      },
    }
    setLoading(true)
    updateLeadAPI(leadCluster.id, params)
      .then((res) => res.json())
      .then((data) => {
        setLoading(false)
        setLeadCluster(data)
        setVisible(false)
      })
  }

  return (
    <div className="mt-3">
      <VehicleBuilder
        manufacturer={manufacturer}
        setManufacturer={setManufacturer}
        family={family}
        setFamily={setFamily}
        year={year}
        setYear={setYear}
        showBadge={true}
        badge={badge}
        setBadge={setBadge}
        colourOptions={colourOptions}
        colour={colour}
        setColour={setColour}
      />
      <div
        className={'btn btn-outline-primary btn-block' + (loading ? ' disabled' : '')}
        onClick={addCar}
        id="save-car-button"
      >
        {loading ? <i className="fa fa-spinner fa-spin"></i> : 'Add Car'}
      </div>
    </div>
  )
}

export default AddCarForm
