import { default as React, useState, useCallback } from 'react'
import { createRoot } from 'react-dom/client'

import { ImageSelectStep, BannerLayoutStep, OverlayStep, ContentStep } from './FormSteps'
import ImageBannerPreview from './ImageBannerPreview'
import { getAvailableSteps } from './Utils'

import { ToolHeader } from '../../common'
import Dialog from '../../common/Dialog'
import {
  renderEditSettingsButton,
  renderHiddenModalButton,
  generateRandomId,
  renderTabs,
  renderStepButtons,
} from '../../common/commonUtils'
import { unique } from '../../../entries/utils'

const steps = [
  { name: 'Image', component: ImageSelectStep },
  { name: 'Layout', component: BannerLayoutStep },
  { name: 'Overlay', component: OverlayStep },
  { name: 'Content', component: ContentStep },
]

const RenderedImageBannerComponent = ({
  onDataChange,
  getUpdatedData,
  data,
  config,
  brands,
  templateData,
  imageEndpoint,
  toolInfo,
  uniqueId,
}) => {
  const [state, setState] = useState(data) // Tool state
  const [show, setShow] = useState(!data?.searchBanner && !data.image?.image?.url ? true : false) // Modal state
  // Track form state (active index, previous active index)
  const [activeIndexState, setActiveIndexState] = useState({
    activeIndex: 0,
    previousActiveIndex: undefined,
  })
  // Get an array of available steps based on the current state
  const [availableSteps, setAvailableSteps] = useState(getAvailableSteps(data))

  // Keep data and state synced
  const syncedStateUpdate = useCallback(
    (item) => {
      setAvailableSteps(getAvailableSteps({ ...item }))
      setState({ ...item })
      onDataChange({
        ...item,
      })
    },
    [onDataChange]
  )

  function customNextStepHandler(index) {
    const data = getUpdatedData()
    const availableIndex = getAvailableSteps(data)

    // If next step is available, or final step, allow step change
    if (availableIndex.includes(index + 1) || index === steps.length - 1) {
      return true
    } else {
      alert('Please complete this step.')
      return false // Prevent step change
    }
  }

  return (
    <>
      <ImageBannerPreview state={state} includeHeader={false} templateData={templateData} />
      <Dialog title="Image Banner" show={show} closeClickHandler={() => setShow(false)}>
        <ToolHeader {...toolInfo} />
        <div className="pt-3 border-top">
          {renderTabs(steps, activeIndexState, setActiveIndexState, availableSteps)}
          <div className="row pt-3">
            <div className="col-12">
              <div className="form-group mb-0">
                {/* Render Steps */}
                {steps.map((step, index) => {
                  const StepComponent = step.component

                  return (
                    <StepComponent
                      key={step.name + index}
                      stepIndex={index}
                      isActive={activeIndexState.activeIndex === index}
                      isModalOpen={show}
                      activeIndexState={activeIndexState}
                      state={state}
                      syncedStateUpdate={syncedStateUpdate}
                      imageEndpoint={imageEndpoint}
                      brands={brands}
                      templateData={templateData}
                      config={config}
                      data={data}
                      onDataChange={onDataChange}
                      getUpdatedData={getUpdatedData}
                    />
                  )
                })}
                {/* Render Buttons */}
                {renderStepButtons(
                  activeIndexState,
                  setActiveIndexState,
                  steps.map((index) => index),
                  setShow,
                  customNextStepHandler
                )}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      {/* Hidden button that handles opening the settings modal */}
      {renderHiddenModalButton(uniqueId, setShow)}
    </>
  )
}

class ImageBannerTool {
  static get toolbox() {
    return {
      title: 'Image Banner',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
        <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
      </svg>`,
    }
  }

  constructor({ data, config, api, block }) {
    this.api = api
    this.config = config
    this.blockAPI = block
    this.uniqueId = unique()

    let defaultValue = {
      image: {
        type: undefined, // Options = 'default', 'custom'
        existingCategory: undefined, // Options = 'default', 'manufacturer', 'model'
        existingFilters: undefined, // e.g. { manufacturer: 'audi', 'model': 'a4'}
        image: {
          url: undefined, // 'default' = use most up to date image for template pages (manufacturer, model, etc)
          previewUrl: undefined, // only used to handle image previews in the editor
        },
      },
      layout: {
        type: 'default', // Options = 'default', 'full-size', 'customHeight', 'customAspectRatio'
        imageLayout: 'cover', // Options = 'cover', 'contain', 'fill'
        customAspectRatio: {
          height: 1,
          width: 4,
        },
        customHeight: {
          mobileHeight: 250,
          desktopHeight: 450,
        },
      },
      overlay: {
        active: undefined,
        color: '#000000',
        opacity: 50,
      },
      content: {
        active: undefined,
        blocks: [
          {
            id: generateRandomId(10),
            type: 'header',
            data: { text: 'Image Banner', level: 2 },
            tunes: {
              alignment: { alignment: 'center' },
              container: { contain: true },
              textColor: {
                textColor: 'light',
                customTextColor: '#000000',
              },
              margin: { spacingTop: 5, spacingBottom: 5 },
              padding: { paddingTop: 0, paddingBottom: 0 },
              classname: { className: '' },
            },
          },
        ],
      },
    }

    this.data = Object.keys(data).length ? data : defaultValue

    this.CSS = {
      wrapper: 'walkthrough-timeline',
    }

    this.nodes = {
      holder: null,
    }

    this.toolInfo = {
      heading: undefined,
      helpText:
        'Add an image banner to be displayed on the page. This is a full-width image that spans the entire width of the page. You can add a customisable overlay as well as a custom text block.',
      hideToggle: true,
    }
  }

  render() {
    const rootNode = document.createElement('div')
    rootNode.setAttribute('class', this.CSS.wrapper)
    this.nodes.holder = rootNode

    const onDataChange = (newData) => {
      this.data = {
        ...this.data,
        ...newData,
      }
      this.config.save()
      // Force editor onChange event
      this.blockAPI.dispatchChange()
    }

    const getUpdatedData = () => {
      return this.data
    }

    const root = createRoot(rootNode)
    root.render(
      <RenderedImageBannerComponent
        onDataChange={onDataChange}
        getUpdatedData={getUpdatedData}
        data={this.data}
        config={this.config}
        brands={this.config?.brands}
        templateData={this.config?.templateData}
        imageEndpoint={this.config.imageUrl}
        toolInfo={this.toolInfo}
        uniqueId={this.uniqueId}
      />
    )

    return this.nodes.holder
  }

  /** Create the settings panel for the block */
  renderSettings() {
    const wrapper = document.createElement('div')

    // Add edit button
    const editButton = renderEditSettingsButton(this.uniqueId)

    wrapper.appendChild(editButton)

    return wrapper
  }

  save() {
    return this.data
  }
}

export default ImageBannerTool
