import AnalyticsDashboard from './AnalyticsDashboard'
import ContextWrapper from '../contexts/ContextWrapper'
import LoadingWrapper from './LoadingWrapper'

const AnalyticsDashboardApp = () => {
  return (
    <>
      <ContextWrapper>
        <LoadingWrapper>
          <AnalyticsDashboard />
        </LoadingWrapper>
      </ContextWrapper>
    </>
  )
}

export default AnalyticsDashboardApp
