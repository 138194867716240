import { FormGroup } from './FormGroup'
import InputLabel from './InputLabel'
import { translateBootstrapColour } from './constants'
import type { DefaultInputProps, SelectOption } from '../types'

export const LabeledSelect = ({
  controlled = true,
  item,
  itemName,
  updateItem,
  label,
  options,
  disabled,
  inputClassName,
  customOnChange,
  tooltip,
}: DefaultInputProps & { options: SelectOption[] | string[] }) => {
  /** We can update the way some options are displayed to make it more user-friendly */
  const transformOptionDisplay = (option: string) => {
    return translateBootstrapColour(option)
  }

  return (
    <FormGroup>
      <InputLabel label={label} itemName={itemName} tooltip={tooltip} />
      <select
        id={itemName}
        className={`form-control ${inputClassName}`}
        value={controlled ? item[itemName] : undefined}
        defaultValue={!controlled ? item[itemName] : undefined}
        onChange={(e) =>
          customOnChange ? customOnChange(e) : updateItem({ ...item, [itemName]: e.target.value })
        }
        disabled={disabled}
      >
        {options.map((option: SelectOption | string, i: number) =>
          // Allow different label & value
          typeof option === 'object' ? (
            <option key={`${itemName}-option-${i}`} value={option.value} disabled={option.disabled}>
              {transformOptionDisplay(option.label)}
            </option>
          ) : (
            <option key={`${itemName}-option-${i}`} value={option}>
              {transformOptionDisplay(option)}
            </option>
          )
        )}
      </select>
    </FormGroup>
  )
}
