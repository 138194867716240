import { useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Link, useParams } from 'react-router-dom'
import { useFetchWebsite } from '../dataHooks'
import { Select } from '../entries/FormElements'
import * as Routes from '../../routes'
import { standardHeaders } from '../entries/utils'

let selectOptions = Array.from({ length: 11 }, (_, i) => i).map((i) => ({ label: i, value: i }))

const StockSettingsForm = ({ soldCarsDays, setSoldCarsDays }) => {
  let [loading, setLoading] = useState(false)
  let { websiteSlug } = useParams()

  const handleUpdate = () => {
    // Logic to update the sold cars days
    fetch(Routes.website_path(websiteSlug), {
      method: 'PATCH',
      headers: standardHeaders,
      body: JSON.stringify({ website: { include_sold_cars_days: soldCarsDays } }),
    }).then((res) => {
      setLoading(false)
      if (res.ok) {
        alert('Updated')
      } else {
        alert('Failed to update')
      }
    })
  }

  return (
    <div className="box p-3">
      <form
        onSubmit={(e) => {
          e.preventDefault()
          setLoading(true)
          handleUpdate()
        }}
      >
        <div className="form-group">
          <Select
            value={selectOptions.find((option) => option.value === soldCarsDays)}
            options={selectOptions}
            onChange={(e) => setSoldCarsDays(e.value)}
            label="Include sold cars for how many days?"
          />
        </div>
        <button className="btn btn-outline-primary" type="submit">
          {loading ? 'Loading...' : 'Update'}
        </button>
      </form>
    </div>
  )
}

const Stock = ({ website }) => {
  const [dealershipWebsites, setDealershipWebsites] = useState([])
  const [soldCarsDays, setSoldCarsDays] = useState(1)
  let { websiteSlug } = useParams()
  let [loading, setLoading] = useState(true)

  useEffect(() => {
    fetch(Routes.stock_website_path(websiteSlug))
      .then((res) => res.json())
      .then((data) => {
        setDealershipWebsites(data.dealership_websites)
        setSoldCarsDays(data.website.include_sold_cars_days)
        setLoading(false)
      })
  }, [website])

  const allowLinkTemplate = (rowData) => {
    return rowData.allow_link_from_dealership_websites_to_website ? (
      <i className="fa fa-check text-success"></i>
    ) : (
      <i className="fa fa-times text-danger"></i>
    )
  }

  const actionsTemplate = (rowData) => {
    return (
      <>
        <a
          href={Routes.edit_website_dealership_website_path(website.id, rowData.id)}
          className="btn btn-outline-primary btn-sm"
          data-remote="true"
        >
          Edit
        </a>
        <div className="btn btn-outline-danger btn-sm ml-2" onClick={() => handleUnlink(rowData)}>
          Unlink
        </div>
      </>
    )
  }

  const handleUnlink = (rowData) => {
    fetch(Routes.website_dealership_website_path(websiteSlug, rowData.id), {
      method: 'DELETE',
      headers: standardHeaders,
    }).then((res) => {
      if (res.ok) {
        setDealershipWebsites((prev) => prev.filter((d) => d.id !== rowData.id))
      }
    })
  }

  return (
    <div className="pb-4 px-4 pt-2">
      <h4>Stock</h4>
      <p>
        This process determines which stock is displayed on the dealer's website. At times, we have
        the option to include stock from different dealerships, and the locations associated with
        each dealership are accessible for integration into the website.
      </p>

      <div className="box mb-3">
        <DataTable value={dealershipWebsites} loading={loading}>
          <Column
            field="dealership.name"
            header="Dealership"
            body={(rowData) => (
              <Link to={Routes.dealership_path(rowData.dealership?.id)}>
                {rowData.dealership?.name}
              </Link>
            )}
          />
          <Column
            field="cars_count"
            sortable
            header="Cars"
            body={(rowData) => rowData.cars_count}
          />
          <Column header="Allow dealership to link to website?" body={allowLinkTemplate} />
          <Column header="Actions" body={actionsTemplate} />
        </DataTable>
        <div className="px-3 pb-3">
          <a
            href={Routes.new_website_dealership_website_path(websiteSlug)}
            className="btn btn-outline-primary"
            data-remote="true"
            data-disable-with="Loading..."
          >
            Add Dealership
          </a>
        </div>
      </div>

      <StockSettingsForm soldCarsDays={soldCarsDays} setSoldCarsDays={setSoldCarsDays} />
    </div>
  )
}

const Wrapper = () => {
  const { website } = useFetchWebsite()

  if (!website) {
    return null
  }

  return <Stock website={website} />
}

export default Wrapper
