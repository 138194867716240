import { filterUniqueItems, generateRandomId } from '../../common/commonUtils'
import { handleBanner } from './manufacturerUtils'
import { getFormattedAddressData, getCarsViewerFilters, getErrorMessage } from '../templateUtils'
import type {
  BrandsAddressProps,
  BrandsDataProps,
  BrandsLocationProps,
  ManufacturerProps,
} from '../../types'

export function manufacturerTemplate(
  brandWebsiteDetails: BrandsDataProps,
  manufacturer: ManufacturerProps
) {
  // Error Handling
  if (brandWebsiteDetails === undefined || manufacturer === undefined) {
    return getErrorMessage()
  }

  // Filter locations & addresses
  const uniqueLocations = filterUniqueItems(brandWebsiteDetails.locations, 'id') ?? undefined
  const uniqueAddresses =
    uniqueLocations?.length > 0 ? filterUniqueItems(uniqueLocations, 'address.id') : undefined

  // Template specific logic
  const bannerBlock = handleBanner(manufacturer)
  const filters = getCarsViewerFilters()
  const formattedAddressDetails = uniqueAddresses
    ? getFormattedAddressData(uniqueAddresses)
    : { uniqueCities: undefined, state: undefined }

  return [
    ...bannerBlock,
    {
      id: generateRandomId(10),
      type: 'header',
      data: { text: brandWebsiteDetails.website_name, level: 1 },
      tunes: {
        alignment: { alignment: 'center' },
        container: { contain: true },
        backgroundColor: {
          backgroundColor: 'none',
          customBackgroundColor: '#000000',
        },
        textColor: {
          textColor: 'none',
          customTextColor: '#000000',
        },
        margin: { spacingTop: 5, spacingBottom: 0 },
        padding: { paddingTop: 0, paddingBottom: 0 },
        classname: { className: '' },
      },
    },
    {
      id: generateRandomId(10),
      type: 'header',
      data: {
        text: `${formattedAddressDetails.uniqueCities} ${formattedAddressDetails.state}`,
        level: 2,
      },
      tunes: {
        alignment: { alignment: 'center' },
        container: { contain: true },
        backgroundColor: {
          backgroundColor: 'none',
          customBackgroundColor: '#000000',
        },
        textColor: {
          textColor: 'none',
          customTextColor: '#000000',
        },
        margin: { spacingTop: 0, spacingBottom: 0 },
        padding: { paddingTop: 0, paddingBottom: 0 },
        classname: { className: '' },
      },
    },
    {
      id: generateRandomId(10),
      type: 'horizontalRule',
      data: {
        usePageTitle: true,
        height: 1,
        borderRadius: 5,
        opacity: 1,
        color: { colorType: 'primary', customColor: '000000' },
      },
      tunes: {
        container: { contain: false },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
          mobile: { active: false, spacingTop: 0, spacingBottom: 0 },
          tablet: { active: false, spacingTop: 0, spacingBottom: 0 },
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
          mobile: { active: false, paddingTop: 0, paddingBottom: 0 },
          tablet: { active: false, paddingTop: 0, paddingBottom: 0 },
        },
        classname: { className: '' },
      },
    },
    {
      id: generateRandomId(10),
      type: 'paragraph',
      data: {
        text: `${manufacturer.name} dealer located at ${formattedAddressDetails.uniqueCities} in ${formattedAddressDetails.state}.`,
      },
      tunes: {
        alignment: { alignment: 'center' },
        container: { contain: true },
        backgroundColor: {
          backgroundColor: 'none',
          customBackgroundColor: '#000000',
        },
        textColor: {
          textColor: 'none',
          customTextColor: '#000000',
        },
        margin: { spacingTop: 0, spacingBottom: 0 },
        padding: { paddingTop: 0, paddingBottom: 0 },
        classname: { className: '' },
      },
    },
    { id: generateRandomId(10), type: 'brandModels', data: { brand: manufacturer.slug } },
    {
      id: generateRandomId(10),
      type: 'header',
      data: { text: `View Our ${manufacturer.name} Stock`, level: 2 },
      tunes: {
        alignment: { alignment: 'center' },
        container: { contain: false },
        backgroundColor: {
          backgroundColor: 'dark',
          customBackgroundColor: '#000000',
        },
        textColor: {
          textColor: 'light',
          customTextColor: '#000000',
        },
        margin: { spacingTop: 5, spacingBottom: 0 },
        padding: { paddingTop: 5, paddingBottom: 0 },
        classname: { className: '' },
      },
    },
    {
      id: generateRandomId(10),
      type: 'cars',
      data: {
        searchState: {
          configure: {
            filters: filters,
          },
          menu: { make: manufacturer.name },
          page: 1,
          refinementList: {
            make: [manufacturer.name],
            car_type: ['new', 'demo'],
          },
        },
        multiSelect: true,
        dateFilter: null,
        hideButton: false,
        hideSearch: true,
        buttonColor: 'light',
      },
      tunes: {
        backgroundColor: {
          backgroundColor: 'dark',
          customBackgroundColor: '#000000',
        },
        margin: { spacingTop: 0, spacingBottom: 0 },
        padding: { paddingTop: 0, paddingBottom: 5 },
        classname: { className: '' },
      },
    },
    {
      id: generateRandomId(10),
      type: 'brandDescription',
      data: {
        isManufacturer: manufacturer.name,
        activated: true,
        useCustomLogo: false,
        logo: manufacturer.logo,
        heading: `About ${manufacturer.name}`,
        useCustomDescription: false,
        description: manufacturer.description,
        btns: [
          {
            id: generateRandomId(10),
            linkText: `View ${manufacturer.name} Stock`,
            href: `/search/${manufacturer.slug}`,
            btnColour: 'primary',
            btnType: 'fill',
          },
          {
            id: generateRandomId(10),
            linkText: `View ${brandWebsiteDetails.website_name} Website`,
            href: brandWebsiteDetails.url,
            btnColour: 'primary',
            btnType: 'outline',
          },
        ],
      },
    },
    {
      id: generateRandomId(10),
      type: 'iconButtonsTool',
      data: {
        buttons: [
          {
            id: generateRandomId(10),
            icon: 'Test Drive',
            text: 'Test Drive',
            href: '/book-test-drive',
          },
          { id: generateRandomId(10), icon: 'Service', text: 'Service', href: '/service' },
          {
            id: generateRandomId(10),
            icon: 'Finance',
            text: 'Finance',
            href: '/finance-pre-approval',
          },
          {
            id: generateRandomId(10),
            icon: 'Sell Your Car',
            text: 'Sell Your Car',
            href: '/trade-in',
          },
        ],
      },
      tunes: {
        alignment: { alignment: 'left' },
        container: { contain: false },
        margin: { spacingTop: 0, spacingBottom: 0 },
        classname: { className: '' },
      },
    },
    {
      id: generateRandomId(10),
      type: 'locations',
      data: { filter: 'all', manufacturer: manufacturer.name, subtype: false },
    },
  ]
}
