import React, { createContext, useState, ReactNode, useEffect } from 'react'
import { getTabTitles } from '../utils'
import { useAnalyticsDashboard } from './hooks'
import { maxModules } from '../constants'
import { ModuleItem, SetState } from '../types'
import { useFetchModuleLayoutByTab, useModuleItemsBasedOnLevel } from '../hooks'

type TabsContextType = {
  selectedTab: number
  handleUpdateSelectedTab: (newTab: number) => void
  tabLayouts: { [key: number]: ModuleItem[] }
  setTabLayouts: SetState<{ [key: number]: ModuleItem[] }>
  tabTitles: string[]
  setTabTitles: SetState<string[]>
  handleUpdateTabTitles: (newTitles: string[]) => void
  handleTabNameChange: (newTabName: string) => void
}

export const TabsContext = createContext<TabsContextType>({
  selectedTab: 0,
  handleUpdateSelectedTab: () => {},
  tabLayouts: {},
  setTabLayouts: () => {},
  tabTitles: [],
  setTabTitles: () => {},
  handleUpdateTabTitles: () => {},
  handleTabNameChange: () => {},
})

export const TabsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { analyticsBlock, analyticsBlockLoading } = useAnalyticsDashboard()
  const [selectedTab, setSelectedTab] = useState<number>(0)
  const [tabTitles, setTabTitles] = useState<string[]>(getTabTitles(analyticsBlock))

  const moduleItems: ModuleItem[] = useModuleItemsBasedOnLevel()

  const [tabLayouts, setTabLayouts] = useState<{ [key: number]: ModuleItem[] }>({})
  const fetchModuleLayoutByTab = useFetchModuleLayoutByTab()

  // Set the tab layouts state based on the saved layouts, if there are no saved layouts for the first tab, use the default modules, otherwise do nothing for the other tabs
  // Use `useEffect` to fetch and set the layouts once `fetchModuleLayout` is available
  useEffect(() => {
    const layouts = {
      0:
        fetchModuleLayoutByTab(0) ??
        (analyticsBlockLoading ? [] : moduleItems.slice(0, maxModules)),
      ...(fetchModuleLayoutByTab(1) && { 1: fetchModuleLayoutByTab(1) }),
      ...(fetchModuleLayoutByTab(2) && { 2: fetchModuleLayoutByTab(2) }),
      ...(fetchModuleLayoutByTab(3) && { 3: fetchModuleLayoutByTab(3) }),
      ...(fetchModuleLayoutByTab(4) && { 4: fetchModuleLayoutByTab(4) }),
      ...(fetchModuleLayoutByTab(5) && { 5: fetchModuleLayoutByTab(5) }),
      ...(fetchModuleLayoutByTab(6) && { 6: fetchModuleLayoutByTab(6) }),
    }

    setTabLayouts(layouts)
  }, [fetchModuleLayoutByTab])

  const handleUpdateSelectedTab = (newTab: number) => {
    setSelectedTab(newTab)
  }

  const handleUpdateTabTitles = (newTitles: string[]) => {
    setTabTitles(newTitles)
  }

  const handleTabNameChange = (newTabName: string): void => {
    setTabTitles((prevTabTitles) =>
      prevTabTitles.map((title, index) => (index === selectedTab ? newTabName : title))
    )
  }

  useEffect(() => {
    if (analyticsBlock) {
      setTabTitles(getTabTitles(analyticsBlock))
    }
  }, [analyticsBlock])

  return (
    <TabsContext.Provider
      value={{
        selectedTab,
        handleUpdateSelectedTab,
        tabLayouts,
        setTabLayouts,
        tabTitles,
        setTabTitles,
        handleUpdateTabTitles,
        handleTabNameChange,
      }}
    >
      {children}
    </TabsContext.Provider>
  )
}
