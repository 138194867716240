import { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Toast } from 'primereact/toast'
import { useNavigate } from 'react-router-dom'

import showToast from './../shared/ShowToast'
import DealershipEmailAddressesForm from './form'

const DealershipEmailAddressesEdit = () => {
  const navigate = useNavigate()
  const notification = useRef(null)
  let { dealershipSlug, dealershipEmailAddressId } = useParams()
  let [dealershipEmailAddress, setDealershipEmailAddress] = useState(undefined)

  useEffect(() => {
    fetchDealershipEmailAddress(dealershipSlug)
  }, [])

  // Use react-router-dom's useNavigate hook to navigate back
  const handleBack = () => {
    navigate(`/dealerships/${dealershipSlug}/dealership_email_addresses`)
  }

  const fetchDealershipEmailAddress = async (dealershipSlug) => {
    try {
      const URL = Routes.dealership_dealership_email_address_path(
        dealershipSlug,
        dealershipEmailAddressId
      )
      const response = await fetch(URL, {
        headers: {
          Accept: 'application/json',
        },
      })
      const data = await response.json()
      setDealershipEmailAddress(data)
    } catch (error) {
      console.error('Error fetching dealership email address:', error)
      showToast(notification, 'error', 'Error fetching dealership email address.', '')
    }
  }

  const submitFunction = async (data) => {
    const URL = Routes.dealership_dealership_email_address_path(
      dealershipSlug,
      dealershipEmailAddressId
    )
    try {
      let response = await fetch(URL, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document
            .querySelector("meta[name='csrf-token']")
            ?.getAttribute('content'),
        },
        body: JSON.stringify(data),
      })
      if (response.ok) {
        notification.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Data saved successfully',
        })
        handleBack()
      } else {
        throw response
      }
    } catch (err) {
      console.error('Error updating dealership email address:', err)
      showToast(notification, 'error', 'Error updating dealership email address.', '')
    }
  }

  return (
    <>
      {dealershipEmailAddress && (
        <div className="container py-3">
          <Toast ref={notification} />
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
              <div className="box p-3">
                <h1>Edit Dealership Email Address</h1>
                <DealershipEmailAddressesForm
                  dealershipEmailAddress={dealershipEmailAddress}
                  submitFunction={submitFunction}
                  handleBack={handleBack}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default DealershipEmailAddressesEdit
