import { useForm } from 'react-hook-form'

const DealershipEmailAddressesForm = ({ dealershipEmailAddress, handleBack, submitFunction }) => {
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    defaultValues: {
      email_address: dealershipEmailAddress.email_address,
      service: dealershipEmailAddress.service,
      verified: dealershipEmailAddress.verified,
      dkim_verified: dealershipEmailAddress.dkim_verified,
      spf_verified: dealershipEmailAddress.spf_verified,
    },
  })
  let buttonLabel = 'Create'
  if (dealershipEmailAddress.id) {
    buttonLabel = 'Update'
  }

  return (
    <form onSubmit={handleSubmit(submitFunction)}>
      <div className="form-inputs">
        <div className="form-group">
          <label className="form-control-label">Email Address</label>
          <input
            className="form-control"
            type="text"
            {...register('email_address')}
            id="dealership-email-address-email-address"
          />
        </div>
        <div className="form-group">
          <label className="form-control-label select">Service</label>
          <select {...register('service')} className="form-control">
            <option value="mailjet">Mailjet</option>
          </select>
        </div>
        <div className="form-group">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="dealership-email-address-verified"
              {...register('verified')}
            />
            <label className="form-check-label" htmlFor="dealership-email-address-verified">
              Verified
            </label>
          </div>
        </div>
        <div className="form-group">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="dealership-email-address-dkim-verified"
              {...register('dkim_verified')}
            />
            <label className="form-check-label" htmlFor="dealership-email-address-dkim-verified">
              DKIM Verified
            </label>
          </div>
        </div>
        <div className="form-group">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="dealership-email-address-spf-verified"
              {...register('spf_verified')}
            />
            <label className="form-check-label" htmlFor="dealership-email-address-spf-verified">
              SPF Verified
            </label>
          </div>
        </div>
      </div>
      <div className="form-actions mt-3">
        <button onClick={handleBack} className="btn btn-link">
          Back
        </button>
        <button type="submit" className="btn btn-primary">
          {buttonLabel}
        </button>
      </div>
    </form>
  )
}

export default DealershipEmailAddressesForm
