import { useState, useRef } from 'react'
import { OverlayPanel } from 'primereact/overlaypanel'
import { Tooltip } from 'react-tooltip'
import { truncateString } from '../entries/utils'
import { Link } from 'react-router-dom'
import Loading from '../Loading'

const OverlayInner = ({ car }) => {
  return (
    <div className="small">
      {!car ? (
        <>Car not found</>
      ) : (
        <>
          <div>
            {car?.price?.toLocaleString('en-US', {
              maximumFractionDigits: 0,
              currencyDisplay: 'narrowSymbol',
              style: 'currency',
              currency: 'AUD',
            })}{' '}
            {car?.price_type}
          </div>
          <div>{car.days_old} days old</div>
        </>
      )}
    </div>
  )
}

const ItemDisplay = ({ item }) => {
  let [car, setCar] = useState(null)
  let [loading, setLoading] = useState(true)
  const op = useRef(null)

  const loadCar = () => {
    if (car) return

    setLoading(true)

    fetch(`/cars/${item?.slug}.json`)
      .then((res) => res.json())
      .then((data) => {
        setCar(data)
        setLoading(false)
      })
  }

  const hasOp = item && typeof item?.slug !== 'undefined' && item?.slug !== null

  return (
    <div
      className="d-flex"
      onMouseEnter={(e) => hasOp && op.current.toggle(e)}
      onMouseLeave={(e) => hasOp && op.current.toggle(e)}
    >
      {item?.image_url && (
        <img src={item?.image_url} height="40" className="rounded mr-2" loading="lazy" />
      )}
      <OverlayPanel ref={op} onShow={loadCar}>
        {loading ? <Loading /> : <OverlayInner car={car} />}
      </OverlayPanel>
      <div style={{ lineHeight: 1.2 }}>
        <div className="mb-0">
          {item?.slug ? (
            <Link to={`/cars/${item?.slug}`}>
              {item?.make} {truncateString(item?.model || '', 30)}
            </Link>
          ) : (
            <>
              {item?.make} {truncateString(item?.model || '', 30)}
            </>
          )}
        </div>
        <div className="small text-secondary">{item.name_excluding_make_model}</div>
        <small className="text-secondary">
          {item?.year}
          {item?.stocknum && (
            <>
              <span id={`item-stocknum-${item?.id}`}> - {item?.stocknum}</span>
              <Tooltip
                anchorSelect={`#item-stocknum-${item?.id}`}
                place="bottom"
                style={{ zIndex: 10000 }}
              >
                Stock no
              </Tooltip>
            </>
          )}
        </small>
      </div>
    </div>
  )
}

export default ItemDisplay
