import type { EnquiryAddon } from './EnquiryAddonTypes'
import { capitalize } from '../editor/common/commonUtils'
import { FormInput, Form, FormSelect } from '../formUtils'
import { FieldErrors } from 'react-hook-form'

export const formatAddonOption = (val: string) => {
  if (!val) {
    return val
  }
  return val
    .split('_')
    .map((v) => capitalize(v))
    .join(' ')
}

const EnquiryAddonForm = ({
  enquiryAddon,
  priceOptions,
  formTypes,
  onSave,
}: {
  enquiryAddon: EnquiryAddon
  priceOptions: string[]
  formTypes: string[]
  onSave: (enquiryAddon: EnquiryAddon) => Promise<void>
}) => {
  const { created_at, updated_at, ...defaultValues } = enquiryAddon ?? {}

  /**
   * Handle additional field validation. The following edge case is accounted for:
   * - If a user sets their price higher than the retail price, an error message will be displayed
   * since the purpose of RRP is to show a discounted price.
   */
  const resolver = async (values: EnquiryAddon) => {
    const errors: FieldErrors<EnquiryAddon> = {}

    if (values.recommended_retail_price) {
      const salePrice = parseFloat(values.price)
      const retailPrice = parseFloat(values.recommended_retail_price)
      if (salePrice > retailPrice) {
        errors.recommended_retail_price = {
          type: 'manual',
          message:
            'RRP should not be lower than your price. Please only set RRP if you want to display a discounted rate.',
        }
      }
    }

    return {
      values: Object.keys(errors).length > 0 ? {} : values,
      errors,
    }
  }

  return (
    <Form onSubmit={onSave} defaultValues={defaultValues} resolver={resolver}>
      <FormInput name="title" label="Title" required />
      <FormInput name="description" label="Description" required />
      <FormInput name="price" label="Price" type="number" step="0.01" required />
      <FormInput
        name="recommended_retail_price"
        label="Recommended Retail Price (RRP)"
        type="number"
        step="0.01"
      />
      <FormSelect
        name="form_type"
        label="Form Type"
        required
        options={formTypes}
        formatOption={formatAddonOption}
      />
      <FormSelect
        name="price_option"
        label="Price Option"
        required
        options={priceOptions}
        formatOption={formatAddonOption}
      />
      <button type="submit" className="btn btn-primary">
        Save
      </button>
    </Form>
  )
}

export default EnquiryAddonForm
