import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import moment from 'moment'
import * as Routes from '../../routes'

let CreatedAt = (rowData) => {
  return <div>{moment(rowData.created_at).fromNow()}</div>
}

const Actions = (rowData) => {
  let { dealershipSlug } = useParams()

  return (
    <div className="btn-group">
      <button
        type="button"
        className="btn btn-sm btn-outline-secondary dropdown-toggle"
        data-toggle="dropdown"
        aria-expanded="false"
      >
        Actions
      </button>
      <ul className="dropdown-menu">
        <li>
          <a
            className="dropdown-item"
            href={Routes.edit_dealership_notification_path(dealershipSlug, rowData.id)}
          >
            Edit
          </a>
        </li>
        <li>
          <a
            className="dropdown-item"
            href={Routes.send_now_dealership_notification_path(dealershipSlug, rowData.id)}
            data-method="put"
          >
            Send Now
          </a>
        </li>
        <li>
          <button className="dropdown-item">Delete</button>
        </li>
      </ul>
    </div>
  )
}

const Reports = () => {
  let [reports, setReports] = useState([])
  let [loading, setLoading] = useState(true)
  let { dealershipSlug } = useParams()

  useEffect(() => {
    fetch(Routes.reports_dealership_notifications_path(dealershipSlug), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setReports(data)
        setLoading(false)
      })
  }, [])

  return (
    <div>
      <div className="d-flex mb-2">
        <h3>Reports</h3>
        <div className="ml-auto">
          <a
            className="btn btn-outline-primary"
            href={Routes.new_dealership_notification_path(dealershipSlug)}
          >
            <i className="fa fa-plus mr-2" />
            Email Report
          </a>
        </div>
      </div>
      <div className="box">
        <DataTable value={reports} className="p-datatable-sm" loading={loading}>
          <Column field="id" header="ID" />
          <Column
            header="Users"
            body={(rowData) => rowData.users.map((user) => user.name).join(', ')}
          />
          <Column field="name" header="Name" sortable />
          <Column field="frequency" header="Frequency" sortable />
          <Column
            header="Locations"
            body={(rowData) => rowData.locations.map((location) => location.name).join(', ')}
          />
          <Column field="created_at" header="Created At" body={CreatedAt} sortable />
          <Column field="actions" header="Actions" body={Actions} />
        </DataTable>
      </div>
    </div>
  )
}

export default Reports
