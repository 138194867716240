// This one could probably be broken up into 2-3 components

import { CSSProperties, useEffect, useRef, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { Group, ModuleItem } from '../../../types'
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox'
import { useAnalyticsDashboard, useItems, useTabs } from '../../../contexts/hooks'
import { useModuleConditionals, useModuleItemsBasedOnLevel } from '../../../hooks'

type Preset = {
  label: string
  title: string
}
const Tabs: React.FC<{}> = () => {
  const { dashboardLevel, editModeEnabled } = useAnalyticsDashboard()
  const {
    selectedTab,
    handleUpdateSelectedTab,
    tabTitles,
    setTabTitles,
    handleTabNameChange,
    tabLayouts,
    setTabLayouts,
  } = useTabs()
  const { handleUpdateItems } = useItems()
  const { hasFacebookAds, hasGoogleAds, hasGA4 } = useModuleConditionals()

  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState<boolean>(false)
  const [isAddDialogVisible, setIsAddDialogVisible] = useState<boolean>(false)
  const [selectedPreset, setSelectedPreset] = useState<Preset | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const moduleItems: ModuleItem[] = useModuleItemsBasedOnLevel()

  const handleTabChange = (tabIndex: number): void => {
    handleUpdateSelectedTab(tabIndex)
    handleUpdateItems(tabLayouts[tabIndex])
  }

  function getModulesFromNames(modules: string[], group: Group): ModuleItem[] {
    const modulesInOrder = modules
      .map((module) => moduleItems.find((item) => item.module === module))
      .filter((item): item is ModuleItem => item !== undefined)

    // Check if there are new modules in the group that aren't hard coded,
    // and add them to the end
    // Disable for now

    // const extraModules = getGroupedModules(group)

    return modulesInOrder

    // return modulesInOrder.concat(
    //   extraModules?.filter(
    //     (module) => !modulesInOrder?.some((item) => item.module === module.module)
    //   )
    // )
  }

  const handleDeleteTab = (tabIndex: number): void => {
    setIsDeleteDialogVisible(false)

    // Updat the tabLayout, and update the index's for proper deletion
    setTabLayouts((prevTabLayouts) => {
      const updatedTabLayouts: { [key: number]: ModuleItem[] } = {}
      Object.keys(prevTabLayouts).forEach((key) => {
        const originalIndex = parseInt(key, 10)
        if (originalIndex < tabIndex) {
          updatedTabLayouts[originalIndex] = prevTabLayouts[originalIndex]
        } else if (originalIndex > tabIndex) {
          updatedTabLayouts[originalIndex - 1] = prevTabLayouts[originalIndex]
        }
      })
      return updatedTabLayouts
    })
    setTabTitles((prevTabTitles) => prevTabTitles.filter((_, index) => index !== tabIndex))

    // When you delete a tab, make the first tab active, unless you delete the first tab
    if (tabIndex !== 0) {
      setTimeout(() => {
        handleTabChange(0)
      }, 0)
    } else {
      let newSelectedTab = selectedTab
      if (selectedTab === tabIndex) {
        newSelectedTab = 0
      } else if (selectedTab > tabIndex) {
        newSelectedTab -= 1
      }
      setTimeout(() => {
        handleUpdateSelectedTab(newSelectedTab)
      }, 0)
    }
  }

  const handleAddTab = (): void => {
    if (!selectedPreset) return

    let presetModules: ModuleItem[] = []
    switch (selectedPreset?.title) {
      case 'Facebook Ads':
        presetModules = getModulesFromNames(
          [
            'facebook_ads_leads',
            'facebook_ads_cost_per_lead',
            'facebook_ads_cpc',
            'facebook_ads_inline_link_clicks',
            'facebook_ads_impressions',
            'facebook_ads_ctr',
            'facebook_ads_engagement_by_campaign',
            // 'facebook_ads_reach',
            // 'facebook_ads_spend',
          ],
          'Facebook Ads'
        )
        break
      case 'Google Ads':
        presetModules = getModulesFromNames(
          [
            'google_ad_leads',
            'google_ad_cost_per_lead',
            'google_ad_cpc',
            'google_ad_website_phone_calls',
            'google_ad_phone_calls',
            'google_ad_vehicle_leads',
            'google_ad_clicks',
            'google_ad_ctr',
            'google_ad_performance_by_campaign',
            // 'google_ad_vehicle_phone_calls',
            // 'google_ad_impressions',
            // 'google_ad_top_performing_keywords', // excluded this in gerneralised group as it doesnt make sense for campaigns other than search
          ],
          'Google Ads'
        )
        break
      case 'Google Adwords Ads':
        presetModules = getModulesFromNames(
          [
            'google_ads_leads',
            'google_ads_cost_per_lead',
            'google_ads_cpc',
            'google_ads_website_phone_calls',
            'google_ads_phone_calls',
            'google_ads_vehicle_leads',
            'google_ads_clicks',
            'google_ads_impressions',
            'google_ads_ctr',
            'google_ads_performance_by_campaign',
          ],
          'Google Adwords Ads'
        )
        break
      case 'Google Display Ads':
        presetModules = getModulesFromNames(
          [
            'google_display_ads_leads',
            'google_display_ads_cost_per_lead',
            'google_display_ads_cpc',
            'google_display_ads_website_phone_calls',
            'google_display_ads_clicks',
            'google_display_ads_ctr',
            'google_display_ads_performance_by_campaign',
          ],
          'Google Display Ads'
        )
        break
      case 'Google VLA Ads':
        presetModules = getModulesFromNames(
          [
            'google_vla_ads_leads',
            'google_vla_ads_cost_per_lead',
            'google_vla_ads_cpc',
            'google_vla_ads_clicks',
            'google_vla_ads_impressions',
            'google_vla_ads_ctr',
            'google_vla_ads_performance_by_campaign',
          ],
          'Google VLA Ads'
        )
        break
      case 'Google Analytics':
      case 'Website Traffic':
        presetModules = getModulesFromNames(
          [
            'sessions',
            'views',
            'total_users',
            'new_users',
            'google_analytics',
            'sessions_by_medium',
            'traffic_by_device',
            'top_page_views',
          ],
          'Google Analytics'
        )
        break
      case 'Lead Conversions':
        presetModules = moduleItems.filter((item) => item.module?.includes('conversion'))
        break
      case 'Empty Tab':
        presetModules = []
      default:
        break
    }

    setTabLayouts((prevTabLayouts) => ({
      ...prevTabLayouts,
      [tabTitles.length]: presetModules,
    }))
    setTabTitles((prevTabTitles) => [...prevTabTitles, selectedPreset?.title])
    handleUpdateSelectedTab(tabTitles.length)
    onCloseAddDialog()
  }

  const handleAddEmptyTab = (): void => {
    setTabLayouts((prevTabLayouts) => ({ ...prevTabLayouts, [tabTitles.length]: [] }))
    setTabTitles((prevTabTitles) => [...prevTabTitles, 'New Tab'])
  }

  const tabStyles: CSSProperties = {
    width: '20%',
    height: '38px',
    position: 'relative',
  }

  const activeStyles: CSSProperties = {
    color: 'rgb(255, 255, 255)',
    backgroundColor: 'rgb(56, 124, 255)',
    borderColor: 'rgb(56, 124, 255)',
  }

  const inputStyles: CSSProperties = {
    width: '80%',
    height: '100%',
    border: 'none',
    backgroundColor: 'white',
    color: 'black',
    borderRadius: '0.25rem',
    textAlign: 'center',
    outline: 'none',
    padding: '0',
    margin: '0',
    fontSize: '1rem',
  }

  const tabPresets = [
    {
      label: 'Empty Tab',
      title: 'New Tab',
      visible: true,
    },
    {
      label: 'Facebook Ads',
      title: 'Facebook Ads',
      excludedLevels: ['Manufacturer'],
      visible: hasFacebookAds,
    },
    {
      label: 'Google Ads',
      title: 'Google Ads',
      excludedLevels: ['Manufacturer'],
      visible: hasGoogleAds,
    },
    {
      label: 'Google Adwords Ads',
      title: 'Google Adwords Ads',
      excludedLevels: ['Manufacturer'],
      visible: hasGoogleAds,
    },
    {
      label: 'Google Display Ads',
      title: 'Google Display Ads',
      excludedLevels: ['Manufacturer'],
      visible: hasGoogleAds,
    },
    {
      label: 'Google VLA Ads',
      title: 'Google VLA Ads',
      excludedLevels: ['Manufacturer'],
      visible: hasGoogleAds,
    },
    {
      label: 'Google Analytics',
      title: 'Google Analytics',
      excludedLevels: ['Manufacturer'],
      visible: hasGA4,
    },
    {
      label: 'Lead Conversion Modules',
      title: 'Lead Conversions',
      excludedLevels: ['Manufacturer', 'Website'],
      visible: true,
    },
  ]

  const onPresetChange = (event: CheckboxChangeEvent) => {
    const checkedPreset = tabPresets.find((preset) => preset.title === event.value)
    if (event.checked) {
      setSelectedPreset({
        title: event.value,
        label: checkedPreset?.label,
      })
    } else {
      setSelectedPreset(null)
    }
  }

  const onCloseAddDialog = (): void => {
    setIsAddDialogVisible(false)
    setSelectedPreset(null)
  }

  // Focus on the input when the edit icon is clicked
  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isEditing])

  return (
    <div className="d-flex w-100">
      {tabTitles.map((title, index) => (
        <button
          key={index}
          className={`btn btn-outline-primary mx-2`}
          style={{ ...tabStyles, ...(selectedTab === index ? activeStyles : {}) }}
          onClick={() => {
            if (isAddDialogVisible || isDeleteDialogVisible) return
            handleTabChange(index)
          }}
        >
          <div className="d-flex w-100 justify-content-center align-items-center">
            {editModeEnabled && tabTitles.length > 1 && (
              <i
                className="fas fa-times-circle closeTab"
                onClick={() => {
                  handleTabChange(index) // Remember the selected tab for deletion
                  setIsDeleteDialogVisible(true)
                }}
              ></i>
            )}
            <Dialog
              header={`Are you sure you want to delete the ${tabTitles[selectedTab]} tab?`}
              visible={isDeleteDialogVisible}
              style={{ width: '50vw', textAlign: 'center' }}
              onHide={() => setIsDeleteDialogVisible(true)}
              dismissableMask
            >
              <i
                className="far fa-exclamation-triangle"
                style={{ color: '#fcc600', fontSize: '22px' }}
              ></i>
              <h5 className="py-1 ">
                This will remove the tab and all module preferences permanently.
              </h5>
              <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <button
                  className="btn btn-primary mt-2 "
                  onClick={() => setIsDeleteDialogVisible(false)}
                  style={{ width: '150px' }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-danger mt-2 "
                  onClick={() => handleDeleteTab(selectedTab)}
                  style={{ width: '150px' }}
                >
                  Delete Tab
                </button>
              </div>
            </Dialog>
            <Dialog
              header={`Would like to use a tab preset?`}
              visible={isAddDialogVisible}
              style={{ width: '50vw', textAlign: 'center' }}
              onHide={() => onCloseAddDialog()}
              dismissableMask
            >
              <div className="col-8 col-lg-12 mx-auto px-5 d-flex flex-column align-items-start ">
                <h5 className="py-1 ">Select your desired tab preset to add to your dashboard:</h5>
                {tabPresets.map((preset) => {
                  if (preset.excludedLevels?.includes(dashboardLevel) || !preset.visible)
                    return null
                  const hasBetaTag =
                    (preset.label.includes('Facebook') || preset.label.includes('Google')) &&
                    preset.label.includes('Ads')

                  return (
                    <div key={preset.title} className="my-2">
                      <Checkbox
                        inputId={preset.title}
                        name="Preset"
                        value={preset.title}
                        onChange={onPresetChange}
                        checked={selectedPreset?.title === preset.title}
                      />
                      <label htmlFor={preset.title} className="mb-0 ml-2">
                        {preset.label}{' '}
                        {hasBetaTag && <span className="badge badge-danger">BETA</span>}
                      </label>
                    </div>
                  )
                })}
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <button
                  className="btn btn-primary mt-2 "
                  onClick={() => onCloseAddDialog()}
                  style={{ width: '150px' }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-success mt-2 "
                  onClick={() => handleAddTab()}
                  style={{ width: '150px' }}
                >
                  Add Tab
                </button>
              </div>
            </Dialog>
            {isEditing && selectedTab === index ? (
              <input
                ref={inputRef}
                type="text"
                value={tabTitles[selectedTab]}
                onChange={(e) => handleTabNameChange(e.target.value)}
                onBlur={() => setIsEditing(false)}
                style={inputStyles}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setIsEditing(false)
                  }
                }}
              />
            ) : (
              <div>
                <h5 className="m-0 text-center flex-grow-1">{title}</h5>
              </div>
            )}
            {editModeEnabled && (
              <i
                className="fal fa-edit ml-auto editTab"
                onClick={() => setIsEditing(!isEditing)}
              ></i>
            )}
          </div>
        </button>
      ))}
      {tabTitles.length < 7 && editModeEnabled && (
        <button
          className="btn btn-success"
          style={{ width: '50px' }}
          onClick={() => {
            if (dashboardLevel === 'Manufacturer') {
              handleAddEmptyTab()
            } else {
              setIsAddDialogVisible(true)
            }
          }}
        >
          +
        </button>
      )}
    </div>
  )
}

export default Tabs
