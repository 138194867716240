import { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import Loading from '../Loading'
import { formatSimpleDuration } from '../entries/timeAgo'
import LeadSourceBadge from './LeadSourceBadge'
import SlideIn from './SlideIn'
import { LeadClusterContext } from '../contexts'
import moment from 'moment'
import Select from 'react-select'
import { CurrentUserContext } from '../contexts'
import { truncateString } from '../entries/utils'

const SlideInComponent = ({ cluster, buttonComponent }) => {
  let [leadCluster, setLeadCluster] = useState(cluster)

  return (
    <LeadClusterContext.Provider value={{ leadCluster, setLeadCluster }}>
      <SlideIn leadCluster={leadCluster} buttonComponent={buttonComponent} />
    </LeadClusterContext.Provider>
  )
}

const LeadClusterBox = ({ leadCluster }) => {
  const itemStyle = {
    flex: '0 0 auto', // Ensures items don’t shrink or grow and maintain their width
    width: '225px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    overflow: 'hidden',
  }

  const renderButton = ({ setVisible }) => (
    <div className="btn btn-outline-primary btn-sm btn-block" onClick={() => setVisible(true)}>
      View
    </div>
  )

  return (
    <div style={itemStyle}>
      <div className="border-bottom p-2">
        <div className="d-flex" style={{ flexWrap: 'wrap' }}>
          <h6 dangerouslySetInnerHTML={{ __html: truncateString(leadCluster.title, 23) }} />
          <div className="text-secondary small ml-auto">
            {formatSimpleDuration(new Date(leadCluster.created_at), new Date())} ago
          </div>
        </div>
      </div>
      <div className="p-2 border-bottom d-flex align-items-center">
        {leadCluster.car && leadCluster.car.make ? (
          <>
            <img
              src={leadCluster.car.primary_image_url}
              height="40"
              className="rounded"
              loading="lazy"
            />
            <div className="ml-2 text-secondary">
              <small>
                {leadCluster.car.year} {leadCluster.car.make} {leadCluster.car.model}
              </small>
              <div className="small">{leadCluster.car.stocknum}</div>
              <div className="small">${leadCluster.car.price.toLocaleString()}</div>
            </div>
          </>
        ) : (
          <div>
            <i className="fas fa-exclamation-triangle mr-1"></i>
            No Item
          </div>
        )}
      </div>
      <div className="p-2 d-flex align-items-center border-bottom">
        {leadCluster.user ? (
          <div className="badge badge-info">{leadCluster.user?.name}</div>
        ) : (
          <div className="badge badge-danger">
            <i className="fas fa-exclamation-triangle mr-1"></i>
            Unassigned
          </div>
        )}
      </div>
      <div className="p-2 d-flex align-items-center border-bottom">
        <div
          className={`badge badge-${leadCluster.lead_status_option?.colour_class_name || 'secondary'}`}
        >
          {leadCluster.lead_state}
        </div>
      </div>
      {leadCluster.next_due_event && leadCluster.next_due_event.followup_datetime && (
        <div className="p-2 d-flex align-items-center border-bottom">
          <div className="badge badge-danger">
            <i className="fas fa-exclamation-triangle mr-1"></i>
            Due {moment(leadCluster.next_due_event.followup_datetime).fromNow()}
          </div>
        </div>
      )}
      <div className="p-2 d-flex align-items-center">
        <div>
          <div className="badge badge-sm badge-secondary">{leadCluster.category}</div>
          <div>
            <LeadSourceBadge lead={leadCluster} />
          </div>
        </div>
        <SlideInComponent cluster={leadCluster} buttonComponent={renderButton} />
      </div>
    </div>
  )
}

const LeadsBox = ({ title, activeBoxes, setActiveBoxes, className, filter, user }) => {
  let [loading, setLoading] = useState(true)
  let [leadClusters, setLeadClusters] = useState([])
  let { dealershipSlug } = useParams()

  const containerStyle = {
    display: 'flex',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    gap: '10px', // Adds spacing between the items
    padding: '20px 10px', // Optional: Adds padding inside the scrollable area
    scrollbarWidth: 'thin', // Modern browsers (not all support this directly via styles)
  }

  let active = activeBoxes.includes(title)

  useEffect(() => {
    if (!active) return

    setLoading(true)

    fetch(`/dealerships/${dealershipSlug}/lead_clusters.json?filter=${filter}&user_id=${user}`)
      .then((res) => res.json())
      .then((data) => {
        setLeadClusters(data.lead_clusters)
        setLoading(false)
      })
  }, [active, user])

  const toggleActive = () => {
    if (activeBoxes.includes(title)) {
      setActiveBoxes(activeBoxes.filter((box) => box !== title))
    } else {
      setActiveBoxes([...activeBoxes, title])
    }
  }

  return (
    <div className="box mb-3">
      <div className="px-3 py-2 border-bottom">
        <div className="d-flex align-items-center">
          <h5 className={`m-0 badge badge-${className}`}>{title}</h5>
          <div className="ml-auto">
            <button className="btn btn-sm btn-outline-primary" onClick={toggleActive}>
              {active ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
            </button>
          </div>
        </div>
      </div>
      {active && (
        <div style={containerStyle}>
          {loading && (
            <div>
              <Loading />
            </div>
          )}

          {leadClusters.map((leadCluster) => (
            <LeadClusterBox key={leadCluster.id} leadCluster={leadCluster} />
          ))}

          {!loading && leadClusters.length === 0 && <div>You have no events {title}! </div>}
        </div>
      )}
    </div>
  )
}

const taskBoxes = {
  new: {
    title: 'New',
    className: 'warning',
  },
  unactioned: {
    title: 'Unactioned',
    className: 'warning',
  },
  overdue: {
    title: 'Overdue',
    className: 'danger',
  },
  dueToday: {
    title: 'Due Today',
    className: 'success',
  },
  dueThisWeek: {
    title: 'Due This Week',
    className: 'secondary',
  },
  dueNextWeek: {
    title: 'Due Next Week',
    className: 'secondary',
  },
  converted: {
    title: 'Converted',
    className: 'success',
  },
  spamLost: {
    title: 'Spam/Lost',
    className: 'danger',
  },
}

const TasksView = ({ data, currentUser }) => {
  let [activeBoxes, setActiveBoxes] = useState(['New', 'Unactioned', 'Overdue', 'Due Today'])

  let [user, setUser] = useState(currentUser?.id)

  let userOptions = data.users.map((user) => ({ value: user.id, label: user.name }))

  let sharedProps = { activeBoxes, setActiveBoxes, user }

  return (
    <div>
      <div className="d-flex">
        <h3>Tasks</h3>
        {console.log(data.users.find((u) => u.id === currentUser.id))}
        <div className="ml-auto">
          <Select
            options={userOptions}
            defaultValue={userOptions.find((u) => u.value === currentUser.id)}
            onChange={(o) => setUser(o.value)}
            placeholder="Filter by user"
          />
        </div>
      </div>
      {/* Loop through the taskBoxes object */}
      {Object.keys(taskBoxes).map((key) => (
        <LeadsBox key={key} {...taskBoxes[key]} filter={key} {...sharedProps} />
      ))}
    </div>
  )
}

const Wrapper = () => {
  let { dealershipSlug } = useParams()
  let [data, setData] = useState({ users: [] })
  let currentUser = useContext(CurrentUserContext)

  useEffect(() => {
    fetch(`/dealerships/${dealershipSlug}/lead_clusters/tasks.json`)
      .then((res) => res.json())
      .then((data) => setData(data))
  }, [])

  if (!currentUser) return <Loading />

  return <TasksView data={data} currentUser={currentUser} />
}

export default Wrapper
