import {
  ChangeEvent,
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Content, ContentParseError } from 'vanilla-jsoneditor'
import { handleEmbeddedEditorActions } from '../../common'
import {
  CustomComponentErrorOptions,
  JSONStructure,
  OldProps,
  PropsContext,
  PropsProviderProps,
} from './customComponentUtils'

const DEFAULT_JSON = [{ key: 'My prop key', value: 'value', type: 'string' }]

const context = createContext<PropsContext | undefined>(undefined)

export const PropsProvider: FC<PropsProviderProps> = ({
  children,
  data,
  onDataChange,
  isAdmin,
  templateType,
  editorApi,
  imageEndpoint,
}) => {
  const [showRaw, setShowRaw] = useState(false)
  const [state, setState] = useState(data)

  // This is a stripped out version of checkError
  const formatCustomComponentProps = () => {
    try {
      const { json: rawJson, text } = data?.customComponentProps
      return rawJson ?? JSON.parse(text) ?? defaultJSON
    } catch (err) {
      // .text will just a string if it is defined
      return data?.customComponentProps?.text ?? defaultJSON
    }
  }

  const [unparsedJson, setUnparsedJson] = useState<OldProps>({
    // Handle older customComponent Pages e.g. Johnhughes before rawProps was introduced
    text: JSON.stringify(
      data?.rawProps?.length
        ? data?.rawProps
        : !!data?.customComponentProps
          ? formatCustomComponentProps()
          : DEFAULT_JSON
    ),
  })

  const embeddedRef = useRef<HTMLDivElement | null>(null)
  const [error, setError] = useState(null)

  // Attempt to parse the raw JSON and error out
  const checkForError = (
    content: OldProps,
    { defaultContent, defaultJSON, status }: CustomComponentErrorOptions = {
      defaultContent: unparsedJson,
      defaultJSON: DEFAULT_JSON,
    }
  ) => {
    try {
      if (!!status?.contentErrors && (status.contentErrors as ContentParseError)?.isRepairable) {
        throw new Error((status.contentErrors as ContentParseError).parseError.message)
      }
      setError(null)
      const { json: rawJson, text } = content
      return rawJson ?? JSON.parse(text) ?? defaultJSON
    } catch (err) {
      setError(`Invalid JSON structure: ${(err as Error).message}`)
      return (defaultContent as OldProps)?.text ?? defaultJSON
    }
  }

  const defaultValues = (value: string, inputName = 'type') => {
    if (inputName !== 'type') return
    switch (value) {
      case 'string':
        return 'my value'
      case 'number':
        return 0
      case 'object':
        return { foo: 'bar' }
      case 'boolean':
        return true
      case 'image':
        return ''
      default:
        return ['my value']
    }
  }

  const parsedJson = useMemo<JSONStructure[]>(() => checkForError(unparsedJson), [unparsedJson])

  const isRaw = typeof parsedJson === 'string'

  const defaultJSON = !isRaw ? parsedJson : error || isRaw ? parsedJson : DEFAULT_JSON

  const updateState = (updatedValue: Object) => {
    const updatedValues = { ...state, ...updatedValue }
    setState(updatedValues)
    onDataChange(updatedValues)
  }

  function setUniqueComponentId(value: string) {
    updateState({ uniqueComponentId: value })
  }

  function setCustomComponentId(e: any) {
    updateState({ customComponentId: e.target.value })
  }

  const updateJson = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>, index) => {
    if (error) return
    // Format JSON with types
    const updatedJSON = parsedJson.map((input, i) => {
      if (index !== i) return input

      // Attempt to parse values based of their type and expected return value
      const typedValue = () => {
        switch (input.type) {
          case 'number':
            return !!Number(value) ? Number(value) : value
          case 'object': {
            try {
              if (typeof value === 'object') return value
              else return JSON.parse(value)
            } catch (err) {
              return value
            }
          }
          default:
            return value
        }
      }

      return {
        ...input,
        ...{ value: name === 'type' ? defaultValues(value, name) : input.value },
        [name.toLowerCase()]: typedValue(),
      }
    })

    const updatedFormatting = updatedJSON.reduce((prev, { key, value }) => {
      prev[key] = Array.isArray(value) ? value.flat() : value
      return prev
    }, {})

    setUnparsedJson({ text: JSON.stringify(updatedJSON), json: updatedJSON })
    updateState({ rawProps: updatedJSON, customComponentProps: updatedFormatting })
  }

  const updateId = (e: ChangeEvent<HTMLSelectElement>) => setUniqueComponentId(e.target.value)

  const updateRawJSON = (content: Content) => setUnparsedJson(content)

  const updateTab = () => setShowRaw((v) => !v)

  const addNewField = (type = 'string') => {
    const keyNumber = parsedJson?.filter(({ key }) => key.includes('Key')).length
    const updatedJson = [
      ...parsedJson,
      { key: `Key ${keyNumber + 1}`, value: defaultValues(type), type },
    ]

    setUnparsedJson({ text: JSON.stringify(updatedJson) })
    updateState({
      customComponentProps: updatedJson,
    })
  }

  const hasDuplicateKey = (key, currentIndex) => {
    const keys = parsedJson.map((obj) => {
      const [keyPair] = Object.entries(obj)
      if (keyPair[0] === 'key') return keyPair[1]
    })
    const containsDuplicates = keys.some((v, i) => v === key && i !== currentIndex)

    return containsDuplicates
  }

  const removeField = (index: number) =>
    setUnparsedJson({
      text: JSON.stringify([...parsedJson.filter((_, i) => i !== index)]),
    })

  useEffect(() => {
    // Show the raw JSON when an error occurred
    if (error && !showRaw) setShowRaw(true)
  }, [error])

  // Update embed actions and parse if the new format is not detected
  useEffect(() => {
    handleEmbeddedEditorActions(embeddedRef.current, editorApi)
    // No parsed JSON means no data has been set
    if (!parsedJson) setUnparsedJson({ text: JSON.stringify(defaultJSON) })

    // Exit formatter if we're already on the new format
    if (Array.isArray(parsedJson) || data?.rawProps) return

    // Parse object structure from previous implementation to new array approach
    const oldFormat = parsedJson as { text?: string; json?: object }
    const isOldFormat = !!oldFormat?.json || !!oldFormat?.text

    // Old structure had text as stringified JSON or JSON as parsed json
    const parsedOldJson = isOldFormat
      ? oldFormat?.text
        ? JSON.parse(oldFormat?.text)
        : oldFormat?.json
      : parsedJson

    const getTypeOf = (value) => {
      if (typeof value !== 'object') return typeof value
      if (Array.isArray(value)) return 'array'
      return 'object'
    }

    const formattedJSON = JSON.stringify(
      Object.entries(parsedOldJson).map(([key, value]) => ({
        key,
        value,
        type: getTypeOf(value),
      }))
    )

    // isRaw is unparsable JSON so we should just set it and let it error out if it has data
    setUnparsedJson({
      text: isRaw
        ? (parsedJson as string).length > 0
          ? parsedJson
          : JSON.stringify(DEFAULT_JSON)
        : formattedJSON,
    })
  }, [])

  return (
    <context.Provider
      value={{
        addNewField,
        checkForError,
        data,
        embeddedRef,
        error,
        hasDuplicateKey,
        imageEndpoint,
        isAdmin,
        isRaw,
        parsedJson,
        removeField,
        setCustomComponentId,
        setUniqueComponentId,
        showRaw,
        state,
        templateType,
        unparsedJson,
        updateId,
        updateJson,
        updateRawJSON,
        updateState,
        updateTab,
      }}
    >
      {children}
    </context.Provider>
  )
}

export const useCustomProps = (): PropsContext => {
  const data = useContext(context)
  if (!data) throw new Error('Missing SearchProvider in tree above useSearch')
  return data
}
