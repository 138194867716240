import { Edit, Eye } from './Icons'

export const ToolHeader = ({
  itemName,
  heading,
  helpText,
  preview,
  setPreview,
  addItem,
  hideToggle = false,
}: {
  itemName: string
  heading?: string
  helpText?: string
  preview: boolean
  setPreview: (value: boolean) => void
  addItem?: (item: any) => void
  hideToggle?: boolean
}) => (
  <div className="row mb-3">
    <div className={`col-12 ${hideToggle && !addItem ? '' : 'col-xl-7'}`}>
      {heading && <h4 className="text-left">{heading}</h4>}
      {helpText && <p className="text-left text-muted mb-0">{helpText}</p>}
    </div>
    <div
      className={`col-12 col-xl-5 w-100 ${
        hideToggle && !addItem ? 'd-none' : 'd-flex'
      } justify-content-end mt-2 mt-xl-0`}
    >
      <div>
        {!hideToggle && (
          <button
            onClick={() => setPreview(!preview)}
            className="btn btn-sm btn-outline-primary mx-2"
          >
            {preview ? <Edit size="20px" /> : <Eye size="20px" />}
          </button>
        )}
        {addItem && (
          <button className="btn btn-sm btn-primary" onClick={addItem}>
            Add New {itemName}
          </button>
        )}
      </div>
    </div>
  </div>
)
