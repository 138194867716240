import React from 'react'
import LoadingBoxes from '../../../../entries/LoadingBoxes'
import { useViewportData } from '../../../hooks'
import { useAnalyticsDashboard } from '../../../contexts/hooks'
import CarStatsContent from './CarStatsContent'
import { ModuleProps } from '../../../types'

const CarStats: React.FC<ModuleProps> = ({ timeRange, item, selectedLocation }) => {
  const { dealership } = useAnalyticsDashboard()
  const { data, loading, viewportRef } = useViewportData(item?.module, timeRange, selectedLocation)

  return (
    <div ref={viewportRef}>
      {!loading ? (
        <CarStatsContent data={data} dealership={dealership} timeframe={'last 7 days'} />
      ) : (
        <LoadingBoxes />
      )}
    </div>
  )
}

export default CarStats
