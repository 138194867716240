import type { API, BlockAPI, BlockTune } from '@editorjs/editorjs'
import type { ClassnameTuneProps } from '../types'

export default class ClassnameTune implements BlockTune {
  public static isTune = true

  private api: API
  private block: BlockAPI
  private config: {
    save: () => void
  }
  private data: ClassnameTuneProps

  constructor({ api, data, config, block }) {
    this.api = api
    this.block = block
    this.config = config
    this.data = data || { className: '' }
  }

  render() {
    const wrapper = document.createElement('div')
    wrapper.classList.add('form-group')
    wrapper.classList.add('mb-1')

    const input = document.createElement('input')
    input.setAttribute('id', 'code-input')
    input.setAttribute('placeholder', `Classname...`)
    input.defaultValue = this.data.className
    input.className = 'form-control form-control-sm'

    let previousValue = this.data.className ?? '' // Initialize previousValue

    input.addEventListener('keyup', (e) => {
      const target = e.target as HTMLInputElement
      this.data.className = target.value
      const currentBlockIndex = this.api.blocks.getCurrentBlockIndex()
      const currentBlockHolder = this.api.blocks.getBlockByIndex(currentBlockIndex).holder

      // Remove the previous value as a class
      if (previousValue) {
        const previousClasses = previousValue.split(' ')
        previousClasses.forEach((className) => {
          currentBlockHolder.classList.remove(className)
        })
      }

      // Split the new value into individual class names and add them
      const newValue = target.value.trim()
      if (newValue) {
        const newClasses = newValue.split(' ')
        newClasses.forEach((className) => {
          if (className.trim() !== '') {
            currentBlockHolder.classList.add(className)
          }
        })
      }

      // Update the previousValue
      previousValue = newValue

      this.config.save()
      // Force trigger Block's onChange event
      this.block.dispatchChange()
    })

    wrapper.appendChild(input)

    return wrapper
  }

  save() {
    return this.data
  }
}
