import { generateRandomId, toSentence } from '../../common/commonUtils'

import { type WebsiteDetailsProps } from '../../types'

export function handleNewOrUsedCarFAQ(websiteDetails: WebsiteDetailsProps) {
  return websiteDetails?.stock?.makes?.length > 0
    ? [
        {
          id: generateRandomId(10),
          type: 'header',
          data: {
            text: 'Can I buy a New or Used Car for Car Finance?',
            level: 2,
          },
        },
        {
          id: generateRandomId(10),
          type: 'paragraph',
          data: {
            text: 'Yes absolutely! You can choose from our huge range of new or used cars!',
          },
        },
        {
          id: generateRandomId(10),
          type: 'paragraph',
          data: {
            text: `We have a huge range including ${toSentence(websiteDetails?.stock?.makes)}.`,
          },
        },
      ]
    : [
        {
          id: generateRandomId(10),
          type: 'header',
          data: {
            text: 'Can I buy a New or Used Car for Car Finance?',
            level: 2,
          },
        },
        {
          id: generateRandomId(10),
          type: 'paragraph',
          data: {
            text: 'Yes absolutely! You can choose from our huge range of new or used cars!',
          },
        },
      ]
}
