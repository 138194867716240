import { useState } from 'react'
import { useSearchBox } from 'react-instantsearch'

function CustomSearchBox() {
  const { query, refine, clear } = useSearchBox()
  const [exactMatch, setExactMatch] = useState(false)

  const handleInputChange = (e) => {
    const value = e.target.value
    refine(exactMatch ? `"${value}"` : value)
  }

  const handleExactMatchChange = (e) => {
    const isChecked = e.target.checked
    setExactMatch(isChecked)

    // Reapply the current query with or without quotes
    if (query) {
      refine(isChecked ? `"${query}"` : query.replace(/"/g, ''))
    }
  }

  return (
    <div className="ais-SearchBox">
      <form action="" className="ais-SearchBox-form" noValidate role="search">
        <input
          type="text"
          className="ais-SearchBox-input"
          value={exactMatch ? query.replace(/"/g, '') : query}
          onChange={handleInputChange}
          placeholder="Stock number, Make, model..."
        />
        <button className="ais-SearchBox-submit" onClick={clear}>
          Clear
        </button>
        <button
          className="ais-SearchBox-reset"
          type="reset"
          title="Clear the search query"
          hidden={!query}
          onClick={clear}
        >
          <svg
            className="ais-SearchBox-resetIcon"
            viewBox="0 0 20 20"
            width="10"
            height="10"
            aria-hidden="true"
          >
            <path d="M8.114 10L.944 2.83 0 1.885 1.886 0l.943.943L10 8.113l7.17-7.17.944-.943L20 1.886l-.943.943-7.17 7.17 7.17 7.17.943.944L18.114 20l-.943-.943-7.17-7.17-7.17 7.17-.944.943L0 18.114l.943-.943L8.113 10z"></path>
          </svg>
        </button>
      </form>
      {query && (
        <div className="mt-1">
          <label className="small">
            <input
              type="checkbox"
              className="mr-1"
              checked={exactMatch}
              onChange={handleExactMatchChange}
            />
            Exact Match Only
          </label>
        </div>
      )}
    </div>
  )
}

export default CustomSearchBox
