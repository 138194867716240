import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { DataTable } from 'primereact/datatable'
import { SplitButton } from 'primereact/splitbutton'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'

import { useFetchDealership } from '../dataHooks'
import { PhoneNumbersService } from './PhoneNumbersService'

function PhoneNumbers() {
  const [phoneNumbers, setPhoneNumbers] = useState([])
  const [globalFilter, setGlobalFilter] = useState(null)
  const [loading, setLoading] = useState(true)
  const { dealership } = useFetchDealership()
  let { dealershipSlug } = useParams()

  useEffect(() => {
    if (dealership) {
      const csrf = document.querySelector("meta[name='csrf-token']").getAttribute('content')
      PhoneNumbersService.getPhoneNumbers({}, csrf, dealershipSlug).then((data) => {
        setPhoneNumbers(data.data.phone_numbers)
        setLoading(false)
      })
    }
  }, [dealership])

  const actionBodyTemplate = (phoneNumber) => {
    const items = [
      {
        label: 'Delete',
        icon: 'fa fa-trash',
        command: () => {
          const userConfirmation = window.confirm('Are you sure?')

          if (!userConfirmation) {
            return
          }
          fetch(phoneNumber.delete_url, {
            method: 'DELETE',
            headers: {
              'X-CSRF-Token': document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute('content'),
            },
          }).then(() => {
            window.location.reload()
          })
        },
      },
    ]

    return (
      <div style={{ position: 'relative' }}>
        <SplitButton
          label="Edit"
          buttonProps={{ id: 'show-button' }}
          onClick={() => {
            window.location.href = phoneNumber.edit_url
          }}
          model={items}
          menuButtonProps={{ id: 'action-button' }}
          outlined
          rounded
        />
      </div>
    )
  }

  const header = (
    <div className="table-header">
      <h2>Phone Numbers</h2>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span>
    </div>
  )

  return (
    <div className="p-3">
      <div className="d-flex mb-2">
        <div className="ml-auto">
          <a
            className="btn btn-outline-primary"
            href={Routes.new_dealership_phone_number_path(dealershipSlug)}
          >
            New Phone Number
          </a>
        </div>
      </div>
      <div className="box">
        <DataTable
          value={phoneNumbers}
          paginator
          rows={10}
          dataKey="id"
          loading={loading}
          globalFilter={globalFilter}
          header={header}
          emptyMessage="No phone numbers found."
          className="p-datatable-striped"
        >
          <Column field="number" header="Number" sortable />
          <Column field="redirect_to" header="Redirect To" sortable />
          <Column field="location_name" header="Location" sortable />
          <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }} />
        </DataTable>
      </div>
    </div>
  )
}

export default PhoneNumbers
