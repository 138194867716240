import React from 'react'
import * as Routes from '../../../../../routes'
import { Car } from '../../../types'

interface CarStatsContentProps {
  data: any
  dealership: any
  timeframe: string
}

const CarStatsContent: React.FC<CarStatsContentProps> = ({ data, dealership, timeframe }) => {
  const {
    count,
    no_photos_count,
    need_description,
    last_7_days,
    removed_or_sold,
    average_price,
    average_age,
    popular_cars,
  } = data || {}

  const carText = (count: number) => {
    return count === 1 ? 'car' : 'cars'
  }

  return (
    <div style={{ overflowY: 'auto' }}>
      <ul>
        <li>{count} cars in stock</li>
        {count > 0 && (
          <li className="text-danger">
            <a
              href={Routes.dealership_cars_path(dealership?.slug, {
                toggle: { needs_photos: true },
              })}
            >
              {no_photos_count} cars DO NOT have any photos
            </a>
          </li>
        )}
        {count > 0 && (
          <li className="text-danger">
            <a
              href={Routes.dealership_cars_path(dealership.slug, {
                toggle: { needs_description: true },
              })}
            >
              {need_description} {carText(need_description)} DO NOT have Dealer Comments
            </a>
          </li>
        )}
        <li>
          {last_7_days} {carText(last_7_days)} added in last 7 days
        </li>
        <li>
          {removed_or_sold} {carText(removed_or_sold)} removed/sold in last 7 days
        </li>
        {average_price > 0 && <li>{'$' + average_price.toLocaleString()} average car price</li>}
        <li>{average_age} average age of inventory in stock</li>
      </ul>
      <div className="d-flex justify-content-between align-items-center">
        <h5>Most popular cars by views</h5>
        <div className="float-right">
          <small className="text-secondary">{timeframe}</small>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>Car</th>
            <th>Stocknum</th>
            <th>Views</th>
          </tr>
        </thead>
        <tbody>
          {popular_cars?.map((car: Car, index: number) => (
            <tr key={`popular_${car.make}_${car.model}_${index} `}>
              <td>
                {car.make} {car.model}
              </td>
              <td>{car.stocknum}</td>
              <td>{car.views}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default CarStatsContent
