import { Toast } from 'primereact/toast'
// MANAGED BY App.js
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import { CarLoanService } from '../carLoans/carLoanService'
import CarLoansBox from '../carLoans/components/CarLoansBox'
import { CarContext, CurrentUserContext } from '../contexts'
import { capitalise } from '../entries/utils'
import { csrfToken } from '../entries/utils'
import Loading from '../Loading'
import showToast from '../shared/ShowToast'
import TestDrivesBox from '../testDrives/components/TestDrivesBox'
import { TestDriveService } from '../testDrives/testDriveService'
import ApiLogs from './Apilogs'
import CarPhotos from './CarPhotos'
import CarSettings from './CarSettings'
import CarSpecs from './CarSpecs'
import CarTabs from './CarTabs'
import LeadBox from './LeadBox'
import ThirdPartyData from './ThirdPartyData'
import ThirdPartyRestriction from './ThirdPartyRestriction'
import PhotoExports from './PhotoExports'
import { CopyableText } from '../shared/CopyableText'
import { humanize } from '../entries/utils'

import 'yet-another-react-lightbox/styles.css'

const CarWrapper = ({ defaultCarId, setVisible }) => {
  const { carId: paramCarId } = useParams()
  const carId = defaultCarId ?? paramCarId
  let [car, setCar] = useState(false)
  let [dealership, setDealership] = useState(false)

  useEffect(() => {
    fetch(`/cars/${carId}/show_react.json`)
      .then((res) => res.json())
      .then((data) => {
        setCar(data.car)
        setDealership(data.dealership)
      })
  }, [])

  if (!car || !dealership)
    return (
      <div className="p-3">
        <Loading />
      </div>
    )

  return <App car_data={car} dealership={dealership} setVisible={setVisible} />
}

const App = ({ car_data, dealership, setVisible }) => {
  const currentUser = useContext(CurrentUserContext)
  const notification = useRef(null)
  let [loading, setLoading] = useState(false)
  const [car, setCar] = useState(car_data)
  const [testDrives, setTestDrives] = useState(null)
  const [carLoans, setCarLoans] = useState(null)

  const loadTestDrives = async () => {
    const params = { car_id: car.id, page: 1, rows: 50 }

    TestDriveService.getTestDrives({ params, csrfToken, dealershipId: car.dealership_id }).then(
      (data) => {
        setTestDrives(data.data.test_drives)
      }
    )
  }

  const loadCarLoans = async () => {
    const params = { car_id: car.id, page: 1, rows: 50 }

    CarLoanService.getCarLoans({ params, csrfToken, dealershipId: car.dealership_id }).then(
      (data) => {
        setCarLoans(data.data.car_loans)
      }
    )
  }

  useEffect(() => {
    loadTestDrives()
    loadCarLoans()
  }, [car])
  let [carSettingsVisible, setCarSettingsVisible] = useState(false)

  const handleThirdPartyRestriction = async () => {
    let ignore_manufacturer_restriction = !car.ignore_manufacturer_restriction
    setLoading(true)
    let response = await fetch(Routes.car_path(car.slug), {
      method: 'PATCH',
      body: JSON.stringify({
        ignore_manufacturer_restriction: ignore_manufacturer_restriction,
      }),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    let data = await response.json()
    setLoading(false)
    if (data) {
      setCar({ ...car, ignore_manufacturer_restriction: ignore_manufacturer_restriction })
      showToast(
        notification,
        'success',
        'Third party restriction updated!',
        `Third party restriction was successfully ${
          ignore_manufacturer_restriction ? 'ignored' : 'applied'
        }.`
      )
    } else {
      showToast(
        notification,
        'error',
        'Third party restriction not updated!',
        'Third party restriction was not updated.'
      )
    }
  }

  const Banner = ({ car }) => {
    let data = {
      status: { value: humanize(car.status), copyable: false },
      car_type: { value: car.car_type, copyable: false },
      simple_colour: { value: car.simple_colour, copyable: false },
      stocknum: { value: car.stocknum, copyable: true },
      regplate: { value: car.regplate, copyable: true },
      location: { value: car.location?.name, copyable: false },
    }

    const string = Object.values(data)
      .filter((d) => d.value?.length > 0)
      .map((d, index) => (
        <React.Fragment key={index}>
          {index > 0 && ' | '}
          {d.copyable ? <CopyableText text={d.value} /> : d.value}
        </React.Fragment>
      ))

    return (
      <div className="banner text-center py-5 bg-light">
        <h1 className="h2 m-0">{car.carsales_title || car.name}</h1>
        <h4 className="text-secondary m-0">{string}</h4>
        {car.tag && <div className="badge badge-danger">{car.tag}</div>}
      </div>
    )
  }

  const carDetails = {
    apilogs: car.apilogs,
    dealership_slug: car.dealership_slug,
    slug: car.slug,
    name: car.name,
    stocknum: car.stocknum,
  }

  return (
    <CarContext.Provider value={{ car, setCar }}>
      <CarSettings
        car={car}
        visible={carSettingsVisible}
        setVisible={setCarSettingsVisible}
        setCar={setCar}
      >
        <></>
      </CarSettings>
      <Toast ref={notification} />
      <Banner car={car} />
      <br />
      <div className="px-3 pb-5">
        <div className="d-md-flex">
          {setVisible === undefined ? (
            <Link
              to={`/dealerships/${car.dealership_slug}/cars`}
              className="btn btn-outline-secondary btn-block-md-down mr-auto mb-2"
              onClick={() => setVisible(false)}
            >
              Back
            </Link>
          ) : (
            <div
              className="btn btn-outline-secondary btn-block-md-down mr-auto mb-2"
              onClick={() => {
                setVisible(false)
                window.history.pushState({}, '', `/dealerships/${car.dealership_slug}/cars`)
              }}
            >
              Back
            </div>
          )}
          {car.can_edit && currentUser?.admin && (
            <a
              href={Routes.new_stock_image_set_path()}
              className="btn btn-outline-primary btn-block-md-down mr-md-2 ml-md-2 mb-2"
            >
              Add Stock images
            </a>
          )}
          {car.can_edit && (
            <>
              <a
                href={Routes.edit_car_path(car.slug)}
                className="btn btn-outline-primary btn-block-md-down mr-md-2 ml-md-2 mb-2"
              >
                <i className="fa fa-edit mr-1"></i>
                Edit
              </a>
              <a
                href={Routes.new_image_car_path(car.slug)}
                className="btn btn-outline-primary btn-block-md-down mr-md-2 mb-2"
              >
                <i className="fa fa-camera mr-1"></i>
                Add Photos
              </a>
              <a
                href={Routes.add_video_car_path(car.slug)}
                className="d-none d-md-block btn btn-outline-primary btn-block-md-down mr-md-2 mb-2"
              >
                <i className="fa fa-video mr-1"></i>
                Add Video
              </a>
              <a
                href={Routes.print_car_path(car.slug)}
                className="d-none d-md-block btn btn-outline-primary btn-block-md-down mr-md-2 mb-2"
              >
                <i className="fa fa-print mr-1"></i>
                Print
              </a>
              <div className="dropdown">
                <button
                  className="btn btn-outline-primary dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                >
                  <i className="fa fa-ellipsis-h"></i>
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <a
                    href={`/dealerships/${car.dealership_slug}/test_drives?car_id=${car.id}`}
                    className="dropdown-item"
                  >
                    <i className="fa fa-steering-wheel mr-1"></i>
                    Test Drives ({testDrives
                      ? testDrives.length
                      : 0})
                  </a>
                  <a
                    href={`/dealerships/${car.dealership_slug}/car_loans?car_id=${car.id}`}
                    className="dropdown-item"
                  >
                    <i className="fa fa-car mr-1"></i>
                    Loans ({carLoans ? carLoans.length : 0})
                  </a>
                  <a href={Routes.car_file_uploads_path(car.slug)} className="dropdown-item">
                    <i className="fa fa-download mr-1"></i>
                    Incoming FTP Photos
                  </a>
                  <PhotoExports />
                  <Link to={Routes.apicalls_car_path(car.slug)} className="dropdown-item">
                    <i className="fa fa-rectangle-history mr-1"></i>
                    Api Logs
                  </Link>
                </div>
              </div>
            </>
          )}
        </div>
        <br />
        {car.stock_rules && car.stock_rules.length > 0 && (
          <div>
            <ThirdPartyRestriction
              ignore_manufacturer_restriction={car.ignore_manufacturer_restriction}
              handleThirdPartyRestriction={handleThirdPartyRestriction}
              manufacturer={car.manufacturer}
              stock_rules={car.stock_rules}
            />
          </div>
        )}
        <CarPhotos dealership={dealership} />
        <CarTabs car={car} setCarSettingsVisible={setCarSettingsVisible} />
        <CarSpecs capitalise={capitalise} />
        <div>
          {car.lead_clusters && car.lead_clusters.length > 0 && (
            <LeadBox leadClusters={car.lead_clusters} dealership={dealership} />
          )}
        </div>
        <div>
          <TestDrivesBox testDrives={testDrives} ownerType="car" />
        </div>
        <div>
          <CarLoansBox carLoans={carLoans} ownerType="car" ownerId={car.id} />
        </div>
        <div>
          {car.third_party_integrations && car.third_party_integrations.length > 0 && (
            <ThirdPartyData third_party_data={car.third_party_integrations} />
          )}
        </div>
        <br />
        {car.apilogs && car.apilogs.length > 0 && (
          <ApiLogs dealership={dealership} car={carDetails} />
        )}
      </div>
    </CarContext.Provider>
  )
}

export default CarWrapper
