import { createRoot } from 'react-dom/client'
import {
  manufacturerTemplate,
  modelTemplate,
  serviceTemplate,
  partsTemplate,
  aboutTemplate,
  careersTemplate,
  contactTemplate,
  fleetTemplate,
  financeTemplate,
  tradeInTemplate,
  privacyPolicyTemplate,
  termsAndConditionsTemplate,
  searchTemplate,
} from './index'

import { generateRandomId, htmlDecode, toSentence } from '../common/commonUtils'

import type {
  BrandsDataProps,
  LocationProps,
  WebsiteDetailsProps,
  TemplateDataProps,
  BrandsAddressProps,
  AddressProps,
} from '../types'

const PAGE_TEMPLATES = {
  'trade-in': tradeInTemplate,
  finance: financeTemplate,
  service: serviceTemplate,
  'vehicle-parts-and-accessories': partsTemplate,
  contact: contactTemplate,
  'about-us': aboutTemplate,
  careers: careersTemplate,
  fleet: fleetTemplate,
  'privacy-policy': privacyPolicyTemplate,
  'terms-and-conditions': termsAndConditionsTemplate,
  search: searchTemplate,
}

/**
 * Asynchronously fetches data from a specified table and ID, with optional query parameters.
 *
 * @param table - The table name for the data.
 * @param id - The ID of the data to be fetched.
 * @param params - Optional query parameters to append to the URL.
 * @returns A promise resolving to the fetched data in JSON format.
 */
async function fetchData(table: string, id: string, params?: string) {
  const data = await fetch(`/${table}${id ? `/${id}` : ''}.json${params ?? ''}`)

  return await data.json()
}

/**
 * Asynchronously retrieves a template based on provided data.
 *
 * @param templateData - Object containing the website id, template type and manufacturer & model ids.
 * @param brands - Array of brand data.
 * @returns - A promise resolving to the template or an error message.
 */
async function getTemplate(templateData: TemplateDataProps, brands: BrandsDataProps[]) {
  let template = undefined

  try {
    if (templateData.type === 'model') {
      template = await fetchData('models', templateData.model).then((data) => {
        const websiteData = brands.filter((brand) => brand.make === data.manufacturer_name)[0]
        return modelTemplate(websiteData, data)
      })
    } else if (templateData.type === 'manufacturer') {
      template = await fetchData('manufacturers', templateData.manufacturer).then((data) => {
        const websiteData = brands.filter((brand) => brand.make === data.name)[0]
        return manufacturerTemplate(websiteData, data)
      })
    } else if (templateData.website_id && templateData.type !== 'default') {
      template = await fetchData('websites', templateData.website_id).then((data) => {
        // Check if the template type is available
        const templateComponent = PAGE_TEMPLATES[templateData.type]
        // If the template type is not available, return an error message template
        if (!templateComponent) {
          return getErrorMessage()
        }
        // Return the template component with the fetched data
        return templateComponent(data)
      })
    }
  } catch (error) {
    console.error('Error fetching template:', error)
    // In case of an error, return an error message template
    template = getErrorMessage()
  }

  return template
}

/**
 * Generates an error message template with a paragraph element.
 *
 * @returns An array containing a template for displaying an error message.
 */
function getErrorMessage() {
  return [
    {
      id: generateRandomId(10),
      type: 'paragraph',
      data: {
        text: 'Sorry, there was a problem generating this template. Please try again or contact support.',
      },
      tunes: {
        alignment: { alignment: 'left' },
        container: { contain: false },
        backgroundColor: { backgroundColor: 'none', customBackgroundColor: '#000000' },
        textColor: { textColor: 'none', customTextColor: '#000000' },
        margin: { spacingTop: 0, spacingBottom: 0 },
        padding: { paddingTop: 0, paddingBottom: 0 },
        classname: { className: '' },
      },
    },
  ]
}

/**
 * Processes CarsViewer filters by replacing encoded characters
 * and decoding HTML entities. Returns the updated filters string.
 *
 * @returns An updated CarsViewer filters string.
 */
function getCarsViewerFilters() {
  // Handle CarsViewer Filters
  let filters = ''

  // @ts-ignore
  if (window.search_filters) {
    // @ts-ignore
    filters = search_filters.replaceAll('&#39;', '"')
    filters = htmlDecode(filters)
  }

  return filters
}

/**
 * Finds the primary location from the website details. If no primary location is set,
 * it returns the first location. Assumes `websiteDetails.locations` is an array of location objects
 * and that a primary location is marked by `location.primary === true`.
 *
 * @param websiteDetails - Object containing website details, including locations.
 * @returns The primary location object or the first location object if no primary is set.
 */
function getPrimaryLocation(websiteDetails: WebsiteDetailsProps) {
  return (
    websiteDetails?.locations?.find((location) => location.primary === true) ??
    websiteDetails?.locations[0]
  )
}

/**
 * Checks if a location array contains a location with a specific type.
 *
 * @param locations - Array of location objects.
 * @param type - The location type to check for.
 * @returns True if the location type is found, false otherwise.
 */
function hasLocationType(locations: LocationProps[], type: string) {
  return locations.some((location) => location.location_type === type)
}

/**
 * Gets the location type from an array of ordered types and a location array.
 *
 * @param orderedTypes - List of location types in order of priority.
 * @param locations - Array of location objects.
 * @returns The location type if found, otherwise 'primary'.
 */
function getLocationType(orderedTypes: string[], locations: LocationProps[]) {
  let type = undefined

  for (let i = 0; i < orderedTypes.length; i++) {
    const locationType = orderedTypes[i]
    if (hasLocationType(locations, locationType)) {
      type = locationType
      break // Exit the loop once the type has been found
    }
  }

  return type ?? 'primary'
}

/**
 * Formats an array of addresses, extracting unique cities and
 * the state from the first address. Returns an object with the results.
 *
 * @param addressArr - Array of addresses to be formatted.
 * @returns An object with uniqueCities and state properties.
 */
function getFormattedAddressData(addressArr: { address: BrandsAddressProps | AddressProps }[]) {
  return {
    uniqueCities: toSentence(addressArr?.map((l) => l.address?.city)),
    state: addressArr[0]?.address?.state,
  }
}

/**
 * Returns the margin value for the map based on the type of map being displayed.
 *
 * @param orderedTypes - Array of location types in order of priority.
 * @param locations - Array of location objects.
 * @returns An integer representing the margin value.
 */
function getMapMarginValue(orderedTypes: string[], locations: LocationProps[]) {
  let marginValue = undefined

  orderedTypes.map((locationType) => {
    if (hasLocationType(locations, locationType) && marginValue === undefined) {
      marginValue =
        locations?.filter((location) => location.location_type === locationType).length > 1 ? 5 : 0
    }
  })

  return marginValue ?? 0
}

// Store the container roots for the template refresh options
const roots = new Map<Element, ReturnType<typeof createRoot>>()

/**
 * Renders the component in the template refresh options container.
 * @param Component - The component to render in the template refresh options container.
 */
function renderTemplateRefreshOptions(Component: React.ReactElement) {
  const container = document.getElementById('template-refresh-children')
  if (container && Component) {
    let root = roots.get(container)
    if (!root) {
      root = createRoot(container)
      roots.set(container, root)
    }
    root.render(Component)
  }
}

export {
  fetchData,
  getTemplate,
  getFormattedAddressData,
  getCarsViewerFilters,
  getErrorMessage,
  getPrimaryLocation,
  hasLocationType,
  getLocationType,
  getMapMarginValue,
  renderTemplateRefreshOptions,
}
