// components/RecentLeads.tsx
import React from 'react'
import * as Routes from '../../../routes'

interface RecentLeadsProps {
  leadsBySource: {
    source: string
    leads: { created_at: string; name: string; email: string; id: string }[]
  }[]
  dealershipSlug: string
}

const RecentLeads: React.FC<RecentLeadsProps> = ({ leadsBySource, dealershipSlug }) => {
  return (
    <>
      {leadsBySource?.map((sourceData, index) => (
        <div className="box mb-3" key={`leads_by_source-${sourceData.source}`}>
          <div className="p-3">
            <h4>Recent Leads from {sourceData.source}</h4>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {sourceData.leads.map((lead, idx) => (
                <tr key={`lead-${sourceData.source}-${idx}`}>
                  <td>{lead.created_at}</td>
                  <td>
                    <a href={Routes.dealership_lead_path(dealershipSlug, lead.id)}>{lead.name}</a>
                  </td>
                  <td>{lead.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </>
  )
}

export default RecentLeads
