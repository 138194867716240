import { generateRandomId } from '../../common/commonUtils'

/**
 * Handles adding a manufacturer section to the template.
 *
 * @param manufacturer the website's manufacturer
 * @returns An array containing a template for the manufacturer section.
 */
export function handleManufacturerSection(manufacturer?: string) {
  const manufacturerSection = []

  if (manufacturer) {
    manufacturerSection.push({
      id: generateRandomId(10),
      type: 'paragraph',
      data: {
        text: `<b>Genuine ${manufacturer}</b> parts are designed to meet the highest level of quality expected not only by ${manufacturer}, but also by our customers.`,
      },
      tunes: {
        alignment: {
          alignment: 'center',
        },
        container: {
          contain: true,
        },
        backgroundColor: {
          backgroundColor: 'none',
          customBackgroundColor: '#000000',
        },
        textColor: {
          textColor: 'none',
          customTextColor: '#000000',
        },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
        },
        classname: {
          className: '',
        },
      },
    })
  }

  return manufacturerSection
}
