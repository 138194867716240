import { useCallback, useEffect, useRef, useState } from 'react'
import {
  DashboardLevel,
  TimeRange,
  Location,
  TimeRangeOverrideGroup,
  Dealership,
  Website,
  Manufacturer,
  ModuleItem,
  AnalyticsBlockConfig,
  DealershipGroup,
} from './types'
import { fetchModuleLayoutByTab, getModuleComponentFromName } from './utils'
import { fetchModuleData, saveConfig, setModuleLayoutsCall } from './api'
import { getYearlyTimeRange } from '../entries/TimeRange'
import { useAnalyticsDashboard, useConfig, useTabs } from './contexts/hooks'
import { facebookAdsModules, ga4Modules, overrideExcludedModules } from './constants'
import { moduleComponents } from './config'

export const useViewportData = (
  module: string,
  timeRange: TimeRange,
  selectedLocation: Location,
  additionalParams?: { [key: string]: any }
): {
  data: any
  loading: boolean
  viewportRef: React.RefObject<HTMLDivElement>
} => {
  const [data, setData] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [viewed, setViewed] = useState<boolean>(false)
  const viewportRef = useRef<HTMLDivElement>(null)
  const isInViewport = useOnScreen(viewportRef)
  const { config }: { config: { defaultTimeRangeOverride?: string[] | TimeRangeOverrideGroup[] } } =
    useConfig()
  const {
    dashboardLevel,
    dashboardLevelLoaded,
    dealership,
    dealershipGroup,
    manufacturer,
    website,
    editModeEnabled,
    layoutSaved,
  } = useAnalyticsDashboard()
  const { selectedTab } = useTabs()
  const moduleConfig = getModuleComponentFromName(module)

  function shouldOverride(group: TimeRangeOverrideGroup): boolean {
    if (overrideExcludedModules.includes(module)) return false
    return moduleConfig?.group === group && config?.defaultTimeRangeOverride?.includes(group)
  }

  let overrideToYearlyTimeRange = false
  switch (true) {
    case shouldOverride('Google Adwords Ads'):
    case shouldOverride('Google Display Ads'):
    case shouldOverride('Google VLA Ads'):
    case shouldOverride('Facebook Ads'):
    case shouldOverride('Google Ads'):
    case shouldOverride('Google Analytics'):
      overrideToYearlyTimeRange = true
      break
    default:
      break
  }

  const yearlyTimeRange: TimeRange | undefined = overrideToYearlyTimeRange
    ? getYearlyTimeRange(timeRange)
    : undefined

  const loadData = async (params: {
    module: string
    timeRange: TimeRange
    yearlyTimeRange?: TimeRange | undefined
    selectedLocation: Location
    dashboardLevel: DashboardLevel
    dashboardLevelLoaded: boolean
    selectedTab: number
    dealership?: Dealership
    dealershipGroup?: DealershipGroup
    manufacturer?: Manufacturer
    website?: Website
    signal?: AbortSignal
    additionalParams?: { [key: string]: any }
  }) => {
    setLoading(true)
    try {
      const newData = await fetchModuleData(params)
      if (newData) {
        setData(newData)
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error('Error fetching data:', error)
      }
    } finally {
      setLoading(false)
      setViewed(true)
    }
  }

  // Load the data when the element is in the viewport and hasn't been viewed yet.
  useEffect(() => {
    // const controller = new AbortController()
    // const signal = controller.signal

    if (isInViewport && !viewed && dashboardLevelLoaded && !loading) {
      loadData({
        module,
        timeRange,
        yearlyTimeRange,
        selectedLocation,
        dashboardLevel,
        dashboardLevelLoaded,
        selectedTab,
        dealership,
        dealershipGroup,
        manufacturer,
        website,
        additionalParams,
        // signal,
      })
    }

    // return () => controller.abort()
  }, [
    isInViewport,
    viewed,
    module,
    timeRange,
    selectedLocation,
    dashboardLevel,
    dashboardLevelLoaded,
    selectedTab,
    config?.defaultTimeRangeOverride,
    dealership,
    dealershipGroup,
    manufacturer,
    website,
    additionalParams,
  ])

  // Reload data when timeRange, selectedLocation or dashboardLevel changes,
  // regardless of whether the component has been viewed or not.
  useEffect(() => {
    if (viewed && dashboardLevelLoaded && !loading) {
      loadData({
        module,
        timeRange,
        yearlyTimeRange,
        selectedLocation,
        dashboardLevel,
        dashboardLevelLoaded,
        selectedTab,
        dealership,
        dealershipGroup,
        manufacturer,
        website,
        additionalParams,
      })
    }
  }, [
    module,
    timeRange,
    selectedLocation,
    dashboardLevel,
    selectedTab,
    config?.defaultTimeRangeOverride,
    editModeEnabled,
    JSON.stringify(additionalParams),
  ])

  return { data, loading, viewportRef }
}

export function useOnScreen(ref: React.RefObject<HTMLDivElement>): boolean {
  const [isIntersecting, setIntersecting] = useState<boolean>(false)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting))

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
      observer.disconnect()
    }
  }, [ref])

  return isIntersecting
}

export const useSaveConfig: () => () => Promise<void> = () => {
  const { config } = useConfig()
  const {
    dashboardLevel,
    dashboardLevelLoaded,
    dealership,
    dealershipGroup,
    manufacturer,
    website,
    analyticsBlock,
  } = useAnalyticsDashboard()

  return async () => {
    await saveConfig(
      config,
      dashboardLevel,
      dashboardLevelLoaded,
      analyticsBlock,
      dealership,
      dealershipGroup,
      manufacturer,
      website
    )
  }
}

export const useSetModuleLayouts: () => {
  setModuleLayouts: (
    layouts?: {
      [key: number]: ModuleItem[]
    },
    previousConfig?: AnalyticsBlockConfig
  ) => Promise<void>
  loading: boolean
} = () => {
  const { config } = useConfig()
  const {
    dashboardLevel,
    dashboardLevelLoaded,
    dealership,
    manufacturer,
    website,
    dealershipGroup,
    analyticsBlock,
  } = useAnalyticsDashboard()
  const { tabLayouts, tabTitles } = useTabs()
  const [loading, setLoading] = useState<boolean>(false)

  const setModuleLayouts = async (layouts, previousConfig) => {
    const layoutsToSave = layouts || tabLayouts
    const configToSave = previousConfig || config

    try {
      setLoading(true)
      await setModuleLayoutsCall(
        layoutsToSave,
        tabTitles,
        configToSave,
        dashboardLevel,
        dashboardLevelLoaded,
        analyticsBlock,
        dealership,
        dealershipGroup,
        manufacturer,
        website
      )
    } catch (error) {
      console.error('Error saving module layouts:', error)
    } finally {
      setLoading(false)
    }
  }

  return { setModuleLayouts, loading }
}

export const useNameBasedOnLevel = (): string | undefined => {
  const { dashboardLevel, dashboardLevelLoaded, dealership, website, dealershipGroup } =
    useAnalyticsDashboard()
  if (!dashboardLevelLoaded) {
    return undefined
  }
  if (dashboardLevel === 'Dealership') {
    return dealership?.name
  } else if (dashboardLevel === 'Website') {
    return website?.name
  } else if (dashboardLevel === 'Dealership Group') {
    return dealershipGroup?.name
  } else {
    return undefined
  }
}

export const useWindowWidth = (): number => {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return windowWidth
}

export const useFetchModuleLayoutByTab: () => (tabIndex: number) => ModuleItem[] = () => {
  const { analyticsBlock } = useAnalyticsDashboard()

  return useCallback(
    (tabIndex: number) => {
      const layouts = fetchModuleLayoutByTab(tabIndex, analyticsBlock)
      return layouts
    },
    [analyticsBlock]
  )
}
// Creates an array of ModuleItem's, if the visibleLevels preoperty includes the dashboardLevel and the module is not excluded
export const useModuleItemsBasedOnLevel = (): ModuleItem[] => {
  const { dashboardLevel } = useAnalyticsDashboard()
  const { hasFacebookAds, hasGoogleAds, hasGA4 } = useModuleConditionals()

  // Map exclusions based on conditions
  const exclusions = {
    facebook: !hasFacebookAds ? facebookAdsModules : [],
    google: !hasGoogleAds
      ? Object.keys(moduleComponents)?.filter(
          (key) => key.includes('google') && key !== 'google_analytics'
        )
      : [],
    ga4: !hasGA4 ? ga4Modules : [],
  }

  // Flatten and gather excluded modules
  const excludedModules: string[] = [
    ...exclusions.facebook,
    ...exclusions.google,
    ...exclusions.ga4,
  ]

  // Return filtered modules based on visibility and exclusions
  return Object.keys(moduleComponents)
    .filter((key) => !excludedModules.includes(key))
    .filter((key) => moduleComponents[key].visibleLevels.includes(dashboardLevel))
    .map((key) => ({ module: key }))
}

export const useModuleConditionals = (): {
  hasFacebookAds: boolean
  hasGoogleAds: boolean
  hasGA4: boolean
} => {
  const { dashboardLevel, dealership, website } = useAnalyticsDashboard()

  const hasFacebookAds =
    (dashboardLevel === 'Website' && website?.facebook_ad_campaigns) ||
    (dashboardLevel === 'Dealership' && dealership?.has_facebook_ad_accounts)

  const hasGoogleAds =
    (dashboardLevel === 'Website' && website?.google_ad_campaigns) ||
    (dashboardLevel === 'Dealership' && dealership?.has_google_ad_accounts)

  const hasGA4 =
    (dashboardLevel === 'Website' && website?.ga_profile) ||
    (dashboardLevel === 'Dealership' && dealership?.has_ga_profiles)

  return { hasFacebookAds, hasGoogleAds, hasGA4 }
}
