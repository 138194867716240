import { useState, useContext } from 'react'
import { Tooltip } from 'react-tooltip'
import { Switch } from '../entries/FormElements'
import { standardHeaders } from '../entries/utils'
import { CurrentUserContext } from '../contexts'
import { DealershipContext } from '../contexts'

const useCanOverwrite = () => {
  const currentUser = useContext(CurrentUserContext)
  const { dealership } = useContext(DealershipContext)

  const canOverwrite =
    currentUser?.admin || currentUser?.dealerships[dealership?.id]?.role === 'manager'

  return canOverwrite
}

export const OverwriteSwitch = ({ hit, setCar }) => {
  let [ignore, setIgnore] = useState(hit.ignore_manufacturer_restriction)
  let [loading, setLoading] = useState(false)

  const onChange = () => {
    setLoading(true)
    let newValue = !ignore
    setIgnore(newValue)
    fetch(`/cars/${hit.id}`, {
      method: 'PATCH',
      headers: standardHeaders,
      body: JSON.stringify({
        car: {
          ignore_manufacturer_restriction: newValue,
        },
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setCar(res)
        setLoading(false)
      })
  }

  let canOverwrite = useCanOverwrite()

  return (
    <>
      <Switch
        label="Ignore manufacturer restriction"
        value={ignore}
        onChange={onChange}
        isDisabled={loading || !canOverwrite}
        id={`ignore_manufacturer_restriction-switch-${hit.id}`}
      />
      {!canOverwrite && (
        <>
          <i className="fa fa-exclamation-triangle text-danger" id="no-access-tooltip" />
          <Tooltip anchorSelect="#no-access-tooltip" place="bottom" style={{ zIndex: 10000 }}>
            You do not have permission to change this
          </Tooltip>
        </>
      )}
    </>
  )
}

export const StockRule = ({ rule }) => (
  <div className="border-bottom p-3">
    <p>
      <b>Action:</b> {rule.action}
    </p>
    <div
      dangerouslySetInnerHTML={{
        __html: rule.description,
      }}
    />
  </div>
)

const ThirdPartyRestriction = ({
  ignore_manufacturer_restriction,
  handleThirdPartyRestriction,
  manufacturer,
  stock_rules,
}) => {
  const changeRestriction = (e) => {
    e.preventDefault()
    if (!ignore_manufacturer_restriction) {
      if (window.confirm('Are you sure you want to ignore the OEM rules?')) {
        handleThirdPartyRestriction()
      }
    } else {
      handleThirdPartyRestriction()
    }
  }

  let canOverwrite = useCanOverwrite()

  return (
    <div className="box mt-3 mb-3">
      <div className="d-flex border-bottom p-3 align-items-center">
        <i className="fa fa-exclamation-triangle text-danger mr-1"></i>&nbsp;
        <span className="mb-0">
          {ignore_manufacturer_restriction ? (
            <>
              Stock restrictions apply however are <b>currently being ignored</b>
            </>
          ) : (
            `${manufacturer} OEM restrictions are in place`
          )}
        </span>
        <div className="ml-auto">
          <a
            href="#"
            onClick={changeRestriction}
            disabled={!canOverwrite}
            className={`btn btn-sm ${
              ignore_manufacturer_restriction ? 'btn-outline-success' : 'btn-outline-danger'
            } ${!canOverwrite ? 'disabled' : ''}`}
          >
            {ignore_manufacturer_restriction ? 'Follow Restrictions' : 'Ignore Restrictions'}
          </a>
        </div>
      </div>
      <div>
        {stock_rules.map((rule, index) => (
          <StockRule rule={rule} key={`stock-rule-${index}`} />
        ))}
      </div>
    </div>
  )
}

export default ThirdPartyRestriction
