import { useState } from 'react'
import { Sidebar } from 'primereact/sidebar'
import LeadShow from './Show'

const sidebarStyle = () => ({
  height: '100%',
  width: '100%',
  maxHeight: '100vh',
  maxWidth: '1000px',
  borderRadius: 0,
  backgroundColor: '#fff',
})

let SlideIn = ({ leadCluster, buttonComponent }) => {
  let [visible, setVisible] = useState(false)

  return (
    <>
      {buttonComponent({ setVisible })}
      <Sidebar
        visible={visible}
        onHide={() => setVisible(false)}
        blockScroll={true}
        dismissable={true}
        position="right"
        style={{ ...sidebarStyle() }}
        header={leadCluster.name || 'Lead Cluster'}
      >
        <LeadShow
          defaultLeadClusterId={leadCluster.id}
          sidebar={false}
          dealershipSlug={leadCluster.dealership_id}
        />
      </Sidebar>
    </>
  )
}

export default SlideIn
