import { useState, useEffect, useContext } from 'react'
import { Dialog } from 'primereact/dialog'
import { useFetchDealership } from '../../dataHooks'
import { LeadClusterContext } from '../../contexts'
import showToast from '../../shared/ShowToast'

const EmailTemplates = ({ setEmailTemplate, setSubject }) => {
  const { leadCluster, notification } = useContext(LeadClusterContext)
  const { dealership } = useFetchDealership()
  let [emailTemplates, setEmailTemplates] = useState([])
  let [visible, setVisible] = useState(false)

  useEffect(() => {
    if (dealership && leadCluster) {
      fetchEmailTemplates()
    }
  }, [visible])

  const fetchEmailTemplates = async () => {
    try {
      const URL = Routes.dealership_email_templates_path({
        dealership_id: dealership.id,
      })
      const response = await fetch(`${URL}.json?lead_cluster_id=${leadCluster.id}`)
      const data = await response.json()
      setEmailTemplates(data)
    } catch (error) {
      console.error('Error fetching dealership email templates:', error)
      showToast(notification, 'error', 'Error fetching dealership email templates.', '')
    }
  }

  return (
    <>
      <button onClick={() => setVisible(true)} className="btn btn-outline-primary btn-sm mt-2">
        Email Templates
      </button>
      <Dialog
        header="Email Templates"
        visible={visible}
        style={{ width: '50vw' }}
        onHide={() => setVisible(false)}
        dismissableMask
      >
        {emailTemplates.map((template) => (
          <div key={template.id} className="rounded border p-3 mb-2">
            <div className="row d-flex align-items-center">
              <div className="col-10 text-secondary">
                <h6>{template.subject}</h6>
                <div dangerouslySetInnerHTML={{ __html: template.processed_content }} />
              </div>
              <div className="col-2">
                <div
                  className="btn btn-outline-primary btn-sm btn-block"
                  onClick={() => {
                    setEmailTemplate(template.processed_content)
                    setSubject(template.subject)
                    setVisible(false)
                  }}
                >
                  Insert
                </div>
              </div>
            </div>
          </div>
        ))}
        <a
          href={`/dealerships/${dealership.id}/email_templates`}
          target="_blank"
          className="btn btn-outline-primary btn-sm mt-2"
        >
          Edit Email Templates
        </a>
      </Dialog>
    </>
  )
}

export default EmailTemplates
