import {
  AlternatingColumnsBlock,
  ButtonsBlock,
  CarsBlock,
  ColumnsBlock,
  FaqBlock,
  FormBlock,
  FormBuilderBlock,
  HTMLBlocks,
} from './blocks'
import type { BlockProps, SelectOption } from '../../types'

type ResolveBlockProps = {
  isInRow?: boolean
  enableTextContainer?: boolean
  locationOptions?: SelectOption[]
  isAlternatingColumns?: boolean
}

export const ResolveBlock = ({
  block,
  isInRow = false,
  enableTextContainer = true,
  locationOptions,
  isAlternatingColumns,
}: {
  block: BlockProps
} & ResolveBlockProps) => {
  switch (block.type) {
    case 'alternatingColumns':
      return <AlternatingColumnsBlock block={block.data} />
    case 'buttonsTool':
      return <ButtonsBlock block={block} isInRow={isInRow} />
    case 'cars':
      return <CarsBlock block={block} />
    case 'columns':
      return <ColumnsBlock block={block.data} locationOptions={locationOptions} />
    case 'faq':
      return <FaqBlock state={block.data} />
    case 'form':
      return <FormBlock block={block} />
    case 'formBuilder':
      return <FormBuilderBlock block={block} locationOptions={locationOptions} />
    default:
      const Element = HTMLBlocks[block.type]
      if (!Element) {
        return null
      }
      return (
        <Element
          block={block}
          isInRow={isInRow || !enableTextContainer}
          isAlternatingColumns={isAlternatingColumns}
        />
      )
  }
}

/** Render an array of Editor.js blocks */
export const BlockRenderer = ({
  blocks,
  ...props
}: {
  blocks: BlockProps[]
} & ResolveBlockProps) => {
  // Leave this console.log, it's useful for checking blocks
  //console.log(blocks)
  return blocks.map((block, index) => (
    <ResolveBlock key={index} block={{ ...block, index }} {...props} />
  ))
}

const EditorJsRenderer = ({
  data,
  ...props
}: {
  data: { blocks: BlockProps[] }
} & ResolveBlockProps) => {
  // Handle unpublished pages (i.e. no data available)
  if (!data?.blocks) {
    return null
  }

  return <BlockRenderer blocks={data.blocks} {...props} />
}

export default EditorJsRenderer
