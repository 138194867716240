import SideMenu from './SideMenu'
import SettingsMenu from './SettingsMenu'
import { useParams } from 'react-router-dom'

const Layout = ({ settings = false, children }) => {
  let { dealershipSlug, websiteSlug } = useParams()

  return (
    <div className="sidemenu-wrapper w-100">
      <div className="d-flex">
        {(dealershipSlug || websiteSlug) && <SideMenu />}
        {settings ? (
          <div className="main w-100">
            <SettingsMenu children={children} />
          </div>
        ) : (
          <div className="main w-100">{children}</div>
        )}
      </div>
    </div>
  )
}

export default Layout
