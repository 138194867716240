import { generateRandomId } from '../../common/commonUtils'
import { getPrimaryLocation, getErrorMessage } from '../templateUtils'
import { handleForm } from './tradeInUtils'

import { type WebsiteDetailsProps } from '../../types'

export function tradeInTemplate(websiteDetails: WebsiteDetailsProps) {
  // Error Handling
  if (websiteDetails === undefined) {
    return getErrorMessage()
  }

  const primaryLocation = getPrimaryLocation(websiteDetails)

  // * Autograb cannot be configured with FormBuilder for now
  const tradeInForm = handleForm(websiteDetails)

  return [
    {
      id: generateRandomId(10),
      type: 'imageBanner',
      data: {
        image: {
          type: 'default',
          existingCategory: 'default',
          existingFilters: {},
          image: {
            url: 'https://res.cloudinary.com/total-dealer/image/upload/v1645834405/td_next/tradein_w4lnrv',
          },
        },
        layout: {
          type: 'default',
          imageLayout: 'cover',
          customAspectRatio: {
            height: 1,
            width: 4,
          },
          customHeight: {
            mobileHeight: 250,
            desktopHeight: 450,
          },
        },
        overlay: {
          active: 'false',
          color: '#000000',
          opacity: 50,
        },
        content: {
          active: 'false',
          blocks: [
            {
              id: generateRandomId(10),
              type: 'header',
              data: {
                text: 'Image Banner',
                level: 2,
              },
              tunes: {
                alignment: {
                  alignment: 'center',
                },
                container: {
                  contain: true,
                },
                textColor: {
                  textColor: 'light',
                  customTextColor: '#000000',
                },
                margin: {
                  spacingTop: 5,
                  spacingBottom: 5,
                },
                padding: {
                  paddingTop: 0,
                  paddingBottom: 0,
                },
                classname: {
                  className: '',
                },
              },
            },
          ],
        },
      },
      tunes: {
        margin: {
          spacingTop: 0,
          spacingBottom: 5,
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'header',
      data: {
        text: 'Sell Your Car',
        level: 1,
      },
      tunes: {
        alignment: {
          alignment: 'center',
        },
        container: {
          contain: false,
        },
        backgroundColor: {
          backgroundColor: 'none',
          customBackgroundColor: '#000000',
        },
        textColor: {
          textColor: 'none',
          customTextColor: '#000000',
        },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'header',
      data: {
        text: `${primaryLocation?.address?.city} ${primaryLocation?.address?.state}`,
        level: 2,
      },
      tunes: {
        alignment: {
          alignment: 'center',
        },
        container: {
          contain: false,
        },
        backgroundColor: {
          backgroundColor: 'none',
          customBackgroundColor: '#000000',
        },
        textColor: {
          textColor: 'secondary',
          customTextColor: '#000000',
        },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'horizontalRule',
      data: {
        usePageTitle: true,
        height: 1,
        borderRadius: 5,
        opacity: 1,
        color: { colorType: 'primary', customColor: '000000' },
      },
      tunes: {
        container: { contain: false },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
          mobile: { active: false, spacingTop: 0, spacingBottom: 0 },
          tablet: { active: false, spacingTop: 0, spacingBottom: 0 },
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
          mobile: { active: false, paddingTop: 0, paddingBottom: 0 },
          tablet: { active: false, paddingTop: 0, paddingBottom: 0 },
        },
        classname: { className: '' },
      },
    },
    {
      id: generateRandomId(10),
      type: 'paragraph',
      data: {
        text: `If you are looking to sell your vehicle or upgrade to a new vehicle, ask the team about our vehicle trade-in service. At ${websiteDetails.name}, we pride ourselves on offering our customers a hassle-free solution to selling your vehicle in ${primaryLocation?.address?.city}.`,
      },
      tunes: {
        alignment: {
          alignment: 'center',
        },
        container: {
          contain: true,
        },
        backgroundColor: {
          backgroundColor: 'none',
          customBackgroundColor: '#000000',
        },
        textColor: {
          textColor: 'none',
          customTextColor: '#000000',
        },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'paragraph',
      data: {
        text: 'We can assess your vehicle and present you with a competitive offer in no time at all, which you can choose to receive or use towards your next purchase with us.',
      },
      tunes: {
        alignment: {
          alignment: 'center',
        },
        container: {
          contain: true,
        },
        backgroundColor: {
          backgroundColor: 'none',
          customBackgroundColor: '#000000',
        },
        textColor: {
          textColor: 'none',
          customTextColor: '#000000',
        },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'paragraph',
      data: {
        text: `To find out what your car is worth, fill out the enquiry form and a member of our team will be in touch. Alternatively, contact ${websiteDetails.name} to organise a vehicle valuation today.`,
      },
      tunes: {
        alignment: {
          alignment: 'center',
        },
        container: {
          contain: true,
        },
        backgroundColor: {
          backgroundColor: 'none',
          customBackgroundColor: '#000000',
        },
        textColor: {
          textColor: 'none',
          customTextColor: '#000000',
        },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'iconButtonsTool',
      data: {
        buttons: [
          {
            id: generateRandomId(10),
            icon: 'Service',
            text: 'No Obligations',
            href: '',
          },
          {
            id: generateRandomId(10),
            icon: 'Check',
            text: 'Free Appraisal',
            href: '',
          },
          {
            id: generateRandomId(10),
            icon: 'Finance',
            text: 'Competitive Offers',
            href: '',
          },
          {
            id: generateRandomId(10),
            icon: 'Sell Your Car',
            text: 'Sell Your Car Today',
            href: '',
          },
        ],
      },
      tunes: {
        alignment: {
          alignment: 'center',
        },
        container: {
          contain: false,
        },
        margin: {
          spacingTop: 3,
          spacingBottom: 3,
        },
        classname: {
          className: '',
        },
      },
    },
    tradeInForm,
    {
      id: generateRandomId(10),
      type: 'faq',
      data: {
        questions: [
          {
            blocks: [
              {
                id: generateRandomId(10),
                type: 'header',
                data: { text: 'What type of vehicles do you buy or trade in?', level: 2 },
                tunes: {
                  alignment: { alignment: 'left' },
                  container: { contain: false },
                  textColor: { textColor: 'none', customTextColor: '#000000' },
                  margin: {
                    spacingTop: 0,
                    spacingBottom: 0,
                    mobile: { active: false, spacingTop: 0, spacingBottom: 0 },
                    tablet: { active: false, spacingTop: 0, spacingBottom: 0 },
                  },
                  classname: { className: '' },
                },
              },
              {
                id: generateRandomId(10),
                type: 'paragraph',
                data: {
                  text: "We will buy or trade in all types of motor vehicles, including cars, vans and utes. There are some vehicles that we won't be able to give you an online estimated value for, but once you provide the details of your vehicle and we organise an inspection, we'll be able to give you a price. Generally, cars over 7 years old or 100,000 kilometres will not generate an online estimate.",
                },
                tunes: {
                  alignment: { alignment: 'left' },
                  container: { contain: false },
                  textColor: { textColor: 'none', customTextColor: '#000000' },
                  margin: {
                    spacingTop: 0,
                    spacingBottom: 0,
                    mobile: { active: false, spacingTop: 0, spacingBottom: 0 },
                    tablet: { active: false, spacingTop: 0, spacingBottom: 0 },
                  },
                  classname: { className: '' },
                },
              },
            ],
          },
          {
            blocks: [
              {
                id: generateRandomId(10),
                type: 'header',
                data: { text: 'How is the online estimate calculated?', level: 2 },
                tunes: {
                  alignment: { alignment: 'left' },
                  container: { contain: false },
                  textColor: { textColor: 'none', customTextColor: '#000000' },
                  margin: {
                    spacingTop: 0,
                    spacingBottom: 0,
                    mobile: { active: false, spacingTop: 0, spacingBottom: 0 },
                    tablet: { active: false, spacingTop: 0, spacingBottom: 0 },
                  },
                  classname: { className: '' },
                },
              },
              {
                id: generateRandomId(10),
                type: 'paragraph',
                data: {
                  text: 'The online estimated valuation is calculated by taking into account the following:',
                },
                tunes: {
                  alignment: { alignment: 'left' },
                  container: { contain: false },
                  textColor: { textColor: 'none', customTextColor: '#000000' },
                  margin: {
                    spacingTop: 0,
                    spacingBottom: 0,
                    mobile: { active: false, spacingTop: 0, spacingBottom: 0 },
                    tablet: { active: false, spacingTop: 0, spacingBottom: 0 },
                  },
                  classname: { className: '' },
                },
              },
              {
                id: generateRandomId(10),
                type: 'list',
                data: {
                  style: 'unordered',
                  items: [
                    'Current market pricing, based on data supplied by an third party independent vehicle valuation tool Autograb',
                    'The make, model and year of your car',
                    'The number of kilometers on the odometer',
                    'The service history of the car and log books are up to date and available',
                    'All the components of your car are working/ still with the car e.g. GPS, cargo blinds',
                    '2 sets of keys are included',
                    'There are no illegal modifications',
                    'The interior and exterior condition of your car is considered good given its age',
                  ],
                },
                tunes: {
                  alignment: { alignment: 'left' },
                  container: { contain: false },
                  textColor: { textColor: 'none', customTextColor: '#000000' },
                  margin: {
                    spacingTop: 0,
                    spacingBottom: 0,
                    mobile: { active: false, spacingTop: 0, spacingBottom: 0 },
                    tablet: { active: false, spacingTop: 0, spacingBottom: 0 },
                  },
                  classname: { className: '' },
                },
              },
            ],
          },
          {
            blocks: [
              {
                id: generateRandomId(10),
                type: 'header',
                data: { text: 'How do I know the exact price for my trade-in?', level: 2 },
                tunes: {
                  alignment: { alignment: 'left' },
                  container: { contain: false },
                  textColor: { textColor: 'none', customTextColor: '#000000' },
                  margin: {
                    spacingTop: 0,
                    spacingBottom: 0,
                    mobile: { active: false, spacingTop: 0, spacingBottom: 0 },
                    tablet: { active: false, spacingTop: 0, spacingBottom: 0 },
                  },
                  classname: { className: '' },
                },
              },
              {
                id: generateRandomId(10),
                type: 'paragraph',
                data: {
                  text: 'The price given online is an estimated valuation. This is an indicative price only, subject to inspection. After submitting your enquiry, one of our team will be in touch to book an inspection of your car. Only after inspection will an exact price be given. An offer will be made to sell your car or trade-in, if it is a vehicle we would like to buy. The final price may differ from the online estimated valuation given the actual condition of the car.',
                },
                tunes: {
                  alignment: { alignment: 'left' },
                  container: { contain: false },
                  textColor: { textColor: 'none', customTextColor: '#000000' },
                  margin: {
                    spacingTop: 0,
                    spacingBottom: 0,
                    mobile: { active: false, spacingTop: 0, spacingBottom: 0 },
                    tablet: { active: false, spacingTop: 0, spacingBottom: 0 },
                  },
                  classname: { className: '' },
                },
              },
            ],
          },
          {
            blocks: [
              {
                id: generateRandomId(10),
                type: 'header',
                data: {
                  text: "Can I sell or trade-in my car if it's still under finance or has money outstanding?",
                  level: 2,
                },
                tunes: {
                  alignment: { alignment: 'left' },
                  container: { contain: false },
                  textColor: { textColor: 'none', customTextColor: '#000000' },
                  margin: {
                    spacingTop: 0,
                    spacingBottom: 0,
                    mobile: { active: false, spacingTop: 0, spacingBottom: 0 },
                    tablet: { active: false, spacingTop: 0, spacingBottom: 0 },
                  },
                  classname: { className: '' },
                },
              },
              {
                id: generateRandomId(10),
                type: 'paragraph',
                data: {
                  text: 'Yes, but you must obtain a letter from your finance institution indicating the outstanding balance. The amount offered will be paid to your financial institution once the vehicle has been traded in. If the offer is higher than the vehicle payout figure, the difference will be paid to you (or the registered owner) via direct credit to your bank account.',
                },
                tunes: {
                  alignment: { alignment: 'left' },
                  container: { contain: false },
                  textColor: { textColor: 'none', customTextColor: '#000000' },
                  margin: {
                    spacingTop: 0,
                    spacingBottom: 0,
                    mobile: { active: false, spacingTop: 0, spacingBottom: 0 },
                    tablet: { active: false, spacingTop: 0, spacingBottom: 0 },
                  },
                  classname: { className: '' },
                },
              },
            ],
          },
          {
            blocks: [
              {
                id: generateRandomId(10),
                type: 'header',
                data: { text: 'Where can I get my car inspected? ', level: 2 },
                tunes: {
                  alignment: { alignment: 'left' },
                  container: { contain: false },
                  textColor: { textColor: 'none', customTextColor: '#000000' },
                  margin: {
                    spacingTop: 0,
                    spacingBottom: 0,
                    mobile: { active: false, spacingTop: 0, spacingBottom: 0 },
                    tablet: { active: false, spacingTop: 0, spacingBottom: 0 },
                  },
                  classname: { className: '' },
                },
              },
              {
                id: generateRandomId(10),
                type: 'paragraph',
                data: {
                  text: "Once your online enquiry has been submitted, one of our team will contact you to arrange an inspection at a time that best suits you. This could be at one of our dealership locations when you're coming in to view and test drive a new vehicle.",
                },
                tunes: {
                  alignment: { alignment: 'left' },
                  container: { contain: false },
                  textColor: { textColor: 'none', customTextColor: '#000000' },
                  margin: {
                    spacingTop: 0,
                    spacingBottom: 0,
                    mobile: { active: false, spacingTop: 0, spacingBottom: 0 },
                    tablet: { active: false, spacingTop: 0, spacingBottom: 0 },
                  },
                  classname: { className: '' },
                },
              },
            ],
          },
        ],
      },
      tunes: {
        container: { contain: false },
        margin: {
          spacingTop: 0,
          spacingBottom: 3,
          mobile: { active: false, spacingTop: 0, spacingBottom: 0 },
          tablet: { active: false, spacingTop: 0, spacingBottom: 0 },
        },
        classname: { className: '' },
      },
    },
    {
      id: generateRandomId(10),
      type: 'locations',
      data: {
        filter: 'primary',
        manufacturer: false,
        subtype: false,
        filteredLocations: [],
        primaryLocationId: primaryLocation?.id,
      },
      tunes: {
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
        },
        classname: {
          className: '',
        },
      },
    },
  ]
}
