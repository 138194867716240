import { useState, useEffect, useRef } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { SplitButton } from 'primereact/splitbutton'
import { Dropdown } from 'primereact/dropdown'
import { Paginator } from 'primereact/paginator'
import { Tag } from 'primereact/tag'
import { FilterMatchMode, PrimeReactProvider } from 'primereact/api'
import WebsiteForm from './Form'
import { csrfToken } from '../entries/utils'
import { CopyableText } from '../shared/CopyableText'
import Form from './Form'

import DataTableSearch from '../entries/DataTableSearch'
import { WebsiteService } from './websiteService'
import { useParams, Link } from 'react-router-dom'

const Action = ({ website, loadLazyData }) => {
  let [websiteFormVisible, setWebsiteFormVisible] = useState(false)

  const items = [
    {
      label: 'Open Website',
      icon: 'fa fa-link',
      command: () => {
        window.open(website.url, '_blank')
      },
    },
    {
      label: 'Edit',
      icon: 'fas fa-edit',
      command: () => {
        window.location.href = website.edit_url
      },
    },
    {
      label: 'Create Vercel Project',
      icon: 'fa fa-coffee',
      disabled: !website.ready_for_vercel_project_creation,
      command: () => {
        fetch(website.create_vercel_project_url, {
          method: 'POST',
          headers: {
            'X-CSRF-Token': csrfToken,
          },
        }).then(() => {
          window.location.reload()
        })
      },
    },
    {
      label: 'Rebuild',
      icon: 'fa fa-upload',
      disabled: !website.website_ready_for_rebuild,
      command: () => {
        fetch(website.rebuild_url, {
          method: 'POST',
          headers: {
            'X-CSRF-Token': csrfToken,
          },
        }).then(() => {
          window.location.reload()
        })
      },
    },
    {
      label: 'Open in Vercel',
      icon: 'fas fa-external-link-alt',
      command: () => {
        window.open(website.vercel_url, '_blank')
      },
    },
    {
      label: 'Deployments',
      icon: 'fa fa-recycle',
      command: () => {
        window.location.href = website.deployments_url
      },
    },
    {
      label: 'Revalidations',
      icon: 'fa fa-recycle',
      command: () => {
        window.location.href = website.admin_revalidations_url
      },
    },
    {
      label: 'Delete',
      icon: 'fa fa-trash',
      command: () => {
        const userConfirmation = window.confirm('Are you sure?')

        if (!userConfirmation) {
          return
        }
        fetch(website.delete_url, {
          method: 'DELETE',
          headers: {
            'X-CSRF-Token': csrfToken,
          },
        }).then(() => {
          window.location.reload()
        })
      },
    },
  ]
  if (website.user_is_admin) {
    return (
      <div style={{ position: 'relative' }}>
        <SplitButton
          label="Edit"
          buttonProps={{ id: 'show-button' }}
          onClick={() => {
            setWebsiteFormVisible(true)
          }}
          model={items}
          menuButtonProps={{ id: 'action-button' }}
          outlined
          rounded
        />
        {website && (
          <WebsiteForm
            visible={websiteFormVisible}
            setVisible={setWebsiteFormVisible}
            website={website}
            loadLazyData={loadLazyData}
          />
        )}
      </div>
    )
  }
  if (!website.user_is_admin) {
    return (
      <div style={{ position: 'relative' }}>
        <a
          href={website.website_url}
          target="_blank"
          rel="noopener noreferrer"
          className="p-button font-bold"
        >
          <i className="fa.fa-eye"></i>
          Show
        </a>
      </div>
    )
  }
}

const Image = (website) => {
  return website.image_url ? (
    <img src={website.image_url} height={50} style={{ maxWidth: '50px', objectFit: 'contain' }} />
  ) : null
}

const Name = (website) => {
  return (
    <div>
      <Link to={website.website_url}>{website.name}</Link>
      <div className="small text-secondary">
        <CopyableText text={website.url} />
      </div>
      {website.published === false && (
        <span className="badge badge-sm badge-danger ml-2">
          <i className="fa fa-exclamation-triangle"></i>
          Unpublished
        </span>
      )}
      {website.dealerstudio === false && (
        <span className="badge badge-sm badge-danger">
          <i className="fa fa-exclamation-triangle mr-1"></i>
          NOT hosted by Dealer Studio
        </span>
      )}
      {website.missing_manufacturer_specific_fields.map((missing_manufacturer_specific_field) => (
        <span className="badge badge-sm badge-warning ml-2">
          <i className="fa fa-exclamation-triangle"></i>
          {missing_manufacturer_specific_field} is missing
        </span>
      ))}
    </div>
  )
}

const Status = (website) => {
  const published = website.status === 'Published'
  return (
    <div className={published ? 'text-success' : 'text-danger'} id={`status-${website.id}`}>
      {website.status}
    </div>
  )
}

const GaTag = (website) => {
  if (!website.dealerstudio) {
    return null
  }

  let { dealershipSlug } = useParams()

  if ((!website.google_analytics_id || !website.ga_profile) && website.dealerstudio === true) {
    return (
      <div className="d-flex flex-column">
        <div>
          {!website.google_analytics_id && (
            <div className="badge badge-warning">
              <i className="fa fa-exclamation-triangle mr-1"></i>
              WARNING: No GA4 tag
            </div>
          )}
        </div>
        <div>
          {!website.ga_profile && (
            <a href={`/dealerships/${dealershipSlug}/ga_profiles`}>
              <div className="badge badge-danger">
                <i className="fa fa-exclamation-triangle mr-1"></i>
                WARNING: No GA4 API Link
              </div>
            </a>
          )}
        </div>
      </div>
    )
  }

  return (
    <div>
      <i className="fa fa-check-circle text-success mr-1"></i>
      <CopyableText text={website.google_analytics_id} />
    </div>
  )
}

const App = ({ ownerId = '', ownerClass = '' }) => {
  const dt = useRef(null)
  let [loading, setLoading] = useState(false)
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  })

  const getSeverity = (status) => {
    switch (status) {
      case 'Unpublished':
        return 'danger'

      case 'Published':
        return 'success'
    }
  }

  const [statuses] = useState(['Published', 'Unpublished'])

  const statusItemTemplate = (option) => {
    return <Tag value={option} severity={getSeverity(option)} />
  }

  const statusRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={statusItemTemplate}
        placeholder="Select Status"
        className="p-column-filter"
        showClear
        style={{ minWidth: '12rem' }}
      />
    )
  }

  const [totalRecords, setTotalRecords] = useState(0)
  const [websites, setWebsites] = useState(null)

  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 50,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {
      global: { value: '', matchMode: 'contains' }, // FilterMatchMode.CONTAINS is not used because lazyState was not updating
      status: { value: null, matchMode: 'equals' },
      name: { value: null, matchMode: 'contains' },
      google_analytics_id: { value: null, matchMode: 'contains' },
    },
  })

  useEffect(() => {
    loadLazyData()
  }, [lazyState])

  const loadLazyData = () => {
    setLoading(true)
    if (lazyState.page === undefined) {
      return
    }
    let page_number = 0
    if (lazyState.page === 0 || lazyState.first === 0) {
      page_number = 1
    } else {
      page_number = lazyState.page + 1
    }
    let params = { page: page_number, rows: lazyState.rows }
    let csrf = csrfToken
    try {
      WebsiteService.getWebsites(params, csrf, ownerId, ownerClass).then((data) => {
        setTotalRecords(data.data.total_records)
        setWebsites(data.data.websites)
        setLoading(false)
      })
    } catch (error) {
      console.error(error)
    }
  }
  const onFilter = (event) => {
    event['first'] = 0
    setlazyState(event)
  }

  const onPageChange = (event) => {
    event['filters'] = lazyState.filters
    setlazyState(event)
  }

  const header = DataTableSearch({ filters: lazyState.filters, setFilters: setFilters, dt: dt })

  return (
    <PrimeReactProvider>
      <div className="box">
        <DataTable
          value={websites}
          tableStyle={{ minWidth: '50rem' }}
          removableSort
          sortMode="multiple"
          scrollable
          globalFilterFields={['name', 'google_analytics_id']}
          style={{ overflow: 'visible' }}
          wrapper={{ style: { overflow: 'visible' } }}
          filterDisplay="row"
          header={header}
          rows={lazyState.rows}
          first={lazyState.first}
          totalRecords={totalRecords}
          filters={lazyState.filters}
          onFilter={onFilter}
          loading={loading}
          ref={dt}
        >
          <Column body={Image} field="image" header="Image"></Column>
          <Column body={Name} field="name" header="Name"></Column>
          <Column
            field="status"
            header="Status"
            sortable
            showFilterMenu={false}
            filterMenuStyle={{ width: '14rem' }}
            body={Status}
            filter
            filterElement={statusRowFilterTemplate}
          />
          <Column body={GaTag} field="google_analytics_id" header="GA tags"></Column>
          <Column
            body={(website) => <Action website={website} loadLazyData={loadLazyData} />}
            header="Actions"
          ></Column>
        </DataTable>
        <Paginator
          first={lazyState.first}
          rows={lazyState.rows}
          totalRecords={totalRecords}
          onPageChange={onPageChange}
        />
      </div>
    </PrimeReactProvider>
  )
}

const Wrapper = () => {
  let { dealershipSlug, manufacturerSlug } = useParams()
  let [newVisible, setNewVisible] = useState(false)

  let ownerId = dealershipSlug || manufacturerSlug
  let ownerClass = dealershipSlug ? 'Dealership' : 'Manufacturer'

  return (
    <div className="p-3">
      <div className="mb-3 d-flex align-items-center">
        <h4 className="m-0">{window.dealership?.name} Websites</h4>
        {dealershipSlug && (
          <>
            <Form
              visible={newVisible}
              setVisible={setNewVisible}
              title="New Website"
              newWebsite={true}
            />
            <div className="ml-auto">
              <a
                className="btn btn-outline-primary"
                onClick={(e) => {
                  e.preventDefault()
                  setNewVisible(true)
                }}
              >
                <i className="fa fa-plus mr-1"></i>
                Website
              </a>
            </div>
          </>
        )}
      </div>
      <App ownerId={ownerId} ownerClass={ownerClass} />
    </div>
  )
}

export default Wrapper
