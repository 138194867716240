import Container from './Container'
import type { BlockProps, ButtonProps } from '../../../types'

// BasicButtons component for rendering buttons
export const BasicButtons = ({
  buttons,
  blockId,
  rounded,
}: {
  buttons: ButtonProps[]
  blockId: string
  rounded?: boolean
}) => {
  if (!buttons) {
    return null
  }

  return (
    <>
      {buttons?.map((button, id) => {
        const btnClassName =
          `btn mx-1 btn-${button.type === 'outline' ? 'outline-' : ''}${button.colour}` +
          (rounded ? ' rounded-pill' : '')

        return (
          <a key={`${blockId}-btn-${id}`} href={button?.href || ''} className={btnClassName}>
            {button.text}
          </a>
        )
      })}
    </>
  )
}

const ButtonsBlock = ({ block, isInRow }: { block: BlockProps; isInRow?: boolean }) => {
  return (
    <Container tunes={block?.tunes} isInRow={isInRow}>
      {block.data.fullWidth ? (
        <div className="row">
          {block?.data?.buttons?.map((button, id) => {
            const btnClassName =
              `btn btn-block mb-2 mb-lg-0 btn-${button.type === 'outline' ? 'outline-' : ''}${
                button.colour
              }` + (block.data.roundedButton ? ' rounded-pill' : '')

            return (
              <a key={`${block.id}-btn-${id}`} href={button?.href || ''} className={btnClassName}>
                {button.text}
              </a>
            )
          })}
        </div>
      ) : (
        <BasicButtons
          buttons={block.data?.buttons}
          rounded={block.data?.roundedButton}
          blockId={block.id}
        />
      )}
    </Container>
  )
}

export default ButtonsBlock
