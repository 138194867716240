import { useEffect, useState } from 'react'
import { useParams, Link, useLocation } from 'react-router-dom'

function setCookie(name, value, days) {
  var expires = ''
  if (days) {
    var date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/'
}

const Anchor = ({ to, children }) => <a href={to}>{children}</a>

const Children = ({ link }) => {
  const { pathname } = useLocation()

  return (
    <div>
      {link.children.map((child) => {
        let LinkComponent = child.reactRouter ? Link : Anchor
        let isCurrentPath = isActive(pathname, child.url)
        return (
          <LinkComponent to={child.url} className={isCurrentPath ? 'active' : 'test'}>
            <div className="sidemenu-item-child">
              <div className={`fa ${child.iconClass} mr-2`}></div>
              {child.name}
            </div>
          </LinkComponent>
        )
      })}
    </div>
  )
}

function isActive(pathname, path) {
  if (pathname === path) return true
  if (
    ['/cars/', '/contacts', '/lead_clusters'].some((p) => path.includes(p)) &&
    pathname.includes(path)
  )
    return true

  return false
}

const LinkItem = ({ link }) => {
  let [isOpen, setIsOpen] = useState(false)
  let LinkComponent = link.reactRouter ? Link : Anchor

  const toggleIsOpen = () => setIsOpen(!isOpen)

  const { pathname } = useLocation()

  const isCurrentPath = isActive(pathname, link.path)

  return (
    <li>
      <LinkComponent to={link.path} className={isCurrentPath ? 'active' : ''}>
        <div className="sidemenu-item">
          <div className={`fa mr-2 ${link.iconClass}`}></div>
          <div className="nav-link-title d-flex w-100 flex-column">
            <div className="d-flex align-items-center">
              {link.text}
              {link.error && (
                <span className="text-danger ml-auto">
                  <i className="fa fa-exclamation-triangle"></i>
                </span>
              )}
              {link.children && (
                <div
                  className="btn btn-outline-secondary btn-sm ml-auto"
                  onClick={(e) => {
                    e.preventDefault()
                    toggleIsOpen()
                  }}
                >
                  <i className={'fa fa-sm ' + (isOpen ? 'fa-angle-up' : 'fa-angle-down')}></i>
                </div>
              )}
            </div>
          </div>
        </div>
      </LinkComponent>
      {link.children && isOpen && <Children link={link} />}
      <div className="sidemenu-popover">{link.text}</div>
    </li>
  )
}

const DashboardLinks = ({ links, toggleIsOpen, isOpen }) => {
  return (
    <>
      {links.map((link) => (
        <LinkItem link={link} key={link.text} />
      ))}
      <li>
        <div className="sidemenu-item" onClick={toggleIsOpen}>
          <div className={'fa ' + (isOpen ? 'fa-caret-left' : 'fa-caret-right')}></div>
        </div>
      </li>
    </>
  )
}

const SideMenu = () => {
  let [links, setLinks] = useState(false)
  let { dealershipSlug, websiteSlug } = useParams()
  let [isOpen, setIsOpen] = useState(false)
  let slug = dealershipSlug || window.dealership?.slug

  if (!slug && !websiteSlug) {
    return <></>
  }

  useEffect(() => {
    let url = `/dealerships/${slug}/sidemenu_links.json`

    if (websiteSlug) {
      url = `/websites/${websiteSlug}/sidemenu_links.json`
    }

    fetch(url)
      .then((res) => res.json())
      .then((res) => {
        setLinks(res.links)
        if (res.sidemenu_state === 'open') {
          setIsOpen(true)
        }
      })
  }, [slug, websiteSlug])

  let toggleIsOpen = () => {
    let newState = !isOpen

    setIsOpen(newState)

    if (newState) {
      setCookie('sidebar', 'open')
    } else {
      setCookie('sidebar', 'collapsed')
    }
  }

  return (
    <div className="wrapper">
      <div className={'sidebar' + (isOpen ? ' open' : ' collapsed')} id="sidebar">
        <ul>
          {links && <DashboardLinks links={links} toggleIsOpen={toggleIsOpen} isOpen={isOpen} />}
        </ul>
      </div>
    </div>
  )
}

export default SideMenu
