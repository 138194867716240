import { useEffect, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Select } from '../entries/FormElements'
import { Sidebar } from 'primereact/sidebar'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { CopyableText } from '../shared/CopyableText'

const JsonBox = ({ title, json }) => {
  return (
    <div className="box mb-2 p-2">
      <h6>{title}</h6>
      <pre>{JSON.stringify(json, null, 2)}</pre>
    </div>
  )
}

const CreatedAt = ({ created_at }) => {
  return <>{moment(created_at).fromNow()}</>
}

const Apicall = (apicall) => {
  let [visible, setVisible] = useState(false)
  let [apicallDetails, setApicallDetails] = useState(null)
  let [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchApicall()
  }, [apicall.id, visible])

  let fetchApicall = () => {
    if (!visible) return
    if (!apicall.id) return

    setLoading(true)
    fetch(`/apicalls/${apicall.id}.json`)
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw response
      })
      .then((data) => {
        setApicallDetails(data)
        setLoading(false)
      })
      .catch((error) => console.error('Error:', error))
  }

  const Details = () => (
    <>
      <div className="mb-2">
        <strong>ID:</strong> {apicallDetails?.id}
      </div>
      <div className="mb-2">
        <strong>Service:</strong> {apicallDetails?.service}
      </div>
      <div className="mb-2">
        <strong>Endpoint URL:</strong> <CopyableText text={apicallDetails?.endpoint_url} />
      </div>
      <div className="mb-2">
        <strong>Response Code:</strong> {apicallDetails?.response_code}
      </div>
      <div className="mb-2">
        <strong>Created At:</strong> {apicallDetails?.created_at}
      </div>
      <div className="mt-3">
        <JsonBox title="Body" json={apicallDetails?.body} />
        <JsonBox title="Request Headers" json={apicallDetails?.request_body} />
        <JsonBox title="Response body" json={apicallDetails?.response_body} />
      </div>
    </>
  )

  return (
    <>
      <Sidebar
        visible={visible}
        style={{ width: '60vw' }}
        onHide={() => setVisible(false)}
        header={`Apicall Details - ${apicallDetails?.service}`}
        position="right"
      >
        <div className="p-3">{loading ? <div>Loading...</div> : <Details />}</div>
      </Sidebar>
      <div className="btn btn-sm btn-outline-secondary" onClick={() => setVisible(true)}>
        Show
      </div>
    </>
  )
}

const ResponseCode = ({ response_code }) => {
  let textClass = 'text-success'

  if (response_code >= 300) {
    textClass = 'text-danger'
  }

  return <span className={textClass}>{response_code}</span>
}

const Apicalls = () => {
  let [apicalls, setApicalls] = useState([])
  let [loading, setLoading] = useState(false)
  let [page, setPage] = useState(1)
  let [totalCount, setTotalCount] = useState(0)
  let [categories, setCategories] = useState([])
  let [dealerships, setDealerships] = useState([])
  let [service, setService] = useState('')
  let [dealership, setDealership] = useState('')
  const rows = 25

  let { carId } = useParams()

  useEffect(() => {
    setLoading(true)

    const params = new URLSearchParams()

    // Conditionally append parameters if they are defined
    if (page !== undefined && page !== null && page !== '') {
      params.append('page', page)
    }
    if (service !== undefined && service !== null && service !== '') {
      params.append('service', service)
    }
    if (dealership !== undefined && dealership !== null && dealership !== '') {
      params.append('dealership_id', dealership)
    }

    if (carId) {
      params.append('car_id', carId)
    }

    // Construct the full URL with the query string
    const url = `/admin/apicalls.json?${params.toString()}`

    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw response
      })
      .then((data) => {
        setApicalls(data.apicalls)
        setTotalCount(data.total_count)
        setLoading(false)
        setCategories(data.services)
        setDealerships(data.dealerships)
      })
      .catch((error) => console.error('Error:', error))
  }, [page, service, dealership])

  const onPageChange = (event) => {
    setPage(event.page + 1) // PrimeReact pages are 0-based; Kaminari pages are 1-based
  }

  let categoryOptions = categories?.map((category) => ({
    label: category,
    value: category,
  }))

  let dealershipOptions = dealerships?.map((dealership) => ({
    label: dealership.name,
    value: dealership.id,
  }))

  return (
    <div className="container py-3">
      <h4>Apicalls</h4>

      <div className="mb-3">
        <div className="row">
          <div className="col-md-6">
            <Select
              label="Service"
              options={categoryOptions}
              onChange={(o) => setService(o?.value || null)}
              isClearable
            />
          </div>
          <div className="col-md-6">
            <Select
              label="Dealership"
              options={dealershipOptions}
              onChange={(o) => setDealership(o?.value || null)}
              isClearable
            />
          </div>
        </div>
      </div>

      <div className="box">
        <DataTable
          value={apicalls}
          paginator
          rows={rows}
          rowsPerPageOptions={[5, 10, 20]}
          loading={loading}
          first={(page - 1) * rows}
          totalRecords={totalCount}
          onPage={onPageChange}
          lazy
        >
          <Column field="created_at" header="Created" body={CreatedAt} />
          <Column field="id" header="ID" />
          <Column field="service" header="Service" />
          <Column field="dealership.name" header="Dealership" />
          <Column field="response_code" header="Response Code" body={ResponseCode} />
          <Column header="Actions" body={Apicall} />
        </DataTable>
      </div>
    </div>
  )
}

export default Apicalls
