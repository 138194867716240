import { snakeCaseToTitleCase } from '../AnalyticsDashboard/utils'

export function ListFromObject({ fields }: { fields: Record<string, any> }) {
  return (
    <ul>
      {Object.entries(fields).map(([key, value]) => {
        if (typeof value === 'object' || typeof value === 'symbol') {
          return null
        }
        return (
          <li key={key}>
            <strong>{snakeCaseToTitleCase(key)}</strong>:&nbsp;
            {typeof value === 'number' ? value.toLocaleString() : value}
          </li>
        )
      })}
    </ul>
  )
}
