import { autocomplete } from '@algolia/autocomplete-js'
import { MeiliSearch } from 'meilisearch'
import { ready, getMeilisearchConfig } from './utils'

let itemUrl = (item) => {
  switch (true) {
    case item.type === 'Dealership':
      return `/dealerships/${item.slug}`
    case item.type === 'Website':
      return `/websites/${item.slug}`
    case item.type === 'Dealership_group':
      return `/dealership_groups/${item.id}`
    default:
      return `/manufacturers/${item.slug}`
  }
}

let itemClass = (item) => {
  switch (true) {
    case item.type === 'Dealership':
      return `badge-secondary`
    case item.type === 'Website':
      return `badge-primary`
    case item.type === 'Dealership_group':
      return `badge-danger`
    default:
      return `badge-success`
  }
}

let itemLabel = (item) => {
  switch (true) {
    case item.type === 'Dealership':
      return `Dealer`
    case item.type === 'Website':
      return `Website`
    case item.type === 'Dealership_group':
      return `Dealer Group`
    default:
      return `OEM`
  }
}

function getUniqueItems(items, propertyName) {
  return items.filter(
    (item, index, self) => index === self.findIndex((i) => i[propertyName] === item[propertyName])
  )
}

ready(function () {
  let meilisearchHostDomElement = document.getElementById('meilisearch-host')
  if (meilisearchHostDomElement) {
    let { meilisearchHost, meilisearchApiKey } = getMeilisearchConfig()

    let searchForm = document.getElementById('autocomplete')
    if (!searchForm) {
      return
    }

    const searchClient = new MeiliSearch({
      host: meilisearchHost,
      apiKey: meilisearchApiKey,
    })

    autocomplete({
      container: '#autocomplete',
      placeholder: 'Dealerships...',
      detachedMediaQuery: 'none',
      openOnFocus: true,
      render({ sections, render, html }, root) {
        render(
          html`<div class="aa-PanelLayout aa-Panel--scrollable">
            <div className="aa-Header">
              <ul class="aa-List">
                <li class="aa-Item">
                  <a href="/dealerships">
                    <div class="aa-ItemWrapper">
                      View All
                    </div>
                  </a>
                </li>
              </ul>
              <hr class="my-1"></hr>
            </div>
            ${sections}
          </div>`,
          root
        )
      },
      getSources({ query }) {
        return [
          {
            sourceId: 'dealerships',
            async getItems() {
              let searchQuery = await searchClient.multiSearch({
                queries: [
                  {
                    indexUid: `Dealership_${process.env.RAILS_ENV}`,
                    q: query,
                  },
                  {
                    indexUid: `Website_${process.env.RAILS_ENV}`,
                    q: query,
                  },
                  {
                    indexUid: `Manufacturer_${process.env.RAILS_ENV}`,
                    q: query,
                    filter: 'published=true',
                  },
                  {
                    indexUid: `DealershipGroup_${process.env.RAILS_ENV}`,
                    q: query,
                  },
                ],
              })

              let combined = []
              searchQuery.results.forEach((queryResult) => {
                const uniqueItems = getUniqueItems(queryResult.hits, 'name')
                combined.push(...uniqueItems)
              })

              return combined
            },
            templates: {
              item({ item, components, html }) {
                let url = itemUrl(item)
                return html`
                  <a href=${url}>
                    <div class="aa-ItemWrapper">
                      <span className="mr-2">${item.name}</span>
                      <span className="small ml-auto badge ${itemClass(item)}">
                        ${itemLabel(item)}
                      </span>
                    </div>
                  </a>
                `
              },
            },
            getItemUrl({ item }) {
              return itemUrl(item)
            },
          },
        ]
      },
    })
  }
})
