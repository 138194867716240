import { useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { SplitButton } from 'primereact/splitbutton'
import moment from 'moment'
import * as Routes from '../../routes'
import AuditTrailSlideIn from '../shared/AuditTrailSlideIn'
import { Link } from 'react-router-dom'

const Logo = (manufacturer) => {
  if (!manufacturer.logo?.url) {
    return null
  }

  return (
    <img
      src={manufacturer.logo.url}
      alt="Logo"
      height={50}
      style={{ maxWidth: 75, objectFit: 'contain' }}
    />
  )
}

const Name = (manufacturer) => (
  <Link to={Routes.manufacturer_path(manufacturer.slug)}>{manufacturer.name}</Link>
)

const UpdatedAt = (manufacturer) => {
  let updated_at = manufacturer.updated_at

  return <span className="small text-secondary">{moment(updated_at).fromNow()}</span>
}

const RedbuildWebsites = (manufacturer) => {
  return (
    <a
      href={Routes.rebuild_websites_manufacturer_path(manufacturer.id)}
      className="btn btn-outline-secondary btn-sm"
      data-method="post"
    >
      Rebuild Websites
    </a>
  )
}

const UpToDate = (manufacturer) => {
  if (manufacturer.supported && manufacturer.checks_required && manufacturer['uptodate?']) {
    return <i className="fa fa-check-circle text-success"></i>
  } else if (manufacturer.supported && manufacturer.checks_required) {
    return <i className="fa fa-times-circle text-danger"></i>
  } else {
    return (
      <>
        <i className="fa fa-info-circle text-secondary"></i>
        <span className="ml-1 small text-secondary">Not required</span>
      </>
    )
  }
}

const Action = (manufacturer) => {
  const [showSlideIn, setShowSlideIn] = useState(false)
  const handleHideSlideIn = () => {
    setShowSlideIn(false)
  }
  const items = [
    {
      label: 'Audit Trail',
      icon: 'fa fa-history',
      command: () => {
        setShowSlideIn(true)
      },
    },
  ]

  return (
    <div style={{ position: 'relative' }}>
      <SplitButton
        label="Edit"
        buttonProps={{ id: 'edit-button' }}
        onClick={() => {
          window.location.href = Routes.edit_manufacturer_path(manufacturer.slug)
        }}
        model={items}
        menuButtonProps={{ id: 'action-button' }}
        outlined
        rounded
      />
      {showSlideIn && (
        <AuditTrailSlideIn
          AuditTrailObject={manufacturer}
          AuditTrailObjectName="Manufacturer"
          Url={Routes.audit_trail_manufacturer_path(manufacturer.slug)}
          ShowSlideIn={showSlideIn}
          onHide={handleHideSlideIn}
        />
      )}
    </div>
  )
}

const Manufacturers = ({ supported, title }) => {
  const [manufacturers, setManufacturers] = useState([])
  let [loading, setLoading] = useState(true)
  let [totalRecords, setTotalRecords] = useState(0)
  const rows = 25
  const [page, setPage] = useState(1)
  const [searchKeyword, setSearchKeyword] = useState('') // Keyword state
  const fetchManufacturers = () => {
    setLoading(true)
    fetch(
      Routes.index_react_manufacturers_path({
        page: page,
        per_page: rows,
        supported: supported,
        search: searchKeyword, // Include search parameter in the fetch
      })
    )
      .then((response) => response.json())
      .then((data) => {
        setManufacturers(data.manufacturers)
        setTotalRecords(data.total_count)
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchManufacturers()
  }, [page, searchKeyword])

  const onPageChange = (event) => {
    setPage(event.page + 1) // PrimeReact pages are 0-based; Kaminari pages are 1-based
  }

  const onSearchChange = (e) => {
    setSearchKeyword(e.target.value)
    setPage(1) // Reset to the first page when searching
  }

  return (
    <div className="box mb-3">
      <div className="p-3">
        <h4>{title}</h4>
      </div>
      <div className="p-2">
        <input
          value={searchKeyword}
          onChange={onSearchChange}
          placeholder="Search manufacturers..."
          className="w-100 form-control"
        />
      </div>
      <DataTable
        value={manufacturers}
        loading={loading}
        paginator
        rows={rows}
        first={(page - 1) * rows}
        totalRecords={totalRecords}
        onPage={onPageChange}
        lazy
      >
        <Column field="logo" header="Logo" body={Logo} />
        <Column field="name" header="Name" body={Name} />
        <Column header="Up to date?" body={UpToDate} />
        <Column header="Banners" field="promotions_count" />
        <Column header="Offers" field="offers_count" />
        <Column header="Models" field="models_count" />
        <Column header="GH Repo" field="frontend_github_repo" />
        <Column field="updated" header="Updated" body={UpdatedAt} />
        <Column header="Rebuild" body={RedbuildWebsites} />
        <Column field="actions" header="Actions" body={Action} />
      </DataTable>
    </div>
  )
}

const Wrapper = () => {
  return (
    <div className="container py-3">
      <h3>Manufacturers</h3>
      <Manufacturers supported={true} title="Supported Manufacturers" />
      <Manufacturers supported={false} title="Other Manufacturers" />
    </div>
  )
}

export default Wrapper
