import { Dispatch, SetStateAction, useState } from 'react'
import { FormGroup } from './FormGroup'
import InputLabel from './InputLabel'
import { Cross } from './Icons'
import type { DefaultInputProps } from '../types'

export const LabeledMultipleInput = ({
  item,
  itemName,
  updateItem,
  label,
  placeholder,
  customAdd,
  customDelete,
  inputClassName,
  tooltip,
}: DefaultInputProps & {
  placeholder?: string
  customAdd?: (
    options: string[],
    itemName: string,
    currentInput: string,
    setCurrentInput: Dispatch<SetStateAction<string>>,
    setOptions: Dispatch<SetStateAction<string[]>>
  ) => void
  customDelete?: (
    options: string[],
    itemName: string,
    option: string,
    setOptions: Dispatch<SetStateAction<string[]>>
  ) => void
}) => {
  const [currentInput, setCurrentInput] = useState('')
  const [options, setOptions] = useState<string[]>(item[itemName] || [])

  const handleAdd = () => {
    if (options.includes(currentInput)) {
      alert('You have already added ' + currentInput)
    } else if (currentInput.length > 0) {
      setOptions([...options, currentInput])
      updateItem({ ...item, [itemName]: [...options, currentInput] })
      setCurrentInput('')
    }
  }

  const handleDelete = (option: string) => {
    const filteredOptions = options.filter((o: string) => o !== option)
    setOptions(filteredOptions)
    updateItem({ ...item, [itemName]: filteredOptions })
  }

  return (
    <FormGroup>
      <InputLabel label={label} itemName={itemName} tooltip={tooltip} />
      <div className="d-flex">
        <input
          id={itemName}
          placeholder={placeholder || `Enter ${itemName}...`}
          className={`form-control ${inputClassName}`}
          value={currentInput}
          onChange={(e) => setCurrentInput(e.target.value)}
        />
        <button
          onClick={() =>
            customAdd
              ? customAdd(options, itemName, currentInput, setCurrentInput, setOptions)
              : handleAdd()
          }
          className="btn btn-sm btn-primary ml-2"
        >
          Add
        </button>
      </div>
      <div className="d-flex align-items-center mt-2 flex-wrap" style={{ gap: '0.25rem' }}>
        {options?.map((option, id) => (
          <span
            key={`input-item-${id}`}
            className="bg-light d-flex align-items-center py-1 px-2"
            style={{ borderRadius: 24 }}
          >
            {option}
            <div
              className="delete-btn ml-1"
              onClick={() =>
                customDelete
                  ? customDelete(options, itemName, option, setOptions)
                  : handleDelete(option)
              }
            >
              <Cross size={15} color="currentColor" />
            </div>
          </span>
        ))}
      </div>
    </FormGroup>
  )
}
