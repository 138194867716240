import { useState } from 'react'
import { timeAgoInDays } from '../../entries/utils'
import { Tooltip } from 'react-tooltip'

import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Select } from '../../entries/FormElements'

const imageTemplate = (car) => {
  let default_image_url =
    'https://res.cloudinary.com/total-dealer/image/upload/v1692498338/td_dashboard/placeholder-image_l1iz3a.png'
  let image_url = car.cover_image_url ? car.cover_image_url : default_image_url
  return (
    <a href={car.listing_url} target="_blank">
      <img
        src={image_url}
        height={66}
        width={100}
        className="border rounded"
        style={{ objectFit: 'cover' }}
      />
    </a>
  )
}

const Locations = (car) => {
  return (
    <>
      {car.listing_sources.map((source) => (
        <div key={`${car.id}-${source}`}>
          <div className={`circle-${source[0]}`} id={`${car.id}-${source}`}>
            {source[0].toUpperCase()}
          </div>
          <Tooltip anchorId={`${car.id}-${source}`} place="bottom" style={{ zIndex: 10000 }}>
            {source}
          </Tooltip>
        </div>
      ))}
    </>
  )
}

const Kms = (car) => {
  return (
    <>
      {car.kms.toLocaleString()}
      <div className="small text-secondary">kms</div>
    </>
  )
}

const Age = (car) => {
  return <>{timeAgoInDays(car.listed_at)}d</>
}

const Price = (car) => {
  return <>${car.price.toLocaleString()}</>
}

let minimumDaysOptions = [
  { value: 30, label: '30' },
  { value: 60, label: '60' },
  { value: 90, label: '90' },
]

let Cars = ({ cars, car, minimumDays, setMinimumDays }) => {
  let [state, setState] = useState(car.state)

  let stateCounts = cars.reduce((acc, car) => {
    acc[car.state] = (acc[car.state] || 0) + 1
    return acc
  }, {})

  let stateFilterOptions = Object.entries(stateCounts).map(([state, count]) => ({
    value: state,
    label: `${state} (${count})`,
  }))

  let filteredCars = cars
  if (state) {
    filteredCars = cars.filter((c) => c.state === state)
  }

  return (
    <>
      <div className="row">
        <div className="col-md-3">
          {stateFilterOptions && state && (
            <Select
              options={stateFilterOptions}
              label="State"
              onChange={(value) => setState(value.value)}
              isClearable={false}
              defaultValue={stateFilterOptions.find((s) => s.value === state)}
            />
          )}
        </div>
        <div className="col-md-3">
          <Select
            options={minimumDaysOptions}
            label="Minimum Days"
            onChange={(value) => setMinimumDays(value.value)}
            defaultValue={minimumDaysOptions.find((s) => s.value === minimumDays)}
            isClearable={false}
          />
        </div>
      </div>
      {filteredCars && (
        <DataTable value={filteredCars} tableStyle={{ minWidth: '50rem' }} sortMode="multiple">
          <Column body={imageTemplate} header="Image"></Column>
          <Column field="year" header="Year"></Column>
          <Column field="color" header="Color"></Column>
          <Column field="state" header="State"></Column>
          <Column field="price" body={Price} header="Price" sortable></Column>
          <Column field="kms" body={Kms} header="Kms" sortable></Column>
          <Column field="listed_at" body={Age} header="Age" sortable></Column>
          <Column field="seller_type" header="Seller Type"></Column>
          <Column body={Locations} header="Locations"></Column>
        </DataTable>
      )}
    </>
  )
}

export default Cars
