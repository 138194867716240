import { Sidebar } from 'primereact/sidebar'
import { createContext, useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { Errors, Input, Select, Switch, DateInput } from '../entries/FormElements'
import { standardHeaders } from '../entries/utils'

const EditWebsiteContext = createContext({})

let attributes = {
  name: { type: 'text', label: 'Name', required: true },
  url: {
    type: 'text',
    label: 'URL',
    hint: 'ALWAYS USE HTTPS://WWW FOR WEBSITES UNLESS USING A SUBDOMAIN',
    required: true,
  },
  manufacturer_id: { type: 'select', label: 'Manufacturer', options: 'manufacturers' },
  primary_developer_id: { type: 'select', label: 'Primary Developer', options: 'admin_users' },
  launch_date: { type: 'date', label: 'Launch Date' },
  dealerstudio: {
    type: 'switch',
    label: 'Dealer Studio',
  },
  use_pages_path: {
    type: 'switch',
    label: 'Use Pages Path',
    hint: 'Determines whether pages are loaded under /pages/[slug] or /[slug]',
  },
  build_model_pages: {
    type: 'switch',
    label: 'Build Model Pages',
    hint: 'Build website model pages at /models/[slug]',
  },
  offers_subscription: {
    type: 'switch',
    label: 'Offers Subscription',
    hint: 'Is the website a subscription website? e.g., Loopit website or primarily is for car subscriptions',
  },
  enable_accessory_addons: {
    type: 'switch',
    label: 'Enable Accessories',
    hint: 'Enable accessory addons which can be used on the checkout enquiry forms',
  },
}

const Field = ({ attribute, hint }) => {
  let type = attributes[attribute].type
  let { control, errors, formData } = useContext(EditWebsiteContext)

  const renderInput = (field) => {
    switch (type) {
      case 'number':
        return (
          <NumberInput
            label={false}
            className={`form-control ${errors[attribute] ? 'is-invalid' : ''}`}
            {...field}
            onChange={(value) => {
              field.onChange(value.target.rawValue)
            }}
          />
        )
      case 'date':
        return (
          <DateInput
            label={attributes[attribute].label}
            className={`${errors[attribute] ? 'is-invalid' : ''}`}
            {...field}
            value={field.value || null}
            onChange={(value) => {
              field.onChange(value)
            }}
          />
        )
      case 'text':
        return (
          <Input
            className={`form-control ${errors[attribute] ? 'is-invalid' : ''}`}
            {...attributes[attribute]}
            {...field}
          />
        )
      case 'switch':
        return (
          <div className="mb-3">
            <Switch
              {...field}
              label={attributes[attribute].label}
              id={attribute}
              value={field.value}
              onChange={(e) => field.onChange(!field.value)}
            />
            {hint && <small className="form-text text-muted">{hint()}</small>}
            {attributes[attribute].hint && (
              <small className="form-text text-muted">{attributes[attribute].hint}</small>
            )}
          </div>
        )
      case 'select':
        let options = formData[attributes[attribute].options]

        return (
          <Select
            label={attributes[attribute].label}
            className={`${errors[attribute] ? 'is-invalid' : ''}`}
            options={options}
            value={options.filter((o) => o.value === field.value)}
            onChange={(e) => field.onChange(e.value)}
          />
        )
      default:
        return null
    }
  }

  return (
    <>
      <Controller name={attribute} control={control} render={({ field }) => renderInput(field)} />
      {errors[attribute] && <div className="invalid-feedback">{errors[attribute].message}</div>}
    </>
  )
}

let WebsiteForm = ({ newWebsite = false, website, formData, loadLazyData, setVisible }) => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      name: website?.name || '',
      url: website?.url || 'https://www.',
      dealerstudio: website?.dealerstudio ?? true,
      manufacturer_id: website?.manufacturer_id || '',
      primary_developer_id: website?.primary_developer_id || '',
      use_pages_path: website?.use_pages_path ?? true,
      build_model_pages: website?.build_model_pages ?? false,
      offers_subscription: website?.offers_subscription ?? false,
      enable_accessory_addons: website?.enable_accessory_addons ?? false,
      launch_date: website?.launch_date ?? false,
    },
  })

  let [errors, setErrors] = useState({})

  let { dealershipSlug } = useParams()

  const onSubmit = async (data) => {
    let url = newWebsite ? `/dealerships/${dealershipSlug}/websites` : `/websites/${website?.id}`
    let method = newWebsite ? 'POST' : 'PATCH'

    try {
      await fetch(url, {
        method: method,
        headers: standardHeaders,
        body: JSON.stringify({ website: data }),
      }).then(async (res) => {
        const responseData = await res.json() // Parse JSON regardless of status
        if (res.ok) {
          if (loadLazyData) {
            loadLazyData()
            setVisible(false)
          } else {
            window.location.reload() // Reload only for successful responses
          }
        } else {
          setErrors(responseData)
          throw new Error(`HTTP error! status: ${res.status}`)
        }
      })
    } catch (error) {
      console.error('Failed to update website:', error)
    }
  }

  return (
    <EditWebsiteContext.Provider value={{ website, control, errors, formData }}>
      <form onSubmit={handleSubmit(onSubmit)} className="py-2">
        <Field attribute="name" />
        <Field attribute="url" />
        <Field attribute="manufacturer_id" />
        <Field attribute="primary_developer_id" />
        <Field attribute="launch_date" />
        <Field
          attribute="dealerstudio"
          hint={() => (
            <>
              Is website hosted by Dealer Studio?{' '}
              <b>If this is turned OFF the stock will NOT be updated</b>
            </>
          )}
        />
        <Field attribute="use_pages_path" />
        <Field attribute="build_model_pages" />
        <Field attribute="offers_subscription" />
        <Field attribute="enable_accessory_addons" />

        <Errors errors={errors} />

        <button
          type="submit"
          className={`btn btn-outline-primary btn-block${isSubmitting ? ' disabled' : ''}`}
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Loading...' : newWebsite ? 'Save' : 'Update'}
        </button>
      </form>
    </EditWebsiteContext.Provider>
  )
}

const Wrapper = ({
  visible,
  newWebsite,
  setVisible,
  title = 'Edit Website',
  website,
  loadLazyData,
}) => {
  let [formData, setFormData] = useState({})
  let [loading, setLoading] = useState(true)
  let { dealershipSlug } = useParams()

  if (!dealershipSlug) {
    return null
  }

  useEffect(() => {
    if (visible) {
      fetch(`/dealerships/${dealershipSlug}/websites/new.json`)
        .then((res) => res.json())
        .then((res) => {
          setFormData(res)
          setLoading(false)
        })
    }
  }, [visible])

  return (
    <Sidebar
      header={title}
      visible={visible}
      style={{ width: '50vw' }}
      onHide={() => setVisible(false)}
      position="right"
    >
      {loading ? (
        <div className="text-center">
          <i className="pi pi-spin pi-spinner" style={{ fontSize: '2em' }}></i>
        </div>
      ) : (
        <WebsiteForm
          newWebsite={newWebsite}
          formData={formData}
          website={website}
          loadLazyData={loadLazyData}
          setVisible={setVisible}
        />
      )}
    </Sidebar>
  )
}

export default Wrapper
