import { Message } from 'primereact/message'
import { TreeSelect } from 'primereact/treeselect'
import { classNames } from 'primereact/utils'
import { useEffect, useState } from 'react'
import { Content, Mode } from 'vanilla-jsoneditor'
import { Checkbox, Input, Label, Select } from '../../../entries/FormElements'
import { LabeledFileInput, LabeledTextarea } from '../../common'
import { FormGroup } from '../../common/FormGroup'
import JSONEditorReact from '../../common/JsonEditor'
import {
  BOOLEAN,
  CustomInputsProps,
  OldProps,
  OPTIONS_TREE,
  RawProps,
} from './customComponentUtils'
import { useCustomProps } from './PropsController'

const formatNestedValue = (value) => {
  if (!!Number(value)) return Number(value)
  try {
    const json = JSON.parse(value)
    if (Array.isArray(json)) {
      return json.map((l) => formatNestedValue(l))
    }
    return json
  } catch {
    return value
  }
}

const NestedControls = ({ currentLength, handleControls, index, max }) => (
  <div className="mb-3 d-flex flex-end" style={{ gap: '0.25rem' }}>
    <button
      className="btn btn-outline-secondary"
      onClick={(e) => handleControls(e, 'up', index)}
      aria-label="Move Up"
      disabled={index === 0}
      style={{ height: '38px' }}
    >
      <i className="fa fa-arrow-up" />
    </button>
    <button
      className="btn btn-outline-secondary"
      onClick={(e) => handleControls(e, 'down', index)}
      aria-label="Move Down"
      style={{ height: '38px' }}
      disabled={index === max}
    >
      <i className="fa fa-arrow-down" />
    </button>
    <button
      className="btn btn-danger"
      disabled={currentLength <= 1}
      onClick={(e) => handleControls(e, 'delete', index)}
      aria-label="Delete"
      style={{ height: '38px' }}
    >
      <i className="fa fa-times" />
    </button>
  </div>
)

const NestedInput = ({ items, type, wrapperClass, label, required, onChange }) => {
  const [inputs, setInputs] = useState(items)
  const [editIndex, setEditIndex] = useState<number | null>(null)
  const [editSubIndex, setSubIndex] = useState<number | null>(null)
  const [editValue, setEditValue] = useState('')

  const handleControls = (event, command, index) => {
    setInputs((prev) => {
      // edge cases
      if (index < 0 || index >= inputs.length) return prev
      if (command === 'up' && index === 0) return prev
      if (command === 'down' && index === inputs.length - 1) return prev

      // Moving item through array
      if (command === 'up' || command === 'down') {
        const newIndex = command === 'up' ? index - 1 : index + 1
        const newArray = [...inputs]

        // Remove the item from the original index
        const [item] = newArray.splice(index, 1)

        // Insert the item at the new index
        newArray.splice(newIndex, 0, item)
        return newArray
      }

      if (command === 'delete') {
        const newItems = prev.filter((_, i) => index !== i)
        return newItems
      }

      // default
      return prev
    })
  }

  const handleChange = (event, index, subIndex) => {
    event.stopPropagation()
    const { value } = event.target
    setEditIndex(index)
    setSubIndex(subIndex)
    setEditValue(formatNestedValue(value))
  }

  const handleSave = () => {
    if (!editIndex && typeof editIndex !== 'number') return
    setInputs((prev) =>
      prev.map((item, idx) => {
        if (idx !== editIndex) return item
        if (!Array.isArray(item)) return editValue
        item[editSubIndex] = editValue
        return item
      })
    )
    setEditIndex(null)
    setEditValue('')
  }

  const hasDuplicateKey = (key, currentIndex) => {
    return inputs.some((item, i) => Array.isArray(item) && item[0] === key && i !== currentIndex)
  }

  useEffect(() => {
    onChange({ target: { name: 'value', value: inputs } })
  }, [inputs])

  // Switching from array <-> object
  useEffect(() => {
    setInputs(items)
  }, [type])

  return (
    <div className="w-100 mb-3">
      {inputs.map((item, i) => {
        const values = Array.isArray(item) ? item : [item]
        return (
          <div
            key={`${item}-${i}`}
            className="d-flex align-items-end justify-content-between"
            style={{ gap: '0.5rem' }}
          >
            <div className="w-100 d-flex" style={{ gap: '0.25rem' }}>
              {values.map((value, j) => {
                const updateValue = editIndex === i && editSubIndex === j ? editValue : value
                const isDuplicate = j === 0 && hasDuplicateKey(value, i)
                return (
                  <div key={`${value}-${i}-${j}`} className="position-relative w-100">
                    <Input
                      wrapperClass={wrapperClass}
                      className={classNames('form-control string', isDuplicate && 'is-invalid')}
                      label={j === 0 && values.length > 1 ? 'Key' : label}
                      required={required}
                      value={
                        typeof updateValue === 'object' ? JSON.stringify(updateValue) : updateValue
                      }
                      onChange={(e) => handleChange(e, i, j)}
                      onBlur={() => handleSave()}
                      onKeyDown={(e) => e.key === 'Enter' && handleSave()}
                    />
                    {isDuplicate && (
                      <span
                        className="text-danger position-absolute px-0 px-lg-1"
                        style={{ bottom: 0, fontSize: 'xx-small' }}
                      >
                        Duplicate key detected. Please use unique keys.
                      </span>
                    )}
                  </div>
                )
              })}
            </div>
            <NestedControls
              handleControls={handleControls}
              currentLength={inputs.length}
              index={i}
              max={inputs.length - 1}
            />
          </div>
        )
      })}
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-outline-success"
          onClick={() => {
            const isObject = Array.isArray(inputs[0])
            setInputs((prev) => [...prev, isObject ? ['', ''] : ''])
          }}
          aria-label="Add another string to array"
          style={{ height: '38px' }}
        >
          <i className="fa fa-plus" />
        </button>
      </div>
    </div>
  )
}

/**
 * User inputs for the custom component props
 */
export const InputFields = ({
  type,
  wrapperClass,
  label,
  required,
  value,
  state,
  onChange,
  imageEndpoint,
}: CustomInputsProps) => {
  const [multiple, setMultiple] = useState(type === 'image' && Array.isArray(value))

  switch (type) {
    case 'array': {
      const items = value as unknown[]
      return (
        <div className="w-100 px-3 px-lg-0" key={items.join()}>
          <NestedInput
            type={type}
            items={items.flat()}
            wrapperClass={wrapperClass}
            label={label}
            required={required}
            onChange={onChange}
          />
        </div>
      )
    }
    case 'object':
      const typedValue = value as Record<string, unknown>
      const items = Object.entries(typedValue)
      const convertArrayBackToObject = (event) => {
        const updatedEvent = {
          target: { name: 'value', value: Object.fromEntries(event.target.value) },
        }
        onChange(updatedEvent)
      }
      return (
        <div className="d-flex flex-column position-relative w-100 px-3 px-lg-0" key={items.join()}>
          {/* @ts-ignore */}
          <NestedInput
            type={type}
            items={items}
            wrapperClass={wrapperClass}
            label={label}
            required={required}
            onChange={convertArrayBackToObject}
          />
          {/* In the case someone is messing with the JSON structure manually */}
          {typeof value !== 'object' && (
            <>
              <Input
                wrapperClass={wrapperClass}
                label={label}
                required={required}
                value={typeof value === 'object' ? JSON.stringify(value) : value}
                onChange={onChange}
              />
              <sup className="pl-1 text-danger">Invalid JSON structure.</sup>
            </>
          )}
        </div>
      )
    case 'number':
      return (
        <Input
          wrapperClass={wrapperClass}
          label={label}
          required={required}
          value={value}
          type="number"
          onChange={onChange}
        />
      )
    case 'boolean':
      return (
        <Select
          options={BOOLEAN}
          wrapperClass={wrapperClass}
          label={label}
          required={required}
          value={BOOLEAN.find((bool) => bool.value === value)}
          onChange={({ value }) => onChange({ target: { name: 'value', value } })}
          hint={undefined}
        />
      )
    case 'image':
      return (
        <div className="form-group w-100 position-relative">
          {/* @ts-ignore */}
          <Checkbox
            label="Use multiple images?"
            value={multiple}
            wrapperClass="mx-2 position-absolute"
            wrapperStyle={{ right: 0 }}
            onChange={() => {
              setMultiple((multiple) => !multiple)
              onChange({ target: { name: 'value', value: '' } })
            }}
          />
          {/* @ts-ignore */}
          <LabeledFileInput
            item={state}
            itemName="Image"
            file={{ url: value as string }}
            label="Image"
            accept="image/*"
            updateItem={({ Image }) => {
              const value = Array.isArray(Image) ? Image.map(({ url }) => url) : Image.url
              onChange({ target: { name: 'value', value } })
            }}
            imageEndpoint={imageEndpoint}
            multiple={multiple}
          />
        </div>
      )

    default:
      return (
        <div className="w-100 px-3 px-lg-0">
          {/* @ts-ignore */}
          <LabeledTextarea
            label={label}
            item={state}
            itemName="value"
            customOnChange={onChange}
            style={{ minHeight: '38px', height: '38px' }}
          />
        </div>
      )
  }
}

export const CustomProps = ({ editorApi, show }) => {
  const {
    checkForError,
    error,
    hasDuplicateKey,
    imageEndpoint,
    isRaw,
    parsedJson,
    removeField,
    showRaw,
    updateJson,
    updateRawJSON,
    updateState,
  } = useCustomProps()

  // Using a 'dummy' input so we avoid: Focus issues/Remounting/Un-intentional Stringify
  const [dummyJSON, setDummyJSON] = useState<RawProps[]>(parsedJson as RawProps[])

  // Update JSON editor props (this will remount the component essentially)
  const [update, setUpdate] = useState(false)

  // Whenever parsedJSON is updated e.g. when 'add additional field'
  useEffect(() => {
    setDummyJSON(parsedJson)
    setUpdate((v) => !v)
  }, [parsedJson])

  // Update user controlled JSON when the user switches tabs or closes the dialog
  useEffect(() => {
    if (showRaw && show) return
    setUpdate((v) => !v)
    updateRawJSON({ json: dummyJSON as unknown as Content })

    const updatedFormatting = dummyJSON.reduce((prev, { key, value }) => {
      prev[key] = Array.isArray(value) ? value.flat() : value
      return prev
    }, {})

    updateState({ rawProps: dummyJSON, customComponentProps: updatedFormatting })
  }, [showRaw, show])

  return (
    <FormGroup>
      {error && <Message className="w-100 my-2" severity="error" text={error} />}
      {/* Unmount so any updates using the JSON editor isn't parsed through the inputs */}
      {!showRaw && (
        <div className={classNames(showRaw && 'd-none', 'pr-3')}>
          {Array.isArray(parsedJson) &&
            parsedJson.map(({ key, value, type }, i) => {
              const isDuplicate = hasDuplicateKey(key, i)
              return (
                <div className="row flex-wrap flex-lg-nowrap" key={`custom-props-index-${i}`}>
                  <div className="form-group col-12 col-lg-2">
                    <Input
                      wrapperClass="mb-0"
                      className={classNames(
                        'form-control string required',
                        isDuplicate && 'is-invalid'
                      )}
                      label="Key"
                      required
                      value={key}
                      onChange={(e) => updateJson(e, i)}
                    />
                    {isDuplicate && (
                      <span
                        className="text-danger px-0"
                        style={{ bottom: 0, fontSize: 'xx-small' }}
                      >
                        Duplicate key.
                      </span>
                    )}
                  </div>
                  <div className="form-group col-12 col-lg-2 pl-3 pl-lg-0 d-flex flex-column">
                    <Label additionalClasses="mb-0" required label="Type" />
                    <TreeSelect
                      value={type}
                      options={OPTIONS_TREE}
                      filter
                      onChange={({ value }) => {
                        // @ts-ignore missing the rest of HTMLInput structure
                        updateJson({ target: { name: 'type', value } }, i)
                      }}
                      style={{ height: '38px', marginTop: '8px' }}
                      placeholder="Please select a Type"
                    />
                  </div>
                  <InputFields
                    type={type}
                    wrapperClass="form-group w-100"
                    label="Value"
                    required
                    value={value}
                    onChange={(e) => updateJson(e, i)}
                    state={{ key, value, type }}
                    imageEndpoint={imageEndpoint}
                    editorApi={editorApi}
                  />
                  <div className="col-12 col-lg-1 d-flex justify-content-end pt-lg-4 flex-end">
                    <button
                      style={{ height: '38px', marginTop: '8px' }}
                      type="button"
                      className="w-100 btn btn-sm btn-outline-danger"
                      onClick={() => removeField(i)}
                    >
                      <i className="fa fa-times" />
                    </button>
                  </div>
                  <hr className="w-100 b-1 border-bottom d-block d-lg-none mx-3" />
                </div>
              )
            })}
        </div>
      )}
      {showRaw && (
        <JSONEditorReact
          id="customComponentProps"
          className="pr-3"
          mode={Mode.text}
          // @ts-ignore it's complaining that we're updating both fields
          content={{
            text: error || isRaw ? JSON.stringify(dummyJSON) : undefined,
            json: !isRaw ? dummyJSON : undefined,
          }}
          // This annoyingly returns under content.text when a user is directly modifying (even valid json)
          // but under .json when programmatically updated
          onChange={(content: OldProps, _, status) => {
            const updatedJSON = checkForError(content, { status })
            setDummyJSON(updatedJSON)
          }}
          updateProps={update}
          editorApi={editorApi}
        />
      )}
    </FormGroup>
  )
}
