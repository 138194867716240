// components/DataAccuracy.tsx
import React from 'react'

const DataAccuracy: React.FC = () => {
  return (
    <div className=" box py-3 px-3 round-corners">
      <h4>*Data Accuracy</h4>
      <p className="text-secondary">
        Please note, marketing source on leads is derived from the UTM parameters of the website
        lead URL (e.g., "fbclid" in the URL). If the lead has no UTM parameters, it will not be
        included in this report. In some cases, certain apps block UTM parameters, which will also
        result in the lead not being included in this report.
      </p>
      <p className="text-secondary">
        Additionally, if a customer enters from an ad in one session but converts in another
        session, this user is also not tracked.
      </p>
      <p className="text-secondary">
        Thus, the data reported here is likely an{' '}
        <b>underestimation of the actual leads generated from a third-party marketing source.</b>
      </p>
      <p className="text-secondary">Additional UTM tags you can set up:</p>
      <ul className="text-secondary">
        <li>utm_source=GMB (Google My Business)</li>
        <li>utm_source=Carsales</li>
        <li>utm_source=sms</li>
        <li>utm_source=email</li>
        <li>utm_source=qr_code</li>
      </ul>
    </div>
  )
}

export default DataAccuracy
