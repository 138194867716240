import { useParams } from 'react-router-dom'
import { useRef, useState } from 'react'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'

import { fetchOnClient, useQuery } from '../tanstackPlaceholderUtils'
import * as Routes from '../../routes'
import { formatSimpleDuration } from '../entries/timeAgo'

import type { Checkout } from './CheckoutTypes'

import PricingSummary from './PricingSummary'
import { ListFromObject } from './ListFromObject'

const Checkouts = () => {
  const { dealershipSlug } = useParams()
  const notification = useRef(null)
  const [selectedCheckout, setSelectedCheckout] = useState<Checkout | null>(null)
  const [selectedPricingSummary, setSelectedPricingSummary] = useState<Checkout | null>(null)

  const { data, loading, error, revalidate } = useQuery<Checkout[]>({
    query: fetchOnClient,
    queryKey: `${Routes.dealership_checkouts_path(dealershipSlug)}.json`,
  })

  if (error) {
    return (
      <div className="p-3">
        <h2>An error occurred</h2>
        <p className="text-danger">{error?.message}</p>
      </div>
    )
  }

  const checkouts = data ?? []

  const dateTemplate = (rowData: Checkout, field: 'created_at' | 'updated_at') => {
    const date = new Date(rowData[field])
    const now = new Date()
    return formatSimpleDuration(date, now) + ' ago'
  }

  const markCheckoutComplete = async (checkout: Checkout) => {
    const res = await fetch(Routes.complete_dealership_checkout_path(dealershipSlug, checkout.id), {
      method: 'PUT',
    })
    if (res.ok) {
      notification.current.show({
        severity: 'success',
        summary: 'Checkout Marked Complete',
      })
      revalidate()
    } else {
      notification.current.show({
        severity: 'error',
        summary: 'Unable To Mark Complete',
        detail: `A server error occurred when marking the checkout as complete: ${res.statusText}`,
      })
    }
  }

  return (
    <div className="p-5">
      <Toast ref={notification} />
      <ConfirmDialog />
      <div className="px-3 py-1">
        <div className="row">
          <div className="col-md-12">
            <div className="mb-3">
              <h2>Checkouts</h2>
              <p>View and manage customer checkouts</p>
            </div>

            <DataTable value={checkouts} loading={loading}>
              <Column
                field="contact"
                header="Contact"
                body={(rowData) => (
                  <a href={`${Routes.dealership_contact_path(dealershipSlug, rowData.contact_id)}`}>
                    {rowData.contact_id}
                  </a>
                )}
              />
              <Column
                field="created_at"
                header="Started"
                body={(rowData) => dateTemplate(rowData, 'created_at')}
              />
              <Column
                field="updated_at"
                header="Last Updated"
                body={(rowData) => dateTemplate(rowData, 'updated_at')}
              />
              <Column
                field="formdata.progress"
                header="Progress"
                body={(rowData) => (
                  <button
                    className="btn btn-outline-primary btn-sm"
                    style={{ borderRadius: '999px' }}
                    onClick={() => setSelectedCheckout(rowData)}
                  >
                    {rowData?.formdata?.progress ?? 0}% <i className="fas fa-eye"></i>
                  </button>
                )}
              />
              <Column
                field="completed"
                header="Completed"
                body={(rowData) => {
                  if (rowData.completed) {
                    return (
                      <div className="text-center">
                        <i
                          className={`fas ${rowData.completed ? 'fa-check text-success' : 'fa-times text-danger'}`}
                        ></i>
                      </div>
                    )
                  }
                  return (
                    <button
                      className="btn btn-outline-secondary btn-sm"
                      style={{ borderRadius: '999px' }}
                      onClick={() =>
                        confirmDialog({
                          message:
                            'Are you sure you want to mark this checkout as complete? This action cannot be undone.',
                          header: 'Mark Checkout Complete',
                          icon: 'pi pi-exclamation-triangle',
                          accept: () => markCheckoutComplete(rowData),
                        })
                      }
                    >
                      Mark Complete
                    </button>
                  )
                }}
              />
              <Column
                field="valuation"
                header="Valuation"
                body={(rowData) => {
                  if (!rowData.valuation?.make || !rowData.valuation?.model) {
                    return 'N/A'
                  }
                  return (
                    <a
                      href={`${Routes.dealership_valuation_path(dealershipSlug, rowData.valuation_id)}`}
                    >
                      {rowData.valuation?.make} {rowData.valuation?.model} -
                      {rowData.valuation?.offer_price > 0
                        ? `$${rowData.valuation?.offer_price.toLocaleString()}`
                        : 'N/A'}
                    </a>
                  )
                }}
              />
              <Column
                field="car.name"
                header="Car"
                body={(rowData) => <a href={`/cars/${rowData.car.slug}`}>{rowData.car.name}</a>}
              />
              <Column field="website.name" header="Website" />
              <Column
                field="formdata"
                header="Pricing Summary"
                body={(rowData) => (
                  <button
                    className="btn btn-outline-primary btn-sm"
                    style={{ borderRadius: '999px' }}
                    onClick={() => setSelectedPricingSummary(rowData)}
                  >
                    View Details <i className="fas fa-eye"></i>
                  </button>
                )}
              />
            </DataTable>
            <Dialog
              header="Checkout Form Data"
              visible={selectedCheckout !== null}
              style={{ width: '50vw' }}
              onHide={() => setSelectedCheckout(null)}
            >
              <ListFromObject fields={selectedCheckout?.formdata ?? {}} />
            </Dialog>
            <Dialog
              header="Pricing Summary"
              visible={selectedPricingSummary !== null}
              style={{ width: '50vw' }}
              onHide={() => setSelectedPricingSummary(null)}
            >
              <PricingSummary
                dealershipSlug={dealershipSlug}
                checkoutId={selectedPricingSummary?.id}
              />
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Checkouts
