import React, { createContext, useState, ReactNode } from 'react'
import { ModuleItem, SetState } from '../types'

type PreviewDataContextType = {
  editingPreviewData: { key: ModuleItem[] } | null
  setEditingPreviewData: SetState<{ key: ModuleItem[] } | null>
  editingPreviewTotal: EditingPreviewTotal | null
  setEditingPreviewTotal: SetState<EditingPreviewTotal | null>
}

export type EditingPreviewTotal = {
  key: Module
}

type Module = {
  total: number | string | null
  previousPeriod: number | string | null
  lastYear: number | string | null
}

export const PreviewDataContext = createContext<PreviewDataContextType>({
  editingPreviewData: null,
  setEditingPreviewData: () => {},
  editingPreviewTotal: null,
  setEditingPreviewTotal: () => {},
})

export const PreviewDataProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [editingPreviewData, setEditingPreviewData] = useState<{ key: ModuleItem[] } | null>(null)
  const [editingPreviewTotal, setEditingPreviewTotal] = useState<EditingPreviewTotal>(null)
  return (
    <PreviewDataContext.Provider
      value={{
        editingPreviewData,
        setEditingPreviewData,
        editingPreviewTotal,
        setEditingPreviewTotal,
      }}
    >
      {children}
    </PreviewDataContext.Provider>
  )
}
