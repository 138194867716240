import { DataTable } from 'primereact/datatable'
import React from 'react'
import { Link } from 'react-router-dom'
import { useViewportData } from '../../../hooks'
import { ModuleProps } from '../../../types'
import { Column } from 'primereact/column'

export type CarStatsByDealership = {
  id: number
  name: string
  cars_count: number
  leads_count: number
  leads_per_car: number
  has_leads_and_cars: boolean
}

const CarsStatsByDealership: React.FC<ModuleProps> = ({ timeRange, item, selectedLocation }) => {
  const { data, loading, viewportRef } = useViewportData(item?.module, timeRange, selectedLocation)

  const DealershipName = (rowData: CarStatsByDealership) => (
    <Link to={`/dealerships/${rowData.id}`}>{rowData?.name}</Link>
  )
  const CarsInStock = (rowData: CarStatsByDealership) => (
    <Link to={`/dealerships/${rowData?.id}/cars`}>{rowData?.cars_count} Cars</Link>
  )

  const LeadsPerCar = (rowData: CarStatsByDealership) => {
    if (rowData?.has_leads_and_cars) {
      return `${rowData?.leads_per_car} Leads/car`
    }
    return null
  }

  if (loading) return null

  return (
    <div className="h-100" style={{ minHeight: 'inherit' }} ref={viewportRef}>
      <DataTable value={data?.[item?.module]}>
        <Column header="Dealerships" body={DealershipName} />
        <Column header="Car In Stock" body={CarsInStock} />
        <Column header="Leads per No of Cars" body={LeadsPerCar} />
      </DataTable>
    </div>
  )
}

export default CarsStatsByDealership
