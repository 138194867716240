import { FormGroup } from './FormGroup'
import InputLabel from './InputLabel'
import type { DefaultInputProps } from '../types'

export const LabeledColorInput = ({
  controlled = true,
  item,
  itemName,
  updateItem,
  label,
  placeholder,
  color,
  disabled = false,
  inputClassName,
  tooltip,
  customOnChange,
}: DefaultInputProps & { placeholder?: string; color?: string }) => {
  return (
    <FormGroup>
      <InputLabel label={label} itemName={itemName} tooltip={tooltip} />
      <div className="d-flex flex-row">
        <input
          id={itemName}
          placeholder={placeholder || 'Enter a color (hexcode & rgb are accepted)'}
          className={`form-control flex-grow-1 mr-1 ${inputClassName}`}
          type="text"
          value={controlled ? item[itemName] : undefined}
          defaultValue={!controlled ? item[itemName] : undefined}
          onChange={(e) =>
            customOnChange ? customOnChange(e) : updateItem({ ...item, [itemName]: e.target.value })
          }
          disabled={disabled}
        />
        <div
          className="rounded border"
          style={{
            height: 38,
            width: 38,
            backgroundColor: color,
          }}
        ></div>
      </div>
    </FormGroup>
  )
}
