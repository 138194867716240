import Loading from '../../Loading'
import { useAnalyticsDashboard } from '../contexts/hooks'

const LoadingWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { analyticsBlockLoading, dashboardLevelLoaded } = useAnalyticsDashboard()

  if (analyticsBlockLoading && !dashboardLevelLoaded) {
    return (
      <div className="d-flex justify-content-center mt-5">
        <Loading />
      </div>
    )
  }

  return <> {children} </>
}

export default LoadingWrapper
