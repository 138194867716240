import { useState, useEffect, useContext } from 'react'
import { useParams, Link } from 'react-router-dom'
import { useFetchManufacturer } from '../dataHooks'
import * as Routes from '../../routes'
import { CurrentUserContext } from '../contexts'

const Anchor = ({ to, children, className }) => (
  <a href={to} className={className}>
    {children}
  </a>
)

const NavigationLinks = () => {
  let { manufacturerSlug } = useParams()
  let currentUser = useContext(CurrentUserContext)

  let [links, setLinks] = useState([])

  useEffect(() => {
    fetch(Routes.sidemenu_links_manufacturer_path(manufacturerSlug), {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setLinks(data.links)
      })
  }, [])

  return (
    <nav className="navbar navbar-expand-md navbar-light bg-light border-top border-bottom">
      <div className="container">
        <ul className="navbar-nav mr-auto">
          {links?.map((link, index) => {
            let LinkComponent = link.reactRouter ? Link : Anchor

            return (
              <li key={index} className="nav-item">
                <LinkComponent to={link.path} className="nav-link">
                  {link.text}
                </LinkComponent>
              </li>
            )
          })}
        </ul>
        {currentUser?.admin && (
          <ul className="navbar-nav">
            <li className="nav-item dropdown">
              <a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown">
                Actions
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                <a href={`/manufacturers/${manufacturerSlug}/edit`} className="dropdown-item">
                  <i className="fa fa-edit" /> Edit Manufacturer
                </a>
                <a
                  href={`/manufacturers/${manufacturerSlug}/reindex_models`}
                  className="dropdown-item"
                  data-method="post"
                >
                  <i className="fa fa-recycle" /> Reindex Models
                </a>
                <a
                  href={`/manufacturers/${manufacturerSlug}/model_specification_categories`}
                  className="dropdown-item"
                >
                  <i className="fa fa-edit" /> Specification Categories
                </a>
                <a
                  href={`/manufacturers/${manufacturerSlug}/scheduled_services`}
                  className="dropdown-item"
                >
                  Scheduled Services
                </a>
                <a
                  href={`/manufacturers/${manufacturerSlug}/refresh_redbook_data`}
                  className="dropdown-item"
                  data-method="post"
                >
                  <i className="fa fa-refresh" /> Fetch Redbook Codes
                </a>
              </div>
            </li>
          </ul>
        )}
      </div>
    </nav>
  )
}

const ManufacturerNav = () => {
  let { manufacturer } = useFetchManufacturer()

  return (
    <>
      <div className="banner text-center py-5 bg-light">
        {manufacturer?.logo?.url ? (
          <img
            src={manufacturer?.logo?.url}
            height="100"
            style={{ maxWidth: '200px', objectFit: 'contain' }}
          />
        ) : (
          <h1 className="m-0">{manufacturer?.name}</h1>
        )}
      </div>
      <NavigationLinks manufacturer={manufacturer} />
    </>
  )
}

export default ManufacturerNav
