import React from 'react'

import * as Routes from '../../routes'
import { Car } from './types'
import { humanize } from '../entries/utils'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Link } from 'react-router-dom'

const Status = ({ status }: { status: string }) => {
  const color = status === 'available' ? 'success' : 'danger'
  return <div className={`text-${color}`}>{humanize(status)}</div>
}

interface WatchlistSectionProps {
  cars: Car[]
}

const Image = ({ primary_image_url }: { primary_image_url: string }) => {
  if (!primary_image_url) return null

  return <img src={primary_image_url} alt="Car" style={{ width: '50px' }} className="rounded" />
}

const WatchlistSection: React.FC<WatchlistSectionProps> = ({ cars }) => (
  <div className="box mb-3">
    <div className="p-3">
      <h4>
        <i className="fa fa-heart" /> Watchlist
      </h4>
    </div>
    <DataTable value={cars}>
      <Column field="primary_image_url" header="Image" body={Image} />
      <Column
        field="name"
        header="Car"
        body={(car) => <Link to={Routes.car_path(car)}>{car.name}</Link>}
      />
      <Column
        field="price"
        header="Price"
        body={({ price }) =>
          price?.toLocaleString('en-US', {
            maximumFractionDigits: 0,
            currencyDisplay: 'narrowSymbol',
            style: 'currency',
            currency: 'AUD',
          })
        }
      />
      <Column field="status" header="Status" body={Status} />
    </DataTable>
  </div>
)

export default WatchlistSection
