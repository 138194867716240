import EditorJsRenderer from '../EditorJsRenderer'
import { generateRandomId } from '../../commonUtils'
import type { ColumnsBlockProps, SelectOption } from '../../../types'

const ColumnsBlock = ({
  block,
  locationOptions,
}: {
  block: ColumnsBlockProps
  locationOptions: SelectOption[]
}) => (
  <div className="container">
    <div className="row justify-content-center">
      {block?.cols?.map((col: any, index: number) => (
        <div
          key={`col-${index}-${generateRandomId(5)}`}
          className={`col-12 mb-3 ${
            block?.cols?.length === 2 ? 'col-md-6 mb-md-0' : 'col-lg-4 mb-lg-0'
          }`}
        >
          <EditorJsRenderer data={{ blocks: col.blocks }} locationOptions={locationOptions ?? []} />
        </div>
      ))}
    </div>
  </div>
)

export default ColumnsBlock
