import { FormGroup } from './FormGroup'
import InputLabel from './InputLabel'
import type { DefaultInputProps } from '../types'

export const LabeledInput = ({
  controlled = true,
  item,
  itemName,
  updateItem,
  label,
  placeholder,
  type = 'text',
  disabled = false,
  inputClassName,
  customOnChange,
  tooltip,
}: DefaultInputProps & { placeholder?: string; type?: string }) => {
  return (
    <FormGroup>
      <InputLabel label={label} itemName={itemName} tooltip={tooltip} />
      <input
        id={itemName}
        placeholder={placeholder || `Enter ${itemName}...`}
        className={`form-control ${inputClassName}`}
        type={type}
        value={controlled ? item[itemName] : undefined}
        defaultValue={!controlled ? item[itemName] : undefined}
        onChange={(e) =>
          customOnChange ? customOnChange(e) : updateItem({ ...item, [itemName]: e.target.value })
        }
        disabled={disabled}
      />
    </FormGroup>
  )
}
