import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Dialog } from 'primereact/dialog'
import HookFormField from '../shared/hookFormField'
import { FormContext } from '../contexts'
import { standardHeaders } from '../entries/utils'
import { useNavigate, useParams } from 'react-router-dom'

let attributes = {
  first_name: { type: 'text', label: 'First Name', required: true },
  last_name: { type: 'text', label: 'Last Name', required: true },
  email: { type: 'text', label: 'Email Address', required: true },
  business_name: { type: 'text', label: 'Business Name', required: false },
  phone_number: { type: 'phone', label: 'Phone Number', required: true },
}

let formData = {}

const ContactForm = ({ contact, visible, setVisible }) => {
  let navigate = useNavigate()
  let [errors, setErrors] = useState({})
  let [loading, setLoading] = useState(false)
  let { dealershipSlug } = useParams()

  const { control, handleSubmit } = useForm({
    defaultValues: {
      first_name: contact?.first_name || '',
      last_name: contact?.last_name || '',
      email: contact?.email || '',
      phone_number: contact?.phone_number || '',
      business_name: contact?.business_name || '',
    },
  })

  const onSubmit = (data) => {
    setLoading(true)

    fetch(`/dealerships/${dealershipSlug}/contacts`, {
      method: 'POST',
      headers: standardHeaders,
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((res) => {
        navigate(`/dealerships/${dealershipSlug}/contacts/${res.id}`)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error:', error)
        setLoading(false)
      })
  }

  return (
    <FormContext.Provider value={{ control, errors, attributes, formData }}>
      <Dialog
        header="Add Contact"
        visible={visible}
        style={{ width: '50vw' }}
        onHide={() => setVisible(false)}
        dismissableMask={true}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-6">
              <HookFormField attribute="first_name" />
            </div>
            <div className="col-md-6">
              <HookFormField attribute="last_name" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <HookFormField attribute="email" />
            </div>
            <div className="col-md-6">
              <HookFormField attribute="business_name" />
            </div>
          </div>
          <HookFormField attribute="phone_number" />
          <button className="btn btn-block btn-outline-success" type="submit">
            {loading ? 'Loading...' : 'Create Contact'}
          </button>
        </form>
      </Dialog>
    </FormContext.Provider>
  )
}

export default ContactForm
