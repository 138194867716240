import { CSSProperties, Dispatch, PropsWithChildren, SetStateAction } from 'react'
import { LeftArrow, RightArrow } from './Icons'

const ArrowButton = ({
  type,
  onClick,
  disabled,
}: {
  type: string
  onClick: () => void
  disabled: boolean
}) => {
  const sharedStyles: CSSProperties = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    opacity: disabled ? 0.5 : 1,
  }
  return (
    <div
      className="btn btn-primary"
      onClick={onClick}
      style={
        type === 'next'
          ? {
              ...sharedStyles,
              right: '2%',
            }
          : {
              ...sharedStyles,
              left: '2%',
            }
      }
    >
      {type === 'next' ? <RightArrow /> : <LeftArrow />}
    </div>
  )
}

const CarouselWrapper = ({
  activeIndex,
  setActiveIndex,
  max,
  loop = true,
  hideButtons = false,
  children,
}: {
  activeIndex: number
  setActiveIndex: Dispatch<SetStateAction<number>>
  max: number
  loop?: boolean
  hideButtons?: boolean
} & PropsWithChildren) => {
  return (
    <div className="w-100 position-relative">
      {children}
      {!hideButtons && (
        <>
          <ArrowButton
            type="prev"
            disabled={!loop && activeIndex === 0}
            onClick={() =>
              activeIndex === 0
                ? loop
                  ? setActiveIndex(max)
                  : setActiveIndex(activeIndex) // i.e. do nothing
                : setActiveIndex(activeIndex - 1)
            }
          />
          <ArrowButton
            type="next"
            disabled={!loop && activeIndex === max}
            onClick={() =>
              activeIndex === max
                ? loop
                  ? setActiveIndex(0)
                  : setActiveIndex(activeIndex) // i.e. do nothing
                : setActiveIndex(activeIndex + 1)
            }
          />
        </>
      )}
    </div>
  )
}

export default CarouselWrapper
