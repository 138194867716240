import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as Routes from '../../routes'

import TimeRangePicker from '../entries/TimeRange'
import { TimeRange, User } from '../AnalyticsDashboard/types'
import ChartSection from './components/ChartSection'
import { fetchResultData } from '../AnalyticsDashboard/utils'
import SelectLocations from '../AnalyticsDashboard/features/DashboardSettings/components/SelectLocations'
import { CurrentUserContext } from '../contexts'
import { showLoading } from '../entries/utils'

const QrCodeAnalytics: React.FC = () => {
  const { dealershipSlug } = useParams()
  const currentUser = useContext(CurrentUserContext) as User
  const [loading, setLoading] = useState<boolean>(true)
  const [qrData, setQrData] = useState<any>(null)
  const [timeRange, setTimeRange] = useState<TimeRange>({
    start: 0,
    end: 0,
    timeframe: '',
    type: '',
  })
  const [selectedLocation, setSelectedLocation] = useState<any | null>(null)

  const subText = (
    <ul className="mt-3">
      <li>
        {qrData?.total_count} total views in the {timeRange.timeframe || 'last 30 days'}
      </li>
    </ul>
  )

  useEffect(() => {
    if (!dealershipSlug) return

    const fetchQRData = async () => {
      setLoading(true)
      try {
        const locationId =
          selectedLocation?.id && selectedLocation.id > 0 ? selectedLocation.id : null
        const queryString = new URLSearchParams({
          start: timeRange.start.toString(),
          end: timeRange.end.toString(),
          timeframe: timeRange.timeframe,
          type: timeRange.type,
          ...(locationId && { location_id: locationId.toString() }),
        }).toString()
        const fetchUrl = `${Routes.analytics_dealership_qr_codes_path(dealershipSlug)}.json?${queryString}`
        const data = await fetchResultData(fetchUrl)
        setQrData(data)
      } catch (err) {
        setLoading(false)
        throw err
      } finally {
        setLoading(false)
      }
    }

    fetchQRData()
  }, [dealershipSlug, timeRange, selectedLocation])

  const handleTimeRangeChange = (dateRange: TimeRange): void => {
    setTimeRange(dateRange)
  }
  const handleUpdateSelectedLocation = (newLocation: any) => setSelectedLocation(newLocation)

  return (
    <div className="p-4 w-100">
      <div className="row">
        <h4 className="col-6">QR Code Analytics</h4>
        <div className="col-6 d-flex justify-content-end">
          <SelectLocations
            handleUpdateSelectedLocation={handleUpdateSelectedLocation}
            currentUser={currentUser}
            analyticLocations={qrData?.locations}
          />
          <TimeRangePicker handleUpdateTimeRange={handleTimeRangeChange} />
        </div>
      </div>

      {qrData && !loading ? (
        <div>
          <div className="row">
            <div className="col-md-6">
              <ChartSection
                title="QR Code Views"
                subtitle={timeRange?.timeframe || 'Last 30 days'}
                chartData={qrData?.views}
                chartType="Bar"
                subText={subText}
              />
            </div>
            <div className="col-md-6">
              <ChartSection
                title="QR Code Views by Location"
                subtitle={timeRange?.timeframe || 'Last 30 days'}
                chartData={qrData?.per_location}
                chartType="Pie"
              />
            </div>
          </div>
        </div>
      ) : (
        showLoading()
      )}
    </div>
  )
}

export default QrCodeAnalytics
