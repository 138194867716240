import React, { useCallback, useState, useEffect } from 'react'
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch'
import { InstantSearch, Configure } from 'react-instantsearch'
import { indexName } from '../../../cars/config'
import { handleEmbeddedModal, htmlDecode, renderHiddenModalButton } from '../../common/commonUtils'
import Dialog from '../../common/Dialog'
import CarsPreview from './CarsPreview'
import { MultiSelectFilters, DefaultFilters, CustomisationsAndSorting } from './CarsToolInputs'

const MultiSelectToggle = ({ state, syncedStateUpdate }) => (
  <>
    <input
      id="multiSelect"
      type="checkbox"
      className="form-check-input hidden"
      checked={undefined}
      defaultChecked={state.multiSelect}
      onChange={(e) => {
        syncedStateUpdate({ ...state, multiSelect: e.target.checked })
      }}
      style={{ visibility: 'hidden' }}
    />
    <label
      htmlFor="multiSelect"
      className={`btn ${!state.multiSelect ? 'btn-success' : 'btn-danger'} btn-sm mb-0`}
    >
      {!state.multiSelect ? 'Use Multi-Select' : 'Use Single-Select'}
    </label>
  </>
)

const initialState = (data) => {
  return {
    multiSelect: data.multiSelect,
    searchState: data.searchState,
    dateFilter: data.dateFilter,
    hideButton: data.hideButton,
    hideSearch: data.hideSearch,
    heading: data.heading,
    background: data.background,
    buttonColor: data.buttonColor,
  }
}

const RenderedCarsComponent = ({ onDataChange, data, uniqueId, templateType }) => {
  const [show, setShow] = useState(false)
  const [state, setState] = useState(initialState(data))

  const meilisearchHost = document.getElementById('meilisearch-host').getAttribute('value')
  const meilisearchApiKey = document.getElementById('meilisearch-api-key').getAttribute('value')

  const { searchClient } = instantMeiliSearch(meilisearchHost, meilisearchApiKey)

  useEffect(() => {
    handleEmbeddedModal(uniqueId, show)
  }, [show])

  /**
   * Update both the React state and the Editor.js data
   */
  const syncedStateUpdate = useCallback(
    (item) => {
      setState({
        ...item,
      })
      const v6Item = {
        ...item,
        searchState: item.searchState[Object.keys(item.searchState)[0]],
      }
      onDataChange(v6Item) // Editor.js must receive v6 format for backwards compatibility
    },
    [onDataChange]
  )

  function onSearchStateChange(updatedSearchState) {
    syncedStateUpdate({ ...state, searchState: updatedSearchState })
  }

  function onDateFilterChange(updatedDateFilter) {
    syncedStateUpdate({ ...state, dateFilter: updatedDateFilter })
  }

  let filters = ''

  if (window.search_filters) {
    filters = search_filters.replaceAll('&#39;', '"')
    filters = htmlDecode(filters)
  }

  const editor = document.getElementById('editorjs')
  const created_at_options = editor.getAttribute('created_at_options')
  const dateOptions = JSON.parse(created_at_options)

  const { searchState, dateFilter } = state

  if (dateFilter) {
    let maxDate = dateOptions.filter((d) => d.label === dateFilter)[0].start
    if (maxDate) {
      filters += ` AND created_at_timestamp < ${maxDate}`
    }
  }

  return (
    <InstantSearch
      indexName={indexName}
      searchClient={searchClient}
      onStateChange={({ uiState }) => {
        onSearchStateChange(uiState)
      }}
      initialUiState={searchState}
    >
      <Configure filters={filters} />
      <CarsPreview state={state} />
      <Dialog title="Cars" show={show} closeClickHandler={() => setShow(false)} forceMount>
        <div className="border-bottom pb-3">
          <div className="row">
            <div className="col-12 col-xl-7 text-secondary">
              Apply filters to customise the cars displayed in this block. By default, multi-select
              will be used which allows users to select multiple filters. You can change this to
              single-select if you prefer.
            </div>
            <div className="col-12 col-xl-5">
              <div className="d-flex justify-content-end mt-2 mt-xl-0">
                {/* Allows updating of data through form input */}
                <MultiSelectToggle state={state} syncedStateUpdate={syncedStateUpdate} />
              </div>
            </div>
          </div>
        </div>
        <CustomisationsAndSorting
          state={state}
          syncedStateUpdate={syncedStateUpdate}
          templateType={templateType}
        />
        <div className={`pt-3 ${templateType === 'search' ? '' : 'mt-3 border-top'}`}>
          <h6>Filters</h6>
          {state.multiSelect ? (
            <MultiSelectFilters
              dateFilter={dateFilter}
              setDateFilter={onDateFilterChange}
              dateOptions={dateOptions}
            />
          ) : (
            <DefaultFilters
              data={state}
              dateFilter={dateFilter}
              setDateFilter={onDateFilterChange}
              dateOptions={dateOptions}
            />
          )}
        </div>
      </Dialog>
      {/* Hidden button that handles opening the settings modal */}
      {renderHiddenModalButton(uniqueId, setShow)}
    </InstantSearch>
  )
}

const CarsViewer = ({ onDataChange, data, uniqueId, templateType }) => {
  // The JSON structure in the page templates for instant search is still from version 6
  // so we apply the v7 format to it to keep Meilisearch happy but we have to save it back to
  // the v6 format
  const convertedData = {
    ...data,
    searchState: {
      [`Car_${window.environment}`]: data.searchState,
    },
  }

  return (
    <RenderedCarsComponent
      onDataChange={onDataChange}
      data={convertedData}
      uniqueId={uniqueId}
      templateType={templateType}
    />
  )
}

export default CarsViewer
