export const textAlignment = {
  left: 'text-left',
  center: 'text-center',
  right: 'text-right',
}

export function getAlignment(alignment: string) {
  return textAlignment[alignment] || ''
}

export function getClassName(className: string) {
  return className ? className : ''
}

export function getMargin(
  spacingTop: string | number | boolean,
  spacingBottom: string | number | boolean
) {
  return `${
    spacingTop
      ? typeof spacingTop === 'boolean'
        ? 'mt-5' // Apply a fixed margin class if 'spacingTop' is a boolean.
        : `mt-${spacingTop}` // Apply a dynamic margin class based on 'spacingTop'.
      : ''
  } ${
    spacingBottom
      ? typeof spacingBottom === 'boolean'
        ? 'mb-5' // Apply a fixed margin class if 'spacingBottom' is a boolean.
        : `mb-${spacingBottom}` // Apply a dynamic margin class based on 'spacingBottom'.
      : ''
  }`
}

export function getPadding(
  paddingTop: string | number | boolean,
  paddingBottom: string | number | boolean
) {
  const top = paddingTop ? `pt-${paddingTop}` : ''
  const bottom = paddingBottom ? `pb-${paddingBottom}` : ''
  return `${top} ${bottom}`
}

export function getColor(color: string, customColor: string) {
  return color
    ? color === 'none'
      ? undefined // No color if 'color' is 'none'.
      : color === 'inherit'
        ? 'inherit'
        : color === 'custom'
          ? customColor // Use custom background color if 'color' is 'custom'.
          : `var(--${color})` // Use a CSS variable for color.
    : undefined
}

export function getScreenSize(screenSize: string) {
  let storeScreenSize = ''
  switch (screenSize) {
    case 'desktop':
      storeScreenSize = 'd-none d-lg-block' // Hide on mobile, show on desktop.
      break
    case 'mobile':
      storeScreenSize = 'd-sm-none' // Hide on small screens.
      break
    case 'mobile-tablet':
      storeScreenSize = 'd-lg-none' // Hide on large screens.
      break
    default:
      break // No specific hiding class if 'screensize' is not specified.
  }

  return storeScreenSize
}

/** Remove HTML entities so that tags like `<sup>` work */
export function cleanHtml(text: string) {
  return !text ? '' : text.replace(/&lt;/g, '<').replace(/&gt;/g, '>')
}
