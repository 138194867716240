import React from 'react'

import type { CompletedCheckout } from './types'

interface CompletedCheckoutsSectionProps {
  completedCheckouts: CompletedCheckout[]
}

const CompletedCheckoutsSection: React.FC<CompletedCheckoutsSectionProps> = ({
  completedCheckouts,
}) => (
  <div className="box mb-3">
    <div className="p-3">
      <h4>
        <i className="fa fa-envelope mr-1" /> Completed Checkouts
      </h4>
    </div>
    <table className="table">
      <thead>
        <tr>
          <th>Completed At</th>
          <th>Purchased Car</th>
          <th>Valuation</th>
        </tr>
      </thead>
      <tbody>
        {completedCheckouts.map((checkout) => (
          <tr key={checkout.id}>
            <td>{new Date(checkout.created_at).toLocaleString()}</td>
            <td>{checkout.car.name}</td>
            <td>
              {checkout.valuation?.make} {checkout.valuation?.model} -
              {checkout.valuation?.offer_price > 0
                ? `$${checkout.valuation?.offer_price.toLocaleString()}`
                : 'N/A'}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)

export default CompletedCheckoutsSection
