import { format } from 'date-fns'
import { useContext, useEffect, useState } from 'react'
import { TrixEditor } from 'react-trix'

import showToast from './../shared/ShowToast'
import { getInitials, standardHeaders } from '../entries/utils'
import { ownerContext } from '../leads/utils'
import { useFetchDealership } from './../dataHooks'
import { CurrentUserContext, LeadClusterContext } from '../contexts'
import Templates from './emailTemplates/Templates'

const AvatarInitials = ({ name }) => {
  let initials = getInitials(name)
  return <div className={`d-none d-md-flex AvatarInitials mr-3`}>{initials}</div>
}

const Message = ({ leadCluster, leadClusterEmail, toDealership }) => {
  const formattedDate = format(new Date(leadClusterEmail.created_at), 'dd/MM/yyyy HH:mm')

  return (
    <>
      <div className={`${toDealership ? 'col-12 d-flex' : 'col-md-10 offset-md-2'} mt-3`}>
        {toDealership && (
          <div className="pt-1">
            <AvatarInitials
              name={`${leadCluster.contact.first_name} ${leadCluster.contact.last_name}`}
            />
          </div>
        )}
        <div
          className={`${toDealership ? 'from-them flex-fill' : 'from-me'} card`}
          id={`lead-cluster-email-${leadClusterEmail.id}`}
        >
          <div className="card-header d-flex justify-content-between">
            <b>{leadClusterEmail.subject}</b>
            <div className={`${toDealership ? 'text-secondary' : ''}`}>{formattedDate}</div>
          </div>
          <div className="card-body">
            <div className="text-secondary mb-2">{leadClusterEmail.from}</div>
            <div className="mb-0" dangerouslySetInnerHTML={{ __html: leadClusterEmail.body }} />
          </div>
        </div>
      </div>
    </>
  )
}

const EmailConversation = () => {
  const { notification } = useContext(LeadClusterContext)
  const { dealership } = useFetchDealership()
  const { owner } = ownerContext()
  let currentUser = useContext(CurrentUserContext)

  let [emails, setEmails] = useState([])
  let [fromEmails, setFromEmails] = useState([])
  let [fromEmail, setFromEmail] = useState('')
  let [loading, setLoading] = useState(false)
  let [emailTemplate, setEmailTemplate] = useState('')
  let [message, setMessage] = useState('')
  let [subject, setSubject] = useState('')

  useEffect(() => {
    const element = document.getElementById('email-conversation-scroll-container')
    element.scrollTop = element.scrollHeight
  }, [emails])

  useEffect(() => {
    fetchDealershipEmailAddresses()
  }, [dealership])

  useEffect(() => {
    var element = document.querySelector('trix-editor')
    element.editor.loadHTML(emailTemplate)
  }, [emailTemplate])

  useEffect(() => {
    fetchLeadClusterEmails()
    const intervalId = setInterval(() => {
      fetchLeadClusterEmails()
    }, 60_000)

    // Cleanup interval on component unmount or when dealershipId changes (component re-renders)
    return () => clearInterval(intervalId)
  }, [owner])

  const fetchDealershipEmailAddresses = async () => {
    try {
      const URL = Routes.dealership_dealership_email_addresses_path(dealership.id, {
        verified: true,
        dkim_verified: true,
        spf_verified: true,
      })
      const response = await fetch(URL, {
        headers: {
          Accept: 'application/json',
        },
      })
      const data = await response.json()
      let fromEmailAddresses = []
      let defaultEmailAddress
      data.forEach((dealershipEmailAddress) => {
        fromEmailAddresses.push(dealershipEmailAddress.email_address)
        if (dealershipEmailAddress.email_address == currentUser.email) {
          defaultEmailAddress = dealershipEmailAddress.email_address
        }
      })
      if (!defaultEmailAddress) {
        defaultEmailAddress = fromEmailAddresses[0]
      }
      setFromEmail(defaultEmailAddress)
      setFromEmails(fromEmailAddresses)
    } catch (error) {
      console.error('Error fetching dealership email addresses:', error)
      showToast(notification, 'error', 'Error fetching dealership email addresses.', '')
    }
  }

  const fetchLeadClusterEmails = async () => {
    try {
      const URL = Routes.lead_cluster_emails_path({ lead_cluster_id: owner.id })
      const response = await fetch(URL)
      const data = await response.json()
      setEmails(data)
    } catch (error) {
      console.error('Error fetching lead cluster emails:', error)
      showToast(notification, 'error', 'Error fetching lead cluster emails.', '')
    }
  }

  const onSubmit = () => {
    setLoading(true)
    fetch(Routes.send_to_lead_cluster_lead_cluster_emails_path(), {
      method: 'POST',
      headers: standardHeaders,
      body: JSON.stringify({
        from: fromEmail,
        lead_cluster_id: owner.id,
        body: message,
        subject,
      }),
    })
      .then(async (_response) => {
        setMessage('')
        setSubject('')
        await fetchLeadClusterEmails()
      })
      .catch((error) => {
        console.error('Error sending email:', error)
        showToast(notification, 'error', 'Error sending email.', '')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div className="mx-3 mt-2 mb-2 email-conversation">
      <div
        id="email-conversation-scroll-container"
        className="container border rounded mb-2 overflow-auto"
      >
        <div className="row mb-0 pb-3 bg-white">
          {emails
            .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
            .map((leadClusterEmail) => (
              <Message
                key={leadClusterEmail.id}
                leadCluster={owner}
                leadClusterEmail={leadClusterEmail}
                toDealership={leadClusterEmail.from == owner.contact.email}
              />
            ))}
        </div>
      </div>
      <div className="border rounded bg-white p-3">
        <div className="d-flex">
          <label className="pt-1 mr-2" style={{ minWidth: '50px' }}>
            From:
          </label>
          <select
            className="form-control form-control-sm"
            onChange={(e) => setFromEmail(e.target.value)}
            value={fromEmail}
          >
            {fromEmails.map((emailAddress) => (
              <option key={emailAddress} value={emailAddress}>
                {emailAddress}
              </option>
            ))}
          </select>
          <a
            href={Routes.dealership_dealership_email_addresses_path(dealership.id)}
            target="_blank"
            rel="noreferrer"
          >
            <div className="btn btn-group-vertical btn-primary ml-2">
              <i className="fas fa-cog"></i>
            </div>
          </a>
        </div>
        <hr className="mb-0 mt-1" />
        <div className="d-flex py-2">
          <label className="mr-2 mb-0 align-self-center" style={{ minWidth: '50px' }}>
            To:
          </label>
          <div className="align-self-center">{owner.contact.email}</div>
        </div>
        <hr className="my-0" />
        <input
          className="form-control border-0 p-0"
          onChange={(e) => setSubject(e.target.value)}
          placeholder="Subject"
          value={subject}
        />
        <hr className="mt-0" />
        <TrixEditor
          className="mb-2"
          placeholder="Type Your Message..."
          onChange={(html, _text) => setMessage(html)}
        />
        <button disabled={message === '' || loading} onClick={onSubmit} className="btn btn-primary">
          {loading ? 'Loading...' : 'Send'}
        </button>
      </div>
      <Templates setEmailTemplate={setEmailTemplate} setSubject={setSubject} />
    </div>
  )
}

export default EmailConversation
