import { generateRandomId } from '../../common/commonUtils'
import {
  getPrimaryLocation,
  getLocationType,
  getMapMarginValue,
  getErrorMessage,
} from '../templateUtils'
import { handleServiceForm } from './serviceUtils'

import { type WebsiteDetailsProps } from '../../types'

export function serviceTemplate(websiteDetails: WebsiteDetailsProps) {
  if (websiteDetails === undefined) {
    return getErrorMessage()
  }

  const primaryLocation = getPrimaryLocation(websiteDetails)

  const serviceForm = handleServiceForm(websiteDetails)

  return [
    {
      id: generateRandomId(10),
      type: 'imageBanner',
      data: {
        image: {
          type: 'default',
          existingCategory: 'default',
          existingFilters: {},
          image: {
            url: 'https://res.cloudinary.com/total-dealer/image/upload/v1644660263/td_next/service_ok7nd6',
          },
        },
        layout: {
          type: 'default',
          imageLayout: 'cover',
          customAspectRatio: {
            height: 1,
            width: 4,
          },
          customHeight: {
            mobileHeight: 250,
            desktopHeight: 450,
          },
        },
        overlay: {
          active: 'false',
          color: '#000000',
          opacity: 50,
        },
        content: {
          active: 'false',
          blocks: [
            {
              id: generateRandomId(10),
              type: 'header',
              data: {
                text: 'Image Banner',
                level: 2,
              },
              tunes: {
                alignment: {
                  alignment: 'center',
                },
                container: {
                  contain: true,
                },
                textColor: {
                  textColor: 'light',
                  customTextColor: '#000000',
                },
                margin: {
                  spacingTop: 5,
                  spacingBottom: 5,
                },
                padding: {
                  paddingTop: 0,
                  paddingBottom: 0,
                },
                classname: {
                  className: '',
                },
              },
            },
          ],
        },
      },
      tunes: {
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'header',
      data: {
        text: `Vehicle Service at ${websiteDetails.name}`,
        level: 1,
      },
      tunes: {
        alignment: {
          alignment: 'center',
        },
        container: {
          contain: false,
        },
        backgroundColor: {
          backgroundColor: 'light',
          customBackgroundColor: '#000000',
        },
        textColor: {
          textColor: 'none',
          customTextColor: '#000000',
        },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
        },
        padding: {
          paddingTop: 5,
          paddingBottom: 0,
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'header',
      data: {
        text: `${primaryLocation?.address?.city} ${primaryLocation?.address?.state}`,
        level: 2,
      },
      tunes: {
        alignment: {
          alignment: 'center',
        },
        container: {
          contain: false,
        },
        backgroundColor: {
          backgroundColor: 'light',
          customBackgroundColor: '#000000',
        },
        textColor: {
          textColor: 'secondary',
          customTextColor: '#000000',
        },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'horizontalRule',
      data: {
        usePageTitle: true,
        height: 1,
        borderRadius: 5,
        opacity: 1,
        color: { colorType: 'primary', customColor: '000000' },
      },
      tunes: {
        container: { contain: false },
        backgroundColor: {
          backgroundColor: 'light',
          customBackgroundColor: '#000000',
        },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
          mobile: { active: false, spacingTop: 0, spacingBottom: 0 },
          tablet: { active: false, spacingTop: 0, spacingBottom: 0 },
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
          mobile: { active: false, paddingTop: 0, paddingBottom: 0 },
          tablet: { active: false, paddingTop: 0, paddingBottom: 0 },
        },
        classname: { className: '' },
      },
    },
    serviceForm,
    {
      id: generateRandomId(10),
      type: 'paragraph',
      data: {
        text: `At ${websiteDetails.name}, we use state of the art technology and world class quality control systems
        to ensure your vehicle gives you many years of motoring pleasure. Rest assured that we will
        inspect, service and install updates to your vehicle with the highest professional standard
        ensuring your vehicle will have the best after sales care.`,
      },
      tunes: {
        alignment: {
          alignment: 'center',
        },
        container: {
          contain: true,
        },
        backgroundColor: {
          backgroundColor: 'none',
          customBackgroundColor: '#000000',
        },
        textColor: {
          textColor: 'none',
          customTextColor: '#000000',
        },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'paragraph',
      data: {
        text: `${websiteDetails.name} can handle all your servicing needs from manufacturers handbook servicing,
        tyres and brake repairs to batteries, and A/C servicing. We service all makes and models
        including Toyota, Mazda, Holden, Ford, Hyundai, Mitsubishi and more!`,
      },
      tunes: {
        alignment: {
          alignment: 'center',
        },
        container: {
          contain: true,
        },
        backgroundColor: {
          backgroundColor: 'none',
          customBackgroundColor: '#000000',
        },
        textColor: {
          textColor: 'none',
          customTextColor: '#000000',
        },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'paragraph',
      data: {
        text: `Just fill out the form below and one of our service representatives will contact you to
        confirm your booking.`,
      },
      tunes: {
        alignment: {
          alignment: 'center',
        },
        container: {
          contain: true,
        },
        backgroundColor: {
          backgroundColor: 'none',
          customBackgroundColor: '#000000',
        },
        textColor: {
          textColor: 'none',
          customTextColor: '#000000',
        },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'iconButtonsTool',
      data: {
        buttons: [
          {
            id: generateRandomId(10),
            icon: 'Service',
            text: 'Trained Technicians',
            href: '',
          },
          {
            id: generateRandomId(10),
            icon: 'Check',
            text: 'Genuine Parts',
            href: '',
          },
          {
            id: generateRandomId(10),
            icon: 'Finance',
            text: 'Competitive Pricing',
            href: '',
          },
          {
            id: generateRandomId(10),
            icon: 'Sell Your Car',
            text: 'Quality Without Compromise',
            href: '',
          },
        ],
      },
      tunes: {
        alignment: {
          alignment: 'center',
        },
        container: {
          contain: false,
        },
        margin: {
          spacingTop: 3,
          spacingBottom: 3,
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'locations',
      data: {
        filter: getLocationType(['service', 'primary'], websiteDetails.locations),
        manufacturer: false,
        subtype: false,
        filteredLocations: [],
        primaryLocationId: primaryLocation?.id,
      },
      tunes: {
        margin: {
          spacingTop: getMapMarginValue(['service', 'primary'], websiteDetails.locations),
          spacingBottom: getMapMarginValue(['service', 'primary'], websiteDetails.locations),
        },
        classname: {
          className: '',
        },
      },
    },
  ]
}
