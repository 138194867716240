import { useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { FilterMatchMode } from 'primereact/api'
import DataTableSearch from '../entries/DataTableSearch'
import ItemStatus from './ItemStatus'
import { standardHeaders } from '../entries/utils'
import AuditTrailSlideIn from '../shared/AuditTrailSlideIn'
import { Tooltip } from 'primereact/tooltip'
import { Dialog } from 'primereact/dialog'
import { Input } from '../entries/FormElements'
import { useParams } from 'react-router-dom'
import { humanize } from '../entries/utils'

const LocationImage = (location) => {
  if (!location.image_url) return null

  return (
    <img
      src={location.image_url}
      width={100}
      className="border rounded"
      style={{ objectFit: 'cover' }}
    />
  )
}

const LocationName = (location) => {
  return (
    <>
      <div>{location.name}</div>
      {location.primary && <span className="badge badge-primary">Primary</span>}&nbsp;
      {location.external && (
        <a
          href={location.external_link}
          title="Location mapped from external dealership"
          className="badge badge-danger mr-1"
        >
          External
        </a>
      )}
    </>
  )
}

const Dropdown = (location) => {
  const isPrimary = location.primary
  const [showSlideIn, setShowSlideIn] = useState(false)
  const handleHideSlideIn = () => {
    setShowSlideIn(false)
  }

  // Function to handle setting the primary location
  const handleSetPrimary = (e) => {
    e.preventDefault()
    // Implement the POST request logic here
    // This might involve calling an API endpoint and then possibly refreshing the page or state

    fetch(location.set_primary_url, {
      method: 'POST',
      headers: standardHeaders,
    })
      .then((response) => {
        if (response.ok) {
          // refresh the page
          window.location.reload()
        }
      })
      .catch((error) => {
        console.error('There was an error!', error)
      })
  }

  const handleDelete = (e) => {
    e.preventDefault()
    // Confirm before deletion
    if (window.confirm('Are you sure?')) {
      // Implement the DELETE request logic here

      fetch(`/dealerships/${location.dealership_id}/locations/${location.id}`, {
        method: 'DELETE',
        headers: standardHeaders,
      })
        .then((response) => {
          if (response.ok) {
            window.location.reload()
          }
        })
        .catch((error) => {
          console.error('There was an error!', error)
        })
    }
  }

  const handleVisibility = (e) => {
    e.preventDefault()
    fetch(location.toggle_visibility_url, {
      method: 'PUT',
      headers: standardHeaders,
    })
      .then((response) => {
        if (response.ok) {
          // refresh the page
          window.location.reload()
        }
      })
      .catch((error) => {
        console.error('There was an error!', error)
      })
  }

  return (
    <div className="dropdown">
      <button className="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown">
        Action
      </button>
      <div className="dropdown-menu dropdown-menu-right">
        {!isPrimary && (
          <a href="#" onClick={handleSetPrimary} className="dropdown-item">
            <i className="fa fa-star mr-2"></i>
            Set Primary
          </a>
        )}
        <a
          href={`/dealerships/${location.dealership_id}/locations/${location.id}/edit`}
          className="dropdown-item"
        >
          <i className="fa fa-edit mr-2"></i>
          Edit
        </a>
        <a onClick={setShowSlideIn} className="dropdown-item">
          <i className="fa fa-history mr-2"></i>
          Audit Trail
        </a>
        {showSlideIn && (
          <AuditTrailSlideIn
            AuditTrailObject={location}
            AuditTrailObjectName="Location"
            Url={`/dealerships/${location.dealership_id}/locations/${location.id}/audit_trail`}
            ShowSlideIn={showSlideIn}
            onHide={handleHideSlideIn}
          />
        )}
        <a href="#" onClick={handleVisibility} className="dropdown-item">
          <i className={`fas fa-eye${location.visible ? '-slash' : ''} mr-2`}></i>
          {location.visible ? 'Hide' : 'Show'}
        </a>
        {!isPrimary && (
          <a href="#" onClick={handleDelete} className="dropdown-item">
            <i className="fa fa-trash mr-2"></i>
            Destroy
          </a>
        )}
      </div>
    </div>
  )
}

const EditDialog = ({ location, visible, setVisible, setLoadtion }) => {
  let [loading, setLoading] = useState(false)
  let [dealerLicenseNumber, setDealerLicenseNumber] = useState(location.dealer_license_number)

  const handleSave = () => {
    setLoading(true)
    fetch(`/dealerships/${location.dealership_id}/locations/${location.id}`, {
      method: 'PUT',
      headers: standardHeaders,
      body: JSON.stringify({ dealer_license_number: dealerLicenseNumber }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
      })
      .then((data) => {
        setLoadtion(data)
        setVisible(false)
      })
      .catch((error) => {
        console.error('There was an error!', error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Dialog
      visible={visible}
      style={{ width: '50vw' }}
      onHide={() => {
        setVisible(false)
      }}
      header="Dealer License Number"
      dismissableMask={true}
    >
      <Input
        value={dealerLicenseNumber}
        label="Dealer License No"
        onChange={(e) => setDealerLicenseNumber(e.target.value)}
      />
      <div
        className={'btn btn-success' + (loading ? ' disabled' : '')}
        onClick={handleSave}
        disabled={loading}
      >
        {loading ? (
          'Saving...'
        ) : (
          <>
            <i className="fa fa-check mr-1"></i>
            Save
          </>
        )}
      </div>
    </Dialog>
  )
}

const DealerLicense = (currentLocation) => {
  let [visible, setVisible] = useState(false)
  let [location, setLoadtion] = useState(currentLocation)

  let blankDealerLicenseNumber =
    !location.dealer_license_number || location.dealer_license_number === ''

  let content = blankDealerLicenseNumber ? (
    <div onClick={() => setVisible(true)} style={{ cursor: 'pointer' }}>
      <i
        className="fa fa-exclamation-circle text-danger mr-1 dealer-license-no"
        data-pr-position="bottom"
      />
      <Tooltip target=".dealer-license-no" content="Missing Dealer License no" />
    </div>
  ) : (
    <div onClick={() => setVisible(true)} style={{ cursor: 'pointer' }}>
      <i className="fa fa-check-circle text-success mr-1"></i>
      <div className="text-secondary small">{location.dealer_license_number}</div>
    </div>
  )

  return (
    <>
      {content}
      <EditDialog
        location={location}
        visible={visible}
        setVisible={setVisible}
        setLoadtion={setLoadtion}
      />
    </>
  )
}

const App = ({ locations, loading }) => {
  let { dealershipSlug } = useParams()
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  })

  const header = DataTableSearch({ filters: filters, setFilters: setFilters })

  return (
    <div className="p-2">
      <div className="clearfix mb-2">
        <div className="float-right">
          <a href={`/dealerships/${dealershipSlug}/locations/new`} className="btn btn-primary">
            New Location
          </a>
        </div>
      </div>
      <div className="box">
        <DataTable
          value={locations}
          tableStyle={{ minWidth: '50rem' }}
          removableSort
          sortMode="multiple"
          scrollable
          filters={filters}
          header={header}
          loading={loading}
          globalFilterFields={['name', 'location_codes', 'address']}
        >
          <Column sortable field="id" header="Location ID" />
          <Column sortable field="location_codes" header="Location Codes" />
          <Column body={LocationImage} field="image_url" header="Image" />
          <Column sortable body={LocationName} field="name" header="Name" />
          <Column sortable field="address" header="Address" />
          <Column sortable field="cars" header="Cars" />
          <Column
            sortable
            field="location_type"
            header="Type"
            body={({ location_type }) => <>{humanize(location_type)}</>}
          />
          <Column sortable field="visible" body={ItemStatus} header="Visibility" />
          <Column
            sortable
            field="dealer_license_number"
            header="Dealer License No"
            body={DealerLicense}
          />
          <Column body={Dropdown} header="Action" />
        </DataTable>
      </div>
    </div>
  )
}

const Wrapper = () => {
  let [locations, setLocations] = useState()
  let [loading, setLoading] = useState(true)
  let { dealershipSlug, websiteSlug } = useParams()

  useEffect(() => {
    let url = `/dealerships/${dealershipSlug}/locations.json`

    if (websiteSlug) {
      url = `/dealerships/${dealershipSlug}/websites/${websiteSlug}/locations.json`
    }

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setLocations(data)
        setLoading(false)
      })
  }, [])

  return <App locations={locations} loading={loading} />
}

export default Wrapper
