import { instantMeiliSearch } from '@meilisearch/instant-meilisearch'
import { history } from 'instantsearch.js/es/lib/routers'
import { Toast } from 'primereact/toast'
// MANAGED BY App.js
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Configure,
  HierarchicalMenu,
  InstantSearch,
  Pagination,
  RefinementList,
  SortBy,
  Stats,
  useInstantSearch,
} from 'react-instantsearch'
import { Link, useParams } from 'react-router-dom'

import { useFetchDealership } from '../dataHooks'
import { getMeilisearchConfig, humanize } from '../entries/utils'
import Loading from '../Loading'
import AgeRefinement from './AgeRefinement'
import Autograb from './Autograb'
import ClearRefinements from './ClearRefinements'
import ConditionalRefinementList from './ConditionalRefinementList'
import { indexName, sortingOptions } from './config'
import CustomHits from './CustomHits'
import LeadsView from './LeadsView'
import MobileFilters from './MobileFilters'
import ToggleRefinement from './ToggleRefinement'
import SearchPanel from './SearchPanel'
import CustomSearchBox from './CustomSearchBox'
import { SelectedHitsWrapper, MultiSelectDropdown, SelectAllHits } from './PrintHits'

const savedToggle = JSON.parse(localStorage.getItem(`toggle_${window.dealer_slug}`)) || {}
// Could not use DealershipContext here as it should be used in the App component
const savedRefinements =
  JSON.parse(localStorage.getItem(`refinementList_${window.dealer_slug}`)) || {}
const savedHierarchicalMenu =
  JSON.parse(localStorage.getItem(`hierarchicalMenu_${window.dealer_slug}`)) || {}
const savedSortBy =
  localStorage.getItem(`sortBy_${window.dealer_slug}`) || `${indexName}:custom_rank:desc`
const defaultStatus = ['in_stock', 'coming_soon', 'loaner']

const ToggleRefinements = () => {
  let [showAll, setShowAll] = useState(false)

  return (
    <div className="search-panel">
      <div className="d-flex">
        <h5>Filters</h5>
        <div className="ml-auto small" onClick={() => setShowAll(!showAll)}>
          {showAll ? 'Show Less' : 'Show More'}
        </div>
      </div>
      <ToggleRefinement attribute="show_on_dealer_website" label="Load to Website" on={true} />
      {showAll && (
        <ToggleRefinement attribute="show_on_dealer_website" label="NOT on Website" on={false} />
      )}
      <ToggleRefinement attribute="load_to_autotrader" label="Load to Autotrader" on={true} />
      {showAll && (
        <ToggleRefinement attribute="load_to_autotrader" label="NOT on Autotrader" on={false} />
      )}
      <ToggleRefinement attribute="load_to_autogate" label="Load to Autogate" on={true} />
      {showAll && (
        <ToggleRefinement attribute="load_to_autogate" label="NOT on Autogate" on={false} />
      )}
      <ToggleRefinement
        attribute="load_to_facebook"
        label="Load to Facebook Marketplace"
        on={true}
      />
      <ToggleRefinement attribute="needs_description" label="Needs Dealer Comments?" on={true} />
      <ToggleRefinement attribute="restrict_third_party" label="Stock Restrictions" on={true} />
      <ToggleRefinement attribute="has_redbook" label="Missing Redbook" on={false} />
      <ToggleRefinement attribute="price" label="Missing Price" on={0} />
      <ToggleRefinement attribute="needs_photos" label="No Photos" on={true} />
      <ToggleRefinement attribute="total_image_count" label="No Photos OR stock photos" on={0} />
      <ToggleRefinement attribute="reserved" label="Reserved" on={true} />
      <ToggleRefinement attribute="preorder" label="Preorder" on="true" />
      <ToggleRefinement attribute="certified" label="Certified" on="true" />
    </div>
  )
}

const routing = {
  router: history(),
  stateMapping: {
    stateToRoute(uiState) {
      const indexUiState = uiState[savedSortBy]
      const refinementList = indexUiState?.refinementList || {}
      const refinementListString = JSON.stringify(refinementList)
      localStorage.setItem(`refinementList_${window.dealer_slug}`, refinementListString)
      const hierarchicalMenu = indexUiState.hierarchicalMenu || {}
      const hierarchicalMenuString = JSON.stringify(hierarchicalMenu)
      localStorage.setItem(`hierarchicalMenu_${window.dealer_slug}`, hierarchicalMenuString)
      const sortBy = indexUiState.sortBy || savedSortBy
      localStorage.setItem(`sortBy_${window.dealer_slug}`, sortBy)
      const savedToggle = JSON.parse(localStorage.getItem(`toggle_${window.dealer_slug}`)) || {}

      return {
        q: indexUiState.query,
        page: indexUiState.page,
        hierarchicalMenu: indexUiState?.hierarchicalMenu || savedHierarchicalMenu,
        toggle: savedToggle || indexUiState.toggle,
        refinements: indexUiState?.refinementList || savedRefinements,
      }
    },
    routeToState(routeState) {
      return {
        [savedSortBy]: {
          query: routeState.q,
          page: routeState.page,
          refinementList: {
            ...routeState.refinements,
            ...(Object.keys(savedRefinements).length !== 0 ? savedRefinements : {}),
            status:
              (Object.keys(savedRefinements).length !== 0
                ? savedRefinements.status
                : routeState?.refinementList?.status) || defaultStatus,
          },
          hierarchicalMenu: savedHierarchicalMenu || routeState.hierarchicalMenu,
          toggle: savedToggle || routeState.toggle,
        },
      }
    },
  },
}

const AddCarBtn = ({ className = 'btn btn-outline-success ml-2' }) => {
  let { dealershipSlug } = useParams()

  return (
    <Link to={`/dealerships/${dealershipSlug}/cars/new`} className={className}>
      <i className="fa fa-plus mr-2" />
      Car
    </Link>
  )
}

function LoadingIndicator() {
  const { status } = useInstantSearch()

  if (status === 'stalled') {
    return (
      <div className="py-3 mb-0 text-secondary">
        <Loading />
      </div>
    )
  }
  return null
}

const ReindexLink = ({ dealership }) => {
  let reindexLink = `/dealerships/${dealership?.slug}/cars/reindex_cars`
  return (
    <a href={reindexLink} className="dropdown-item">
      Reindex Cars
    </a>
  )
}

const App = ({ searchFilters, hasAutograb, carSwitches, beta, dealership }) => {
  const [autograb, setAutograb] = useState()
  const [leadsView, setLeadsView] = useState()
  const [recentlyUpdatedCars, setRecentlyUpdatedCars] = useState([])
  const notification = useRef(null)
  let { dealershipSlug } = useParams()

  const { searchClient, filters, index } = useMemo(() => {
    const { meilisearchHost, meilisearchApiKey } = getMeilisearchConfig()
    const index =
      localStorage.getItem(`sortBy_${window.dealer_slug}`) || `${indexName}:custom_rank:desc`
    let filters = searchFilters
    const { searchClient } = instantMeiliSearch(meilisearchHost, meilisearchApiKey)

    return { searchClient, filters, index }
  }, [indexName]) // Add any other dependencies if they exist

  // The recentlyUpdatedCars is our solution to the potential delay between postgres being updated
  // And meilisearch being updated (which can under particular circumstances take a minute or two)
  // The server sends down any cars updated in the last 10 minutes, so if a user refreshes the page
  // prior to meilisearch being updated the correct data will be shown
  const addRecentlyUpdatedCar = (car) => {
    setRecentlyUpdatedCars([...recentlyUpdatedCars.filter((c) => c.id !== car.id), car])
  }

  useEffect(() => {
    fetch(`/dealerships/${dealershipSlug}/cars/recently_updated_cars.json`)
      .then((res) => res.json())
      .then((res) => setRecentlyUpdatedCars(res.recentlyUpdatedCars))
  }, [])

  // useCallback was introduced to prevent component re-render issues
  // when setSelectedHits or setAutograb are called
  const humanizeTransformItems = useCallback((items) => {
    return items.map((item) => ({
      ...item,
      label: humanize(item.label),
    }))
  }, [])

  return (
    <div className=" bg-light container-fluid pt-3">
      <InstantSearch
        indexName={index}
        searchClient={searchClient}
        routing={routing}
        initialUiState={{
          [`${index}`]: {
            refinementList: {
              status: ['in_stock', 'coming_soon', 'loaner'],
            },
            sortBy: `${indexName}:stocknum:asc`,
          },
        }}
      >
        <SelectedHitsWrapper>
          <Toast ref={notification} />
          <Configure filters={filters} hitsPerPage={40} />
          <div className="row no-gutters">
            <div className="w-100 col-xs-12 d-block d-md-none">
              <MobileFilters humanizeTransformItems={humanizeTransformItems} />
            </div>
            <div className="col-md-3 d-none d-lg-block">
              <div className="rounded border bg-white mr-3 mb-3">
                <div className="search-panel">
                  <h5>Search</h5>
                  <CustomSearchBox placeholder="Stock number, Make, model..." />
                </div>
                <div className="p-2">
                  <ClearRefinements
                    translations={{
                      resetButtonText: 'Clear Filters',
                    }}
                    excludedAttributes={['status']}
                  />
                </div>
                <div className="search-panel">
                  <h5>Sorting</h5>
                  <SortBy items={sortingOptions} />
                </div>
                <SearchPanel title="Status">
                  <RefinementList attribute="status" transformItems={humanizeTransformItems} />
                </SearchPanel>
                <SearchPanel title="Age">
                  <AgeRefinement attribute="created_at_timestamp" />
                </SearchPanel>
                <ConditionalRefinementList attribute="dms_status" title="DMS Status" />
                <SearchPanel title="Item Type">
                  <RefinementList attribute="item_type" transformItems={humanizeTransformItems} />
                </SearchPanel>
                <SearchPanel title="Status">
                  <RefinementList attribute="car_type" transformItems={humanizeTransformItems} />
                </SearchPanel>
                <SearchPanel title="Location Name">
                  <RefinementList attribute="location_name" showMore={true} showMoreLimit={100} />
                </SearchPanel>
                <SearchPanel title="Colour">
                  <RefinementList attribute="colour" showMore={true} showMoreLimit={100} />
                </SearchPanel>
                <SearchPanel title="Make/Model">
                  <HierarchicalMenu
                    attributes={['categories.lvl0', 'categories.lvl1', 'categories.lvl2']}
                    sortBy={['isRefined']}
                    limit={500}
                  />
                </SearchPanel>
                <ConditionalRefinementList attribute="carsales_account" title="Carsales Account" />
                <SearchPanel title="Source">
                  <RefinementList
                    attribute="source"
                    transformItems={(items) =>
                      items.map((item) => ({
                        ...item,
                        label: humanize(item.label),
                      }))
                    }
                  />
                </SearchPanel>
                <SearchPanel title="Body">
                  <RefinementList attribute="simple_body" showMore={true} showMoreLimit={100} />
                </SearchPanel>
                <SearchPanel title="City">
                  <RefinementList attribute="city" showMore={true} />
                </SearchPanel>
                {!dealership?.slug && (
                  <SearchPanel title="Dealership">
                    <RefinementList attribute="dealership_name" showMore={true} />
                  </SearchPanel>
                )}
                <SearchPanel title="Fuel Source">
                  <RefinementList attribute="simple_fuel" showMore={true} />
                </SearchPanel>
                <SearchPanel title="Tag">
                  <RefinementList attribute="tag" showMore={true} />
                </SearchPanel>
                <SearchPanel title="Categories">
                  <RefinementList attribute="categories" showMore={true} />
                </SearchPanel>
                {hasAutograb && (
                  <SearchPanel title="Car Grade">
                    <RefinementList attribute="car_grade" />
                  </SearchPanel>
                )}
                <ToggleRefinements />
                <SearchPanel title="360 Spin">
                  <h5></h5>
                  <RefinementList attribute="has_spin" />
                </SearchPanel>
              </div>
            </div>
            {hasAutograb && <Autograb autograb={autograb} setAutograb={setAutograb} />}
            <LeadsView leadsView={leadsView} setLeadsView={setLeadsView} />
            <div className="col-md-12 col-lg-9">
              <div className="pb-3">
                <div className="box p-3 d-flex justify-content-between align-items-center">
                  <div className="small text-secondary d-md-flex align-items-center d-none">
                    <div>
                      <Stats />
                      <SelectAllHits />
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="d-none d-md-block">
                      <MultiSelectDropdown
                        notification={notification}
                        setRecentlyUpdatedCars={setRecentlyUpdatedCars}
                      />
                    </div>
                    <div className="d-none d-md-block">
                      <div className="btn-group btn-block">
                        <AddCarBtn dealership={dealership} />
                        <button
                          id="btnGroupDrop1"
                          type="button"
                          className="btn btn-outline-success dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        ></button>
                        <div
                          className="dropdown-menu dropdown-menu-right"
                          aria-labelledby="btnGroupDrop1"
                        >
                          <Link
                            to={`/dealerships/${dealership?.slug}/cars/reports`}
                            className="dropdown-item"
                          >
                            Reports
                          </Link>
                          <ReindexLink dealership={dealership} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-md-none w-100">
                    <AddCarBtn className="btn btn-block btn-success" />
                  </div>
                </div>
                <LoadingIndicator />
                <CustomHits
                  setAutograb={setAutograb}
                  beta={beta}
                  hasAutograb={hasAutograb}
                  recentlyUpdatedCars={recentlyUpdatedCars}
                  carSwitches={carSwitches}
                  setLeadsView={setLeadsView}
                  addRecentlyUpdatedCar={addRecentlyUpdatedCar}
                />
                <Pagination />
              </div>
            </div>
          </div>
        </SelectedHitsWrapper>
      </InstantSearch>
    </div>
  )
}

const Wrapper = () => {
  let [params, setParams] = useState(false)
  const { dealership } = useFetchDealership()
  const { dealershipSlug } = useParams()

  useEffect(() => {
    if (params) return

    fetch(`/dealerships/${dealershipSlug || dealership?.slug}/cars/react_index_params`)
      .then((res) => res.json())
      .then((data) => {
        setParams(data)
      })
  }, [])

  if (!params) {
    return (
      <div className="p-3 mb-0 text-secondary">
        <Loading />
      </div>
    )
  }

  return <App {...params} dealership={dealership} carSwitches={params.car_switches} />
}

export default Wrapper
