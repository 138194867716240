import { format } from 'date-fns'
import { toZonedTime } from 'date-fns-tz'

const FormattedDateTime = ({ timestamp }) => {
  let { formattedDate, formattedTime } = UserDateTime({ timestamp })

  return (
    <div>
      <p>
        <span className="text-secondary">{formattedDate}</span>{' '}
        <span className="text-secondary">{formattedTime}</span>
      </p>
    </div>
  )
}

export const UserDateTime = ({ timestamp, currentUser = null }) => {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  // Convert UTC to the user's timezone
  const zonedDate = toZonedTime(new Date(timestamp), userTimezone)

  // Format date and time
  const formattedDate = format(zonedDate, 'dd/MM/yyyy')
  const formattedTime = currentUser?.twenty_four_hour_clock
    ? format(zonedDate, 'HH:mm') // 24-hour format
    : format(zonedDate, 'hh:mm a') // 12-hour format

  const formatedDateTime = `${formattedDate} ${formattedTime}`

  return {
    formattedDate,
    formattedTime,
    formatedDateTime,
  }
}

export default FormattedDateTime
