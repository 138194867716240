import { useState, useEffect } from 'react'
import Loading from '../Loading'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useParams, Link } from 'react-router-dom'
import moment from 'moment'
import { humanize } from '../entries/utils'

const CreatedAt = ({ created_at }) => {
  return <span>{moment(created_at).fromNow()}</span>
}

const Car = ({ car }) => {
  return (
    <div>
      <Link to={`/cars/${car.slug}`}>{car.name}</Link>
    </div>
  )
}

const Lead = ({ lead }) => {
  let { dealershipSlug } = useParams()
  return (
    <div>
      <Link to={`/dealerships/${dealershipSlug}/lead_clusters/${lead.lead_cluster_id}`}>
        {lead.name}
      </Link>
    </div>
  )
}

const Actions = (rowData) => {
  let { dealershipSlug } = useParams()
  return (
    <div>
      <a href={`/dealerships/${dealershipSlug}/payments/${rowData.id}`}>View</a>
    </div>
  )
}

const Amount = ({ amount }) => {
  return (
    <span>
      {amount.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}
    </span>
  )
}

const CarStatus = ({ car }) => {
  let className = 'text text-success'
  if (car.status === 'sold') {
    className = 'text text-danger'
  }

  return <span className={className}>{humanize(car.status)}</span>
}

const Payments = () => {
  let [payments, setPayments] = useState([])
  let [loading, setLoading] = useState(true)
  let [totalCount, setTotalCount] = useState(0)
  let { dealershipSlug } = useParams()
  const [page, setPage] = useState(1)
  const rows = 25

  useEffect(() => {
    setLoading(true)
    fetch(`/dealerships/${dealershipSlug}/payments.json?page=${page}&per_page=${rows}`)
      .then((response) => response.json())
      .then((data) => {
        setPayments(data.payments)
        setTotalCount(data.total_count)
        setLoading(false)
      })
  }, [page])

  if (loading) {
    return (
      <div className="p-3">
        <Loading />
      </div>
    )
  }

  const onPageChange = (event) => {
    setPage(event.page + 1) // PrimeReact pages are 0-based; Kaminari pages are 1-based
  }

  return (
    <div className="p-3">
      <h3>Payments</h3>
      <div className="box">
        <DataTable
          value={payments}
          paginator
          rows={rows}
          first={(page - 1) * rows}
          totalRecords={totalCount}
          onPage={onPageChange}
          loading={loading}
          lazy={true}
        >
          <Column field="created_at" header="Created At" body={CreatedAt} />
          <Column field="amount" header="Amount" body={Amount} />
          <Column header="Lead" body={Lead} />
          <Column header="Car" body={Car} />
          <Column field="car.status" header="Car Status" body={CarStatus} />
          <Column field="car.reserved" header="Car Reserved" />
          <Column body={Actions} />
        </DataTable>
      </div>
    </div>
  )
}

export default Payments
