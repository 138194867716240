import * as Routes from '../../routes'
export const PhoneCallsService = {
  getPhoneCalls(params, csrf, dealershipId) {
    const queryParams = params
      ? Object.keys(params)
          .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
          .join('&')
      : ''
    let phoneCallsRoute = ''

    phoneCallsRoute = Routes.dealership_phone_calls_path(dealershipId)

    return fetch(`${phoneCallsRoute}.json?${queryParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf,
      },
      credentials: 'same-origin',
    }).then((res) => res.json())
  },
}
